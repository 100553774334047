import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import Header from './components/Header';
import SideMenu from './components/SideMenu';

import Home from '../../screens/Home';
import { MainContentWrapper } from '../../components/layout';
import { useRedirectToHome } from '../../hooks/useRedirectToHome';
import JoinOrganization from '../../screens/JoinOrganization';
import { orgSelector } from '../../store/organization/organization.selectors';
import { authSelector } from '../../store/auth/auth.selectors';
// import gtmTrack from '../../services/googleTagManager/track'

const MainRoutes = () => {
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const org = useSelector(orgSelector);
  const user = useSelector(authSelector);

  const userOrg = useMemo(() => {
    return user?.organizations?.find((o) => parseInt(o.id, 10) === org.id);
  }, [user?.organizations, org]);

  useRedirectToHome({ userOrg });
  const handleClick = (open) => {
    setIsSideMenuOpen(open);
    // gtmTrack('opening_side_menu', { curr_organization_id: activeOrgId })
  };

  return (
    <>
      <ToastContainer position={toast.POSITION.BOTTOM_LEFT} />
      <SideMenu isOpen={isSideMenuOpen} onClose={() => setIsSideMenuOpen(false)} />
      <Header onHamburgerClick={() => handleClick(true)} />
      <MainContentWrapper>
        <Switch>
          <Route path={['/bites', '/playlists']} component={Home} />
          <Route path='/join-organization' component={JoinOrganization} />
        </Switch>
      </MainContentWrapper>
    </>
  );
};

export default MainRoutes;

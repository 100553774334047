import styled, { css } from 'styled-components';

import { getIsRtl } from '../../../locale/i18n';

export const BaseTextArea = styled.textarea`
  text-align: ${() => (getIsRtl() ? 'right' : 'left')};
  font-size: 16px;
  line-height: 20px;
  ${({ theme }) => css`
    color: ${theme.colors.darkPrimary};
    font-family: ${theme.fontFamilies.Arimo};
  `};
`;

const TextArea = styled(BaseTextArea)`
  width: 100%;
  border-radius: 15px;
  padding: 20px 25px;
  outline: none;
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
  `};
  box-shadow: 0 12px 19px 0 rgba(60, 128, 209, 0.09);
  writing-mode: horizontal-tb;
`;

export default TextArea;

import { RootState } from '..';
import { Features } from '../../utils/featureFlag/featureFlag.types';

export const organizationSelector = (state: RootState) => state.profile.organization;
export const organizationLoadingSelector = (state: RootState) => state.profile.isLoading;

export const organizationHasFeatureFlagSelector =
  ({ feature, orgId }: { feature: Features; orgId: number }) =>
  (state: RootState) => {
    return state.profile.featureFlagsByOrgId[orgId]?.[feature] || false;
  };

export const featureFlagsByOrgIdSelector = (state: RootState) => state.profile.featureFlagsByOrgId;

import { all, put, select, takeLatest } from 'redux-saga/effects';

import { getSettings, updateUserDefaultOrganization } from './../api/calls/organization.calls';
import {
  getOrgSettings,
  getOrgSettingsFail,
  getOrgSettingsSuccess,
  setBranding,
  updateDefaultOrg,
} from './organization.slice';
import { authSelector } from '../auth/auth.selectors';
import { gtmSetUserProps } from '../../services/googleTagManager/track';
import { setShowSomethingWentWrong } from '../appActivity/appActivity.slice';
import { logError } from '../tracking/tracking.slice';
import { EShowSomethingWentWrongError } from '../appActivity/appActivity.types';

function* getOrgSettingsSaga(action) {
  try {
    const { data } = yield getSettings(action.payload);
    const user = yield select(authSelector);
    const userOrg = user?.organizations.find(({ id }) => parseInt(id, 10) === parseInt(data.org, 10));
    if (userOrg) {
      yield put(setBranding(userOrg.branding));
    }
    yield put(getOrgSettingsSuccess(data));
  } catch (error) {
    yield put(logError({ event: 'getOrgSettingsSaga: error', data: { error } }));
    yield put(setShowSomethingWentWrong({ type: EShowSomethingWentWrongError.DEFAULT }));
    yield put(getOrgSettingsFail());
  }
}

function* updateDefaultOrganization(action: any) {
  const org = action.payload;
  const body = { is_default: true };
  const user = yield select(authSelector);
  try {
    gtmSetUserProps(
      user?.id,
      {
        email: user?.email,
        first_name: user?.full_name,
        last_name: user?.last_name,
        user_orgs: user ? ',' + user.organizations.map(({ id }) => id).join(',') + ',' : '',
        last_org_id: `${org.id}`,
        last_org_name: org.name,
      },
      { user },
    );
    yield updateUserDefaultOrganization(org.user_organization_id, body);
  } catch (error) {
    yield put(logError({ event: 'updateDefaultOrganization: error', data: { error } }));
  }
}

export default function* organizationSaga() {
  yield all([takeLatest(getOrgSettings, getOrgSettingsSaga)]);
  yield all([takeLatest(updateDefaultOrg, updateDefaultOrganization)]);
}

import React, { useCallback, useMemo } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as List } from 'react-window';

import { IComment } from '../../../../types/bite';
import useCommentList from '../hooks/useCommentList';
import CommentsRowRender from './CommentsRowRender';

interface IProps {
  comments: IComment[];
  createReplyOnComment: any;
}

const CommentsList: React.FC<IProps> = ({ comments, createReplyOnComment }) => {
  const { flattenedComments, listRef, setSize, getSize } = useCommentList(comments);

  const itemData = useMemo(
    () => ({ comments: flattenedComments, createReplyOnComment, setSize }),
    [flattenedComments, createReplyOnComment, setSize],
  );

  const CommentsRowRenderCallback = useCallback(
    ({ height, width }) => (
      <List
        ref={listRef}
        height={height}
        itemCount={flattenedComments.length}
        width={width}
        itemData={itemData}
        itemSize={getSize}
      >
        {CommentsRowRender}
      </List>
    ),
    [flattenedComments.length, getSize, itemData, listRef],
  );

  return <AutoSizer>{CommentsRowRenderCallback}</AutoSizer>;
};

export default CommentsList;

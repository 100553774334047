import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const useCustomTranslation = (customPrefix: string) => {
  const { t } = useTranslation();

  const prefixedT = useCallback((path: string) => t(`${customPrefix}.${path}`), [customPrefix, t]);

  return { t, prefixedT };
};

export default useCustomTranslation;

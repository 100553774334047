import axios from 'axios';
import BitesApi, { API_BASE_URL } from '../';
import { IUserOrganizations } from '../../auth/auth.types';

export const aiKnowledgeRequest = (orgId: IUserOrganizations['id'], prompt: string) =>
  axios.post(
    `${API_BASE_URL}/api/common_services/knowledge/bites/prompt`,
    {
      orgId,
      prompt,
    },
    {
      headers: {
        Authorization: BitesApi.defaults.headers.common.Authorization,
      },
    },
  );

export const aiKnowledgeHistoryRequest = ({
  filters,
  fields,
  pageSize,
  page = 0,
}: {
  filters: { orgId: IUserOrganizations['id']; userId: string };
  fields: { historyItem: string[] };
  pageSize: number;
  page: number;
}) => {
  return axios.post(
    `${API_BASE_URL}/api/common_services/knowledge/bites/prompt/history`,
    { filters, fields, pageSize, page },
    {
      headers: {
        Authorization: BitesApi.defaults.headers.common.Authorization,
      },
    },
  );
};

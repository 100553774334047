import { useState, useEffect, useCallback } from 'react';

const getOrientation = (): OrientationType => window.screen?.orientation?.type;

const IS_LANDSCAPE = {
  'landscape-primary': true,
  'landscape-secondary': true,
  'portrait-primary': false,
  'portrait-secondary': false,
};

const useScreenOrientation = () => {
  const [orientation, setOrientation] = useState(getOrientation());

  const updateOrientation = useCallback((event) => {
    event.preventDefault();
    setOrientation(getOrientation());
  }, []);

  useEffect(() => {
    window.addEventListener('orientationchange', updateOrientation);
    return () => {
      window.removeEventListener('orientationchange', updateOrientation);
    };
  }, [updateOrientation]);

  return { orientation, isScreenLandscape: IS_LANDSCAPE[orientation] };
};

export default useScreenOrientation;

const useGetVideoPlayerSize = () => {
  const getVideoPlayerSize = (isFullScreen, videoWidth, videoHeight, isYoutube) => {
    if (isFullScreen) {
      return {
        width: '100%',
        height: '100%',
      };
    }

    if (videoWidth / videoHeight < 4 / 5) {
      return {
        height: 'auto !important',
        width: '100% !important',
      };
    }

    const height = `${((Math.min(window.innerWidth, 500) - 60) * 5) / 4}px !important`;
    const width = isYoutube ? '100% !important' : 'auto !important';

    return {
      width,
      height,
    };
  };

  return { getVideoPlayerSize };
};

export default useGetVideoPlayerSize;

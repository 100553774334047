import axios from 'axios';
import { APP } from '../../constants/app';
import { getDevelopmentBackendUrl, isProd } from '../../utils/env';
import { acrossSessionsId, sessionId } from '../tracking/tracking.constants';
import { withInterceptors } from './interceptors';

export const API_BASE_URL = getApiBaseUrl();
const API_KEY = '02dff1d6617b476dbe55970fdd5f5219';

const API_URL = `${API_BASE_URL}/api`;

export const defaultHeaders = {
  'Content-Type': 'application/json',
  'X-Platform': 'web',
  'X-Origin': APP,
  'X-Across-Sessions-Id': acrossSessionsId,
  'X-Bites-Session-Id': sessionId,
};

const BitesApi = withInterceptors(
  axios.create({
    withCredentials: true,
    baseURL: API_URL,
    headers: {
      ...defaultHeaders,
      'Guest-Id': acrossSessionsId,
    },
  }),
);

export const BitesApiWithKey = withInterceptors(
  axios.create({
    baseURL: API_URL,
    headers: {
      ...defaultHeaders,
      'X-API-Key': API_KEY,
    },
  }),
);

// apply interceptors to the global axios instance
withInterceptors(axios);

export const setBitesApiAuthorization = (token: string | null) => {
  if (token) {
    BitesApi.defaults.headers.common.Authorization = `Bearer ${token}`;
    return;
  }
  delete BitesApi.defaults.headers.common.Authorization;
};

export default BitesApi;

function getApiBaseUrl() {
  // url for testing teams app locally
  // return 'https://adam-bites-be.ngrok.io'
  // return 'https://gil-bites-be.ngrok.io'

  if (isProd() && window.location.hostname.includes('mybites.io')) {
    return 'https://api.mybites.io';
  }

  if (isProd()) {
    return 'https://api.mybiteshares.com';
  }

  if (getDevelopmentBackendUrl()) {
    return getDevelopmentBackendUrl();
  }

  return 'https://api.small-bites.com';
}

import TagManager from 'react-gtm-module';
import { browserName } from 'react-device-detect';
import { getAnalytics, setUserId, setUserProperties } from 'firebase/analytics';
import smartlook from 'smartlook-client';
import { datadogRum } from '@datadog/browser-rum';

import { IGtmSetUserId, IGtmTrack, IGtmSetUserProps } from './track.types';

import { APP } from '../../constants/app';
import firebaseApp from '../firebase';
import store from '../../store';
import { log, logError } from '../../store/tracking/tracking.slice';
import { getFullName } from '../../utils/auth';

const analytics = getAnalytics(firebaseApp);

const URL = 'https://ip.nf/me.json';
let info: {
  ip: {
    ip: string;
    latitude: string;
    longitude: string;
  };
} = null;

fetch(URL, { method: 'get' })
  .then((response) => response.json())
  .then((data) => {
    info = data;
  });

const intercomEventsWhitelist = ['login_error', 'FinalScreen', 'view-inspiration-bite', 'share_ms_teams', 'login'];

export const getGenericProps = () => {
  const isLandscape = () => window.matchMedia('(orientation:landscape)').matches;
  const timezoneOffset = new Date().getTimezoneOffset();
  const orientation = isLandscape() ? 'landscape' : 'portrait';

  const genericProps = {
    browser_name: browserName,
    device_orientation: orientation,
    page_url: window.location.href,
    ip: info?.ip.ip,
    ip_latitude: info?.ip.latitude,
    ip_longitude: info?.ip.longitude,
    referring_url: document.referrer,
    timezone_offset: timezoneOffset / 60,
    origin: APP,
    time_stamp: Date.now(),
    date_utc: new Date().toISOString(),
    org_id: store.getState().organization.id,
    bites_user_id: store.getState().authReducer.auth?.id,
  };

  return genericProps;
};

const gtmTrack: IGtmTrack = (event, data = {}) => {
  const genericProps = getGenericProps();

  const props = { ...genericProps, ...data };

  TagManager.dataLayer({
    dataLayer: {
      event,
      ...props,
    },
  });

  try {
    if (intercomEventsWhitelist.includes(event)) {
      window.Intercom?.('trackEvent', event, props);
    }
  } catch (error) {
    store.dispatch(
      logError({
        data: {
          error,
        },
      }),
    );
  }

  //   try {
  //     smartlook.track(event, props);
  //   } catch (error) {
  //     store.dispatch(
  //       logError({
  //         data: {
  //           error,
  //         },
  //       }),
  //     );
  //   }

  try {
    window.top.postMessage(
      {
        action: 'event',
        data: {
          event,
          ...props,
        },
      },
      '*',
    );
  } catch (error) {
    store.dispatch(
      logError({
        data: {
          error,
        },
      }),
    );
  }

  store.dispatch(
    log({
      event,
      data, // enrichment happens in saga
    }),
  );
};
export default gtmTrack;

export const gtmSetUserId: IGtmSetUserId = (userId) => {
  try {
    setUserId(analytics, `${userId}`);
    // gtmSetUserProps({ 'bites_user_id': `${userId}` }) // to use - need to set in GA as user props instead of event props
  } catch (error) {
    store.dispatch(
      logError({
        data: {
          error,
        },
      }),
    );
  }
  try {
    smartlook.identify(`${userId}`, {});
  } catch (error) {
    store.dispatch(
      logError({
        data: {
          error,
        },
      }),
    );
  }
};

export const gtmSetUserProps: IGtmSetUserProps = async (userId, props, { user }) => {
  try {
    setUserProperties(analytics, props);
  } catch (error) {
    store.dispatch(
      logError({
        data: {
          error,
        },
      }),
    );
  }

  if (userId) {
    try {
      smartlook.identify(`${userId}`, props);
    } catch (error) {
      store.dispatch(
        logError({
          data: {
            error,
          },
        }),
      );
    }
  }

  if (user) {
    const username = getFullName(user);
    try {
      datadogRum.setUser({
        ...user,
        id: `${userId}`,
        name: username,
      });
    } catch (error) {
      store.dispatch(
        logError({
          data: {
            error,
          },
        }),
      );
    }
  } else {
    datadogRum.clearUser();
  }
};

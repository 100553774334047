import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  contentLocalesErrorSelector,
  contentLocalesIsLoadingSelector,
  contentLocalesSelector,
} from '../store/appActivity/appActivity.selectors';
import { loadContentLocales } from '../store/appActivity/appActivity.slice';
import { orgSelector } from '../store/organization/organization.selectors';
import { isProd } from '../utils/env';
import useBrowserLang from './useBrowserLang';

export interface ILocale {
  locale: string;
  label: string;
  googleTranslate?: boolean;
  voiceover?: boolean;
  isSelected?: boolean;
}

interface IProps {
  defaultLocale?: string | null;
  selectedLocale?: string;
  searchStr?: string;
}
const useTranslationOptions = ({ defaultLocale, selectedLocale = null, searchStr: searchStrProp }: IProps = {}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { id: orgId } = useSelector(orgSelector);
  const locales: ILocale[] = useSelector(contentLocalesSelector);
  const isLoading = useSelector(contentLocalesIsLoadingSelector);
  const error = useSelector(contentLocalesErrorSelector);

  const browserLang = useBrowserLang();

  const localesWithVoiceover = useLocalesWithVoiceover();

  const groupedOptions = useMemo<
    {
      label: string;
      locale?: string;
      voiceover?: boolean;
      isSelected?: boolean;
      options?: ILocale[];
    }[]
  >(() => {
    const searchStr = searchStrProp?.toLowerCase() || '';

    const defaultLocaleLabel = (() => {
      if (!defaultLocale) {
        return t('components.translations.default');
      }

      const localeData = (locales || []).find(({ locale }) => locale === defaultLocale);
      if (!localeData) {
        return t('components.translations.default');
      }

      return `${t(`locales.${localeData.locale}`)}${
        defaultLocale === selectedLocale ? ` (${t('components.translations.default')})` : ''
      }`;
    })();

    const defaultLocaleOption = {
      locale: defaultLocale,
      label: defaultLocaleLabel,
      voiceover: false,
    };

    const selectedLocaleOption = (() => {
      if (selectedLocale === defaultLocale) {
        return {
          ...defaultLocaleOption,
          isSelected: true,
        };
      }

      const localeData = selectedLocale && (locales || []).find(({ locale }) => locale === selectedLocale);

      if (!localeData) {
        return {
          ...defaultLocaleOption,
          isSelected: true,
        };
      }

      return {
        ...localeData,
        isSelected: true,
        label: t(`locales.${localeData.locale}`),
      };
    })();

    const popularLangIds = POPULAR_LANGS_BY_ORG[orgId] || DEFAULT_POPULAR_LANGS;
    const popularLangs = popularLangIds.map((locale) => {
      return {
        locale,
        label: t(`locales.${locale}`),
        voiceover: localesWithVoiceover.has(locale),
      };
    });

    const addedLocales = new Set<string>([selectedLocaleOption.locale]);

    const suggestedGroupOptions = [];
    [
      {
        label: t(`locales.${browserLang}`) || browserLang,
        locale: browserLang,
        voiceover: localesWithVoiceover.has(browserLang),
      },
      defaultLocale !== undefined ? defaultLocaleOption : null,
      ...popularLangs,
    ].forEach((option) => {
      if (!option || addedLocales.has(option.locale)) {
        return;
      }

      suggestedGroupOptions.push(option);
      addedLocales.add(option.locale);
    });

    const otherLocalesGroupOptions = [];
    (locales || []).forEach((localeData) => {
      if (addedLocales.has(localeData.locale)) {
        return;
      }

      otherLocalesGroupOptions.push({
        ...localeData,
        label: t(`locales.${localeData.locale}`),
      });
      addedLocales.add(localeData.locale);
    });
    otherLocalesGroupOptions.sort((a, b) => (a.label > b.label ? 1 : -1));

    let result = [
      selectedLocaleOption,
      {
        label: t('components.translations.suggested'),
        options: suggestedGroupOptions,
      },
      {
        label: t('components.translations.autoTranslated'),
        options: otherLocalesGroupOptions,
      },
    ];

    if (searchStr) {
      result = result.flatMap((option) => {
        const options = (option as any).options;

        if (!options) {
          if (option.label.toLowerCase().includes(searchStr)) {
            return option;
          }
          return [];
        }

        const filteredOptions = options.filter((localeData) => {
          return localeData.label.toLowerCase().includes(searchStr);
        });

        if (!filteredOptions.length) {
          return [];
        }

        return {
          ...option,
          options: filteredOptions,
        };
      });
    }

    return result;
  }, [browserLang, defaultLocale, locales, localesWithVoiceover, orgId, searchStrProp, selectedLocale, t]);

  const options = useMemo<ILocale[]>(() => {
    return (locales || [])
      .map((localeData: ILocale) => {
        return {
          ...localeData,
          label: t(`locales.${localeData.locale}`),
        };
      })
      .sort((a, b) => (a.label > b.label ? 1 : -1));
  }, [locales, t]);

  const loadOptions = useCallback(() => {
    dispatch(loadContentLocales());
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadContentLocales());
  }, [dispatch]);

  return {
    localesWithVoiceover,
    groupedOptions,
    options,
    isLoading,
    error,
    loadOptions,
  };
};

export default useTranslationOptions;

export const useLocalesWithVoiceover = () => {
  const locales: ILocale[] = useSelector(contentLocalesSelector);
  return useMemo(
    () => new Set(locales?.filter((locale) => locale.voiceover).map((locale) => locale.locale)),
    [locales],
  );
};

export const useGetLocaleLabel = () => {
  const { t } = useTranslation();

  const locales: ILocale[] = useSelector(contentLocalesSelector);

  const getLocaleLabel = useCallback(
    (locale: string) => {
      const localeOption = locales?.find((option) => option.locale === locale);
      return t(`locales.${localeOption?.label || locale}`) || localeOption?.label || locale;
    },
    [locales, t],
  );

  return getLocaleLabel;
};
export const useLocaleLabel = (locale: string) => {
  const getLocaleLabel = useGetLocaleLabel();

  const label = useMemo(() => getLocaleLabel(locale), [getLocaleLabel, locale]);
  return label;
};

const DEFAULT_POPULAR_LANGS = ['en', 'es', 'ar', 'ru'];
const POPULAR_LANGS_BY_ORG = isProd()
  ? {
      11363: ['de', 'fr', 'es', 'cs', 'it', 'hu', 'pl', 'ro'], // Leonardo
      // Amazon
      11173: [
        'ar', // Arabic
        'fr-CA', // French (Canada)
        'de', // German
        'id', // Indonesian
        'it', // Italian
        'ja', // Japanese
        'ko', // Korean
        'pt', // Portuguese
        'es', // Spanish
        'zh-CN', // Simplified Chinese (mainland)
        'zh-TW', // Traditional Chinese (Taiwan)
        'th', // Thai
      ],
      17656: ['en', 'pt', 'id', 'ko', 'zh-CN', 'es', 'th', 'tr', 'vi'],
    }
  : {
      4314: ['de', 'fr', 'es', 'cs', 'it', 'hu', 'pl', 'ro'], // Notification tests
    };

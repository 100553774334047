import { createSelector } from 'reselect';

import { RootState } from '..';

export const authCommonSelector = (state: RootState) => state.authReducer;

export const microsoftTeamsContextSelector = createSelector(authCommonSelector, (item) => item.microsoftTeamsContext);

export const isInitialAuthInProgressSelector = (state: RootState) => state.authReducer.isInitialAuthInProgress;

export const isAuthenticatedSelector = (state: RootState) => !!state.authReducer.auth;

export const isWrongVerificationSelector = (state: RootState) => state.authReducer.isWrongVerificationCode;

export const organizationsListSelector = (state: RootState) =>
  authSelector(state)?.organizations?.filter((org) => org.id !== 1);

export const isPhoneMissingSelector = (state: RootState) => state.authReducer.isPhoneMissing;

export const authSelector = (state: RootState) => state.authReducer.auth;
export const authInProgressSelector = (state: RootState) => state.authReducer.authSuite.auth.isLoading;
export const isAuthErrorSelector = (state: RootState) => state.authReducer.authError;
export const errorCodesSelector = (state: RootState) => state.authReducer.errorCodes;

export const ssoInProgressSelector = (state: RootState) => state.authReducer.authSuite.social.isLoading;

export const isProfileLoadingSelector = (state: RootState) => state.authReducer.authSuite.userProfile.isLoading;

export const profileErrorsSelector = (state: RootState) => state.authReducer.authSuite.userProfile.errors;

export const isSsoErrorSelector = (state: RootState) => state.authReducer.authSuite.social.error;

export const isResetPasswordInProgressSelector = (state: RootState) =>
  state.authReducer.authSuite.newRecoverPassword.isLoading;
export const resetPasswordErrorsSelector = (state: RootState) => state.authReducer.authSuite.newRecoverPassword.errors;

export const isForgotEmailInProgressSelector = (state: RootState) =>
  state.authReducer.authSuite.passwordRecovery.isLoading;
export const isForgotEmailErrorSelector = (state: RootState) => state.authReducer.authSuite.passwordRecovery.error;

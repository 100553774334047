import { useCallback, useEffect, useRef } from 'react';

function useDelayedLogic(timeout: number) {
  const lastCallTimeRef = useRef(0);
  const timeoutRef = useRef(null);

  const delayedCall = useCallback(
    (callback) =>
      (...props) => {
        const now = Date.now();
        const timeSinceLastCall = now - lastCallTimeRef.current;

        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        if (timeSinceLastCall < timeout) {
          const delay = timeout - timeSinceLastCall;
          timeoutRef.current = setTimeout(() => {
            lastCallTimeRef.current = Date.now();
            callback(...props);
          }, delay);
        } else {
          lastCallTimeRef.current = now;
          callback(...props);
        }
      },
    [timeout],
  );

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return [delayedCall];
}

export default useDelayedLogic;

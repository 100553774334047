import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import last from 'lodash/last';

import { selectQuiz } from '../../../store/quiz/quiz.slice';

const useQuizRedirections = () => {
  const {
    // @ts-ignore
    params: { quizId },
  } = useRouteMatch();
  const { questions } = useSelector(selectQuiz);
  const history = useHistory();
  const isFinished = last(questions).isAnswered;
  const didStart = questions[0].isAnswered;
  const nextQuestionId = questions.find((q) => !q.isAnswered)?.id;

  useEffect(() => {
    const isPreview = new URLSearchParams(window.location.search).has('isPreview');
    if (isPreview) {
      return;
    }

    const redirectToNextQuestion = () =>
      history.push(`/quiz/${quizId}/question/${nextQuestionId}${window.location.search}`);
    const redirectToEnd = () => history.push(`/quiz/${quizId}/end`);

    if (isFinished) {
      redirectToEnd();
    } else if (didStart) {
      redirectToNextQuestion();
    }
  }, [isFinished, didStart, quizId, nextQuestionId, history]);
};

export default useQuizRedirections;

import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import MediaViewer from './components/MediaViewer';
import useQuizRedirections from './hooks/useQuizRedirections';

import { selectQuiz } from '../../store/quiz/quiz.slice';
import { Button } from '../../components/shared';
import { getIsRtl } from '../../locale/i18n';
import { startQuizDataSet } from './quizIntro.constants';

interface Props {
  match: any;
}

function QuizIntro(props: Props) {
  const history = useHistory();
  const { quizId } = props.match.params;
  const { t } = useTranslation();
  const { intro, subject, questions, linked_cover_url } = useSelector(selectQuiz);
  const quizCoverUrl = linked_cover_url || intro?.cover_url;
  const WIDTH = intro.width;
  const HEIGHT = intro.height;

  useQuizRedirections();

  return (
    <S.Container>
      <S.QuizSubject>{subject}</S.QuizSubject>
      <S.QuestionCount>
        {questions.length} {t('screens.quiz.intro.questions')}
      </S.QuestionCount>
      {intro.media_type === 'google_slides' ? (
        <S.IframeWrapper>
          <S.IframeContainer
            src={intro.media_url}
            allowFullScreen={false}
            isPortrait={WIDTH < HEIGHT}
            slidesWidth={WIDTH}
            slidesHeight={HEIGHT}
          />
        </S.IframeWrapper>
      ) : (
        <MediaViewer
          media_type={intro.media_type}
          media_url={intro.media_url}
          image_url={intro.image_url}
          cover_url={quizCoverUrl}
        />
      )}
      <S.Button
        onClick={() => history.push(`/quiz/${quizId}/question/${questions[0].id}${window.location.search}`)}
        data-cy={startQuizDataSet.cy}
      >
        {t('screens.quiz.intro.Start')}
      </S.Button>
    </S.Container>
  );
}

const S = {
  Container: styled.div`
    background-color: ${({ theme }) => theme.colors.lightGray14};
    display: flex;
    flex-direction: column;
  `,
  QuizSubject: styled.div`
    margin-top: 25px;
    margin-bottom: 31px;
    text-align: center;
    font-size: 21px;
  `,
  QuestionCount: styled.div`
    margin-bottom: 31px;
    text-align: center;
    font-size: 16px;
    ${() => getIsRtl() && 'direction: rtl'}
    writing-mode: horizontal-tb;
  `,
  Button: styled(Button)`
    width: 130px;
    margin: 0 auto 48px;
  `,
  IframeWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    flex: 1;
    margin-bottom: 15px;
  `,
  IframeContainer: styled.iframe<{ isPortrait: boolean; slidesWidth: number; slidesHeight: number }>`
    height: ${({ isPortrait }) => (isPortrait ? '100%' : 'auto')};
    width: ${({ isPortrait }) => (isPortrait ? 'auto' : '100%')};
    aspect-ratio: ${({ slidesWidth, slidesHeight }) => `${slidesWidth / slidesHeight}`};
    border: 0;
  `,
};

export default QuizIntro;

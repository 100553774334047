import React, { FC } from 'react';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import BiteItem from './BiteItem';

import { getIsRtl } from '../../locale/i18n';
import { biteLoadNext } from '../../store/bite/bite.actions';
import { bitesSelector } from '../../store/bite/bite.selectors';
import { searchSelector } from '../../store/search/search.selectors';
import { Loader } from '../shared';
import MetaTitle from '../MetaTitle';
import EmptyList from '../shared/EmptyList';

interface IProps {
  orgId?: number;
  isPublicFeed?: boolean;
  withoutProgressIndication?: boolean;
  onBiteClick?: (biteId: number, subject: string) => void;
}

const InfiniteBiteList: FC<IProps> = ({ orgId, isPublicFeed, withoutProgressIndication, onBiteClick }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { bites, totalBites, isLoadingNext } = useSelector(bitesSelector);
  const search = useSelector(searchSelector);

  const handleNext = () => {
    if (isLoadingNext) {
      return;
    }
    dispatch(biteLoadNext({ orgId, isPublicFeed }));
  };
  const isEmptyList = bites.length === 0;
  const emptyListMessage = search.bite === '' ? t('screens.home.emptyBiteList') : t('screens.home.emptySearchList');

  return !isEmptyList ? (
    <S.List
      dataLength={bites.length}
      next={handleNext}
      hasMore={totalBites > bites.length}
      scrollableTarget='app-container'
      loader={
        <div style={{ margin: 20 }}>
          <Loader />
        </div>
      }
    >
      {bites.map((bite) => (
        <BiteItem
          bite={bite}
          withoutProgressIndication={withoutProgressIndication}
          onClick={onBiteClick}
          key={bite.id}
        />
      ))}
      <MetaTitle title={t('metaTitles.bitesList')} />
    </S.List>
  ) : (
    <EmptyList message={emptyListMessage} />
  );
};

const S: any = {};

S.List = styled(InfiniteScroll)`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 10px;
  grid-row-gap: 23px;
  padding-bottom: 20px;
  ${() => (getIsRtl() ? 'direction: rtl;' : '')};
  writing-mode: horizontal-tb;
`;

export default InfiniteBiteList;

/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import useIsInsideMsTeams from './useIsInsideMsTeams';
import useCustomAuth from './useCustomAuth';

import { refreshUser } from '../store/auth/auth.actions';
import { log } from '../store/tracking/tracking.slice';
import { setCurrentForm } from '../store/authForm/authForm.slice';
import { EAuthFormType } from '../screens/auth/auth.types';
import { setRedirectUri } from '../utils/redirection';
import { getUserHasMissedAuthData } from '../store/auth/auth.saga';
import { authSelector } from '../store/auth/auth.selectors';

interface Props {
  sharingMode?: string;
  userOrg?: any;
  processId?: string;
}

export const useRedirectToHome = (props: Props) => {
  const { sharingMode, userOrg, processId } = props;
  const { skipProfile, isFetchingOrgSettings } = useCustomAuth();
  const { isInsideMsTeams } = useIsInsideMsTeams();
  const user = useSelector(authSelector);

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      log({
        event: 'useRedirectToHome: checking redirection',
        processId,
        data: {
          isInsideMsTeams,
          isFetchingOrgSettings,
          skipProfile,
          sharingMode,
          userOrg,
        },
      }),
    );

    const hasMissedAuthData = getUserHasMissedAuthData(user);
    if (
      isInsideMsTeams ||
      isFetchingOrgSettings ||
      skipProfile ||
      sharingMode === 'anyone' ||
      !userOrg ||
      hasMissedAuthData // in this case user will be redirected to the user info screen in a parallel flow, we do not want to override this
    ) {
      return;
    }

    if (!userOrg.is_profile_complete) {
      dispatch(
        log({
          event: 'useRedirectToHome: redirecting to set-your-profile',
          processId,
          data: {
            userOrg,
          },
        }),
      );

      dispatch(setCurrentForm(EAuthFormType.EXTENDED_INFO));
      setRedirectUri({ pathname: '/set-your-profile', redirectUri: location.pathname + location.search });
    }
  }, [isInsideMsTeams, isFetchingOrgSettings, skipProfile, userOrg]);

  useEffect(() => {
    if (sharingMode && sharingMode !== 'anyone' && !userOrg) {
      dispatch(
        log({
          event: 'useRedirectToHome: refreshUser',
          processId,
          data: {
            sharingMode,
            userOrg,
          },
        }),
      );

      dispatch(refreshUser({ processId }));
    }
  }, [sharingMode, userOrg]);
};

import React, { memo, useCallback } from 'react';
import styled from 'styled-components';

import bitesLogo from '../../assets/images/bite-logo.png';
import Button from '../../components/shared/Buttons/Button';
import useCustomTranslation from '../../hooks/useCustomTranslation';
import loginWithMicrosoftIframe from '../../hooks/useSocialLogins/loginWithMicrosoftIframe';
import useMicrosoftLogin from '../../hooks/useSocialLogins/useMicrosoftLogin';
import { getRedirectUri } from '../../utils/redirection';

function MsTeamsLoginPopup() {
  const { prefixedT } = useCustomTranslation('screens.auth.SignIn');

  const loginWithMicrosoft = useMicrosoftLogin();

  const handleClick = useCallback(() => {
    if (window.top === window) {
      loginWithMicrosoft();
      return;
    }

    const redirectURL = getRedirectUri();
    loginWithMicrosoftIframe(redirectURL);
  }, [loginWithMicrosoft]);

  return (
    <S.Container>
      <S.LogoContainer>
        <S.LogoImage src={bitesLogo} />
      </S.LogoContainer>
      <Button onClick={handleClick}>{prefixedT('SIGN IN WITH MICROSOFT')}</Button>
    </S.Container>
  );
}

const S = {
  Container: styled.div`
    height: 100%;
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  `,
  LogoContainer: styled.div`
    margin-bottom: 20px;
    height: 160px;
    display: flex;
    justify-content: center;
    text-align: center;
  `,
  LogoImage: styled.img`
    max-width: 100%;
    max-height: 100%;
    border-radius: 16px;
    object-fit: contain;
    margin: 0 auto;
    vertical-align: middle;
  `,
};

export default memo(MsTeamsLoginPopup);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, FC } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as SearchSVG } from '../../../../assets/icons/search.svg';

interface IProps {
  setBitesFilter: (filter: string) => void;
  setPlaylistsFilter: (filter: string) => void;
}

const Search: FC<IProps> = ({ setBitesFilter, setPlaylistsFilter }) => {
  const [filter, setFilter] = useState('');

  useEffect(() => {
    setBitesFilter(filter);
    setPlaylistsFilter(filter);
  }, [filter]);

  const handleFilterChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(ev.target.value);
  };

  return (
    <S.Container>
      <S.Input value={filter} onChange={handleFilterChange} placeholder='Search for a bite/playlist' />
      <S.IconContaoner>
        <S.SearchSVG />
      </S.IconContaoner>
    </S.Container>
  );
};

const S = {
  Container: styled.div(
    ({ theme }) => css`
      background-color: ${theme.msColors.lightBgColor};
      height: 32px;
      width: 300px;
      border: ${`1px solid ${theme.msColors.lightBorderColor}`};
      display: flex;
      @media ${theme.breakPoints.mobileL} {
        width: 100%;
      }
    `,
  ),
  Input: styled.input(
    ({ theme }) => css`
      font-size: 14px;
      padding: 5px 5px;
      flex: 1;
      color: ${theme.msColors.primaryTextcolor};

      &::placeholder {
        opacity: 0.5;
        color: ${theme.msColors.primaryTextcolor};
      }
    `,
  ),
  IconContaoner: styled.div`
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  SearchSVG: styled(SearchSVG)`
    fill: ${({ theme }) => theme.msColors.primaryTextcolor};
    width: 15px;
    height: auto;
  `,
};

export default Search;

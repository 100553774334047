import React, { FC, memo, useCallback, useState } from 'react';
import styled from 'styled-components';
import Header from '../Header';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FormTextInput from '../../../../components/shared/Inputs/FormTextInput';
import Button from '../../../../components/shared/Buttons';
import { passwordRecovery, resetAuthErrors } from '../../../../store/auth/auth.actions';
import { emailFormSelector } from '../../../../store/authForm/authForm.selectors';
import { setCurrentForm, setEmail } from '../../../../store/authForm/authForm.slice';
import { EAuthFormType } from '../../auth.types';
import { log } from '../../../../store/tracking/tracking.slice';
import { getIsEmailValid } from '../../../../utils/auth';
import { isForgotEmailErrorSelector, isForgotEmailInProgressSelector } from '../../../../store/auth/auth.selectors';
import { v4 as uuid } from 'uuid';
import { useIsMounted } from '../../../../hooks/useIsMounted';

const EnterEmail: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMountedRef = useIsMounted();

  const email = useSelector(emailFormSelector);
  const isLoading = useSelector(isForgotEmailInProgressSelector);
  const isForgotEmailError = useSelector(isForgotEmailErrorSelector);

  const [emailError, setEmailError] = useState(null);
  const emailDisplayError = isForgotEmailError ? t('common.somethingWentWrong') : emailError;

  const handleChangeEmail = useCallback(
    (text: string) => {
      dispatch(setEmail(text));
      setEmailError(null);
      dispatch(resetAuthErrors());
    },
    [dispatch],
  );

  const handleContinue = useCallback(() => {
    if (isLoading) {
      return;
    }

    setEmailError(null);
    dispatch(resetAuthErrors());

    const isEmailValid = getIsEmailValid(email);
    if (!isEmailValid) {
      setEmailError(t('EnterEmail.emailIsNotValid'));
      return;
    }

    const processId = uuid();

    const onSuccess = () => {
      if (!isMountedRef.current) {
        return;
      }

      dispatch(
        log({
          event: 'EnterEmail.handleSuccess',
          processId,
          data: { destination: EAuthFormType.FORGOT_PASSWORD_INFO },
        }),
      );

      dispatch(setCurrentForm(EAuthFormType.FORGOT_PASSWORD_INFO));
    };

    dispatch(
      passwordRecovery({
        email,
        processId,
        onSuccess,
      }),
    );
  }, [dispatch, email, isLoading, isMountedRef, t]);

  return (
    <S.Content>
      <Header title={t('authForm.forgotPassword')} description={t('authForm.forgotPasswordForm.typeEmail')} />
      <S.Form>
        <S.TextInput
          value={email}
          onChange={handleChangeEmail}
          label={t('authForm.forgotPasswordForm.emailInputLabel')}
          onEnterPress={handleContinue}
          error={emailDisplayError}
        />
        <S.ContinueButton isLoading={isLoading} onClick={handleContinue}>
          {t('common.Continue')}
        </S.ContinueButton>
      </S.Form>
    </S.Content>
  );
};

const S = {
  Content: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Form: styled.div`
    margin-top: 32px;
    align-items: center;
    width: 298px;
  `,
  TextInput: styled(FormTextInput)`
    height: 50px;
  `,
  ContinueButton: styled(Button)`
    min-height: 50px;
    min-width: 298px;
    margin-bottom: 50px;
    margin-top: 18px;
  `,
};

export default memo(EnterEmail);

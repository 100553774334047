import React, { FC, useCallback } from 'react';
import styled from 'styled-components';

interface IProps {
  show: boolean;
  closeModal?: () => void;
}

const BackDrop: FC<IProps> = ({ show, closeModal }) => {
  const handleClick = useCallback(() => {
    if (typeof closeModal === 'function') {
      closeModal();
    }
  }, [closeModal]);

  return <S.BackDropContainer className={show ? 'show' : ''} onClick={handleClick} />;
};

const BackDropContainer = styled.div`
  cursor: pointer;
  width: 0;
  height: 0;
  position: absolute;
  z-index: 49;
  opacity: 0;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  transition: opacity 0.3s ease;

  &.show {
    width: 100%;
    height: 100%;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease;
  }
`;

const S = { BackDropContainer };

export default BackDrop;

import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Lightbox from 'react-image-lightbox';
import Linkify from 'react-linkify';
import { useDispatch, useSelector } from 'react-redux';

import DeleteComment from './DeleteComment';

import { IComment } from '../../../../types/bite';
import { ReactComponent as CommentIcon } from '../../../../assets/icons/comment.svg';
import useCustomTranslation from '../../../../hooks/useCustomTranslation';
import { authSelector } from '../../../../store/auth/auth.selectors';
import { selectedBiteSelector } from '../../../../store/bite/bite.selectors';
import TranslationsDropdown from '../../../../components/shared/TranslationsDropdown';
import defaultTheme from '../../../../style/themes/defaultTheme';
import { translateTexts } from '../../../../store/api/calls/common.calls';
import { log, logError } from '../../../../store/tracking/tracking.slice';
import { useIsMounted } from '../../../../hooks/useIsMounted';
import { getIsRtl } from '../../../../locale/i18n';
import { commentTextDataSet } from './commentItem.constants';
import withRetry from '../../../../utils/withRetry';
import { TCommentItemRefType } from '../types';

interface IProps {
  comment: IComment;
  index: number;
  commentIndex: number;
  createReplyOnComment: any;
  itemRef?: React.MutableRefObject<TCommentItemRefType>;
  onContentUpdate?: () => void;
}

const getDisplayText = (translation: string, text: string) => {
  const t = translation || text;
  try {
    return decodeURIComponent(t);
  } catch (error) {
    return t;
  }
};

const prepareDate = (value) => {
  const date = new Date(value);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const prepareValue = (str) => (str < 10 ? `0${str}` : str);

  const dateString = `${year}-${prepareValue(month)}-${prepareValue(day)}`;
  const time = date.toTimeString().slice(0, 5);
  return `${dateString} ${time}`;
};

const CommentItem: FC<IProps> = ({ comment, createReplyOnComment, itemRef, onContentUpdate }) => {
  const dispatch = useDispatch();
  const isMountedRef = useIsMounted();
  const localeRef = useRef(null);

  const [isMediaLightboxOpen, setIsMediaLightboxOpen] = useState(false);
  const { selectedBite } = useSelector(selectedBiteSelector);
  const [relatedCommentLightboxImage, setRelatedCommentLightboxImage] = useState('');
  const { comment: text, user, created_at, related_comments, media } = comment;
  const { prefixedT } = useCustomTranslation('screens.viewBite.Discussion');
  const commentsCount = related_comments.length;
  const activeUser = useSelector(authSelector);

  const defineCommentsCounter =
    commentsCount !== 0
      ? commentsCount <= 1
        ? `${commentsCount} ${prefixedT('Comment')}`
        : `${commentsCount} ${prefixedT('Comments')}`
      : '';

  const isCreator = comment.user.id === selectedBite.creator_id;
  const [isLoadingTranslations, setIsLoadingTranslations] = useState(false);
  const [translation, setTranslation] = useState(null);

  const displayText = useMemo(() => {
    return getDisplayText(translation, text);
  }, [text, translation]);

  useEffect(() => {
    onContentUpdate();
  }, [displayText, onContentUpdate]);

  const selectLocale = useCallback(
    async ({ locale }) => {
      const startTs = Date.now();
      localeRef.current = locale;

      dispatch(
        log({
          event: 'CommentItem.selectLocale: start',
          data: {
            locale,
          },
        }),
      );

      if (!locale) {
        setTranslation(null);
        return;
      }

      setIsLoadingTranslations(true);

      try {
        const {
          data: { texts },
        } = await withRetry(
          () =>
            translateTexts({
              texts: [displayText],
              locale,
            }),
          {
            errorContext: {
              action: 'CommentItem.selectLocale',
            },
          },
        );

        if (!isMountedRef.current || localeRef.current !== locale) {
          return;
        }

        setTranslation(texts[0]);
      } catch (error) {
        dispatch(
          logError({
            event: 'CommentItem.selectLocale: error',
            data: {
              error,
              locale,
            },
          }),
        );
      }

      dispatch(
        log({
          event: 'CommentItem.selectLocale: done',
          data: {
            locale,
            duration: Date.now() - startTs,
          },
        }),
      );

      setIsLoadingTranslations(false);
    },
    [dispatch, displayText, isMountedRef],
  );

  const renderRelatedComment = useCallback(
    (item) => {
      return (
        <RelatedComment
          item={item}
          setRelatedCommentLightboxImage={setRelatedCommentLightboxImage}
          key={item.id}
          isCreator={item.user.id === selectedBite.creator_id}
          onContentUpdate={onContentUpdate}
        />
      );
    },
    [onContentUpdate, selectedBite.creator_id],
  );

  const rtl = getIsRtl();

  const translationsDropdownStyles = useMemo(
    () => ({
      zIndex: 100,
      position: 'absolute',
      top: -7,
      left: rtl ? 'auto' : 40,
      right: rtl ? 40 : 'auto',
    }),
    [rtl],
  );

  const translationsRef = useRef<HTMLDivElement>();

  // const { driverRef } = usePopoverHighlight({
  //   displayedLocalStorageKey: commentIndex === 0 ? 'viewedCommentTranslationsBtnTour' : null,
  //   clickedLocalStorageKey: 'openedCommentTranslationsDropdown',
  //   text: t('screens.viewBite.Discussion.commentTranslationsBtnTour'),
  //   ref: translationsRef,
  //   delay: activeUser ? 2000 : 5000,
  //   side: 'right',
  //   align: 'end',
  //   backdropOpacity: 0,
  //   withBackdrop: false,
  // });

  // const handleOpenTranslationsDropdown = useCallback(() => {
  //   driverRef.current?.destroy();
  //   localStorage.setItem('openedCommentTranslationsDropdown', '1');
  // }, [driverRef]);

  // useEffect(() => {
  //   const driver = driverRef.current;

  //   return () => {
  //     driver?.destroy();
  //   };
  // }, [driverRef]);

  return (
    <S.Container ref={itemRef}>
      <S.Wrapper isCreator={isCreator}>
        <S.AuthorDetailsContainer>
          <S.AuthorName isCreator={isCreator}>{user.full_name}</S.AuthorName>
          <S.AuthotTel>{prepareDate(created_at)}</S.AuthotTel>
        </S.AuthorDetailsContainer>
        <S.CommentText dir='auto' data-cy={commentTextDataSet.cy}>
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target='_blank' rel='noreferrer' href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
            {displayText}
          </Linkify>
        </S.CommentText>
        {media && (
          <S.UploadedImageContainer onClick={() => setIsMediaLightboxOpen(true)}>
            <S.UploadedImage src={media.image_url} onLoad={onContentUpdate} alt='' />
          </S.UploadedImageContainer>
        )}
        {isMediaLightboxOpen && (
          <Lightbox
            mainSrc={media.image_url}
            onCloseRequest={() => {
              setIsMediaLightboxOpen(false);
            }}
          />
        )}
      </S.Wrapper>
      <S.CommentsContainer>
        <S.CommentControls>
          <S.DeleteAndCounterComment>
            {comment.user.id === activeUser?.id && <DeleteComment commentId={comment.id} type='biteshare' />}

            <TranslationsDropdown
              isLoading={isLoadingTranslations}
              controlStyles={S.translationControlStyles}
              dropdownStyles={translationsDropdownStyles}
              iconColor={defaultTheme.colors.lightGray4}
              withDelayedDropdownOpen
              menuPortalTarget={document.body}
              containerRef={translationsRef}
              // onOpen={handleOpenTranslationsDropdown}
              onSelect={selectLocale}
            />

            <S.CommentsCounter>{defineCommentsCounter}</S.CommentsCounter>
          </S.DeleteAndCounterComment>

          <S.ReplyOnComment onClick={() => createReplyOnComment(comment)} id='replyButton'>
            <CommentIcon id='replyButtonIcon' />
            {prefixedT('ToComment')}
          </S.ReplyOnComment>
        </S.CommentControls>
        {related_comments.map(renderRelatedComment)}
        {relatedCommentLightboxImage && (
          <Lightbox
            mainSrc={relatedCommentLightboxImage}
            onCloseRequest={() => {
              setRelatedCommentLightboxImage('');
            }}
          />
        )}
      </S.CommentsContainer>
    </S.Container>
  );
};

const RelatedComment = ({ item, setRelatedCommentLightboxImage, isCreator, onContentUpdate }) => {
  const dispatch = useDispatch();
  const isMountedRef = useIsMounted();

  const localeRef = useRef(null);

  const activeUser = useSelector(authSelector);

  const [isLoadingTranslations, setIsLoadingTranslations] = useState(false);
  const [translation, setTranslation] = useState(null);

  const displayText = useMemo(() => {
    return getDisplayText(translation, item.comment);
  }, [item.comment, translation]);

  useEffect(() => {
    onContentUpdate();
  }, [displayText, onContentUpdate]);

  const selectLocale = useCallback(
    async ({ locale }) => {
      const startTs = Date.now();
      localeRef.current = locale;

      dispatch(
        log({
          event: 'RelatedComment.selectLocale: start',
          data: {
            locale,
          },
        }),
      );

      if (!locale) {
        setTranslation(null);
        return;
      }

      setIsLoadingTranslations(true);

      try {
        const {
          data: { texts },
        } = await withRetry(
          () =>
            translateTexts({
              texts: [displayText],
              locale,
            }),
          {
            errorContext: {
              action: 'RelatedComment.selectLocale',
            },
          },
        );

        if (!isMountedRef.current || localeRef.current !== locale) {
          return;
        }

        setTranslation(texts[0]);
      } catch (error) {
        dispatch(
          logError({
            event: 'RelatedComment.selectLocale: error',
            data: {
              error,
              locale,
            },
          }),
        );
      }

      dispatch(
        log({
          event: 'RelatedComment.selectLocale: done',
          data: {
            locale,
            duration: Date.now() - startTs,
          },
        }),
      );

      setIsLoadingTranslations(false);
    },
    [dispatch, displayText, isMountedRef],
  );

  const rtl = getIsRtl();

  const relatedTranslationsDropdownStyles = useMemo(
    () => ({
      zIndex: 100,
      position: 'absolute',
      top: -7,
      left: rtl ? 40 : 'auto',
      right: rtl ? 'auto' : 40,
    }),
    [rtl],
  );

  return (
    <S.RelatedComment isCreator={isCreator}>
      <S.AuthorDetailsContainer>
        <S.AuthorName>{item.user.full_name}</S.AuthorName>
        <S.AuthotTel>{prepareDate(item.created_at)}</S.AuthotTel>
      </S.AuthorDetailsContainer>
      <S.RelatedCommentTextContainer>
        <S.CommentText>
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target='_blank' rel='noreferrer' href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
            {displayText}
          </Linkify>
        </S.CommentText>
        <S.DeleteAndCounterComment>
          <S.RelatedCommentControls>
            <TranslationsDropdown
              isLoading={isLoadingTranslations}
              controlStyles={S.translationControlStyles}
              dropdownStyles={relatedTranslationsDropdownStyles}
              iconColor={defaultTheme.colors.lightGray4}
              withDelayedDropdownOpen
              menuPortalTarget={document.body}
              onSelect={selectLocale}
            />

            {item.user.id === activeUser?.id && <DeleteComment commentId={item.id} type='comment' />}
          </S.RelatedCommentControls>
        </S.DeleteAndCounterComment>
      </S.RelatedCommentTextContainer>
      {item.media && (
        <S.UploadedImageContainer onClick={() => setRelatedCommentLightboxImage(item.media.image_url)}>
          <S.UploadedImage src={item.media.image_url} alt='' onLoad={onContentUpdate} />
        </S.UploadedImageContainer>
      )}
    </S.RelatedComment>
  );
};

const textBaseStyle = () => css`
  letter-spacing: 0.5px;
`;

const S: any = {};

S.UploadedImageContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  cursor: pointer;
`;

S.UploadedImage = styled.img`
  width: 100%;
  max-width: 100%;
  height: auto;
`;

S.Wrapper = styled.div<{ isCreator: boolean }>`
  padding: 15px 16px;
  border-radius: 5px 5px 0 0;

  ${({ theme, isCreator }) => css`
    border: 1px solid ${theme.colors.lightGray10}66;
    ${isCreator ? 'background-color: #E6E6FF;' : ''}
  `}
`;

S.CommentsContainer = styled.div`
  padding: 6px 16px;
  display: flex;
  flex-direction: column;

  ${({ theme }) => css`
    color: ${theme.colors.black};
    border: 1px solid ${theme.colors.lightGray10}66;
  `}

  border-radius: 0 0 5px 5px;
  border-top: none;
`;

S.ReplyOnComment = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 13px;
  cursor: pointer;
  user-select: none;
  align-items: center;
`;

S.DeleteAndCounterComment = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

S.CommentControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

S.RelatedComment = styled.div<{ isCreator: boolean }>`
  border-radius: 5px;
  padding: 15px 16px;
  margin-top: 10px;
  ${({ isCreator, theme }) => `background: ${isCreator ? theme.colors.lightPurple2 : theme.colors.lightGray};`}
`;

S.CommentsCounter = styled.div`
  margin-left: 10px;
`;

S.Container = styled.div``;

S.AuthorDetailsContainer = styled.div`
  margin-bottom: 12px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

S.AuthorName = styled.span<{ isCreator: boolean }>`
  ${textBaseStyle};
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  ${({ theme, isCreator }) => css`
    color: ${theme.colors.darkGray5};
    ${isCreator ? 'font-weight: bold;' : ''}
  `}
`;

S.AuthotTel = styled.span`
  ${textBaseStyle};
  font-size: 12px;
  ${({ theme }) => css`
    color: ${theme.colors.lightGray6};
  `}
`;

S.RelatedCommentTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
S.CommentText = styled.div`
  text-align: initial;
  font-size: 14px;
  line-height: 17px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  overflow: hidden;
  ${({ theme }) => css`
    color: ${theme.colors.black};
  `}
`;

S.RelatedCommentControls = styled.div`
  position: relative;
  display: flex;
`;

S.translationControlStyles = {
  marginLeft: 8,
  marginRight: 8,
  backgroundColor: 'transparent',
  color: '#000',
  borderColor: defaultTheme.colors.lightGray2,
  borderRadius: 8,
  width: 25,
  height: 25,
  padding: 4,
};

export default CommentItem;

import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export default () => {
  const history = useHistory();

  const location = useLocation();

  const historyPushWithPersistedQueryParams = useCallback(
    (pathname: string) =>
      history.push({
        pathname,
        search: location.search,
      }),
    [history, location.search],
  );

  return { history, historyPushWithPersistedQueryParams };
};

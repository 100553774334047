import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import store from '../store';
import { logError } from '../store/tracking/tracking.slice';

import localStorage from '../utils/localStorage';

export type TLocale = 'he' | 'en' | 'es' | 'fr' | 'de' | 'pt';

export const LOCALES: TLocale[] = [
  'en',
  'he',
  'es',
  // 'fr',
  'de',
  'pt',
];

export const ORGS_WITH_PREFERRED_LANGUAGE = {
  '12641': 'pt',
  '12772': 'pt',
};

const browserLanguage = navigator.language?.split('-')[0].toLowerCase();
export const matchedBrowserLanguage = LOCALES.find((locale) => locale === browserLanguage);

let isNeedToSetPreferredLanguage;

export const setPreferredLanguage = async ({ orgId }) => {
  try {
    if (!orgId || !isNeedToSetPreferredLanguage) {
      return;
    }

    if (ORGS_WITH_PREFERRED_LANGUAGE[orgId]) {
      await setLanguage(ORGS_WITH_PREFERRED_LANGUAGE[orgId]);
    }
  } catch (error) {
    store.dispatch(logError({ data: { error } }));
  }
};

export const initI18 = (translations) => {
  isNeedToSetPreferredLanguage = !localStorage.getItem('locale') && !matchedBrowserLanguage;
  i18n.use(initReactI18next).init({
    resources: translations,
    lng: localStorage.getItem('locale') || matchedBrowserLanguage || 'en',
    fallbackLng: 'en',
    returnEmptyString: false,
    interpolation: {
      escapeValue: false,
    },
  });
};

export default i18n;

export const setLanguageByNumber = (number) => {
  if (localStorage.getItem('locale')) {
    return;
  }
  if (number === 2 && i18n.language !== 'he') {
    setLanguage('he');
  }
};

export const setLanguage = async (locale) => {
  try {
    const t = await i18n.changeLanguage(locale);
    localStorage.setItem('locale', locale);
    t('key');
  } catch (error) {
    store.dispatch(logError({ data: { error } }));
  }
};

export const getIsRtl = () => i18n?.dir(i18n.language) === 'rtl';

export const applyLocaleIfNeeded = (locale) => {
  locale = locale?.toLowerCase();
  if (locale && !localStorage.getItem('locale')) {
    setLanguage(locale);
  }
};

import styled, { css } from 'styled-components';

import horizontalSpace from './horizontalSpace';

export const HEADER_HEIGHT = 75;

const StyledHeader = styled.header`
  ${horizontalSpace};
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  ${({ theme }) => css`
    background-color: ${theme.colors.primaryBlue};
  `}
`;

export default StyledHeader;

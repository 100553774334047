import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useIsEndOfBite from './useIsEndOfBite';

import useCustomHistory from '../../../../hooks/useCustomHistory';
import useIsInsideMsTeams from '../../../../hooks/useIsInsideMsTeams';
import useQueryParams from '../../../../hooks/useQueryParams';
import { biteShareUserSelector } from '../../../../store/biteShareUser/biteShareUser.selectors';
import HeaderHomeButton from '../../../../components/HeaderHomeButton/HeaderHomeButton';
import gtmTrack from '../../../../services/googleTagManager/track';

function HomeOrPlaylistButton() {
  const [isAnimated, setIsAnimated] = useState(false);
  const isEndOfBite = useIsEndOfBite();
  const { history } = useCustomHistory();
  const { isInsideMsTeams } = useIsInsideMsTeams();
  const { queryParams } = useQueryParams();
  const playlistId = queryParams.playlistId;
  const { is_guest } = useSelector(biteShareUserSelector);

  const goToPlaylist = () => {
    gtmTrack('back_to_playlist', {
      playlist_id: playlistId,
    });
    history.push(`/playlists/${playlistId}`, {
      withoutModal: true,
    });
  };

  const goHome = () => history.push('/');

  useEffect(() => {
    setIsAnimated(isEndOfBite);
  }, [isEndOfBite]);

  if (playlistId) {
    return <HeaderHomeButton onClick={goToPlaylist} icon='door' isAnimated={isAnimated} />;
  }
  if (!isInsideMsTeams && !is_guest) {
    return (
      <HeaderHomeButton
        onClick={() => {
          goHome();
          gtmTrack('back_to_home_button', {});
        }}
        icon='home'
        isAnimated={isAnimated}
      />
    );
  }
  return null;
}

export default HomeOrPlaylistButton;

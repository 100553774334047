import { useSelector } from 'react-redux';

const useCustomAuth = () => {
  // @ts-ignore
  const { organization } = useSelector((state) => state);

  return {
    skipProfile: organization.skipProfile,
    forceEmployeeId: organization.forceEmployeeId,
    forceIsraeliId: organization.forceIsraeliId,
    isFetchingOrgSettings: organization.isFetching,
  };
};

export default useCustomAuth;

export interface IConfig {
  defaultBrandShareImage: string;
}

export interface IGeolocation {
  timezone: {
    id: string;
    current_time: string;
    code: string;
    is_daylight_saving: boolean;
    gmt_offset: number;
  };
  ip: string;
  type: string;
  connection: {
    asn: number;
    organization: string;
    isp: string;
  };
  location: {
    geonames_id: number;
    latitude: number;
    longitude: number;
    zip: string;
    continent: {
      code: string;
      name: string;
      name_translated: string;
    };
    country: {
      alpha2: string;
      alpha3: string;
      calling_codes: string[];
      currencies: {
        symbol: string;
        name: string;
        symbol_native: string;
        decimal_digits: number;
        rounding: number;
        code: string;
        name_plural: string;
      }[];
      emoji: string;
      ioc: string;
      languages: {
        name: string;
        name_native: string;
      }[];
      name: string;
      name_translated: string;
      timezones: string[];
      is_in_european_union: boolean;
    };
    city: {
      name: string;
      name_translated: string;
    };
    region: {
      fips: string;
      alpha2: string;
      name: string;
      name_translated: string;
    };
  };
}

export interface IInitialState {
  config: IConfig;
  geolocation: IGeolocation | null;
  showSomethingWentWrong: null | { type: EShowSomethingWentWrongError };
  translationsAreInitialized: boolean;
  contentLocales: {
    data: IContentLocale[] | null;
    isLoading: boolean;
    error: any;
  };
}

export enum EShowSomethingWentWrongError {
  AUTH = 'AUTH',
  DEFAULT = 'DEFAULT',
  PASSWORD_RECOVERY = 'PASSWORD_RECOVERY',
  SETTING_PROFILE = 'SETTING_PROFILE',
}

export interface IContentLocale {
  locale: string;
  googleTranslate: boolean;
}

import React, { FC } from 'react';
import styled from 'styled-components';

import { ReactComponent as EmptyListIcon } from '../../assets/icons/empty-list.svg';

interface IProps {
  message: string;
}

const EmptyList: FC<IProps> = ({ message }) => {
  return (
    <S.EmptyListContainer>
      <S.EmptyListIcon />
      <S.Message>{message}</S.Message>
    </S.EmptyListContainer>
  );
};

const S = {
  EmptyListContainer: styled.div`
    display: flex;
    flex: 1;
    height: 50vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  EmptyListIcon: styled(EmptyListIcon)`
    width: 100px;
    height: 100px;
  `,
  Message: styled.p`
    margin-top: 20px;
    font-size: 17px;
    font-weight: bold;
    text-align: center;
    color: ${({ theme }) => theme.colors.darkGray2};
  `,
};
export default EmptyList;

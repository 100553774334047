import microsoftTeams from '../../services/msTeams';
import store from '../../store';
import { log, logError } from '../../store/tracking/tracking.slice';

const loginWithMicrosoftIframe = (redirectUrl = '/') => {
  microsoftTeams.authentication.authenticate({
    url: window.location.origin + '/auth/msteams/start',
    successCallback: () => {
      store.dispatch(log({ event: 'MSTeams authtication was succesful' }));
      window.location.href = redirectUrl;
    },
    failureCallback: (error) => {
      store.dispatch(logError({ event: `MSTeams authtication failure ${JSON.stringify(error)}` }));
    },
  });
};

export default loginWithMicrosoftIframe;

import { RootState } from '../store';

export const knowledgeRequestPromptSelector = (state: RootState) => state.knowledge.requestsPrompt;
export const knowledgeLocalPromptSelector = (state: RootState) => state.knowledge.localPrompt;
export const knowledgeIsLoadingSelector = (state: RootState) => state.knowledge.relatedData.isLoading;
export const knowledgeRelatedContentSelector = (state: RootState) => state.knowledge.relatedData.data;
export const knowledgeErrorSelector = (state: RootState) => state.knowledge.relatedData.isError;

export const knowledgeHistorySelector = (state: RootState) => state.knowledge.history.data;
// export const knowledgeHistoryIsLoadingSelector = (state: RootState) => state.knowledge.history.isLoading;
// export const knowledgeHistoryErrorSelector = (state: RootState) => state.knowledge.history.error;

export const knowledgeTagsSearchDataSelector = (state: RootState) => state.knowledge.tagsSearchData.data;
export const knowledgeTagsSearchDataIsLoadingSelector = (state: RootState) => state.knowledge.tagsSearchData.isLoading;
export const knowledgeTagsSearchDataNextSelector = (state: RootState) => state.knowledge.tagsSearchData.next;
export const knowledgeTagsSearchDataCountSelector = (state: RootState) => state.knowledge.tagsSearchData.totalCount;
// export const knowledgeTagsSearchDataErrorSelector = (state: RootState) => state.knowledge.tagsSearchData.error;

export const requestIdSelector = (state: RootState) => state.knowledge.requestId;

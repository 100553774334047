import React, { FC, memo, useCallback } from 'react';
import styled from 'styled-components';
import { getIsRtl } from '../../../../locale/i18n';
import { Loader } from '../../../../components/shared';
import { useDispatch } from 'react-redux';
import { log } from '../../../../store/tracking/tracking.slice';
import { suggestionButtonDataSet } from './commentSuggestions.constants';

interface IProps {
  comments: string[];
  isLoading: boolean;
  onCommentSelected: (comment: string) => void;
  isCreator: boolean;
}

interface IProps2 {
  comment: string;
  onCommentSelected: (comment: any) => void;
}

const CommentSuggestion: FC<IProps> = ({ comments, onCommentSelected, isLoading, isCreator }) => {
  const dispatch = useDispatch();
  const handleCommentSelected = useCallback(
    (comment) => {
      onCommentSelected(comment);
      dispatch(
        log({
          event: 'CommentSuggestion.applyCommentSuggestion',
          data: {
            isCreator,
          },
        }),
      );
    },
    [dispatch, isCreator, onCommentSelected],
  );

  return (
    <S.Wrapper>
      {isLoading ? (
        <Loader />
      ) : (
        comments?.map((comment) => (
          <Suggestion comment={comment} onCommentSelected={handleCommentSelected} key={comment} />
        ))
      )}
    </S.Wrapper>
  );
};

const Suggestion: FC<IProps2> = ({ comment, onCommentSelected }) => {
  const handleClick = useCallback(() => {
    onCommentSelected(comment);
  }, [comment, onCommentSelected]);

  return (
    <S.Button key={comment} onClick={handleClick} data-cy={suggestionButtonDataSet.cy}>
      {comment}
    </S.Button>
  );
};

const S = {
  Wrapper: styled.div`
    height: 42px;
    padding: 4.5px 45px;
    justify-content: space-between;
    overflow: hidden;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.lightGray17};
    writing-mode: horizontal-tb;
    ${() => (getIsRtl() ? 'direction: rtl;' : '')};
  `,
  Button: styled.button`
    height: 30px;
    cursor: pointer;
    max-width: 250px;
    overflow: hidden;
    white-space: nowrap;
    margin: 3px;
    padding: 7px;
    background-color: white;
    border-radius: 15px;
    text-overflow: ellipsis;
    ${({ theme }) => `
      color: ${theme.colors.primaryBlue};
      border: 1px solid ${theme.colors.primaryBlue};
    `}
    ${() => (getIsRtl() ? 'direction: rtl;' : '')};
    writing-mode: horizontal-tb;
  `,
};

export default memo(CommentSuggestion);

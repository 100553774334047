import React, { CSSProperties, FC } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import bitesLogo from '../../assets/images/bites-logo-text.png';
import { organizationSelector } from '../../store/organization/organization.slice';

interface IProps {
  style?: CSSProperties;
}

const EnterBrandingLogo: FC<IProps> = ({ style: containerStyle }) => {
  const { settingsLoaded, logoUrl } = useSelector(organizationSelector);

  return (
    <S.Container style={containerStyle}>
      <S.LogoImage src={settingsLoaded ? logoUrl : bitesLogo} data-test='logo' />
    </S.Container>
  );
};

const S: any = {};

S.Container = styled.div`
  width: 74px;
  margin-top: 20px;
  margin-left: 16px;
  display: flex;
  justify-content: center;
  text-align: center;
`;

S.LogoImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  margin: 0 auto;
  vertical-align: middle;
`;

export default EnterBrandingLogo;

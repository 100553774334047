import { IAuth } from '../types/auth';

function hasNumber(myString) {
  return /\d/.test(myString);
}

function hasUppercase(myString) {
  return /\.*[A-Z]/.test(myString);
}

export const isValidPasswordMessage = (pass: string) => {
  if (!pass) {
    return 'authForm.passwordErrors.empty';
  } else if (pass.length < 8) {
    return 'authForm.passwordErrors.tooShort';
  } else if (!hasNumber(pass)) {
    return 'authForm.passwordErrors.mustContainNumber';
  } else if (!hasUppercase(pass)) {
    return 'authForm.passwordErrors.mustContainUppercase';
  } else {
    return '';
  }
};

export const getIsEmailValid = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !!re.test(email.trim());
};

export const getFullName = (user: IAuth) => {
  if (!user) {
    return '';
  }

  if (user.first_name || user.last_name) {
    return [user.first_name, user.last_name].filter((_) => _).join(' ');
  }

  if (user.full_name) {
    return user.full_name;
  }

  if (user.username) {
    return user.username;
  }

  return '';
};

export const TERMS_CONDITIONS_URL = 'https://mybites.io/termsconditions/';

export enum EResponseErrors {
  phone_conflict = 'phone_conflict',
  bad_phone = 'bad_phone',
  email_conflict = 'email_conflict',
  bad_email = 'bad_email',
}

export const getIsIsraeliIdValid = (israeliId) => {
  israeliId = String(israeliId).trim();
  if (israeliId.length > 9 || isNaN(israeliId)) {
    return false;
  }
  israeliId = ('00000000' + israeliId).slice(-9);
  return (
    Array.from(israeliId, Number).reduce((counter, digit, i) => {
      const step = digit * ((i % 2) + 1);
      return counter + (step > 9 ? step - 9 : step);
    }) %
      10 ===
    0
  );
};

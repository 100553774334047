/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';

import { CustomPopup } from '../../../components/shared/CustomModal/CustomPopup';
import { MainContentWrapper } from '../../../components/layout';
import { authSelector } from '../../../store/auth/auth.selectors';
import useCustomTranslation from '../../../hooks/useCustomTranslation';
import { selectedPlaylistSelector } from '../../../store/playlist/playlist.selectors';
import { StyledImageTiles, ContinueButton, PoweredByBites } from '../../../components/shared';
import CoverImage from './CoverImage';
import { getFullName } from '../../../utils/auth';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const PlaylistAwaitsModal: FC<Props> = ({ open, onClose }) => {
  const auth = useSelector(authSelector) || {};
  const username = getFullName(auth);
  const { prefixedT } = useCustomTranslation('screens.ViewPlaylist');

  const [timer, setTimer] = useState(0);

  const { selectedPlaylist } = useSelector(selectedPlaylistSelector);

  const calculatedValues = useMemo(() => {
    const noEmptyBites = selectedPlaylist
      ? selectedPlaylist.bite_shares?.filter((bite) => !bite.no_sections) || []
      : [];
    const coverImages = noEmptyBites.map((bite) => bite.cover_url);
    return {
      coverImages,
      bitesCount: noEmptyBites.length,
    };
  }, [selectedPlaylist]);

  useEffect(() => {
    const timeFunction = setTimeout(() => {
      setTimer(timer + 1);
    }, 3000);

    return () => {
      clearTimeout(timeFunction);
    };
  }, [selectedPlaylist]);

  useEffect(() => {
    if (timer !== 0) {
      onClose();
    }
  }, [timer]);

  return (
    <CustomPopup open={open} onClose={onClose} style={{ marginLeft: -30 }}>
      <S.Container>
        <MainContentWrapper>
          <S.Greeting>{`${prefixedT('GreetingWord')}, ${username}`}</S.Greeting>
          <S.SubGreeting>{prefixedT('AwaitTitle')}</S.SubGreeting>

          <S.BiteDetailsContainer>
            <S.CompanyLogoWrapper>
              <S.CompanyLogo src={selectedPlaylist.organization_logo} />
            </S.CompanyLogoWrapper>

            {selectedPlaylist?.linked_cover_url ? (
              <CoverImage url={selectedPlaylist.linked_cover_url} />
            ) : (
              <S.StyledImageTilesContainer>
                {calculatedValues?.coverImages?.map((coverImage, idx) => (
                  <StyledImageTiles.ImageTile key={idx} src={coverImage} />
                ))}
              </S.StyledImageTilesContainer>
            )}

            <S.PlaylistSubject>{selectedPlaylist.subject}</S.PlaylistSubject>

            <S.BitesCount>{calculatedValues?.bitesCount + ' ' + prefixedT('Bites')} </S.BitesCount>
          </S.BiteDetailsContainer>

          <ContinueButton
            style={{ marginBottom: '30px' }}
            onClick={() => setTimer(timer + 1)}
            text={prefixedT('ButtonText')}
          />

          <PoweredByBites />
        </MainContentWrapper>
      </S.Container>
    </CustomPopup>
  );
};
const textBaseStyle = css`
  text-align: center;
  line-height: 24px;
  ${({ theme }) => css`
    color: ${theme.colors.white};
  `}
`;

const S: any = {};
S.CompanyLogoWrapper = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

S.Container = styled.div`
  padding: 20px 0;
  height: 100%;
  width: 100%;

  ${({ theme }) => css`
    background: linear-gradient(${theme.colors.primaryBlue} 50%, transparent 50%);
  `}
`;

S.Header = styled.header`
  height: 23px;
  text-align: right;
  padding: 0 25px;
  @media (max-height: 680px) {
    margin-bottom: 5px;
  }
`;

S.Greeting = styled.h3`
  ${textBaseStyle}
  font-size: 20px;
  line-height: 24px;
  @media (max-height: 680px) {
    font-size: 18px;
  }
`;

S.SubGreeting = styled.p`
  ${textBaseStyle}
  font-size: 18px;

  margin-bottom: 80px;

  @media (max-height: 720px) {
    margin-bottom: 20px;
  }
  @media (max-height: 680px) {
    font-size: 16px;
  }
`;

S.BiteDetailsContainer = styled.div`
  border-radius: 15px;
  width: 100%;
  min-height: 275px;

  margin-bottom: 32px;
  padding: 27px 15px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  box-shadow: 0 12px 19px 0 rgba(60, 128, 209, 0.09);

  background-color: ${({ theme }) => theme.colors.white};

  @media (max-height: 680px) {
    margin-bottom: 20px;
    padding: 22px 10px;
  }
`;

S.BitesCount = styled.p`
  font-size: 16px;
  line-height: 19px;
  ${({ theme }) => css`
    color: ${theme.colors.lightGray6};
  `};
`;

S.CompanyLogo = styled.img`
  max-width: 100%;
  margin-bottom: 30px;
  width: auto;
  height: 100%;
  @media (max-height: 680px) {
    margin-bottom: 15px;
  }
`;

S.PlaylistSubject = styled.h4`
  text-align: center;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 12px;
  @media (max-height: 680px) {
    font-size: 21px;
    margin-bottom: 5px;
  }
  ${({ theme }) => css`
    color: ${theme.colors.darkGray1};
  `};
`;

S.StyledImageTilesContainer = styled(StyledImageTiles.Container)`
  border-radius: 0;
  margin-bottom: 18px;
  @media (max-height: 680px) {
    width: 100px;
    height: 100px;
  }
`;

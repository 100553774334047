export interface IInitialState {
  isContentNotAvailable: boolean;
  isGeofenceModalOpened: boolean;
  contentInfo: {
    type: EContentType | null;
    id: number;
    processId: string;
  };
  geolocation: {
    latitude: number;
    longitude: number;
    accuracy: number;
  };
}

export enum EContentType {
  bite = 'bite',
  playlist = 'playlist',
  quiz = 'quiz',
}

import React, { FC, memo } from 'react';
import styled from 'styled-components';
import Header from './Header';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/shared/Buttons';

interface IProps {
  title: string;
  description: string;
  onContinue?: () => void;
}

const AuthInfo: FC<IProps> = ({ title, description, onContinue }) => {
  const { t } = useTranslation();
  return (
    <S.Content>
      <Header title={title} />
      <S.Info>{description}</S.Info>
      {typeof onContinue === 'function' && (
        <S.ContinueButton onClick={onContinue}>{t('common.Continue')}</S.ContinueButton>
      )}
    </S.Content>
  );
};

const S = {
  Content: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
  `,
  Info: styled.span`
    font-size: 14px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.gray19};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    margin-top: 8px;
    text-align: center;
    margin-bottom: 31px;
  `,
  ContinueButton: styled(Button)`
    min-height: 50px;
    margin-bottom: 50px;
    margin-top: 18px;
    max-width: 298px;
  `,
};

export default memo(AuthInfo);

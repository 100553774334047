import React, { FC, useCallback } from 'react';
import Modal from 'react-modal';
import styled, { css } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { authCommonSelector } from '../../../store/auth/auth.selectors';
import { Button } from '../../../components/shared';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-modal.svg';
import { setIsNothingToDisplayModalShown } from '../../../store/auth/auth.actions';
import { ReactComponent as EmptyListIcon } from '../../../assets/icons/empty-list.svg';
import defaultTheme from '../../../style/themes/defaultTheme';

const modalStyles = {
  overlay: {
    zIndex: 1000,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: defaultTheme.colors.lightGray14,
    padding: 0,
    paddingTop: '30px',
    paddingBottom: '5px',
    borderRadius: 20,
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '90%',
    width: '360px',
    height: '295px',
    boxShadow: '0 12px 19px 0 rgba(60, 128, 209, 0.09)',
  },
};

const NoContentModal: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { isNothingToDisplayModalShown } = useSelector(authCommonSelector);

  const close = useCallback(() => {
    dispatch(setIsNothingToDisplayModalShown(false));
  }, [dispatch]);

  return (
    <Modal ariaHideApp={false} isOpen={isNothingToDisplayModalShown} onRequestClose={close} style={modalStyles}>
      <S.CloseModalIcon onClick={close} />
      <S.EmptyListContainer>
        <EmptyListIcon />
        <S.Text>{t('components.nothingToDisplay')}</S.Text>
      </S.EmptyListContainer>
      <S.Divider />
      <S.NextButton onClick={close}>{t('common.ok')}</S.NextButton>
    </Modal>
  );
};

const S = {
  CloseModalIcon: styled(CloseIcon)`
    width: 32px;
    height: 32px;
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 10px;
  `,
  NextButton: styled(Button)`
    margin-top: 20px;
    width: 90px;
  `,
  EmptyListContainer: styled.div`
    display: flex;
    height: 20vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  Text: styled.text`
    margin: 20px;
  `,
  Divider: styled.div(
    ({ theme }) => css`
      margin: 0px 0;
      width: 100%;
      height: 1px;
      background-color: ${theme.colors.lightGray12};
      @media ${theme.breakPoints.mobileL} {
        display: none;
      }
    `,
  ),
};

export default NoContentModal;

export default {
  UPDATE_BITE_SHARE_USER_PROGRESS_REQUEST: 'UPDATE_BITE_SHARE_USER_PROGRESS_REQUEST',
  UPDATE_BITE_SHARE_USER_PROGRESS_SUCCESS: 'UPDATE_BITE_SHARE_USER_PROGRESS_SUCCESS',

  OPEN_ENDED_QUESTION_REQUEST: 'OPEN_ENDED_QUESTION_REQUEST',
  OPEN_ENDED_QUESTION_SUCCESS: 'OPEN_ENDED_QUESTION_SUCCESS',
  OPEN_ENDED_QUESTION_ERROR: 'OPEN_ENDED_QUESTION_ERROR',

  MULTIPLE_CHOICES_QUATION_REQUEST: 'MULTIPLE_CHOICES_QUATION_REQUEST',
  MULTIPLE_CHOICES_QUATION_SUCCESS: 'MULTIPLE_CHOICES_QUATION_SUCCESS',
  MULTIPLE_CHOICES_QUATION_ERROR: 'MULTIPLE_CHOICES_QUATION_ERROR',

  FULLY_WATCHED_BITE_INTRO_VIDEO_REQUEST: 'FULLY_WATCHED_BITE_INTRO_VIDEO_REQUEST',
  FULLY_WATCHED_BITE_INTRO_VIDEO_SUCCESS: 'FULLY_WATCHED_BITE_INTRO_VIDEO_SUCCESS',
};

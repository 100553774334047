import store from '../../store';
import { setShowSomethingWentWrong } from '../../store/appActivity/appActivity.slice';
import { EShowSomethingWentWrongError } from '../../store/appActivity/appActivity.types';
import { logError } from '../../store/tracking/tracking.slice';
import { cloneDeep } from 'lodash';

interface IProps {
  event?: string;
  processId?: string;
  error: any;
  data?: any;
  showSomethingWentWrongError: EShowSomethingWentWrongError;
}
const handleHttpErrors = ({
  event = 'handleHttpErrors',
  processId,
  error,
  data = {},
  showSomethingWentWrongError,
}: IProps) => {
  store.dispatch(
    logError({
      event,
      processId,
      data: {
        ...data,
        error,
        errorResponse: cloneDeep(error?.response),
      },
    }),
  );

  if (error?.response?.status === 400 && error.response.headers['x-captcha-validation'] === 'bad_token') {
    store.dispatch(setShowSomethingWentWrong({ type: showSomethingWentWrongError }));
  }
};
export default handleHttpErrors;

import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import ConfirmationModal from './ConfirmationModal';

import { logout } from '../../store/auth/auth.actions';
import logoutIcon from '../../assets/icons/logout.svg';
import useCustomTranslation from '../../hooks/useCustomTranslation';

interface IProps {
  className?: string;
}

const LogoutButton: FC<IProps> = ({ className }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const { prefixedT } = useCustomTranslation('screens.auth.SignOut');

  const handleCancel = () => {
    setIsDialogOpen(false);
  };

  const handleLogout = () => {
    dispatch(logout());
    setIsDialogOpen(false);
  };

  return (
    <>
      <S.Button className={className} onClick={() => setIsDialogOpen(true)} id='btnLogout' data-cy='logoutButton'>
        {prefixedT('logout')}
      </S.Button>
      <ConfirmationModal
        isOpen={isDialogOpen}
        modalIcon={logoutIcon}
        onConfirm={handleLogout}
        onCancel={handleCancel}
        messageText={prefixedT('message')}
        confirmText={prefixedT('confirm')}
        cancelText={prefixedT('no')}
      />
    </>
  );
};

const Button = styled.div`
  padding: 6px;
  border-radius: 20px;
  display: relative;
  cursor: pointer;
`;

const S = { Button };

export default LogoutButton;

import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import SnackbarProvider from 'react-simple-snackbar';
import { IntercomProvider } from 'react-use-intercom';

import './initImports';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './App';
import store, { persistor } from './store';
// for some reason the dev server shows a warning about no default export
// eslint-disable-next-line import/default
import './types/global';
import defaultTheme from './style/themes';
import GlobalStyle from './style/GlobalStyle';
import history from './navigation/history';
import Intercom from './components/Intercom';
import gtmTrack from './services/googleTagManager/track';
import './services/datadog/datadog';

const Loading = () => <div>Loading ...</div>;

const handleOnShow = () => {
  gtmTrack('intercom_chat', { close_time: Date.now() });
};

const handleOnHide = () => {
  gtmTrack('intercom_chat', { open_time: Date.now() });
};

const render = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <IntercomProvider appId='y3w6xsal' onHide={handleOnHide} onShow={handleOnShow}>
        <Intercom />
        <PersistGate loading={<Loading />} persistor={persistor}>
          <SnackbarProvider>
            <ThemeProvider theme={defaultTheme}>
              <GlobalStyle />
              <Router history={history}>
                <Component />
              </Router>
            </ThemeProvider>
          </SnackbarProvider>
        </PersistGate>
      </IntercomProvider>
    </Provider>,
    document.getElementById('root'),
  );
};

render(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { answerLoaderCy } from '../aiPrompt.constants';

const AnswerLoader = () => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      if (dots.length >= 8) {
        setDots('');
        return;
      }

      setDots(dots + '.');
    }, 300);

    return () => clearInterval(interval);
  }, [dots]);

  return <S.LoadingDots data-cy={answerLoaderCy}>{dots}</S.LoadingDots>;
};

const S = {
  LoadingDots: styled.span``,
};

export default AnswerLoader;

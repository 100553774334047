/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useIsUserAnsweredQuestion from './useIsUserAnsweredQuestion';

import { updateBiteShareUserProgressRequest } from '../store/biteShareUser/biteShareUser.actions';
import { sectionsOrder } from '../utils/bite';
import { biteShareUserSelector } from '../store/biteShareUser/biteShareUser.selectors';
import { selectedBiteSelector } from '../store/bite/bite.selectors';

const useUpdateBiteShareUserProgress = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { selectedBite } = useSelector(selectedBiteSelector);

  const activeSections = useMemo(() => {
    const sections: string[] = [];

    const introSection = selectedBite?.bite_sections.find((section) => section?.type === 'intro' && section.media);
    const questionSection = selectedBite?.bite_sections.find(
      (section) => section?.type === 'question' && section?.questions?.length > 0,
    );
    const summarySection = selectedBite?.bite_sections.find(
      (section) => section?.type === 'summary' && section?.summary_cards?.length > 0,
    );

    if (introSection) {
      sections.push('intro');
    }

    if (questionSection) {
      sections.push('question');
    }

    if (summarySection) {
      sections.push('summary');
    }

    if (selectedBite?.discussion_enabled) {
      sections.push('discussion');
    }

    const contentSec = selectedBite?.bite_sections.find((sec) => sec?.type === 'content');
    if (!selectedBite?.discussion_enabled && contentSec?.content?.type === 'html') {
      sections.push('content');
    }

    return sections;
  }, [selectedBite]);

  const isQuestionAnswered = useIsUserAnsweredQuestion(selectedBite);

  const biteShareUser = useSelector(biteShareUserSelector);

  useEffect(() => {
    // find current section name
    let currentSectionName = history.location.pathname.split('').reverse().join('');

    let sectionName = '';

    const isIncludesSectionName = ['intro', 'question', 'summary', 'discussion', 'content'].filter((el) =>
      currentSectionName.includes(el.toString().split('').reverse().join('')),
    ).length;

    const isIncludeSomeSections = (...sections: any[]) => {
      for (let i = 0; i < sections.length; i++) {
        if (activeSections.includes(sections[i])) {
          return true;
        }
      }

      return false;
    };

    const isLastSection = (): boolean => {
      const fullSectionName = history.location.pathname.split('/')[3];

      if (fullSectionName === 'intro' && !isIncludeSomeSections('question', 'summary', 'discussion', 'content')) {
        return true;
      }

      if (
        fullSectionName === 'question' &&
        !isIncludeSomeSections('summary', 'discussion', 'content') &&
        isQuestionAnswered
      ) {
        return true;
      }

      if (fullSectionName === 'summary' && !isIncludeSomeSections('discussion', 'content')) {
        return true;
      }

      if (fullSectionName === 'discussion' || fullSectionName === 'content') {
        return true;
      }

      return false;
    };

    if (isIncludesSectionName) {
      currentSectionName = currentSectionName.slice(0, currentSectionName.indexOf('/'));
      currentSectionName = currentSectionName.split('').reverse().join('');

      if (isLastSection()) {
        currentSectionName = 'done';
      }

      sectionName = currentSectionName;
    }

    const isRedirectedToFirstSection = parseInt(sectionName, 10) !== biteShareUser.bite_share;

    if (
      (biteShareUser.id !== null && sectionsOrder[sectionName] > sectionsOrder[biteShareUser.bite_progress]) ||
      (!sectionsOrder[biteShareUser.bite_progress] && sectionName.length)
    ) {
      if (isRedirectedToFirstSection) {
        dispatch(
          updateBiteShareUserProgressRequest({
            bite_progress: sectionName,
          }),
        );
      }
    }
  }, [history.location]);
};

export default useUpdateBiteShareUserProgress;

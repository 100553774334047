import React, { useEffect, useState, useMemo, FC, useRef, useCallback } from 'react';
import { Route, useRouteMatch, useLocation } from 'react-router-dom';
import type { RouteChildrenProps } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import Header from './components/Header';
import useLogic from './useLogic';

import { Loader } from '../../components/shared';
import useCustomAuth from '../../hooks/useCustomAuth';
import { getBiteFirstSection } from '../../utils/bite';
import { resetSelectedBite } from '../../store/bite/bite.actions';
import useCustomHistory from '../../hooks/useCustomHistory';
import { useRedirectToHome } from '../../hooks/useRedirectToHome';
import useUpdateBiteShareUserProgress from '../../hooks/useUpdateBiteShareUserProgress';
import { fetchSelectedPlaylistRequest } from '../../store/playlist/playlist.actions';
import { selectedPlaylistSelector } from '../../store/playlist/playlist.selectors';
import { refreshUser, setIsNothingToDisplayModalShown } from '../../store/auth/auth.actions';
import { authSelector } from '../../store/auth/auth.selectors';
import useQueryParams from '../../hooks/useQueryParams';
import gtmTrack from '../../services/googleTagManager/track';
import { NextBitePanel } from '../../screens/viewBite/Discussion/components/NextBitePanel';
import { getRedirectURLs } from '../../hooks/useCheckForRedirections';
import useIsInsideMsTeams from '../../hooks/useIsInsideMsTeams';
import { v4 as uuidv4 } from 'uuid';
import { log } from '../../store/tracking/tracking.slice';
import useRemoveQueryParams from '../../hooks/useRemoveQueryParams';
import TranslationsDropdown from '../../components/shared/TranslationsDropdown';
import SectionTitleHeader from './components/Header/SectionTitleHeader';
import getIsMobileDevice from '../../utils/getIsMobileDevice';
import useScreenOrientation from '../../hooks/useScreenOrientation';
import { translatedVoiceoversAudios } from '../../store/bite/bites.data';
import { viewBiteContainerCy } from './ViewBite.constants';
import { usePopoverHighlight } from '../../hooks/usePopoverHighlight';

const ViewBite: FC<RouteChildrenProps> = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { selectedPlaylist } = useSelector(selectedPlaylistSelector);
  const user = useSelector(authSelector);

  const match = useRouteMatch();
  const location = useLocation();

  const { queryParams } = useQueryParams();
  const playlistId = queryParams?.playlistId;
  const hideHeader = queryParams?.hideHeader;
  const showSectionTitleHeader = queryParams?.showSectionTitleHeader;

  const isMobileDevice = getIsMobileDevice();
  const { isScreenLandscape } = useScreenOrientation();

  const [view, setView] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [showNextBitePanel, setShowNextBitePanel] = useState(false);

  const processId = useMemo(() => uuidv4(), []);

  const { skipProfile, isFetchingOrgSettings } = useCustomAuth();
  useUpdateBiteShareUserProgress();
  const { history } = useCustomHistory();
  const { isInsideMsTeams } = useIsInsideMsTeams();

  const {
    selectedBite,
    intro,
    isLoading,
    dynamicBiteSectionDetails,
    selectLocale,
    currentSection,
    isLoadingTranslations,
  } = useLogic({
    isModalOpen,
    setIsModalOpen,
    processId,
    isPlaylist: !!playlistId,
  });

  const isHeaderVisible =
    !(isMobileDevice && isScreenLandscape && intro?.file_type === 'google_slides' && currentSection === 'intro') &&
    !hideHeader;
  const isTitleVisible = !(isMobileDevice && isScreenLandscape && intro?.file_type === 'google_slides');

  const lastScreen = selectedBite?.discussion_enabled
    ? 'discussion'
    : selectedBite?.bite_sections[selectedBite?.bite_sections.length - 1].type;

  const isLastSection = currentSection === lastScreen;

  const isPlaylistLastSection = selectedPlaylist?.id && playlistId && isLastSection;

  const questionAnswer = selectedBite?.bite_share_user.choices.length;

  useEffect(() => {
    setShowNextBitePanel(false);

    if (!isPlaylistLastSection) {
      return;
    }
    if (isPlaylistLastSection && questionAnswer < 1) {
      return;
    }
    let timeout = null;

    if (lastScreen === 'intro') {
      if (intro.file_type === 'youtube' || intro.file_type === 'video') {
        if (intro.isVideoWatchCompleted) {
          timeout = 0;
        } else {
          return;
        }
      } else {
        timeout = 10000;
      }
    } else {
      timeout = 6000;
    }

    const timer = setTimeout(() => {
      setShowNextBitePanel(true);
    }, timeout);

    return () => {
      clearTimeout(timer);
    };
  }, [selectedBite, intro.isVideoWatchCompleted, isPlaylistLastSection, intro.file_type, lastScreen, questionAnswer]);

  const biteUserOrg = useMemo(() => {
    return (
      selectedBite && user?.organizations?.find((org) => parseInt(org.id, 10) === parseInt(selectedBite?.orgid, 10))
    );
  }, [selectedBite, user?.organizations]);

  useRemoveQueryParams(['timestamp']);
  useRedirectToHome({
    sharingMode: selectedBite?.sharing_mode,
    userOrg: biteUserOrg,
    processId,
  });

  const isWaiting = useMemo(() => {
    return (
      !selectedBite ||
      isLoading ||
      isFetchingOrgSettings ||
      (!isInsideMsTeams && selectedBite.sharing_mode !== 'anyone' && !skipProfile && !biteUserOrg?.is_profile_complete)
    );
  }, [selectedBite, isLoading, isFetchingOrgSettings, isInsideMsTeams, skipProfile, biteUserOrg]);

  const firstSection = useMemo(() => selectedBite && getBiteFirstSection(selectedBite), [selectedBite]);

  const dynamicLinks = useMemo(() => {
    return dynamicBiteSectionDetails.map((dynamicSectionDetails) => {
      const {
        path,
        link: { svgIcon },
      } = dynamicSectionDetails;
      return { path, svgIcon };
    });
  }, [dynamicBiteSectionDetails]);

  const dynamicRoutes = useMemo(() => {
    return dynamicBiteSectionDetails.map(({ path, route: { component: Component } }) => {
      return <Route key={path} path={match.url + path} render={Component} />;
    });
  }, [dynamicBiteSectionDetails, match.url]);

  useEffect(() => {
    if (!selectedPlaylist && playlistId) {
      dispatch(
        log({
          event: 'ViewBite: fetchSelectedPlaylistRequest',
          processId,
          data: {
            selectedPlaylist,
            playlistId,
          },
        }),
      );
      dispatch(fetchSelectedPlaylistRequest({ playlistId, processId, isFromBite: true }));
    }
  }, [playlistId, dispatch, selectedPlaylist, processId]);

  useEffect(() => {
    if (!selectedBite?.id) {
      return;
    }

    translatedVoiceoversAudios.current = {};

    gtmTrack('pageview', {
      page_title: 'viewBite',
      bites_user_id: user?.id,
      bite_id: selectedBite.bite,
      bite_share_id: selectedBite.id,
      content_org_id: selectedBite.orgid,
      sharing_mode: selectedBite.sharing_mode,
      playlist_id: playlistId,
    });

    if (selectedBite?.side_effects?.opted_in) {
      toast(t('components.optedInNotification'));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBite?.id]);

  useEffect(() => {
    if (isWaiting) {
      dispatch(
        log({
          event: 'ViewBite: Bite is waiting',
          processId,
          data: {
            isWaiting,
          },
        }),
      );
      return;
    }

    if (queryParams?.r) {
      dispatch(refreshUser({ processId }));
      const redirectUrl = getRedirectURLs(queryParams?.r);

      dispatch(
        log({
          event: 'ViewBite: redirecting by URL r param',
          processId,
          data: {
            queryParams,
            redirectUrl,
            isWaiting,
          },
        }),
      );

      history.push(redirectUrl);
      return;
    }

    dispatch(
      log({
        event: 'ViewBite: redirecting to section',
        processId,
        data: {
          queryParams,
          isWaiting,
          firstSection,
          selectedBite,
          pathname: location.pathname,
          search: location.search,
        },
      }),
    );

    if (!firstSection) {
      dispatch(setIsNothingToDisplayModalShown(true));
      history.push('/');
      return;
    }

    history.replace({
      pathname: queryParams?.keepSection ? location.pathname : `/bites/${selectedBite.id}/${firstSection}`,
      search: location.search,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWaiting, firstSection]);

  useEffect(() => {
    const path = history.location.pathname;
    const selectedTab = path.slice(path.lastIndexOf('/'));
    let index = 0;
    dynamicBiteSectionDetails.forEach((el, i) => {
      if (el.path === selectedTab) {
        index = i;
      }
    });
    setView(index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname, match]);

  useEffect(() => {
    return () => {
      dispatch(resetSelectedBite());
    };
  }, [dispatch]);

  if (isWaiting) {
    return <Loader />;
  }

  return (
    <FlexLayout data-cy={viewBiteContainerCy}>
      {isHeaderVisible && <Header links={dynamicLinks} />}
      <StyledMainContentWrapper isVideoOpen={isModalOpen}>
        {showNextBitePanel && <NextBitePanel />}
        {isTitleVisible && (
          <BiteNameContainer>
            {showSectionTitleHeader ? (
              <SectionTitleHeader currentSection={currentSection} title={selectedBite?.subject} />
            ) : (
              <BiteName>{selectedBite?.subject}</BiteName>
            )}
            <ViewBiteTranslationsDropdown
              currentSection={currentSection}
              isLoadingTranslations={isLoadingTranslations}
              selectLocale={selectLocale}
            />
          </BiteNameContainer>
        )}
        <SwipeableViews
          style={{
            height: '100%',
            paddingTop: isHeaderVisible ? 60 : 0,
          }}
          index={view}
          disabled
        >
          {dynamicRoutes}
        </SwipeableViews>
        <ToastContainer position={toast.POSITION.BOTTOM_LEFT} />
      </StyledMainContentWrapper>
    </FlexLayout>
  );
};

const StyledMainContentWrapper = styled.main<{
  isVideoOpen?: boolean;
}>`
  flex: 1;
  overflow: scroll;
  height: 100%;
  .react-swipeable-view-container {
    height: 100%;
    ${({ isVideoOpen }) => (isVideoOpen ? ' will-change: inherit !important; transform: none !important' : null)}
  }
`;
const FlexLayout = styled.div`
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;
const BiteNameContainer = styled.div`
  position: absolute;
  width: 100%;
`;
const BiteName = styled.div`
  font-size: 24px;
  margin-top: 18px;
  width: 100%;
  text-align: center;
  line-height: 24px;
  overflow: hidden;
  min-height: 50px;
  text-overflow: ellipsis;
  padding: 0 10px;
  color: ${({ theme }) => theme.colors.primaryBlue};
`;
const TranslationsContainer = styled.div`
  z-index: 100;
  position: absolute;
  top: 8px;
  right: 4px;
`;

const translationsDropdownStyles = {
  position: 'absolute',
  top: 40,
  right: 4,
};

export default ViewBite;

const ViewBiteTranslationsDropdown = ({
  currentSection,
  isLoadingTranslations,
  selectLocale,
}: {
  currentSection: string;
  isLoadingTranslations: boolean;
  selectLocale: (props: { locale: string; isManually?: boolean }) => void;
}) => {
  const { t } = useTranslation();

  const btnRef = useRef<HTMLDivElement>(null);

  const { driverRef } = usePopoverHighlight({
    displayedLocalStorageKey:
      currentSection === 'question'
        ? 'viewedQuestionTranslationsBtnTour'
        : currentSection === 'summary'
        ? 'viewedSummaryTranslationsBtnTour'
        : null,
    clickedLocalStorageKey: 'openedTranslationsDropdown',
    text:
      currentSection === 'question'
        ? t('screens.Questions.questionTranslationsBtnTour')
        : currentSection === 'summary'
        ? t('screens.Summary.summaryTranslationsBtnTour')
        : null,
    ref: btnRef,
    delay: 2000,
    align: 'end',
    backdropOpacity: 0,
    withBackdrop: false,
  });

  const handleOpenTranslationsDropdown = useCallback(() => {
    driverRef.current?.destroy();
    localStorage.setItem('openedTranslationsDropdown', '1');
  }, [driverRef]);

  useEffect(() => {
    const driverInstance = driverRef.current;

    return () => {
      driverInstance?.destroy();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSection]);

  return (
    <>
      {(currentSection === 'question' || currentSection === 'summary') && (
        <TranslationsContainer ref={btnRef}>
          <TranslationsDropdown
            isLoading={isLoadingTranslations}
            dropdownStyles={translationsDropdownStyles}
            onSelect={selectLocale}
            onOpen={handleOpenTranslationsDropdown}
          />
        </TranslationsContainer>
      )}
    </>
  );
};

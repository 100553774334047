import React, { CSSProperties, FC, memo, ReactNode, useCallback, useMemo } from 'react';
import AppleLogin from 'react-apple-login';
import SocialButton from './SocialButton';
import { APP } from '../../../constants/app';
import { API_BASE_URL } from '../../../store/api';

interface IProps {
  title: string;
  icon: ReactNode;
  style?: CSSProperties;
}

const AppleAuthButton: FC<IProps> = ({ title, icon, style }) => {
  const state = useMemo(
    () =>
      encodeURIComponent(
        JSON.stringify({
          app: APP,
          redirect: `${window.location.protocol}//${window.location.hostname}${
            window.location.hostname === 'localhost' ? ':' + window.location.port : ''
          }${window.location.pathname}${window.location.search || '?'}&token=ACCESS_TOKEN`,
        }),
      ),
    [],
  );

  const render = useCallback(
    ({ onClick }) => <SocialButton style={style} onClick={onClick} icon={icon} title={title} />,
    [style, icon, title],
  );

  return (
    <AppleLogin
      clientId='app.bites.creatorapp'
      redirectURI={`${API_BASE_URL}/teams/get_auth_token/`}
      scope='name email'
      responseType='code id_token'
      responseMode='form_post'
      state={state}
      render={render}
    />
  );
};

export default memo(AppleAuthButton);

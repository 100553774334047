import SearchActionTypes from './search.types';

export const updateSearch = (data) => ({
  type: SearchActionTypes.UPDATE_SEARCH,
  payload: data,
});

export const resetSearch = () => ({
  type: SearchActionTypes.RESET_SEARCH,
});

import { useRouteMatch } from 'react-router-dom';
import type { match } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentFlow, setCurrentForm, setRecoveryToken } from '../../../store/authForm/authForm.slice';
import { EAuthFlowType, EAuthFormType } from '../auth.types';
import useQueryParams from '../../../hooks/useQueryParams';

const useAuthLocationCheck = () => {
  const dispatch = useDispatch();
  const { queryParams } = useQueryParams();

  const isFirstRenderRef = useRef(true);

  const signInRouteMatch = useRouteMatch({
    path: '/auth/signin',
  });

  const recoveryPasswordRouteMatch: match<{ recovery_token?: string }> = useRouteMatch({
    path: '/auth/password-recovery/create-new-password',
  });

  const setYourProfileRouteMatch = useRouteMatch({
    path: '/set-your-profile',
  });

  useEffect(() => {
    if (!isFirstRenderRef.current) {
      return;
    }

    isFirstRenderRef.current = false;

    if (setYourProfileRouteMatch) {
      dispatch(setCurrentForm(EAuthFormType.EXTENDED_INFO));
    }

    if (recoveryPasswordRouteMatch) {
      dispatch(setCurrentForm(EAuthFormType.FORGOT_PASSWORD_RESET));
      if (queryParams?.recovery_token) {
        dispatch(setRecoveryToken(queryParams.recovery_token));
      }
    }

    if (signInRouteMatch) {
      dispatch(setCurrentFlow(EAuthFlowType.SIGN_IN));
      return;
    }

    dispatch(setCurrentFlow(EAuthFlowType.SIGN_UP));
  }, [signInRouteMatch, dispatch, recoveryPasswordRouteMatch, setYourProfileRouteMatch, queryParams?.recovery_token]);
};

export default useAuthLocationCheck;

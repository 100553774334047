import { AxiosInstance } from 'axios';
import { cloneDeep } from 'lodash';
import store from '../store';
import { log, logError } from '../tracking/tracking.slice';

const whitelist = [
  // main auth endpoints
  '/accounts/user/',
  '/accounts/registration_v2/',
  '/teams/get_auth_token/',
  '/accounts/login_v2/',
  '/generate_otp/',
  '/generate_email_otp/',
  '/accounts/logout/',

  // other auth endpoints
  '/accounts/validate_email/',
  '/organization/validate_code/',
  '/teams/is_authenticated/',
  '/accounts/validate_social_user/',
  '/accounts/password_reset/',
  '/accounts/password_reset/confirm/',
  '/teams/tenant/',
  '/accounts/attach_organization/',
];

const getIsWhitelisted = (url) => {
  return whitelist.some((endpoint) => url.includes(endpoint));
};

const requestInterceptor = (config) => {
  if (!getIsWhitelisted(config?.url)) {
    return config;
  }

  store.dispatch(
    log({
      event: 'axios: requestInterceptor',
      data: {
        config,
      },
    }),
  );

  return config;
};

const requestErrorInterceptor = (error) => {
  if (!getIsWhitelisted(error?.config?.url)) {
    return Promise.reject(error);
  }

  store.dispatch(
    logError({
      event: 'axios: requestErrorInterceptor',
      data: {
        error,
        errorResponse: cloneDeep(error?.response),
      },
    }),
  );

  return Promise.reject(error);
};

const response2XXInterceptor = (response) => {
  if (!getIsWhitelisted(response?.config?.url)) {
    return response;
  }

  store.dispatch(
    log({
      event: 'axios: response2XXInterceptor',
      data: {
        response,
      },
    }),
  );

  return response;
};

const responseErrorInterceptor = (error) => {
  if (!getIsWhitelisted(error?.config?.url)) {
    return Promise.reject(error);
  }

  store.dispatch(
    logError({
      event: 'axios: responseErrorInterceptor',
      data: {
        error,
        errorResponse: cloneDeep(error?.response),
      },
    }),
  );

  return Promise.reject(error);
};

export const withInterceptors = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(requestInterceptor, requestErrorInterceptor);
  axiosInstance.interceptors.response.use(response2XXInterceptor, responseErrorInterceptor);
  return axiosInstance;
};

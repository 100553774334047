import React from 'react';
import Modal from 'react-modal';
import styled, { useTheme } from 'styled-components';

import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';

interface Props {
  isOpen: boolean;
  modalIcon?: string;
  onConfirm: (any) => void;
  onCancel: (any) => void;
  messageText: any;
  confirmText: string;
  cancelText: string;
}

function ConfirmationModal(props: Props) {
  const { isOpen, modalIcon, onConfirm, onCancel, messageText, confirmText, cancelText } = props;
  const theme = useTheme();
  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onCancel}
      style={{
        overlay: {
          zIndex: 1000,
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: theme.colors.lightGray14,
          padding: '10px',
          paddingTop: '33px',
          borderRadius: 20,
          border: 'none',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: modalIcon ? '300px' : '200px',
          width: '320px',
          boxShadow: '0 12px 19px 0 rgba(60, 128, 209, 0.09)',
        },
      }}
    >
      <S.CloseContainer onClick={onCancel}>
        <S.CloseIcon />
      </S.CloseContainer>
      {modalIcon && <S.QuestionIcon src={modalIcon} style={{ width: 115, height: 115 }} />}
      <S.MessageText>{messageText}</S.MessageText>
      <S.Actions>
        <S.ActionContainer onClick={onCancel} isMain={false}>
          <S.CancelText>{cancelText}</S.CancelText>
        </S.ActionContainer>
        <S.ActionContainer onClick={onConfirm} isMain={true} data-cy='confirmationModalConfirm'>
          <S.ConfirmText>{confirmText}</S.ConfirmText>
        </S.ActionContainer>
      </S.Actions>
    </Modal>
  );
}

const S = {
  CloseContainer: styled.div`
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    z-index: 1000;
    background: ${({ theme }) => theme.colors.darkGray7};
    padding: 5px 8px;
  `,
  CloseIcon: styled(CloseIcon)`
    width: 10px;
    height: 10px;
    fill: white;
  `,
  QuestionIcon: styled.img`
    fill: white;
  `,
  MessageText: styled.div`
    color: ${({ theme }) => theme.colors.darkGray5};
    font-size: 18px;
    text-align: center;
    margin-top: -22px;
  `,
  ConfirmText: styled.p`
    color: ${({ theme }) => theme.colors.white};
    font-size: 15px;
  `,
  CancelText: styled.p`
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-size: 15px;
    font-weight: 900;
  `,
  Actions: styled.div`
    z-index: 10;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    width: 100%;
  `,
  ActionContainer: styled.div<{ isMain: boolean }>`
    background: ${({ theme, isMain }) => (isMain ? theme.colors.primaryBlue : 'transparent')};
    cursor: pointer;
    border-radius: 20px;
    padding: 10px 24px;
  `,
};

export default ConfirmationModal;

import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '..';

interface ISideEffects {
  opted_in?: boolean;
}

const getInitialState = () => ({
  id: '-1',
  isLoadingQuiz: false,
  isError: false,
  isProcessing: false,
  intro: null,
  settings: {
    correctAnswerIndication: false,
    showReport: false,
    backgroundColor: '#1900ff',
    questionColor: '#25b6cc',
    background: null,
    linkedBackgroundUrl: null,
    backgroundUrl: null,
  },
  multiChoiceUserChoices: [],
  // this array is a mixed object containing: available choices and user answers
  questions: [],
  subject: '',
  side_effects: {} as ISideEffects,
  organization: null,
  sharingMode: null,
  linked_cover_url: null,
  creator: null,
});

const quizSlice = createSlice({
  name: 'quiz',
  initialState: getInitialState(),
  reducers: {
    fetchQuiz: (state, action) => {
      const initialState = getInitialState();

      return {
        ...initialState,
        isLoadingQuiz: true,
        isError: false,
        id: action.payload.quizId,
      };
    },
    fetchQuizSuccess: (state, action) => {
      const {
        id,
        intro,
        questions,
        subject,
        side_effects,
        organization,
        sharingMode,
        biteShares,
        linked_cover_url,
        creator,
      } = action.payload;
      state.id = id;
      state.isLoadingQuiz = false;
      state.intro = intro;
      state.questions = questions;
      state.subject = subject;
      state.side_effects = side_effects;
      state.organization = organization;
      state.sharingMode = sharingMode;
      state.linked_cover_url = linked_cover_url;
      const formattedMultiChoiceUserChoices = biteShares
        .filter((bite) => bite?.bite_share_user?.choices?.[0]?.question_type === 'multiple choices')
        ?.map((bite) => {
          const choice = bite?.bite_share_user?.choices?.[0]?.choice;
          return { id: choice.id, isCorrect: choice.is_correct };
        });
      state.multiChoiceUserChoices = formattedMultiChoiceUserChoices;
      state.creator = creator;
    },
    fetchQuizError: (state) => {
      state.isLoadingQuiz = false;
      state.isError = true;
    },
    answerQuizQuestion: (state) => {
      state.isProcessing = true;
      state.settings.correctAnswerIndication = true;
    },
    answerQuizQuestionSuccess: (state, action) => {
      const { questionId, choices, userChoicesIds, type, text, media } = action.payload;
      const question = state.questions.find((q) => q.id === questionId);
      question.isAnswered = true;
      question.isAnsweredPreview = true;
      question.choices = choices || [];
      question.answerMedia = media;
      question.userChoicesIds = userChoicesIds;
      question.openEndedText = text;
      if (type === 'multiple choices') {
        const userChoice = choices.find((choice) => choice?.id === userChoicesIds[0]);
        state.multiChoiceUserChoices.push({ id: userChoice.id, isCorrect: userChoice.is_correct });
      }
    },
    setQuizProcessingFalse: (state) => {
      state.isProcessing = false;
    },
    setQuizSettings: (state, action) => {
      const settings = action.payload;
      const createdSettings = {
        background: settings.background,
        backgroundColor: settings.background_color,
        backgroundUrl: settings.background_url,
        correctAnswerIndication: settings.correct_answer_indication,
        linkedBackgroundUrl: settings.linked_background_url,
        questionColor: settings.question_color,
        showReport: settings.show_report,
      };
      state.settings = createdSettings;
    },
    resetQuiz: () => getInitialState(),
  },
});

export const {
  fetchQuiz,
  fetchQuizSuccess,
  fetchQuizError,
  answerQuizQuestion,
  answerQuizQuestionSuccess,
  setQuizProcessingFalse,
  setQuizSettings,
  resetQuiz,
} = quizSlice.actions;

export default quizSlice.reducer;

export const selectQuiz = (state: RootState) => state.quiz;

export const selectedQuizSettingSelector = (state: RootState) => state.quiz.settings;

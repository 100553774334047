import React from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import { selectQuiz } from '../../../store/quiz/quiz.slice';

function QuestionCount() {
  const { questions } = useSelector(selectQuiz);
  // @ts-ignore
  const questionId = Number(useRouteMatch().params.questionId);
  const activeQuestionIdx = questions.findIndex((q) => q.id === questionId) + 1;

  return (
    <S.Text>
      {activeQuestionIdx}/{questions.length}
    </S.Text>
  );
}

const S = {
  Text: styled.div`
    text-align: center;
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-size: 16px;
    margin-bottom: 22px;
  `,
};

export default QuestionCount;

import React, { CSSProperties, FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { getIsRtl } from '../../../locale/i18n';
import { ReactComponent as NextBite } from '../../../assets/icons/next-bite-btn.svg';
import { proceedToNextBiteDataSet } from './goToNextBiteButton.constants';
import useGoToNext from './hooks/useGoToNext';

interface IProps {
  style?: CSSProperties;
}

const GoNextButton: FC<IProps> = ({ style }) => {
  const { t } = useTranslation();
  const isRtl = getIsRtl();
  const { handleNext, shouldRedirectToHome } = useGoToNext();

  return (
    <S.Container isRtl={isRtl} style={style} onClick={handleNext}>
      <NextBite />
      <S.Text data-cy={proceedToNextBiteDataSet.cy}>
        {shouldRedirectToHome ? t('common.next') : t('screens.ViewPlaylist.nextBite')}
      </S.Text>
    </S.Container>
  );
};

const S = {
  Container: styled.div<{ isRtl?: boolean }>`
    width: 72px;
    height: 100px;
    cursor: pointer;
    user-select: none;
    text-align: right;
    z-index: 5;
    position: absolute;
    bottom: 0;
    ${({ isRtl }) =>
      isRtl
        ? `
      left: -1px;
      border-radius: 0 160px 0 0;
      `
        : `
      right: -1px;
      border-radius: 160px 0 0 0;
      `};

    svg {
      margin-top: 10px;
      ${({ isRtl }) =>
        isRtl
          ? `
                    margin-right: 10px;
                    transform: rotate(180deg)
                    `
          : 'margin-left: 10px;'};
    }
  `,
  Text: styled.div`
    color: ${({ theme }) => theme.colors.blue3};
    font-size: 12px;
    margin-right: 5px;
  `,
};

export default GoNextButton;

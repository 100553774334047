/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import debounce from 'lodash/debounce';
import * as microsoftTeams from '@microsoft/teams-js';

import { biteQueryRequest } from '../../store/bite/bite.actions';
import { playlistQueryRequest } from '../../store/playlist/playlist.actions';
import { microsoftTeamsContextSelector } from '../../store/auth/auth.selectors';
import { openTaskModule } from '../../services/msTeams';
import useWindowSize from '../../hooks/useWindowSize';
import { microsoftDashboardBreakPoint } from '../../components/layout/breakPoints';
import { searchSelector } from '../../store/search/search.selectors';
import gtmTrack from '../../services/googleTagManager/track';

export default ({ orgId = null, isPublicFeed = false }: { orgId?: number; isPublicFeed?: boolean } = {}) => {
  const dispatch = useDispatch();
  const search = useSelector(searchSelector);

  const microsoftTeamsContext: null | microsoftTeams.Context = useSelector(microsoftTeamsContextSelector);
  const windowSize = useWindowSize();
  const history = useHistory();

  const updateBites = useCallback(
    debounce(() => dispatch(biteQueryRequest({ orgId, isPublicFeed })), 300),
    [dispatch, orgId, isPublicFeed],
  );

  const updatePlaylists = useCallback(
    debounce(() => dispatch(playlistQueryRequest({ orgId, isPublicFeed })), 300),
    [dispatch, orgId, isPublicFeed],
  );

  useEffect(() => {
    updateBites();
  }, [search.bite, orgId, isPublicFeed]);

  useEffect(() => {
    updatePlaylists();
  }, [search.playlist, orgId, isPublicFeed]);

  const shouldOpenInsideTaskModule = microsoftTeamsContext && windowSize.width > microsoftDashboardBreakPoint;

  const handleBiteClick = (biteShareId: number, subject: string) => {
    gtmTrack('view', {
      view_type: 'bite',
      'gtm.bitesShareID': biteShareId,
      time_stamp: Date.now(),
    });
    const url = `/bites/${biteShareId}`;
    if (shouldOpenInsideTaskModule) {
      openTaskModule(url, subject);
    } else {
      history.push(url);
    }
  };

  const handlePlaylistClick = (playlist) => {
    if (playlist.is_quiz) {
      gtmTrack('view', {
        view_type: 'quiz',
        quiz_id: playlist.id,
        time_stamp: Date.now(),
      });
    } else {
      gtmTrack('view', {
        view_type: 'playlist',
        playlist_id: playlist.id,
        time_stamp: Date.now(),
      });
    }
    const url = playlist.is_quiz ? `/quiz/${playlist.id}` : `/playlists/${playlist.id}`;
    if (shouldOpenInsideTaskModule) {
      openTaskModule(url, playlist.subject);
    } else {
      history.push(url);
    }
  };

  return {
    handleBiteClick,
    handlePlaylistClick,
  };
};

import { useEffect } from 'react';
import removeQueryParams from '../utils/removeQueryParams';

const useRemoveQueryParams = (params: string[]) => {
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const isParamsExist = params.some((param) => queryParams.has(param));
    if (!isParamsExist) {
      return;
    }
    removeQueryParams(params);
  }, [params]);
};

export default useRemoveQueryParams;

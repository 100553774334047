import queryString from 'query-string';

import gtmTrack from '../../../services/googleTagManager/track';

export const getNextPlaylistItem = (history, selectedPlaylist, selectedBite, auth) => {
  const queryParams = queryString.parse(window.location.search, {
    parseBooleans: true,
  });
  const isPreview = queryParams?.isPreview;

  const noEmptyBites = selectedPlaylist?.bite_shares.filter((bite) => !bite.no_sections);
  let nextBiteUrl = `/playlists/${selectedPlaylist?.id}${isPreview ? '?isPreview=1' : ''}`;

  for (let i = 0; i < noEmptyBites.length; i++) {
    if (noEmptyBites[i].id === selectedBite?.id && i + 1 <= noEmptyBites.length - 1) {
      gtmTrack('next_bite_playlist', { bites_user_id: auth?.id });
      nextBiteUrl = `/bites/${noEmptyBites[i + 1].id}/?playlistId=${selectedPlaylist?.id}${
        isPreview ? '&isPreview=1' : ''
      }`;
    }
  }

  history.push(nextBiteUrl, { withoutModal: true });
};

import React from 'react';

import Home from './Home';

import MicrosoftHome from '../../microsoft-ui/screens/Home';
import useIsInsideMsTeams from '../../hooks/useIsInsideMsTeams';

const CurrentHomeScreen = () => {
  const { isInsideMsTeams } = useIsInsideMsTeams();
  const Component = isInsideMsTeams ? MicrosoftHome : Home;
  return <Component />;
};

export default CurrentHomeScreen;

import React, { FC } from 'react';
import styled from 'styled-components';

const Preloader: FC = () => {
  return (
    <S.LoaderContainer>
      <object aria-label={'preloader'} data='https://bites-bucket.s3-eu-west-1.amazonaws.com/feed_preloader.svg' />
    </S.LoaderContainer>
  );
};

const S = {
  LoaderContainer: styled.div`
    justify-content: center;
    align-self: center;
    width: 70px;
    height: 70px;
  `,
};

export default Preloader;

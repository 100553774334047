import React, { FC, useEffect } from 'react';
import styled from 'styled-components';

import Carusel from './components/Carusel';

import { ICard } from '../../../types/summary';
import gtmTrack from '../../../services/googleTagManager/track';
import { useSelector } from 'react-redux';
import { selectedBiteSelector } from '../../../store/bite/bite.selectors';
import useQueryParams from '../../../hooks/useQueryParams';

interface IProps {
  cards: ICard[];
  summaryTranslations: string[];
  hasDiscussion: boolean;
}

const Summary: FC<IProps> = ({ cards, summaryTranslations, hasDiscussion }) => {
  const { queryParams } = useQueryParams();
  const playlistId = queryParams?.playlistId;

  const { selectedBite } = useSelector(selectedBiteSelector);

  useEffect(() => {
    gtmTrack('summary_section', {
      bite_id: selectedBite?.bite,
      bite_share_id: selectedBite?.id,
      content_org_id: selectedBite?.orgid,
      sharing_mode: selectedBite?.sharing_mode,
      playlist_id: playlistId,
    });
  }, [playlistId, selectedBite]);

  return (
    <S.Container data-intercom-target={'summary-tab'}>
      <Carusel cards={cards} summaryTranslations={summaryTranslations} hasDiscussion={hasDiscussion} />
    </S.Container>
  );
};

const S: any = {};
S.Container = styled.div`
  padding-top: 30px;
  height: 100%;
  overflow-x: hidden;
  overscroll-behavior: none;
`;

export default React.memo(Summary);

import { useSelector } from 'react-redux';

import { microsoftTeamsContextSelector } from '../store/auth/auth.selectors';

const useIsInsideMsTeams = () => {
  const microsoftTeamsContext = useSelector(microsoftTeamsContextSelector);
  const isInsideTeamsMobile =
    microsoftTeamsContext?.hostClientType === 'android' || microsoftTeamsContext?.hostClientType === 'ios';

  const isInsideMsTeams =
    !!microsoftTeamsContext &&
    (isInsideTeamsMobile ||
      window.name === 'embedded-page-container' ||
      window.name === 'extension-tab-frame' ||
      window.name === 'appAuthWindow');

  return {
    isInsideMsTeams,
    isInsideTeamsMobile,
  };
};

export default useIsInsideMsTeams;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { logout, setIsInitialAuthInProgress, setTokenAndGetUser } from '../store/auth/auth.actions';
import { microsoftTeamsContextSelector } from '../store/auth/auth.selectors';
import { log, logError } from '../store/tracking/tracking.slice';
import * as calls from '../store/api/calls/auth.calls';

const useInitAuth = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [isInitAuthFinished, setIsInitAuthFinished] = useState(false);

  const microsoftTeamsContext = useSelector(microsoftTeamsContextSelector);

  const processId = useMemo(() => uuidv4(), []);

  useEffect(() => {
    async function acquireAuthToken() {
      const query = new URLSearchParams(location.search);
      const tokenFromQuery = query.get('token');

      if (tokenFromQuery) {
        const paramsList = [];
        for (const [key, value] of query.entries()) {
          if (key !== 'token') {
            paramsList.push(`${key}=${value}`);
          }
        }

        dispatch(
          log({
            event: 'useInitAuth: tokenFromQuery',
            processId,
            data: {
              tokenFromQuery,
            },
          }),
        );

        dispatch(setTokenAndGetUser({ token: tokenFromQuery, processId }));
        const pathname = window.location.pathname;
        history.replace(pathname + (paramsList.length > 0 ? '?' + paramsList.join('&') : ''));

        setIsInitAuthFinished(true);
        return;
      }

      const otat = query.get('otat');
      if (otat) {
        try {
          const response = await calls.validateOtat(otat);
          if (response.status > 299) {
            throw new Error(`Otat validation response status is ${response.status}`);
          }

          const token = response.data.token;
          dispatch(
            log({
              event: 'useInitAuth: tokenFromOtat',
              processId,
              data: {
                token,
                otat,
              },
            }),
          );

          dispatch(setTokenAndGetUser({ token, processId }));
          setIsInitAuthFinished(true);

          return;
        } catch (error) {
          logError({
            event: 'useInitAuth: Otat validation failure',
            processId,
            data: {
              error,
            },
          });
          dispatch(setIsInitialAuthInProgress(false));
        }
      }

      const depointToken = query.get('dpt');
      if (depointToken) {
        try {
          const response = await calls.validateDpt(depointToken);
          if (response.status > 299) {
            throw new Error(`Depoint Token validation response status is ${response.status}`);
          }

          const token = response.data.token;
          dispatch(
            log({
              event: 'useInitAuth: tokenFromDepoint',
              processId,
              data: {
                token,
                depointToken,
              },
            }),
          );

          dispatch(setTokenAndGetUser({ token, processId }));
          setIsInitAuthFinished(true);

          return;
        } catch (error) {
          logError({
            event: 'useInitAuth: Depoint Token validation failure',
            processId,
            data: {
              error,
            },
          });
          dispatch(setIsInitialAuthInProgress(false));
        }
      }

      dispatch(
        log({
          event: 'useInitAuth: not tokenFromQuery',
          processId,
          data: {
            microsoftTeamsContext,
          },
        }),
      );

      dispatch(setTokenAndGetUser({ processId }));
      setIsInitAuthFinished(true);
    }

    const query = new URLSearchParams(window.location.search);
    if (query.get('action') === 'logout') {
      dispatch(
        log({
          event: 'initAuth: action logout',
        }),
      );

      dispatch(logout());
      setIsInitAuthFinished(true);
      return;
    }

    acquireAuthToken();
  }, []);

  return { isInitAuthFinished };
};

export default useInitAuth;

import React, { FC, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import useCustomTranslation from '../../../hooks/useCustomTranslation';
import { NavLink } from 'react-router-dom';
import Search from './Search';
import { CONTROLLERS_BAR_HEIGHT } from '../Home.constants';
import { useSelector } from 'react-redux';
import { organizationHasFeatureFlagSelector } from '../../../store/profile/profile.selectors';
import { Features } from '../../../utils/featureFlag/featureFlag.types';
import { orgSelector } from '../../../store/organization/organization.selectors';

interface IProps {
  pathPrefix?: string;
}
const ControllersBar: FC<IProps> = ({ pathPrefix = '' }) => {
  const { id: orgId } = useSelector(orgSelector);
  const askAiFeatureFlag = useSelector(
    organizationHasFeatureFlagSelector({
      orgId,
      feature: Features.ASK_AI_FEED,
    }),
  );

  const [containerWidth, setContainerWidth] = useState(0);
  const { prefixedT } = useCustomTranslation('common');
  const containerRef = useRef();

  useEffect(() => {
    const containerEl = containerRef.current as HTMLElement;
    setContainerWidth(containerEl.offsetWidth);
  }, [containerRef]);

  return (
    <S.Wrapper ref={containerRef}>
      <S.Link to={`${pathPrefix}/bites`} id='btnNavToMyBites' data-cy='bitesTab'>
        {prefixedT('MyBites')}
      </S.Link>
      <S.Link data-cy='playlistsTab' to={`${pathPrefix}/playlists`} id='btnNavToPlaylists'>
        {prefixedT('Playlists')}
      </S.Link>
      {!askAiFeatureFlag && <Search containerWidth={containerWidth} />}
    </S.Wrapper>
  );
};

const S: any = {
  Wrapper: styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: ${CONTROLLERS_BAR_HEIGHT}px;
    margin-bottom: 20px;
    margin-inline-end: 20px;
  `,
  Link: styled(NavLink)`
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    border-bottom: 1px solid transparent;
    padding: 0 5px;
    margin: 0 10px;
    text-align: center;

    &:hover {
      text-decoration: none;
    }

    ${({ theme }) => css`
      color: ${theme.colors.darkPrimary};
      &.active {
        color: ${theme.colors.primaryBlue};
        font-weight: 600;
        border-color: ${theme.colors.primaryBlue};
      }
    `}
  `,
};

export default ControllersBar;

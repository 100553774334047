import styled, { css } from 'styled-components';

const StyledLink = styled.a`
  display: block;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
  ${({ theme }) => css`
    color: ${theme.colors.primaryBlue};
  `}
`;

export default StyledLink;

import { IResponseOpenEndedAnswer } from './../../../types/biteShare';

import BitesApi, { API_BASE_URL } from '..';
import axios from 'axios';

export const updateBiteShareUserProgress = (biteShareUserId, progress) =>
  BitesApi.patch(`/bite_share_users/${biteShareUserId}/`, progress);

export const addOpenEndedAnswer = (answer: IResponseOpenEndedAnswer) => {
  const data = new FormData();
  Object.entries(answer).forEach(([key, value]) => {
    if (key === 'choices_ids') {
      data.append(key, JSON.stringify(value));
      return;
    }
    data.append(key, value);
  });
  return BitesApi.post('/user_choices/', data);
};

export const fullyWatchedBiteIntroVideo = (biteShareUserId: number) =>
  BitesApi.put(`bite_share_users/${biteShareUserId}/`, { video_progress: 100 });

export const getLastBiteActivity = (params: { biteShareId: number }) => {
  return axios.post(`${API_BASE_URL}/api/common_services/content/bite_shares/last`, params);
};

import React, { FC } from 'react';
// eslint-disable-next-line import/named
import styled, { css, DefaultTheme } from 'styled-components';
import Linkify from 'react-linkify';

import { ICard } from '../../../../types/summary';
import { getIsRtl } from '../../../../locale/i18n';
import { lightOrDark } from '../../../../style/lightOrDarkColor';

interface IProps {
  card: ICard;
  summaryTranslation?: string;
  position: {
    cardPosition: number;
    totalCards: number;
  };
}

const Card: FC<IProps> = ({ card, summaryTranslation, position }) => {
  const { text, color, font } = card;
  const { cardPosition, totalCards } = position;
  const textColor = lightOrDark(color);

  const lineHeight = 30 - Math.max(card.text.split(/\r\n|\r|\n/).length, card.text.length / 30);
  const fontSize = 32 - 3 * Math.floor(card.text.length / 20);

  return (
    <S.Container backgroundColor={color} data-cy={`card-${cardPosition}`} id='summaryCard' color={textColor}>
      <S.Order id='summaryCardPosition' color={textColor}>
        {cardPosition} / {totalCards}
      </S.Order>
      <S.Text lineHeight={lineHeight} fontSize={fontSize} font={font} color={textColor} id='summaryCardText'>
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a target='_blank' rel='noreferrer' href={decoratedHref} key={key}>
              {decoratedText}
            </a>
          )}
        >
          {summaryTranslation || text}
        </Linkify>
      </S.Text>
    </S.Container>
  );
};

const S = {
  Container: styled.div<{
    backgroundColor: string;
    color: string;
    theme: DefaultTheme;
  }>`
    width: 285px;
    height: 255px;
    margin-right: 20px;
    border-radius: 12px;
    padding: 0 15px;
    background-color: ${({ backgroundColor }) => backgroundColor};
    color: ${({ color, theme }) => (color === 'white' ? theme.colors.white : theme.colors.black)};
  `,
  Order: styled.p`
    text-align: center;
    margin-top: 20px;
    margin-bottom: 5px;
  `,
  Text: styled.p<{ fontSize: number; font: string; lineHeight: number }>`
    text-align: center;
    direction: ${() => (getIsRtl() ? 'rtl' : 'ltr')};
    writing-mode: horizontal-tb;
    ${({ theme, fontSize, font, lineHeight }) => css`
      line-height: ${lineHeight}px;
      font-family: ${font === 'classic'
        ? theme.fontFamilies.FrankRuhlLibre
        : font === 'typewriter'
        ? theme.fontFamilies.Fredoka
        : theme.fontFamilies.AmaticSC};
      font-size: ${fontSize}px;
    `}
    word-break: break-word;
  `,
};

export default Card;

import styled, { css } from 'styled-components';

const Stat = {
  Container: styled.div(
    ({ theme }) => css`
      margin-bottom: 20px;
      @media ${theme.breakPoints.mobileL} {
        border: 1px solid ${theme.msColors.lightBorderColor};
        flex: 1;
        margin: 0;
        padding: 6px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: row-reverse;
      }
    `,
  ),
  Number: styled.div(
    ({ theme }) => css`
      font-size: 38px;
      font-weight: 300;
      @media ${theme.breakPoints.mobileL} {
        font-size: 24px;
      }
    `,
  ),
  Text: styled.p(
    ({ theme }) => css`
      font-size: 14px;
      font-weight: 500;
      @media ${theme.breakPoints.mobileL} {
        font-size: 12px;
      }
    `,
  ),
  LightTextContainer: styled.div`
    margin-top: 5px;
  `,
  LightText: styled.span`
    font-size: 10px;
    margin-right: 5px;
    color: #616161;
  `,
};

export default Stat;

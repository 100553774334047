import { css } from 'styled-components';

import { getIsRtl } from '../../locale/i18n';

const styles = css`
  position: absolute;
  ${() =>
    getIsRtl()
      ? `
    left: -1px;
    transform: scaleX(-1);
    `
      : `
    right: -1px;
    `};
  width: 40px;
  height: 40px;
  top: 18px;
  cursor: pointer;
`;

export default styles;

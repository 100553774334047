import ReactPlayer from 'react-player';
import { createReactPlayer } from 'react-player/lib/ReactPlayer';
import ReactPlayerFilePlayer from 'react-player/lib/players/FilePlayer';
export const HTML5Player = createReactPlayer([
  {
    key: 'file-custom',
    canPlay: () => true,
    lazyPlayer: ReactPlayerFilePlayer,
  },
]);

export default ReactPlayer;

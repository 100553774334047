import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const SectionTitleHeader = ({ currentSection, title }) => {
  const { t } = useTranslation();
  return (
    <S.SectionTitleHeader>
      <S.SectionTitle>{t(`screens.viewBite.sectionTitle.${currentSection}`)}</S.SectionTitle>
      <S.Title>{title}</S.Title>
    </S.SectionTitleHeader>
  );
};

const S = {
  SectionTitleHeader: styled.div`
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 10px;
  `,
  SectionTitle: styled.div`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;
  `,
  Title: styled.div`
    text-align: center;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    margin-top: 4px;
    max-width: calc(100% - 120px);
  `,
};

export default SectionTitleHeader;

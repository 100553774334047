import React, { FC, useCallback } from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import Button, { IProps as IButtonProps } from './Button';

import { ReactComponent as NextArrowSVG } from '../../../assets/icons/next.svg';
import { Loader, StyledIconWrapper } from '..';
import { getIsRtl } from '../../../locale/i18n';
import { questionContinueButtonDataSet } from './continueButton.constants';

interface IProps extends IButtonProps {
  className?: string;
  text?: string;
  timer?: number;
  id?: string;
  dataCy?: string;
  style?: any;
  isBigText?: boolean;
  paddingStart?: number;
  isLoading?: boolean;
}

const ContinueButton: FC<IProps> = ({
  disabled,
  text,
  style,
  id,
  dataCy = 'continueButton',
  isBigText,
  paddingStart,
  isLoading,
  ...props
}) => {
  const { t } = useTranslation();

  const renderIcon = useCallback(
    () => (isLoading ? <Loader size='150%' /> : <StyledContinueIcon disabled={disabled} />),
    [isLoading, disabled],
  );

  return (
    <Button
      data-cy={dataCy}
      {...props}
      disabled={disabled}
      isBigText={isBigText}
      paddingStart={paddingStart}
      style={{ flexDirection: getIsRtl() ? 'row-reverse' : 'row', ...style }}
      icon={renderIcon}
      id={id}
    >
      {text ? text : t('common.Continue')}
    </Button>
  );
};

const StyledContinueIcon: FC<{ disabled: boolean }> = ({ disabled }) => {
  const theme = useTheme();
  return (
    <StyledIconWrapper
      disabled={disabled}
      style={{
        transform: getIsRtl() ? 'rotate(180deg)' : '',
        backgroundColor: theme.colors.white,
      }}
      data-cy={questionContinueButtonDataSet.cy}
    >
      <StyledNextArrowSVG disabled={disabled} />
    </StyledIconWrapper>
  );
};

const StyledNextArrowSVG = styled(NextArrowSVG)<{ disabled: boolean }>`
  width: 12px;
  fill: ${({ theme, disabled }) => (disabled ? theme.colors.lightGray2 : theme.colors.primaryBlue)};
`;

export default ContinueButton;

import { jsPDF } from 'jspdf';
import { AWS_URL } from '../../../constants/app';
import themes from '../../../style/themes';
import { font } from './Karantina-Regular-normal';

interface IProps {
  userName: string;
  israeliId: string;
  serialNumber: string;
  gender: number;
}

function containsHeb(str) {
  return /[\u0590-\u05FF]/.test(str);
}

const FEMALE_CERTIFICATE = `${AWS_URL}/staticfiles/bites-web/assets/certificate-female.png`;
const MALE_CERTIFICATE = `${AWS_URL}/staticfiles/bites-web/assets/certificate-male.png`;

async function createPdf({ userName, israeliId, serialNumber, gender }: IProps) {
  const doc = new jsPDF();

  //for putting the text in the center of the page- adjusted to the text
  const getCenteredTextX = (text: string) => {
    const textWidth = doc.getTextWidth(text);
    const pageWidth = doc.internal.pageSize.width;
    return (pageWidth - textWidth) / 2;
  };

  const [year, month, day] = new Date().toISOString().slice(0, 10).split('-');
  const formattedDate = `${day}-${month}-${year}`;
  doc.addFileToVFS('Karantina-Regular-normal.ttf', font);
  doc.addFont('Karantina-Regular-normal.ttf', 'Karantina-Regular-normal', 'normal');
  doc.setFont('Karantina-Regular-normal');

  doc.setFontSize(50);
  //gender checking for the pdf
  const certificate = gender === 1 ? FEMALE_CERTIFICATE : MALE_CERTIFICATE;
  doc.addImage(certificate, 'PNG', 0, 0, 210, 270);

  doc.setTextColor('#2A5980');
  //checking if the name is in hebrew- then needs to revert the text
  if (containsHeb(userName)) {
    doc.setR2L(true);
  }

  const centeredUserNameX = getCenteredTextX(userName);
  doc.text(userName, centeredUserNameX, 65);

  doc.setR2L(false);
  doc.setFontSize(26);
  const centeredIsraeliIdX = getCenteredTextX(israeliId);
  doc.text(israeliId, centeredIsraeliIdX, 85);

  doc.setFontSize(20);
  const centeredFormattedDateX = getCenteredTextX(formattedDate);
  doc.text(formattedDate, centeredFormattedDateX, 187);
  doc.setFontSize(15);
  doc.setTextColor(themes.colors.lightGray6);
  doc.text(serialNumber, 142, 260.5);

  doc.save('certificate.pdf');
}

export default createPdf;

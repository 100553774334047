import { ILocale } from '../../hooks/useTranslationOptions';
import { RootState } from '../index';

export const configSelector = (state: RootState) => state.appActivity.config;
export const geolocationSelector = (state: RootState) => state.appActivity.geolocation;
export const showSomethingWentWrongSelector = (state: RootState) => state.appActivity.showSomethingWentWrong;
export const translationsAreInitializedSelector = (state) => state.appActivity.translationsAreInitialized;
export const countryCodeSelector = (state) => geolocationSelector(state)?.location?.country?.alpha2;
export const contentLocalesSelector = (state): ILocale[] => state.appActivity.contentLocales.data;
export const contentLocalesIsLoadingSelector = (state): boolean => state.appActivity.contentLocales.isLoading;
export const contentLocalesErrorSelector = (state): any => state.appActivity.contentLocales.error;

import React, { FC, memo } from 'react';
import styled, { css } from 'styled-components';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { Fabric } from '@fluentui/react/lib/Fabric';

import microsoftTeams from '../../../services/msTeams';
import BitesImage from '../../../assets/images/bite-logo.png';
import { logError } from '../../../store/tracking/tracking.slice';
import { useDispatch } from 'react-redux';

const MsTeamsLogin: FC = () => {
  const dispatch = useDispatch();

  const handleSignInClick = () => {
    microsoftTeams.authentication.authenticate({
      url: window.location.origin + '/auth/signin?dontRedirect=true&inMSTeams=true',
      successCallback: () => {
        window.location.href = window.location.origin;
      },
      failureCallback: (reason) => {
        dispatch(logError({ event: 'Login failed', reason }));
      },
    });
  };
  return (
    <Fabric>
      <S.Container>
        <S.BitesLogo src={BitesImage} />
        <S.Title>Log in to get started</S.Title>
        <S.ExplanationText>
          To start using Bites for Microsoft Teams and view your dashboard log in to your account.
        </S.ExplanationText>
        <S.SignInButton onClick={handleSignInClick}>Login with Bites</S.SignInButton>
      </S.Container>
    </Fabric>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px;
  align-items: center;
`;

const SignInButton = styled(PrimaryButton)(
  () => css`
    background-color: ${({ theme }) => theme.msColors.purple};
    border-color: ${({ theme }) => theme.msColors.purple};
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);
  `,
);

const Title = styled.p`
  font-size: 20px;
  margin-bottom: 8px;
`;

const ExplanationText = styled.p`
  max-width: 350px;
  text-align: center;
  margin-bottom: 10px;
`;
const BitesLogo = styled.img`
  width: 60px;
  height: 75px;
  margin-top: 30px;
  margin-bottom: 20px;
`;

const S = { SignInButton, Container, BitesLogo, Title, ExplanationText };

export default memo(MsTeamsLogin);

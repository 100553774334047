import PlaylistActionTypes from './playlist.types';
import BiteShareUserActionTypes from '../biteShareUser/biteShareUser.types';
import { sortPlaylistBites } from './helpers';
import { IPlaylist } from '../../types/playlist';

const initState = {
  playlists: {
    playlists: [] as IPlaylist[],
    isLoading: false,
    isLoadingNext: false,
    error: '',
    totalPlaylists: 0,
    nextPage: 1,
  },
  selectedPlaylist: {
    selectedPlaylist: null as IPlaylist,
    isLoading: false,
    error: '',
  },
};

const playlistReducer = (state = initState, action): typeof initState => {
  switch (action.type) {
    case PlaylistActionTypes.PLAYLIST_QUERY_REQUEST:
      return {
        ...state,
        playlists: {
          ...state.playlists,
          isLoading: true,
          error: '',
          nextPage: 1,
        },
      };
    case PlaylistActionTypes.PLAYLIST_QUERY_SUCCESS: {
      const { playlists, totalPlaylists } = action.payload;
      return {
        ...state,
        playlists: {
          ...state.playlists,
          playlists,
          isLoading: false,
          totalPlaylists,
          nextPage: 2,
        },
      };
    }
    case PlaylistActionTypes.PLAYLIST_LOAD_NEXT:
      return {
        ...state,
        playlists: {
          ...state.playlists,
          isLoadingNext: true,
          error: '',
        },
      };
    case PlaylistActionTypes.PLAYLIST_LOAD_NEXT_SUCCESS: {
      const playlists = state.playlists.playlists.concat(action.payload);
      return {
        ...state,
        playlists: {
          ...state.playlists,
          playlists,
          isLoadingNext: false,
          nextPage: state.playlists.nextPage + 1,
        },
      };
    }
    case PlaylistActionTypes.FETCH_SELECTED_PLAYLIST_REQUEST:
      return {
        ...state,
        selectedPlaylist: {
          selectedPlaylist: null,
          isLoading: true,
          error: '',
        },
      };
    case PlaylistActionTypes.FETCH_SELECTED_PLAYLIST_SUCCESS:
      return {
        ...state,
        selectedPlaylist: {
          ...state.selectedPlaylist,
          selectedPlaylist: {
            ...action.payload,
            bite_shares: sortPlaylistBites(action.payload.bite_shares),
          },
          isLoading: false,
        },
      };

    case BiteShareUserActionTypes.UPDATE_BITE_SHARE_USER_PROGRESS_SUCCESS:
      return (() => {
        const selectedPlaylist = state.selectedPlaylist?.selectedPlaylist;

        if (!selectedPlaylist) {
          return state;
        }

        const biteShare = selectedPlaylist?.bite_shares.find((bs) => bs.id === action.payload.bite_share);

        if (!biteShare) {
          return state;
        }

        const updatedBiteShare = {
          ...biteShare,
          bite_share_user: {
            ...biteShare.bite_share_user,
            bite_progress: action.payload.bite_progress,
          },
        };

        return {
          ...state,
          selectedPlaylist: {
            ...state.selectedPlaylist,
            selectedPlaylist: {
              ...selectedPlaylist,
              bite_shares: selectedPlaylist.bite_shares.map((bs) => {
                if (bs.id === updatedBiteShare.id) {
                  return updatedBiteShare;
                }
                return bs;
              }),
            },
          },
        };
      })();

    case PlaylistActionTypes.RESET_SELECTED_PLAYLIST:
      return {
        ...state,
        selectedPlaylist: initState.selectedPlaylist,
      };
    default:
      return state;
  }
};

export default playlistReducer;

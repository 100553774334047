import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { bodyPortal } from '../../../../App';
import useIsDisplayedIsVisible from '../../../../hooks/useIsDisplayedIsVisible';
import { useIsMounted } from '../../../../hooks/useIsMounted';

interface IProps {
  targetRef: React.RefObject<HTMLElement>;
  isVisible: boolean;
  showDelay?: number;
  children: React.ReactNode;
  onClick?: () => void;
}

interface IPosition {
  bottom: number;
  left: number;
}

// currently developed to display above the target element
const Popover = ({ targetRef, isVisible: isVisibleProp, showDelay = 0, children, onClick }: IProps) => {
  const isMountedRef = useIsMounted();

  const [position, setPosition] = useState<IPosition>({ bottom: 0, left: 0 });
  const [isDisplayedState, setIsDisplayedState] = useState(false);

  const { isDisplayed, isVisible } = useIsDisplayedIsVisible(isDisplayedState, 250);

  useEffect(() => {
    if (!isVisibleProp || !targetRef.current) {
      setIsDisplayedState(false);
      return;
    }

    setTimeout(() => {
      const rect = targetRef.current.getBoundingClientRect();

      const bottom = window.innerHeight - rect.top;
      const left = rect.left + rect.width / 2;

      setPosition({ bottom, left });

      if (!isMountedRef.current) {
        return;
      }

      setIsDisplayedState(true);
    }, showDelay);
  }, [isMountedRef, isVisibleProp, showDelay, targetRef]);

  const ref = useRef();

  return createPortal(
    <S.Container isDisplayed={isDisplayed} isVisible={isVisible} position={position} onClick={onClick} ref={ref}>
      {children}
    </S.Container>,
    bodyPortal.current,
  );
};
export default Popover;

const S = {
  Container: styled.div<{ isDisplayed: boolean; isVisible: boolean; position: IPosition; onClick: () => void }>`
    position: fixed;
    bottom: ${({ position }) => position.bottom + 10}px;
    left: ${({ position }) => position.left}px;
    display: ${({ isDisplayed }) => (isDisplayed ? 'block' : 'none')};
    padding: 4px 16px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.pinkError};
    transform: translateX(-50%);
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: opacity 150ms ease-in-out;
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};

    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-10px);
      border: 10px solid transparent;
      border-top-color: ${({ theme }) => theme.colors.pinkError};
    }
  `,
};

import React, { FC, useRef } from 'react';
import styled, { css, useTheme } from 'styled-components';
import ReactPlayer from 'react-player';

import { VideoPlayer } from '../shared';
import { ReactComponent as CloseSVG } from '../../assets/icons/close.svg';

interface IProps {
  closeModal: () => void;
  media_url: string | null;
  file_type: 'video' | 'youtube' | 'image';
  videoStartsAt: null | number;
  videoEndsAt: null | number;
  stayOnCurrentSection?: boolean;
  section?: string | null;
}

const VideoModal: FC<IProps> = ({
  media_url,
  file_type,
  videoStartsAt,
  videoEndsAt,
  closeModal,
  stayOnCurrentSection,
  section,
}) => {
  const playerRef = useRef<ReactPlayer>(null);
  const theme = useTheme();
  return (
    <S.Container>
      <S.TopContainer>
        <CloseSVG onClick={closeModal} width='15px' height='15px' fill={theme.colors.primaryBlue} />
      </S.TopContainer>

      <S.VideoContainer>
        <VideoPlayer
          closeModal={closeModal}
          stayOnCurrentSection={stayOnCurrentSection}
          {...{
            media_url,
            file_type,
            videoStartsAt,
            videoEndsAt,
            section,
            playerRef,
          }}
        />
      </S.VideoContainer>
    </S.Container>
  );
};

const S = {
  Container: styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    z-index: 20;

    display: flex;
    justify-content: flex-start;

    ${({ theme }) => css`
      background-color: ${theme.colors.darkBlueBg};
    `}
  `,

  ContentContainer: styled.div``,

  VideoContainer: styled.div`
    padding: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    video {
      border-radius: 10px;
      object-fit: contain;
      flex: 1;
    }
  `,

  TopContainer: styled.div`
    padding: 17px;
    display: flex;
    flex-direction: row-reverse;
    position: absolute;
    z-index: 999;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 34px;
  `,

  Instructions: styled.p`
    text-align: center;
    margin-top: 68px;
    font-size: 16px;
    line-height: 20px;
    ${({ theme }) => css`
      color: ${theme.colors.white};
    `}
  `,
};

export default VideoModal;

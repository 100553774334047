import ProfileActionTypes from './profile.types';

const initialState = {
  organization: null,
  featureFlagsByOrgId: {},
  isLoading: false,
};

const profileReducer = (state = initialState, action: any): typeof initialState => {
  switch (action.type) {
    case ProfileActionTypes.GET_ORGANIZATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ProfileActionTypes.GET_ORGANIZATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        organization: action.payload,
      };
    case ProfileActionTypes.GET_ORGANIZATION_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case ProfileActionTypes.SET_ORGANIZATION_FEATURE_FLAGS:
      return {
        ...state,
        featureFlagsByOrgId: {
          ...state.featureFlagsByOrgId,
          [action.payload.orgId]: action.payload.featureFlags,
        },
      };

    default:
      return state;
  }
};

export default profileReducer;

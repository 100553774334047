import styled, { css } from 'styled-components';

const StyledIndicatorItem = styled.div<{ isActive: boolean }>`
  width: 60px;
  height: 6px;
  border-radius: 6px;

  ${({ theme, isActive }) => css`
    background-color: ${isActive ? theme.colors.blue3 : theme.colors.lightGray5};
  `}
`;

export default StyledIndicatorItem;

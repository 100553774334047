import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { CollapsablePanel } from './CollapsablePanel';

import useCustomTranslation from '../../../../hooks/useCustomTranslation';
import { selectedPlaylistSelector } from '../../../../store/playlist/playlist.selectors';
import { selectedBiteSelector } from '../../../../store/bite/bite.selectors';
import GoNextButton from '../../GoNextBite/GoNextButton';
import { getIsRtl } from '../../../../locale/i18n';

export const NextBitePanel = () => {
  const { prefixedT } = useCustomTranslation('screens.ViewPlaylist.Modal');
  const { selectedPlaylist } = useSelector(selectedPlaylistSelector);
  const { selectedBite } = useSelector(selectedBiteSelector);
  const { bite_shares } = selectedPlaylist;

  const noEmptyBites = useMemo(() => {
    return bite_shares.filter((bite) => !bite.no_sections);
  }, [bite_shares]);

  const getNextPlaylistItem = useMemo(() => {
    let nextBite = null;

    for (let i = 0; i < noEmptyBites.length; i++) {
      if (noEmptyBites[i].id === selectedBite?.id && i + 1 <= noEmptyBites.length - 1) {
        nextBite = noEmptyBites[i + 1];
      }
    }
    if (nextBite === null) {
      return { toOpen: false, nextBite: nextBite };
    }
    return { toOpen: true, nextBite };
  }, [noEmptyBites, selectedBite?.id]);

  return (
    <CollapsablePanel open={getNextPlaylistItem.toOpen}>
      <S.ModalContent>
        <S.ModalLogo>
          <S.ModalLogoBackground image={getNextPlaylistItem?.nextBite?.cover_url} />
        </S.ModalLogo>
        <S.ModalContainer>
          <S.ModalTitle>{prefixedT('Heading')} </S.ModalTitle>
          <S.SubTitleNCounterNbutton>
            <S.SubTitleNCounter>
              <S.ModalSubTitle>{getNextPlaylistItem?.nextBite?.subject}</S.ModalSubTitle>
              <S.countdownBackground>
                <S.countdownFill></S.countdownFill>
              </S.countdownBackground>
            </S.SubTitleNCounter>
            <GoNextButton />
          </S.SubTitleNCounterNbutton>
        </S.ModalContainer>
      </S.ModalContent>
    </CollapsablePanel>
  );
};

const ModalContent = styled.div`
  width: 100%;
  background: #f5f5f5;
  border-radius: 0 0 25px 25px;
  margin-bottom: 0px;
  display: flex;
  ${() =>
    getIsRtl()
      ? `
      flex-direction: row-reverse;
                  `
      : `
      flex-direction: row;
                  `};
`;

const ModalLogo = styled.div`
  border-radius: 20%;
  background: #fff;
  margin: 12px;
  display: flex;
  justify-content: center;
`;

const ModalLogoBackground = styled.div<{ image: string }>`
  background-image: url(${({ image }) => image});
  width: 120px;
  border-radius: 20%;
  background-size: cover;
`;
const SubTitleNCounterNbutton = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${() =>
    getIsRtl()
      ? `
      flex-direction: row-reverse;
                  `
      : `
      flex-direction: row;
                  `};
`;

const SubTitleNCounter = styled.div`
  flex-grow: 1;
  widht: 100%;
  display: flex;
  flex-direction: column;
`;
const ModalTitle = styled.div`
  margin-top: 12px;
  width: 100%;
  color: #6a6a6a;
  display: flex;
  font-size: 16px;
  ${() =>
    getIsRtl()
      ? `
      flex-direction: row-reverse;
                  `
      : `
      flex-direction: row;
                  `};
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${() =>
    getIsRtl()
      ? `
      align-items: flex-end;
                  `
      : `
      align-items: flex-start;
                  `};
`;

const ModalSubTitle = styled.div`
  margin-top: 12px;
  width: 80%;
  color: #1253fa;
  line-height: 1.25;
  font-size: 18px;
  display: flex;
  ${() =>
    getIsRtl()
      ? `
      flex-direction: row-reverse;
                  `
      : `
      flex-direction: row;
                  `};
`;
const countdownBackground = styled.div`
  height: 20px;
  margin-top: 10px;
  box-sizing: border-box;
  border: 1px solid #e5e5e5;
  background-color: #ffffff;
  border-radius: 25px;
  display: flex;
`;

const countdownFill = styled.div`
  height: 100%;
  background-color: #1253fa;
  display: flex;
  border-radius: 25px;
  animation: progress ease 5s;
  @keyframes progress {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }
`;

const S = {
  ModalContent,
  ModalLogo,
  ModalTitle,
  ModalContainer,
  ModalSubTitle,
  ModalLogoBackground,
  countdownBackground,
  countdownFill,
  SubTitleNCounterNbutton,
  SubTitleNCounter,
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, FC } from 'react';
import styled, { css } from 'styled-components';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import { ReactComponent as SearchSVG } from '../../../assets/icons/search.svg';
import { ReactComponent as CloseSVG } from '../../../assets/icons/close.svg';
import { updateSearch, resetSearch } from '../../../store/search/search.actions';
import { searchSelector } from '../../../store/search/search.selectors';
import useCustomTranslation from '../../../hooks/useCustomTranslation';
import { CONTROLLERS_BAR_HEIGHT } from '../Home.constants';

interface IProps {
  containerWidth: number;
}

const Search: FC<IProps> = ({ containerWidth }) => {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const [isOpen, setIsOpen] = useState(false);
  const { prefixedT } = useCustomTranslation('common');
  const search = useSelector(searchSelector);
  const [bitesInput, setBitesInput] = useState(search.bite);
  const [playlistsInput, setPlaylistsInput] = useState(search.playlist);
  useEffect(() => {
    if (search.bite !== bitesInput) {
      setBitesInput(search.bite);
    }
  }, [search.bite]);
  useEffect(() => {
    if (search.playlist !== playlistsInput) {
      setPlaylistsInput(search.playlist);
    }
  }, [search.playlist]);

  const inputSlateRef = useRef();
  const inputRef = useRef();

  const isOnBites = (): boolean => path === '/bites';

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    const inputSlateEl = inputSlateRef.current as HTMLElement;
    const inputEl = inputRef.current as HTMLElement;

    inputEl.style.opacity = '1';
    inputEl.style.width = '40px';
    inputEl.focus();
    inputSlateEl.style.width = containerWidth / 2.2 + 'px';
  }, [isOpen]);

  useEffect(() => {
    dispatch(resetSearch());
  }, []);

  const debouncedSearch = useDebouncedCallback((value) => {
    dispatch(
      updateSearch({
        value,
        type: isOnBites() ? 'bite' : 'playlist',
      }),
    );
  }, 150);

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const value = evt.target.value;
    if (isOnBites()) {
      setBitesInput(value);
    } else {
      setPlaylistsInput(value);
    }
    debouncedSearch(value);
  };

  const handleCloseClick = () => {
    const inputSlateEl = inputSlateRef.current as HTMLElement;
    const inputEl = inputRef.current as HTMLElement;

    inputEl.style.opacity = '0.2';
    inputSlateEl.style.width = '44px';

    setTimeout(() => {
      setIsOpen(false);
      dispatch(
        updateSearch({
          value: '',
          type: isOnBites() ? 'bite' : 'playlist',
        }),
      );
    }, 1000);
  };

  return (
    <S.Container>
      {isOpen ? (
        <S.InputSlate ref={inputSlateRef}>
          <S.Input
            ref={inputRef}
            placeholder={prefixedT('Search')}
            value={isOnBites() ? bitesInput : playlistsInput}
            onChange={handleChange}
            id='txtSearchInput'
            data-cy='searchInput'
          />
          <S.CloseIconContainer>
            <S.CloseSVG onClick={handleCloseClick} id='btnCloseSearch' />
          </S.CloseIconContainer>
        </S.InputSlate>
      ) : (
        <S.SearchIconContainer onClick={() => setIsOpen(true)} data-cy='openSearch'>
          <S.SearchSVG id='btnOpenSearchInput' />
        </S.SearchIconContainer>
      )}
    </S.Container>
  );
};

const S = {
  Container: styled.div`
    height: ${CONTROLLERS_BAR_HEIGHT - 10}px;
  `,
  SearchIconContainer: styled.button`
    position: relative;
    width: 44px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.white};
  `,

  SearchSVG: styled(SearchSVG)`
    width: 51%;
    height: 51%;
    fill: ${({ theme }) => theme.colors.primaryBlue};
  `,

  InputSlate: styled.div`
    border-radius: 22px;
    overflow: hidden;
    transform-origin: right;
    height: 100%;
    width: ${CONTROLLERS_BAR_HEIGHT - 10}px;
    transition: width 1s ease-in-out;
    box-shadow: 0 12px 19px 0 rgba(60, 128, 209, 0.09);

    display: flex;

    ${({ theme }) => css`
      background-color: ${theme.colors.white};
    `};
  `,

  Input: styled.input`
    transition: opacity 1s ease-in-out;
    opacity: 0.2;
    padding-left: 15px;
    flex: 1;
  `,

  CloseIconContainer: styled.div`
    width: ${CONTROLLERS_BAR_HEIGHT - 14}px;
    background-color: ${({ theme }) => theme.colors.primaryBlue};

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  `,

  CloseSVG: styled(CloseSVG)`
    width: 15px;
    height: 15px;
    fill: ${({ theme }) => theme.colors.white};
  `,
};

export default Search;

import { takeLatest, put, all, select, call, takeLeading } from 'redux-saga/effects';

import {
  runInit,
  setConfig,
  requestGeolocation,
  setGeolocation,
  setTranslationsAreInitialized,
  loadContentLocales,
  setContentLocales,
  setContentLocalesIsLoading,
  setContentLocalesError,
} from './appActivity.slice';
import { contentLocalesSelector, geolocationSelector } from './appActivity.selectors';

import * as calls from '../api/calls/common.calls';
import { initI18 as applyTranslations } from '../../locale/i18n';
import { logError } from '../tracking/tracking.slice';
import { cloneDeep } from 'lodash';

function* initSaga() {
  const [{ data: config }, { data: translations }] = yield all([calls.getConfig(), calls.loadTranslations()]);
  yield put(setConfig(config));
  yield call(applyTranslations, translations);
  yield put(setTranslationsAreInitialized());
}

function* requestGeolocationSaga() {
  try {
    const geolocation = yield select(geolocationSelector);
    if (geolocation) {
      return;
    }
    const { data } = yield calls.getGeolocation();
    yield put(setGeolocation(data?.data));
  } catch (error) {
    yield put(
      logError({
        event: 'requestGeolocationSaga: error',
        data: {
          error,
          errorResponse: cloneDeep(error?.response),
        },
      }),
    );
  }
}

function* loadContentLocalesSaga() {
  try {
    const locales = yield select(contentLocalesSelector);

    if (locales) {
      yield put(setContentLocalesIsLoading(false));
      return;
    }

    const { data } = yield calls.getContentLocales();
    yield put(setContentLocales(data.locales));
  } catch (error) {
    yield put(
      logError({
        event: 'loadContentLocalesSaga: error',
        data: {
          error,
          errorResponse: cloneDeep(error?.response),
        },
      }),
    );

    yield put(setContentLocalesError(error));
  }
}

export default function* appActivitySaga() {
  yield all([
    takeLatest(runInit, initSaga),
    takeLatest(requestGeolocation, requestGeolocationSaga),
    takeLeading(loadContentLocales, loadContentLocalesSaga),
  ]);
}

import styled from 'styled-components';

const StyledLink = styled.a`
  font-size: 14px;
  color: ${({ theme }) => theme.msColors.purple};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.msColors.hoverColor};
  }
`;

export default StyledLink;

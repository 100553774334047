import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInitialState, THistoryItem, TRelatedContent } from './knowledge.types';
import { IBite } from '../../types/bite';
import { v4 as uuid } from 'uuid';

const initialState: IInitialState = {
  // Counter to identify the current request
  requestId: null,
  requestsPrompt: '',
  localPrompt: '',
  relatedData: {
    isLoading: false,
    isError: false,
    data: {
      answer: null,
      answerRelated: null,
      searchRelated: null,
    },
  },
  tagsSearchData: {
    totalCount: 0,
    isLoading: false,
    isError: false,
    data: null,
    next: null,
  },
  history: {
    isLoading: false,
    isError: false,
    data: null,
  },
};

type TKeysOfInitialState = keyof IInitialState;

const KNOWLEDGE = 'knowledge';

const knowledgeSlice = createSlice({
  name: KNOWLEDGE,
  initialState,
  reducers: {
    setLocalPrompt: (state, { payload }: PayloadAction<string>) => {
      state.localPrompt = payload;
    },
    fetchKnowledgeRequest: (state, { payload }: PayloadAction<string>) => {
      // Increment the request counter to identify the current user-initiated prompt
      state.requestId = uuid();

      state.localPrompt = payload;
      state.requestsPrompt = payload;

      state.relatedData.isLoading = true;
      state.relatedData.isError = false;
      state.relatedData.data = {
        answer: null,
        answerRelated: null,
        searchRelated: null,
      };
      state.tagsSearchData = initialState.tagsSearchData;

      const history = state.history.data ? [...state.history.data] : [];
      const itemIndex = history.findIndex((item) => item.prompt === state.requestsPrompt);
      if (itemIndex > -1) {
        history.splice(itemIndex, 1);
      }
      history.unshift({
        prompt: state.requestsPrompt,
        id: uuid(),
      });
      state.history.data = history;
    },
    setKnowledgeAnswer: (state, { payload }: PayloadAction<{ answer: string }>) => {
      state.relatedData.data = { ...state.relatedData.data, answer: payload.answer };
    },
    setKnowledge: (state, { payload }: PayloadAction<{ relatedContent: Omit<TRelatedContent, 'answer'> }>) => {
      state.relatedData.isLoading = false;
      state.relatedData.isError = false;
      state.relatedData.data = {
        ...state.relatedData.data,
        answerRelated: payload.relatedContent?.answerRelated || [],
        searchRelated: payload.relatedContent?.searchRelated || [],
      };
    },
    fetchKnowledgeFailure: (state) => {
      state.relatedData.isLoading = false;
      state.relatedData.isError = true;
      state.relatedData.data = {
        answer: null,
        answerRelated: [],
        searchRelated: [],
      };
    },
    fetchTagSearchData: (state) => {
      state.tagsSearchData.isLoading = true;
      state.tagsSearchData.isError = false;
    },
    setTagSearchData: (state, { payload }: PayloadAction<{ data: IBite[]; next: string; count: number }>) => {
      state.tagsSearchData.isLoading = false;
      state.tagsSearchData.isError = false;
      state.tagsSearchData.data = [...(state.tagsSearchData.data || []), ...(payload.data || [])];
      state.tagsSearchData.next = payload.next;
      state.tagsSearchData.totalCount = payload.count;
    },
    fetchTagSearchDataFailure: (state) => {
      state.tagsSearchData.isLoading = false;
      state.tagsSearchData.isError = true;
      state.tagsSearchData.data = [];
    },
    resetTagSearchData: (state) => {
      state.tagsSearchData = initialState.tagsSearchData;
    },
    fetchHistory: (state) => {
      state.history.isLoading = true;
      state.history.isError = false;
    },
    setHistory: (state, { payload }: PayloadAction<{ data: THistoryItem[] }>) => {
      state.history.isLoading = false;
      state.history.isError = false;
      state.history.data = payload.data || [];
    },
    fetchHistoryFailure: (state) => {
      state.history.isLoading = false;
      state.history.isError = true;
    },

    resetKnowledge: (state, { payload }: PayloadAction<{ excludeFields: TKeysOfInitialState[] }>) => {
      if (payload?.excludeFields?.length) {
        const excludeFields = payload.excludeFields;
        Object.keys(state).forEach((key) => {
          if (!excludeFields.includes(key as TKeysOfInitialState)) {
            state[key] = initialState[key];
          }
        });
      } else {
        return initialState;
      }
    },
  },
});

export const {
  fetchKnowledgeRequest,
  setKnowledge,
  fetchKnowledgeFailure,
  fetchHistory,
  setHistory,
  fetchHistoryFailure,
  fetchTagSearchData,
  setTagSearchData,
  fetchTagSearchDataFailure,
  resetTagSearchData,
  setKnowledgeAnswer,
  setLocalPrompt,
  resetKnowledge,
} = knowledgeSlice.actions;

export default knowledgeSlice.reducer;

export const fetchKnowledge = createAction<string>(`${KNOWLEDGE}/fetchKnowledge`);

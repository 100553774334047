import queryString from 'query-string';
import { all, put, select, takeLatest } from 'redux-saga/effects';

import {
  bitesSelector,
  selectedBiteSelector,
  translatedVoiceoversLocaleSelector,
  translateVoiceoversByLocaleSelector,
} from './bite.selectors';
import BiteActionTypes from './bite.types';
import {
  addCommentSuccess,
  biteLoadNextSuccess,
  biteQuerySuccess,
  deleteCommentSuccess,
  fetchSelectedBiteSuccess,
  setCommentSuggestion,
  setCommentSuggestionIsLoading,
  translateSubtitlesError,
  translateSubtitlesRequest,
  translateVoiceoversRequest,
  translateVoiceoversError,
  translateSubtitlesSuccess,
  setTranslateVoiceoversPendingData,
  setTranslateVoiceoversData,
  biteQueryError,
  setCurrentLocale,
} from './bite.actions';

import * as calls from '../api/calls/bite.calls';
import { searchSelector } from '../search/search.selectors';
import { getOrgSettings, resetOrgSettings, setBranding } from '../organization/organization.slice';
import { setTitle } from '../socialShare/socialShare.slice';
import { orgSelector } from '../organization/organization.selectors';
import handleUnaothrizedContentResponse from '../../utils/handleUnaothrizedContentResponse';
import { applyLocaleIfNeeded, setPreferredLanguage } from '../../locale/i18n';
import { setShowSomethingWentWrong } from '../appActivity/appActivity.slice';
import { log, logError } from '../tracking/tracking.slice';
import { cloneDeep } from 'lodash';
import { EShowSomethingWentWrongError } from '../appActivity/appActivity.types';
import withRetry from '../../utils/withRetry';
import { translateSubtitles, translateVoiceovers } from '../api/calls/common.calls';
import { PayloadAction } from '@reduxjs/toolkit';
import { ISubtitles, ITranslatedVoiceover } from '../../types/bite';
import { translatedVoiceoversAudios } from './bites.data';
import { AxiosResponse } from 'axios';
import { EContentType } from '../geofence/geofence.types';
import { setGeofenceContentInfo } from '../geofence/geofence.slice';
import history from '../../navigation/history';
import { v4 as uuid } from 'uuid';
import { applyOnboardingToken } from '../onboarding/onboarding.saga';
import { getOrganizationFeatureFlags } from '../profile/profile.actions';
import gtmTrack from '../../services/googleTagManager/track';

function* query(action?) {
  const search = yield select(searchSelector);
  const { id: activeOrgId } = yield select(orgSelector);
  const { orgId, isPublicFeed } = action.payload;

  const params: any = {
    feed: true,
    page_size: 20,
    pagination: true,
    organization: orgId || activeOrgId,
  };

  if (search.bite) {
    params.search = search.bite;
  }

  const queryParams = '?' + queryString.stringify(params);

  try {
    yield applyOnboardingToken();
    const {
      data: { results, count },
    } = isPublicFeed
      ? yield calls.queryPublicFeed(queryParams)
      : yield calls.queryFeed(params.organization, queryParams);

    yield put(biteQuerySuccess({ bites: results, totalBites: count }));
  } catch (error) {
    yield put(biteQueryError());

    yield put(
      logError({
        event: 'bite.saga query: error',
        data: {
          error,
          errorResponse: cloneDeep(error?.response),
        },
      }),
    );

    if (error?.response?.status === 403) {
      yield put(resetOrgSettings());
    } else {
      yield put(setShowSomethingWentWrong({ type: EShowSomethingWentWrongError.DEFAULT }));
    }
  }
}

function* loadNext(action) {
  const { orgId, isPublicFeed } = action.payload;

  const search = yield select(searchSelector);
  const bitesState = yield select(bitesSelector);
  const { id: activeOrgId } = yield select(orgSelector);

  const params = {
    feed: true,
    page_size: 20,
    pagination: true,
    page: bitesState.nextPage,
    organization: orgId || activeOrgId,
  };
  if (search.bite) {
    // @ts-ignore
    params.search = search.bite;
  }
  const queryParams = '?' + queryString.stringify(params);

  try {
    const { data } = isPublicFeed
      ? yield calls.queryPublicFeed(queryParams)
      : yield calls.queryFeed(params.organization, queryParams);

    yield put(biteLoadNextSuccess(data.results));
  } catch (error) {
    yield put(
      logError({
        event: 'bite.saga loadNext: error',
        data: {
          error,
          errorResponse: cloneDeep(error?.response),
        },
      }),
    );
    yield put(setShowSomethingWentWrong({ type: EShowSomethingWentWrongError.DEFAULT }));
  }
}

function* getById(action) {
  const { biteId, processId, isPlaylist } = action.payload;
  yield put(
    log({
      event: 'bite.saga getById: start',
      processId,
      data: { biteId },
    }),
  );
  try {
    yield applyOnboardingToken();
    const headers = !isPlaylist ? { 'x-geofence': true } : {};
    const { data: bite } = yield calls.getById(
      biteId,
      {
        extend: ['enhancements', 'subtitles', 'sections'],
      },
      headers,
    );

    yield put(
      log({
        event: 'bite.saga getById: result',
        processId,
        data: { bite },
      }),
    );

    yield put(getOrganizationFeatureFlags(bite.orgid));

    yield setBiteResultSaga({ bite });
  } catch (error) {
    yield put(
      logError({
        event: 'bite.saga getById: error',
        data: {
          error,
          errorResponse: cloneDeep(error?.response),
        },
      }),
    );

    if (error?.response?.data?.detail?.orgid) {
      yield setPreferredLanguage({ orgId: error.response.data.detail.orgid });
    }

    // On getting 428 status code, we should set geofence component active and set geofence content info
    if (error?.response?.status === 428) {
      yield put(setGeofenceContentInfo({ type: EContentType.bite, id: biteId, processId }));
      history.replace('/');
      return;
    }

    yield handleUnaothrizedContentResponse({ error, processId });
  }
}

export function* setBiteResultSaga({ bite }) {
  const { extend, ...biteShare } = bite;
  yield put(setTitle(biteShare.subject));
  yield put(getOrgSettings(biteShare.orgid));
  yield setPreferredLanguage({ orgId: biteShare.orgid });
  yield put(fetchSelectedBiteSuccess(biteShare));
  yield put(setBranding(bite.branding));
  yield applyLocaleIfNeeded(bite.branding.locale);
  yield getCommentSuggestionSaga(biteShare, extend);
}

function getIsCreatorAndFirstComment(biteShare) {
  const isCreator = biteShare.bite_share_user.user === biteShare.creator_id;
  const hasCommented = biteShare.comments.some((comment) => comment.user.id === biteShare.creator_id);

  const hasReplied = biteShare.comments.some((comment) => {
    return comment.related_comments.some((rComment) => rComment.user.id === biteShare.creator_id);
  });

  return isCreator && !hasCommented && !hasReplied;
}

function* getCommentSuggestionSaga(biteShare, extend) {
  try {
    const isCreatorAndFirstComment = getIsCreatorAndFirstComment(biteShare);
    if (!isCreatorAndFirstComment) {
      return;
    }
    const taskId = extend.subtitles?.[0]?.task_id;

    if (!taskId) {
      return;
    }

    yield put(setCommentSuggestionIsLoading(true));
    const { data: comment } = yield calls.getCommentSuggestion(taskId);
    yield put(setCommentSuggestion(comment));
  } catch (error) {
    yield put(
      logError({
        event: 'bite.saga getCommentSuggestion: error',
        data: {
          error,
          errorResponse: cloneDeep(error?.response),
        },
      }),
    );
    yield put(setCommentSuggestionIsLoading(false));
  }
}

function* addComment(action) {
  const { data, id } = action.payload;
  const { text, file, type } = data;
  try {
    let fileID = null;
    if (file) {
      const { data: result } = yield calls.uploadImage(file);
      if (result?.id) {
        fileID = result.id;
      }
    }
    const { data: comment } = yield calls.addComment({
      id,
      commentText: text,
      fileID,
      type,
    });
    yield put(addCommentSuccess(comment, type));
  } catch (error) {
    yield put(
      logError({
        event: 'bite.saga addComment: error',
        data: {
          error,
          errorResponse: cloneDeep(error?.response),
        },
      }),
    );
    yield put(setShowSomethingWentWrong({ type: EShowSomethingWentWrongError.DEFAULT }));
  }
}

function* deleteComment(action) {
  const { id, type } = action.payload.data;
  try {
    yield calls.deleteComment({ id });
    yield put(deleteCommentSuccess(id, type));
  } catch (error) {
    yield put(
      logError({
        event: 'bite.saga deleteComment: error',
        data: {
          error,
          errorResponse: cloneDeep(error?.response),
        },
      }),
    );
    yield put(setShowSomethingWentWrong({ type: EShowSomethingWentWrongError.DEFAULT }));
  }
}

function* defineSubtitlesLocaleSaga() {
  try {
    yield put(
      log({
        event: 'bite.saga defineSubtitlesLocaleSaga',
      }),
    );

    const { selectedBite } = yield select(selectedBiteSelector);

    const taskId = selectedBite?.enhancements?.[0]?.task_id;

    const {
      data: { subtitles },
    } = yield calls.defineSubtitlesLocale(taskId);

    const { selectedBite: selectedBite2 } = yield select(selectedBiteSelector);
    if (selectedBite2.id !== selectedBite.id) {
      return;
    }

    yield put(
      fetchSelectedBiteSuccess({
        ...selectedBite,
        subtitles,
      }),
    );

    yield put(
      log({
        event: 'bite.saga defineSubtitlesLocaleSaga: done',
      }),
    );
  } catch (error) {
    yield put(
      logError({
        event: 'bite.saga defineSubtitlesLocaleSaga: error',
        data: {
          error,
          errorResponse: cloneDeep(error?.response),
        },
      }),
    );
  }
}

function* translateSubtitlesSaga({
  payload: { locale, isManually = false, source, withSetCurrentLocale = true },
}: PayloadAction<{ locale: string; isManually?: boolean; withSetCurrentLocale?: boolean; source?: string }>) {
  const processId = uuid();
  const { selectedBite } = yield select(selectedBiteSelector);

  gtmTrack('subtitles_translation_start', {
    bite_id: selectedBite?.bite,
    locale,
    is_manually: isManually,
    source,
  });

  try {
    yield put(
      log({
        event: 'bite.saga translateSubtitlesSaga',
        processId,
        data: {
          locale,
        },
      }),
    );

    const existingSubtitles: ISubtitles = selectedBite?.subtitles?.find((x) => x.locale === locale);

    if (existingSubtitles) {
      yield put(
        log({
          event: 'bite.saga translateSubtitlesSaga: existing subtitles',
          processId,
          data: {
            locale,
          },
        }),
      );

      yield put(translateSubtitlesSuccess(existingSubtitles));

      gtmTrack('subtitles_translation_success', {
        bite_id: selectedBite?.bite,
        locale,
        is_manually: isManually,
        source,
      });

      if (withSetCurrentLocale) {
        yield put(setCurrentLocale(locale));
      }
      return;
    }

    if (!locale) {
      yield put(
        log({
          event: 'bite.saga translateSubtitlesSaga: locale not defined',
          processId,
          data: {
            locale,
          },
        }),
      );
      return;
    }

    yield put(translateSubtitlesRequest(locale));

    const taskId = selectedBite?.enhancements?.[0]?.task_id;

    const {
      data: { subtitles, audios },
    } = yield withRetry(
      () =>
        translateSubtitles({
          taskId,
          locale,
        }),
      {
        errorContext: {
          action: 'translateSubtitlesSaga',
          processId,
        },
      },
    );

    yield put(
      log({
        event: 'bite.saga translateSubtitlesSaga: done',
        processId,
      }),
    );

    yield put(
      fetchSelectedBiteSuccess({
        ...selectedBite,
        subtitles: [...selectedBite.subtitles, subtitles],
        audios,
      }),
    );

    yield put(translateSubtitlesSuccess(subtitles));

    gtmTrack('subtitles_translation_success', {
      bite_id: selectedBite?.bite,
      locale,
      is_manually: isManually,
      source,
    });

    if (withSetCurrentLocale) {
      yield put(setCurrentLocale(locale));
    }
  } catch (error) {
    gtmTrack('subtitles_translation_error', {
      bite_id: selectedBite?.bite,
      locale,
      is_manually: isManually,
      source,
    });

    yield put(
      logError({
        event: 'bite.saga translateSubtitlesSaga: error',
        processId,
        data: {
          error,
          errorResponse: cloneDeep(error?.response),
        },
      }),
    );

    yield put(translateSubtitlesError(error));
  }
}

function* translateVoiceoversSaga({
  payload: { locale, source },
}: PayloadAction<{
  locale: string;
  source?: string;
}>) {
  const processId = uuid();

  if (!locale) {
    yield put(
      log({
        event: 'bite.saga translateVoiceoversSaga: no locale provided, nothing to do',
        processId,
        data: {
          locale,
        },
      }),
    );
    return;
  }

  const { selectedBite } = yield select(selectedBiteSelector);

  gtmTrack('voiceover_translation_start', {
    bite_id: selectedBite?.bite,
    locale,
    source,
  });

  try {
    yield put(
      log({
        event: 'bite.saga translateVoiceoversSaga',
        processId,
        data: {
          locale,
        },
      }),
    );

    const translateVoiceoversByLocale = yield select(translateVoiceoversByLocaleSelector);

    const translatedVoiceoverForLocale: ITranslatedVoiceover | null = translateVoiceoversByLocale[locale];

    if (translatedVoiceoverForLocale) {
      yield put(
        log({
          event: 'bite.saga translateVoiceoversSaga: existing translated voiceovers',
          processId,
          data: {
            locale,
          },
        }),
      );
      gtmTrack('voiceover_translation_success', {
        bite_id: selectedBite?.bite,
        locale,
        source,
      });
      return;
    }

    yield put(translateVoiceoversRequest(locale));

    const enhancements = selectedBite.enhancements.find((enhancement) => enhancement.enabled);
    const taskId = enhancements.task_id;
    const enhanceType = enhancements.enhance_type;

    const isPreview = new URLSearchParams(window.location.search).has('isPreview');
    const isFrame = window !== window.top;
    // check isPreview for mobile preview use case
    const withPresignedUrls = isFrame || isPreview ? true : undefined;

    const {
      data: { audio },
    }: AxiosResponse<{ audio: ITranslatedVoiceover }> = yield withRetry(
      () =>
        translateVoiceovers({
          taskId,
          locale,
          enhanceType,
          videoStorage: {
            type: 's3',
            bucket: enhancements.s3_video_bucket,
            key: enhancements.s3_video_key,
          },
          withPresignedUrls,
        }),
      {
        errorContext: {
          action: 'translateVoiceoversSaga',
          processId,
        },
      },
    );

    yield put(
      log({
        event: 'bite.saga translateVoiceoversSaga: request done',
        data: {
          isPreview,
          isFrame,
          withPresignedUrls,
        },
        processId,
      }),
    );

    if (audio.audioStorage) {
      const instance = new Audio(audio.audioStorage.url);

      translatedVoiceoversAudios.current[taskId] = translatedVoiceoversAudios.current[taskId] || {};
      translatedVoiceoversAudios.current[taskId][audio.locale] = instance;

      yield new Promise((resolve, reject) => {
        instance.addEventListener(
          'canplaythrough',
          () => {
            resolve(true);
          },
          { once: true },
        );

        // Event listener for audio loading errors
        instance.addEventListener(
          'error',
          () => {
            reject(new Error(`Failed to load audio ${audio.audioStorage.url}`));
          },
          { once: true },
        );

        instance.load();
      });
    }

    yield put(
      log({
        event: 'bite.saga translateVoiceoversSaga: done',
        processId,
      }),
    );

    gtmTrack('voiceover_translation_success', {
      bite_id: selectedBite?.bite,
      locale,
      source,
    });

    const currentLocale = yield select(translatedVoiceoversLocaleSelector);
    if (currentLocale === locale) {
      yield put(
        setTranslateVoiceoversPendingData({
          locale,
          pendingAudio: audio,
        }),
      );
    } else {
      yield put(
        setTranslateVoiceoversData({
          locale,
          audio,
        }),
      );
    }
  } catch (error) {
    gtmTrack('voiceover_translation_error', {
      bite_id: selectedBite?.bite,
      locale,
      source,
    });

    yield put(
      logError({
        event: 'bite.saga translateVoiceoversSaga: error',
        processId,
        data: {
          error,
          errorResponse: cloneDeep(error?.response),
          locale,
        },
      }),
    );

    yield put(translateVoiceoversError({ locale, error }));
  }
}

export default function* biteSagaWatcher() {
  yield all([
    takeLatest(BiteActionTypes.BITE_QUERY_REQUEST, query),
    takeLatest(BiteActionTypes.BITE_LOAD_NEXT, loadNext),
    takeLatest(BiteActionTypes.FETCH_SELECTED_BITE_REQUEST, getById),
    takeLatest(BiteActionTypes.ADD_COMMENT_REQUEST, addComment),
    takeLatest(BiteActionTypes.DELETE_COMMENT_REQUEST, deleteComment),
    takeLatest(BiteActionTypes.DEFINE_SUBTITLES_LOCALE, defineSubtitlesLocaleSaga),
    takeLatest(BiteActionTypes.TRANSLATE_SUBTITLES, translateSubtitlesSaga),
    takeLatest(BiteActionTypes.TRANSLATE_VOICEOVERS, translateVoiceoversSaga),
  ]);
}

import BitesApi from '..';
import { IResponseOpenEndedAnswer } from '../../../types/biteShare';

export const getQuiz = (id: number, headers?: { [key: string]: number | string | boolean }) =>
  BitesApi.get(`/playlists/${id}/`, { headers });

export const getSettings = (id) => BitesApi.get(`/playlists/${id}/settings`);

export const getBiteShareSections = (biteShareId) => BitesApi.get(`/bite_shares/${biteShareId}/sections/`);

export const getSettingsById = (playlistId: number) => BitesApi.get(`/playlists/${playlistId}/settings/`);

export const getBiteShareUser = (id) =>
  BitesApi.get(`/bite_shares/${id}/?extend=sections`).then((response) => response.data.bite_share_user);

export const answerQuestion = (answer: IResponseOpenEndedAnswer) => {
  const data = new FormData();
  Object.entries(answer).forEach(([key, value]) => {
    if (key === 'choices_ids') {
      data.append(key, JSON.stringify(value));
      return;
    }
    data.append(key, value);
  });
  return BitesApi.post('/user_choices/', data);
};

export const quizCertificate = (quizId: string, userId: string) =>
  BitesApi.post('/quiz_certificate/', { user: userId, quiz: quizId });

import React, { FC, memo } from 'react';
import styled from 'styled-components';

interface IProps {
  title: string;
  description?: string;
  texts?: {
    text: string;
    url: string;
  }[];
  destination?: string;
}

const Header: FC<IProps> = ({ title, description, texts, destination }) => {
  return (
    <S.Header>
      <S.HeaderTitle>{title}</S.HeaderTitle>
      {description && <S.HeaderDescription>{description}</S.HeaderDescription>}
      {texts?.map((item) => (
        <S.HeaderText href={item.url} target='_blank' key={item.text}>
          {item.text}
        </S.HeaderText>
      )) || null}
      {destination && <S.HeaderDestination>{destination}</S.HeaderDestination>}
    </S.Header>
  );
};

const S = {
  Header: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
  `,
  HeaderTitle: styled.span`
    display: flex;
    color: ${({ theme }) => theme.colors.text};
    font-size: 20px;
    line-height: 28px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-weight: 400;
    margin-top: 10px;
  `,
  HeaderDescription: styled.span`
    display: flex;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.gray19};
    font-size: 14px;
    line-height: 16px;
    margin-top: 4px;
    text-align: center;
  `,
  HeaderText: styled.a`
    display: flex;
    cursor: pointer;
    margin-left: 4px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.gray19};
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.gray19};
  `,
  HeaderDestination: styled.span`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-weight: bold;
    color: ${({ theme }) => theme.colors.text};
    font-size: 14px;
    line-height: 16px;
    margin-top: 4px;
    text-align: center;
  `,
};
export default memo(Header);

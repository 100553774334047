import PlaylistActionTypes from './playlist.types';

import { IPlaylist } from '../../types/playlist';
import { ILoadFeedRequest } from '../../types/bite';

export const playlistQueryRequest = ({ orgId = null, isPublicFeed = false }: ILoadFeedRequest = {}) => ({
  type: PlaylistActionTypes.PLAYLIST_QUERY_REQUEST,
  payload: { orgId, isPublicFeed },
});

export const playlistQuerySuccess = ({ playlists, totalPlaylists }) => ({
  type: PlaylistActionTypes.PLAYLIST_QUERY_SUCCESS,
  payload: { playlists, totalPlaylists },
});

export const playlistLoadNext = ({ orgId = null, isPublicFeed = false }: ILoadFeedRequest = {}) => ({
  type: PlaylistActionTypes.PLAYLIST_LOAD_NEXT,
  payload: { orgId, isPublicFeed },
});

export const playlistLoadNextSuccess = (playlists: any[]) => ({
  type: PlaylistActionTypes.PLAYLIST_LOAD_NEXT_SUCCESS,
  payload: playlists,
});

export const fetchSelectedPlaylistRequest = (payload: {
  playlistId: number;
  processId: string;
  isFromBite?: boolean;
}) => ({
  type: PlaylistActionTypes.FETCH_SELECTED_PLAYLIST_REQUEST,
  payload,
});

export const fetchSelectedPlaylistSuccess = (playlist: IPlaylist) => ({
  type: PlaylistActionTypes.FETCH_SELECTED_PLAYLIST_SUCCESS,
  payload: playlist,
});

export const resetSelectedPlaylist = () => ({
  type: PlaylistActionTypes.RESET_SELECTED_PLAYLIST,
});

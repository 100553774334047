import React, { FC, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';

import { StyledTimeAgo } from '../shared';
import { ReactComponent as PlaySVG } from '../../assets/icons/triangle.svg';
import { ReactComponent as ShareSVG } from '../../assets/icons/share_icon.svg';
import { ReactComponent as LockSVG } from '../../assets/icons/lock_icon.svg';
import { ReactComponent as VmarkOn } from '../../assets/icons/v_on.svg';
import { ReactComponent as VmarkOff } from '../../assets/icons/v_off.svg';
import { ReactComponent as WrongAnswer } from '../../assets/icons/wrong-answer-icon.svg';
import { ReactComponent as RightAnswer } from '../../assets/icons/right-answer-icon.svg';
import { ReactComponent as NotAnswered } from '../../assets/icons/not-answered-icon.svg';
import { WEB_URL } from '../../constants/urls';
import useDataFormatter from '../../hooks/useDateFormatter';
import { getIsRtl } from '../../locale/i18n';
import gtmTrack from '../../services/googleTagManager/track';
import { playButtonIconDataSet } from './biteItem.constants';

interface IProps {
  dataCy?: string;
  bite: {
    id: number;
    subject: string;
    created_at: string;
    participants: number;
    success_rate: number;
    comments: number;
    cover_url: string;
    bite_share_user: null | {
      bite_progress: string;
      question_type: string;
      choices: {
        choice: {
          is_correct: boolean;
        };
      }[];
    };
  };
  isLocked?: boolean;
  withoutProgressIndication?: boolean;
  onClick?: (biteId: number, subject: string) => void;
}

const BiteItem: FC<IProps> = ({
  dataCy = 'bite',
  bite: { id, subject, cover_url, created_at, bite_share_user },
  withoutProgressIndication,
  onClick,
  isLocked,
}) => {
  const { t } = useTranslation();
  const { formatToRelativeDate } = useDataFormatter();
  const answerStatus = useMemo(() => {
    if (!bite_share_user) {
      return null;
    }
    if (bite_share_user.question_type === 'multiple choices') {
      if (bite_share_user?.choices.length !== 0) {
        if (bite_share_user.choices[0]?.choice.is_correct) {
          return <S.RightAnswer />;
        }
        return <S.WrongAnswer />;
      }
      return <S.NotAnswered />;
    }
  }, [bite_share_user]);

  const onCopyToClipboard = () => {
    toast(t('screens.home.shareToast'));
  };

  const handleClick = (e) => {
    e.stopPropagation();
    gtmTrack('share', { share_type: 'bite', 'gtm.bitesShareID': id });
  };

  return (
    <S.Container
      onClick={() => !isLocked && onClick?.(id, subject)}
      id='biteContainer'
      isLocked={isLocked}
      data-cy={dataCy}
      data-cy-id={id}
    >
      {isLocked && (
        <S.LockContainer>
          <LockSVG fill='#fff' width='72px' height='72px' />
        </S.LockContainer>
      )}
      <S.CoverImageContainer coverImage={cover_url} id='biteImage' data-cy='headerHomeButton' isLocked={isLocked}>
        <S.PlayAndShareIcons>
          <S.PlayButtonWrapper>
            <PlaySVG fill='#fff' width='16px' height='16px' id='bitePlayIcon' data-cy={playButtonIconDataSet.cy} />
          </S.PlayButtonWrapper>
          <CopyToClipboard text={`${WEB_URL}/bites/${id}`} onCopy={onCopyToClipboard}>
            <S.ShareButtonWrapper onClick={handleClick}>
              <ShareSVG fill='#fff' width='18px' height='18px' id='biteShareIcon' />
            </S.ShareButtonWrapper>
          </CopyToClipboard>
        </S.PlayAndShareIcons>
      </S.CoverImageContainer>
      <S.TextsContainer id='biteTextContainer' isLocked={isLocked}>
        <S.HorizontalView>
          <S.TimeAgo id='biteDateText'>{formatToRelativeDate(created_at)}</S.TimeAgo>
          <S.Icons>
            {bite_share_user && !withoutProgressIndication && (
              <>
                {answerStatus}
                {bite_share_user.bite_progress === 'done' ? <S.VmarkOnIcon /> : <S.VmarkOffIcon />}
              </>
            )}
          </S.Icons>
        </S.HorizontalView>
        <S.Subject id='biteNameText' data-cy='biteName'>
          {subject}
        </S.Subject>
      </S.TextsContainer>
    </S.Container>
  );
};

const backImageStyles = `
	background-size: cover;
	background-position: center;
`;

const S = {
  Container: styled.li<{ isLocked: boolean }>`
    width: 100%;
    cursor: pointer;
    list-style: none;
    position: relative;

    ${({ isLocked }) => css`
      cursor: ${isLocked ? 'not-allowed' : 'pointer'};
    `}
  `,
  CoverImageContainer: styled.div<{ coverImage: string; isLocked: boolean }>`
    & {
      width: 100%;
      aspect-ratio: 0.8;
      margin-bottom: 10px;
      background-size: cover;
      border-radius: 18px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-repeat: no-repeat;
      background-position: center center;
      z-index: 1;
      position: relative;

      ${({ theme, coverImage }) => css`
        background-image: url(${coverImage});
        background-color: ${theme.colors.lightGray6};
      `}
    }
    &::before {
      content: '';
      background-image: url(${({ coverImage }) => coverImage});
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 18px;
      filter: blur(4px) brightness(0.8);
      opacity: ${({ isLocked }) => (isLocked ? '0.6' : '0')};
      display: block;
      position: absolute;
      top: 5px;
      bottom: 5px;
      right: 5px;
      left: 5px;
      width: auto;
      height: auto;
      z-index: -3;
    }
  `,

  LockContainer: styled.div`
    position: absolute;
    width: 100%;
    aspect-ratio: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 18px;
    z-index: 5;
    cursor: not-allowed;
    background-color: rgba(200, 200, 200, 0.8);
  `,

  PlayButtonWrapper: styled.div`
    background-color: rgba(0, 0, 0, 0.3);
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: ${() => (getIsRtl() ? '0px' : '20px')};
    border-bottom-right-radius: ${() => (getIsRtl() ? '0px' : '7px')};
    border-top-right-radius: ${() => (getIsRtl() ? '20px' : '0px')};
    border-bottom-left-radius: ${() => (getIsRtl() ? '7px' : '0px')};
  `,

  ShareButtonWrapper: styled.div`
    background-color: rgba(0, 0, 0, 0.3);
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: ${() => (getIsRtl() ? '20px' : '0px')};
    border-bottom-right-radius: ${() => (getIsRtl() ? '7px' : '0px')};
    border-top-right-radius: ${() => (getIsRtl() ? '0px' : '20px')};
    border-bottom-left-radius: ${() => (getIsRtl() ? '0px' : '7px')};
  `,

  HorizontalView: styled.div`
    display: flex;
    justify-content: space-between;
  `,

  Icons: styled.div`
    display: flex;
  `,

  VmarkOnIcon: styled(VmarkOn)`
    width: 16px;
    height: 16px;
    margin-left: 5px;
  `,

  VmarkOffIcon: styled(VmarkOff)`
    width: 16px;
    height: 16px;
    margin-left: 5px;
  `,

  NotAnswered: styled(NotAnswered)`
    width: 16px;
    height: 16px;
  `,

  WrongAnswer: styled(WrongAnswer)`
    width: 16px;
    height: 16px;
  `,

  RightAnswer: styled(RightAnswer)`
    width: 16px;
    height: 16px;
  `,

  PlayAndShareIcons: styled.div`
    display: flex;
    justify-content: space-between;
  `,

  IconContainer: styled.div<{ backImage: string | null }>`
    align-self: flex-end;
    width: 26px;
    height: 26px;
    margin: 11px;
    border-radius: 18px;
    background-color: ${({ theme }) => theme.colors.white};
    ${({ backImage }) =>
      backImage &&
      `background-image: url(${backImage});
				${backImageStyles}`}
  `,

  TextsContainer: styled.div<{ isLocked: boolean }>`
    padding-inline-start: 8px;
    font-size: 10px;
    ${({ isLocked }) => css`
      cursor: ${isLocked ? 'not-allowed' : 'pointer'};
    `}
  `,

  TimeAgo: styled(StyledTimeAgo)`
    margin-bottom: 7px;
  `,
  Subject: styled.p`
    font-size: 15px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.darkGray1};
    font-weight: 300;
  `,
};

export default BiteItem;

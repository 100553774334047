import React from 'react';
import styled from 'styled-components';

import DoorAnimatedButton from './DoorAnimatedButton';
import HomeAnimatedButton from './HomeAnimatedButton';
import styles from './styles';

import { ReactComponent as DoorButton } from '../../assets/icons/door.svg';
import { ReactComponent as HomeButton } from '../../assets/icons/home-rounded.svg';

interface Props {
  onClick: () => void;
  icon: 'home' | 'door';
  isAnimated?: boolean;
}

function HeaderHomeButton(props: Props) {
  const { onClick, icon, isAnimated } = props;

  if (icon === 'home' && !isAnimated) {
    return <S.HomeButton data-cy='headerHomeButton' onClick={onClick} />;
  }
  if (icon === 'home' && isAnimated) {
    return <S.HomeAnimatedButton data-cy='headerHomeButton' onClick={onClick} />;
  }
  if (icon === 'door' && !isAnimated) {
    return <S.DoorButton data-cy='headerHomeButton' onClick={onClick} />;
  }
  if (icon === 'door' && isAnimated) {
    return <S.DoorAnimatedButton data-cy='headerHomeButton' onClick={onClick} />;
  }
}

const S = {
  HomeButton: styled(HomeButton)`
    ${styles}
  `,
  HomeAnimatedButton: styled(HomeAnimatedButton)`
    ${styles}
  `,
  DoorButton: styled(DoorButton)`
    ${styles}
  `,
  DoorAnimatedButton: styled(DoorAnimatedButton)`
    ${styles}
  `,
};

export default HeaderHomeButton;

import { TokenTypes } from '../types/auth';
import { API_BASE_URL } from '../store/api/index';
import localStorage from '../utils/localStorage';

export const removeAmpersands = (searchUri: string) => searchUri.replace(/&amp;/g, '&');

export const removeQuestionMarkFromFirstChar = (uri: string) => uri.slice(1);

export const checkForRedirectionAfterAuthAndRedirect = (token: string, tokenType: TokenTypes) => {
  const redirectUriAfterAuth = localStorage.getItem('botRedirectUrl');

  if (redirectUriAfterAuth) {
    const redirectUri =
      API_BASE_URL +
      redirectUriAfterAuth.slice(1, redirectUriAfterAuth.length) + // Remove the question mark at the beginning
      '&' +
      tokenType +
      '=' +
      token;
    localStorage.removeItem('botRedirectUrl');
    window.location.href = redirectUri;

    return true;
  }

  return false;
};

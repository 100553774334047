import React, { memo, useCallback, useMemo } from 'react';
import styled from 'styled-components';

import ChoiceList from './ChoiceList';
import MediaViewer from './MediaViewer';
import OpenEndedAnswer from './OpenEndedAnswer';

import { getIsRtl } from '../../locale/i18n';
import { ContinueButton } from '../shared';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectedBiteSelector } from '../../store/bite/bite.selectors';

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (boolean) => void;
  coverUrl: string;
  onPlay: (evt) => void;
  media: any;
  question: { text: string; type: string; is_multi_choice?: boolean };
  openEndedText: string;
  onOpenEndedTextChange: (string) => void;
  imageForOpenEnded: any;
  onImageForOpenEndedChange: (any) => void;
  onSubmitOpenEnded: () => void;
  isUserCorrect: boolean;
  choices: any[];
  questionTranslation: string[];
  choicesTranslations: string[];
  onChoiceClick: (any) => void;
  userChoicesIds: number[];
  onContinue: () => void;
  questionColor?: string;
  hideContinue?: boolean;
  hasSummaryOrDiscussion?: boolean;
  isMultiChoiceSurvey: boolean;
  isAnswered: boolean;
}

function Question(props: Props) {
  const {
    isModalOpen,
    setIsModalOpen,
    coverUrl,
    onPlay,
    media,
    question,
    openEndedText,
    onOpenEndedTextChange,
    imageForOpenEnded,
    onImageForOpenEndedChange,
    onSubmitOpenEnded,
    isUserCorrect,
    choices,
    questionTranslation,
    choicesTranslations,
    onChoiceClick,
    userChoicesIds,
    onContinue,
    questionColor,
    hideContinue,
    isMultiChoiceSurvey,
    isAnswered,
  } = props;

  const { selectedBite } = useSelector(selectedBiteSelector);

  const { t } = useTranslation();
  const history = useHistory();

  const intro = useMemo(
    () => selectedBite?.bite_sections.find((section) => section.type === 'intro'),
    [selectedBite?.bite_sections],
  );

  const isIntroVideo = selectedBite?.enhancements?.length || intro?.media?.file_type === 'youtube';

  const handleBackClick = useCallback(() => {
    history.push({ ...history.location, pathname: `/bites/${selectedBite?.id}/intro` });
  }, [history, selectedBite?.id]);

  return (
    <S.Wrapper>
      <S.Container>
        <MediaViewer
          media={media}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          coverUrl={coverUrl}
          onPlay={onPlay}
        />
        <S.QuestionText dir='auto'>{questionTranslation || question.text}</S.QuestionText>
        {isMultiChoiceSurvey && <S.MultiSurveyText>{t('components.multiChoiceSurvey.title')}</S.MultiSurveyText>}
        {question.type === 'multiple choices' && (
          <S.DynamicBlueText isUserCorrect={isUserCorrect} data-test='questionInstruction'>
            {isUserCorrect === null
              ? t('screens.viewBite.MarkAnswer')
              : isUserCorrect
              ? t('screens.viewBite.Correct')
              : t('screens.viewBite.WrongAnswer')}
          </S.DynamicBlueText>
        )}
        {intro && !isAnswered && (
          <S.BackToIntroContainer>
            <S.BackToIntroButton onClick={handleBackClick}>
              {isIntroVideo ? t('screens.Questions.returnToVideo') : t('screens.Questions.returnToIntro')}
            </S.BackToIntroButton>
          </S.BackToIntroContainer>
        )}
        {question.type !== 'open ended' && (
          <ChoiceList
            choices={choices}
            choicesTranslations={choicesTranslations}
            onChoiceClick={onChoiceClick}
            questionColor={questionColor}
            userChoicesIds={userChoicesIds}
            withoutHightlight={question.type === 'survey'}
            isAnswered={isAnswered}
            isMultiChoiceSurvey={isMultiChoiceSurvey}
          />
        )}
        {question.type === 'open ended' && (
          <OpenEndedAnswer
            text={openEndedText}
            onTextChange={onOpenEndedTextChange}
            image={imageForOpenEnded}
            onImageChange={onImageForOpenEndedChange}
            onSubmit={onSubmitOpenEnded}
            hideContinue={hideContinue}
            isAnswered={isAnswered}
          />
        )}
      </S.Container>
      {question.type !== 'open ended' && userChoicesIds.length > 0 && (
        <S.ContinueButton id='questionContinue' onClick={onContinue} />
      )}
    </S.Wrapper>
  );
}

const S = {
  Wrapper: styled.div`
    padding: 20px 30px 30px;
  `,
  Container: styled.div`
    position: relative;
    border-radius: 15px;
    padding: 44px 20px 24px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0 12px 19px 0 rgba(60, 128, 209, 0.09);
    min-height: 350px;
    ${() => getIsRtl() && ' direction: rtl; '};
  `,
  QuestionText: styled.p`
    text-align: center;
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.darkPrimary};
  `,
  BackToIntroContainer: styled.div`
    margin-bottom: 16px;
    text-align: center;
  `,
  BackToIntroButton: styled.span`
    color: ${({ theme }) => theme.colors.lightPurple1};
    text-decoration: underline;
    cursor: pointer;
  `,
  DynamicBlueText: styled.p<{ isUserCorrect: boolean }>`
    text-align: center;
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 16px;
    color: ${({ theme, isUserCorrect }) =>
      isUserCorrect === null ? theme.colors.primaryBlue : isUserCorrect ? theme.colors.green1 : theme.colors.red1};
  `,
  ContinueButton: styled(ContinueButton)`
    margin-top: 20px;
  `,
  MultiSurveyText: styled.div`
    text-align: center;
    font-size: 14px;
    margin-bottom: 24px;
    color: ${({ theme }) => theme.colors.darkGray9};
  `,
};

export default memo(Question);

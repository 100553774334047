import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { authSelector, organizationsListSelector } from '../../../store/auth/auth.selectors';
import { biteQueryRequest } from '../../../store/bite/bite.actions';
import { orgSelector } from '../../../store/organization/organization.selectors';
import { getOrgSettings, updateDefaultOrg } from '../../../store/organization/organization.slice';
import { playlistQueryRequest } from '../../../store/playlist/playlist.actions';
import gtmTrack from '../../../services/googleTagManager/track';

const BITE_ORG_ID = 1;

interface Props {
  onSelect: () => void;
}

function SideMenuOrgs({ onSelect }: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector(authSelector);
  const { id: activeOrgId } = useSelector(orgSelector);
  const organizationsList = useSelector(organizationsListSelector);

  const handleClick = (orgId) => {
    if (orgId === activeOrgId) {
      return;
    }
    gtmTrack('change_organization', {
      from_organization_id: activeOrgId,
      to_organization_id: orgId,
    });
    history.push('/bites');
    const newActiveOrganization = organizationsList?.find((org) => org.id === orgId);
    if (newActiveOrganization) {
      dispatch(updateDefaultOrg(newActiveOrganization));
    }
    dispatch(getOrgSettings(orgId));
    dispatch(biteQueryRequest({ orgId }));
    dispatch(playlistQueryRequest({ orgId }));
    onSelect();
  };

  let shownOrganizations = auth?.organizations.filter((org) => org.id !== BITE_ORG_ID);

  shownOrganizations = shownOrganizations?.length ? shownOrganizations : auth?.organizations;

  return (
    <div>
      <hr style={{ marginTop: 20, marginBottom: 20 }} />
      {shownOrganizations?.map((org) => (
        <p
          style={{
            marginTop: 20,
            marginBottom: 20,
            opacity: org.id === activeOrgId ? 1 : 0.4,
            cursor: 'pointer',
          }}
          onClick={() => handleClick(org.id)}
          key={org.id}
          data-cy='sideMenuOrg'
          data-id={org.id}
        >
          {org.name}
        </p>
      ))}
      <hr style={{ marginTop: 20, marginBottom: 20 }} />
    </div>
  );
}

export default SideMenuOrgs;

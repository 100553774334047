import React, { FC, memo, useCallback, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  currentFlowSelector,
  emailFormSelector,
  passwordFormSelector,
} from '../../../store/authForm/authForm.selectors';
import { setCurrentForm, setEmail, setPassword } from '../../../store/authForm/authForm.slice';
import { login, resetAuthErrors, signupWithEmail } from '../../../store/auth/auth.actions';
import { v4 as uuid } from 'uuid';
import { getIsEmailValid, isValidPasswordMessage } from '../../../utils/auth';
import { EAuthFlowType, EAuthFormType } from '../auth.types';
import { authInProgressSelector } from '../../../store/auth/auth.selectors';
import FormTextInput from '../../../components/shared/Inputs/FormTextInput';
import Button from '../../../components/shared/Buttons';
import { log } from '../../../store/tracking/tracking.slice';
import Header from './Header';
import AuthError from './AuthError';
import useAuthError from '../hooks/useAuthError';
import useAuthTitles from '../hooks/useAuthTitles';
import { continueButtonDataCy, emailInputDataCy, passwordInputDataCy } from './emailForm.constants';

interface IProps {
  isAuthForm?: boolean;
}

const EmailForm: FC<IProps> = ({ isAuthForm }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const email = useSelector(emailFormSelector);
  const password = useSelector(passwordFormSelector);
  const authInProgress = useSelector(authInProgressSelector);

  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  const currentFlow = useSelector(currentFlowSelector);
  const currentForm = isAuthForm ? EAuthFormType.AUTH : EAuthFormType.EMAIL;

  const { title, subtitle, texts } = useAuthTitles();

  const formError = useAuthError();
  const handleChangeEmail = useCallback(
    (text) => {
      dispatch(setEmail(text));
      setEmailError(null);
      dispatch(resetAuthErrors());
    },
    [dispatch],
  );

  const handleChangePassword = useCallback(
    (text) => {
      dispatch(setPassword(text));
      setPasswordError(null);
      dispatch(resetAuthErrors());
    },
    [dispatch],
  );

  const handleOtp = useCallback(
    (processId) => {
      dispatch(
        log({
          event: 'EmailForm.handleOtp',
          processId,
          data: { destination: EAuthFormType.ENTER_CODE_EMAIL, currentFlow, currentForm },
        }),
      );
      dispatch(setCurrentForm(EAuthFormType.ENTER_CODE_EMAIL));
    },
    [currentFlow, currentForm, dispatch],
  );

  const handleEmailSignUpSubmit = useCallback(
    (processId) => {
      dispatch(
        log({
          event: 'EmailForm.handleEmailSignUpSubmit',
          processId,
          data: {
            currentFlow,
            currentForm,
            email: email.trim(),
            password,
            creator: false,
          },
        }),
      );

      dispatch(
        signupWithEmail({
          email: email.trim(),
          password,
          processId,
          onOtp: handleOtp,
        }),
      );
    },
    [currentFlow, currentForm, dispatch, email, handleOtp, password],
  );

  const handleEmailSignInSubmit = useCallback(
    (processId) => {
      dispatch(
        log({
          event: 'EmailForm.handleEmailSignInSubmit',
          processId,
          data: {
            currentFlow,
            currentForm,
            username: email.trim(),
            password,
          },
        }),
      );

      dispatch(
        login({
          username: email.trim(),
          password,
          processId,
          onOtp: handleOtp,
        }),
      );
    },
    [dispatch, currentFlow, currentForm, email, password, handleOtp],
  );

  const handleEmailSubmit = useCallback(
    (processId) => {
      if (currentFlow === EAuthFlowType.SIGN_IN) {
        handleEmailSignInSubmit(processId);
        return;
      }
      handleEmailSignUpSubmit(processId);
    },
    [currentFlow, handleEmailSignInSubmit, handleEmailSignUpSubmit],
  );

  const handleContinuePress = useCallback(() => {
    if (authInProgress) {
      return;
    }

    const isEmailValid = getIsEmailValid(email);
    const invalidPasswordMessage = isValidPasswordMessage(password);

    const processId = uuid();
    dispatch(
      log({
        event: 'EmailForm.handleContinuePress',
        processId,
        data: {
          currentFlow,
          currentForm,
          password,
          invalidPasswordMessage,
          isEmailValid,
        },
      }),
    );

    if ((invalidPasswordMessage && currentFlow === EAuthFlowType.SIGN_UP) || !password) {
      setPasswordError(t(invalidPasswordMessage));
    }

    if (!isEmailValid) {
      setEmailError(t('authForm.emailIsNotValid'));
    }

    if ((invalidPasswordMessage && currentFlow === EAuthFlowType.SIGN_UP) || !password || !isEmailValid) {
      return;
    }

    handleEmailSubmit(processId);
  }, [authInProgress, currentFlow, currentForm, dispatch, email, handleEmailSubmit, password, t]);

  const handleForgotPasswordPress = useCallback(() => {
    dispatch(
      log({
        event: 'EmailForm.handleForgotPasswordPress',
        data: { destination: EAuthFormType.FORGOT_PASSWORD_EMAIL, currentFlow, currentForm },
      }),
    );
    dispatch(setCurrentForm(EAuthFormType.FORGOT_PASSWORD_EMAIL));
  }, [currentFlow, currentForm, dispatch]);

  const renderForm = useCallback(() => {
    return (
      <>
        <S.TextInput
          style={S.TextInputContainerStyles}
          value={email}
          onChange={handleChangeEmail}
          label={t('authForm.email')}
          onEnterPress={handleContinuePress}
          error={emailError}
          data-cy={emailInputDataCy}
        />
        <S.TextInput
          style={S.TextInputContainerStyles}
          value={password}
          onChange={handleChangePassword}
          label={t('authForm.password')}
          onEnterPress={handleContinuePress}
          error={passwordError}
          password
          data-cy={passwordInputDataCy}
        />
        <S.ContinueButton isLoading={authInProgress} onClick={handleContinuePress} data-cy={continueButtonDataCy}>
          {t('common.Continue')}
        </S.ContinueButton>
        {currentFlow === EAuthFlowType.SIGN_IN && (
          <S.ForgotPassword onClick={handleForgotPasswordPress}>
            <S.ForgotPasswordText>{t('authForm.forgotPassword')}</S.ForgotPasswordText>
          </S.ForgotPassword>
        )}
      </>
    );
  }, [
    authInProgress,
    currentFlow,
    email,
    emailError,
    handleChangeEmail,
    handleChangePassword,
    handleContinuePress,
    handleForgotPasswordPress,
    password,
    passwordError,
    t,
  ]);

  if (isAuthForm) {
    return renderForm();
  }

  return (
    <S.Container>
      <Header
        title={isAuthForm ? title : t('authForm.emailForm.title')}
        description={isAuthForm && subtitle}
        texts={texts}
      />
      <AuthError isVisible={!!formError}>{formError}</AuthError>
      <S.Form>{renderForm()}</S.Form>
    </S.Container>
  );
};

const S = {
  Container: styled.div`
    @keyframes slideInFromLeft {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
    animation: 0.3s ease-out 0s 1 slideInFromLeft;
  `,

  Form: styled.div`
    margin-top: 32px;
    align-items: center;
    margin-bottom: 50px;
  `,
  TextInput: styled(FormTextInput)`
    display: flex;
    margin-bottom: 18px;
  `,
  ContinueButton: styled(Button)`
    min-height: 50px;
    min-width: 298px;
    margin-bottom: 18px;
  `,
  ForgotPassword: styled.div`
    text-align: center;
    margin-top: 24px;
  `,
  ForgotPasswordText: styled.span`
    cursor: pointer;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.gray19};
    font-size: 14px;
    line-height: 16px;
    margin-top: 24px;
    text-align: center;
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.gray19};
  `,
  TextInputContainerStyles: { marginBottom: 18 },
};

export default memo(EmailForm);

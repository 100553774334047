import biteShareUserActionTypes from './biteShareUser.types';

import { IUpdateBiteShareUserProgressRequestPayload, IAnswer } from '../../types/bite';
import { IResponseOpenEndedAnswer } from '../../types/biteShare';

export const updateBiteShareUserProgressRequest = (payload: IUpdateBiteShareUserProgressRequestPayload) => ({
  type: biteShareUserActionTypes.UPDATE_BITE_SHARE_USER_PROGRESS_REQUEST,
  payload,
});

export const updateBiteShareUserProgressSuccess = (payload) => ({
  type: biteShareUserActionTypes.UPDATE_BITE_SHARE_USER_PROGRESS_SUCCESS,
  payload,
});

export const addOpenEndedAnswer = (comment: IAnswer) => ({
  type: biteShareUserActionTypes.OPEN_ENDED_QUESTION_REQUEST,
  payload: comment,
});

export const addOpenEndedAnswerSuccess = (comment: IResponseOpenEndedAnswer) => ({
  type: biteShareUserActionTypes.OPEN_ENDED_QUESTION_SUCCESS,
  payload: comment,
});

export const addOpenEndedAnswerError = (comment: IAnswer) => ({
  type: biteShareUserActionTypes.OPEN_ENDED_QUESTION_ERROR,
  payload: comment,
});

export const addMultipleChoicesAnswer = (answer: IAnswer, callback: () => void) => ({
  type: biteShareUserActionTypes.MULTIPLE_CHOICES_QUATION_REQUEST,
  payload: { answer, callback },
});

export const addMultipleChoicesAnswerSuccess = (data: any) => ({
  type: biteShareUserActionTypes.MULTIPLE_CHOICES_QUATION_SUCCESS,
  payload: data,
});

export const fullyWatchedBiteIntroVideo = (biteShareUserId: number) => ({
  type: biteShareUserActionTypes.FULLY_WATCHED_BITE_INTRO_VIDEO_REQUEST,
  payload: biteShareUserId,
});

export const fullyWatchedBiteIntroVideoSuccess = () => ({
  type: biteShareUserActionTypes.FULLY_WATCHED_BITE_INTRO_VIDEO_SUCCESS,
});

import { useMemo } from 'react';

const useIsUserAnsweredQuestion = (selectedBite: any): boolean => {
  const questionSec = useMemo(() => {
    return selectedBite?.bite_sections.find((s) => s.type === 'question');
  }, [selectedBite?.bite_sections]);

  if (selectedBite?.bite_share_user?.choices?.length > 0 || questionSec?.questions?.length === 0) {
    return true;
  }
  return false;
};

export default useIsUserAnsweredQuestion;

import React, { FC } from 'react';
import styled, { css } from 'styled-components';

interface IProps {
  varient?: 'bite' | 'playlist';
}

const StatusBadge: FC<IProps> = ({ varient = 'bite' }) => {
  const StyledContainer = styled.div`
    display: inline-block;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 700;

    ${varient === 'bite' ? biteVarient : playlistVarient}
  `;

  return <StyledContainer>In Progress</StyledContainer>;
};

const biteVarient = css`
  background-color: ${({ theme }) => theme.msColors.lightBorderColor};
  color: ${({ theme }) => theme.msColors.lightTextColor};
`;

const playlistVarient = css`
  background-color: ${({ theme }) => theme.msColors.transparentBlack};
  color: white;
`;

export default StatusBadge;

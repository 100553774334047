import React, { memo } from 'react';
import styled from 'styled-components';
import Preloader from '../../../../components/shared/Preloader';

function SsoAuthLoader({ className }: { className?: string }) {
  return (
    <S.Container className={className}>
      <Preloader />
    </S.Container>
  );
}

export default memo(SsoAuthLoader);

const S = {
  Container: styled.div`
    display: flex;
    flex-grow: 1;
    min-height: 1px;
    align-items: center;
    justify-content: center;
  `,
};

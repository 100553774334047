import { useMemo } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

const useQueryParams = () => {
  const location = useLocation();

  const queryParams = useMemo(() => {
    const parsedQueryParams: any = queryString.parse(location.search, {
      parseBooleans: true,
    });
    return parsedQueryParams;
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

  return { queryParams };
};

export default useQueryParams;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { ContinueButton, Input } from '../components/shared';
import { getIsRtl } from '../locale/i18n';
import gtmTrack from '../services/googleTagManager/track';
import { addUserToOrganization } from '../store/api/calls/auth.calls';
import { refreshUser, setIsRestrictedContentForAnotherOrgModalShown } from '../store/auth/auth.actions';
import { getOrgSettings } from '../store/organization/organization.slice';
import { getRedirectUri } from '../utils/redirection';
import { log, logError } from '../store/tracking/tracking.slice';
import { cloneDeep } from 'lodash';

function JoinOrganization() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isProcessing, setIsProcessing] = useState(false);
  const [code, setCode] = useState();
  const [error, setError] = useState('');

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setIsProcessing(true);

    try {
      dispatch(
        log({
          event: 'JoinOrganization.handleSubmit: start',
          data: {
            code,
          },
        }),
      );

      const {
        data: { organization },
      } = await addUserToOrganization(code);

      dispatch(
        log({
          event: 'JoinOrganization.handleSubmit: addUserToOrganization done',
          data: {
            organization,
          },
        }),
      );

      dispatch(getOrgSettings(organization));
      dispatch(refreshUser());
      dispatch(setIsRestrictedContentForAnotherOrgModalShown(false));

      const redirectURL = getRedirectUri();
      if (redirectURL) {
        history.push(redirectURL);
      } else {
        history.push('/bites');
      }
    } catch (err) {
      dispatch(
        logError({
          event: 'JoinOrganization.handleSubmit: error',
          data: {
            error: err,
            errorResponse: cloneDeep(err?.response),
          },
        }),
      );
      setError(err.message);
      setIsProcessing(false);
    }
  };
  return (
    <S.Root>
      <S.P>{t('screens.joinOrganization.header')}</S.P>
      <form onSubmit={handleSubmit}>
        <S.Input
          value={code}
          onChange={(evt) => setCode(evt.target.value)}
          placeholder={t('screens.joinOrganization.placeholder')}
          type='password'
          data-cy='joinOrgInput'
        />
        <ContinueButton
          disabled={!code || isProcessing}
          data-cy='joinOrgButton'
          onClick={() => {
            gtmTrack('join_new_organization', {});
          }}
        />
        {error && <S.Error>{error}</S.Error>}
      </form>
    </S.Root>
  );
}

const S = {
  Root: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 75px);
  `,

  P: styled.p`
    direction: ${() => (getIsRtl() ? 'rtl' : 'ltr')};
    writing-mode: horizontal-tb;
  `,

  Input: styled(Input)`
    margin-top: 20px;
    margin-bottom: 20px;
  `,

  Error: styled.p`
    color: ${({ theme }) => theme.colors.warning};
    margin-top: 10px;
  `,
};

export default JoinOrganization;

import React, { createRef, FC, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Div100vh from 'react-div-100vh';
import { useLocation } from 'react-router-dom';

import Routes from './navigation/Routes';
import { setMicrosoftContext } from './store/auth/auth.actions';
import microsoftTeams from './services/msTeams';
import useIsInsideMsTeams from './hooks/useIsInsideMsTeams';
import { setRedirectUri } from './utils/redirection';
import useFetchOrgSettingsByQueryParams from './hooks/useFetchOrgSettingsByQueryParams';
import MetaTags from './components/MetaTags';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import { requestGeolocation, runInit } from './store/appActivity/appActivity.slice';
import SomethingWentWrongModal from './components/modals/SomethingWentWrongModal';
import {
  geolocationSelector,
  showSomethingWentWrongSelector,
  translationsAreInitializedSelector,
} from './store/appActivity/appActivity.selectors';
import { Loader } from './components/shared';
import { GoogleOAuthProvider } from '@react-oauth/google';
import useAuthLocationCheck from './screens/auth/hooks/useAuthLocationCheck';
import { isMobile } from './utils/utils';
import { APP_HEIGHT, APP_WIDTH } from './utils/constants';
import Geofence from './components/Geofence/Geofence';
import { geofenceContentInfoSelector } from './store/geofence/geofence.selectors';

export const bodyPortal = createRef<HTMLDivElement>();

const App: FC = () => {
  const geolocation = useSelector(geolocationSelector);
  const location = useLocation();
  const dispatch = useDispatch();

  const geofenceContentInfo = useSelector(geofenceContentInfoSelector);
  const translationsAreInitialized = useSelector(translationsAreInitializedSelector);

  useAuthLocationCheck();
  useFetchOrgSettingsByQueryParams();
  const showSomethingWentWrong = useSelector(showSomethingWentWrongSelector);

  useEffect(() => {
    if (!geolocation) {
      dispatch(requestGeolocation());
    }
  }, [dispatch, geolocation]);

  useEffect(() => {
    // to initially display playlists on the feed
    if (location.pathname === '/playlists') {
      setRedirectUri({ redirectUri: location.pathname + location.search });
    }
    dispatch(runInit());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    microsoftTeams.getContext((context) => {
      dispatch(setMicrosoftContext(context));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isInsideMsTeams, isInsideTeamsMobile } = useIsInsideMsTeams();
  const shouldAddPadding = useMemo(
    () => isInsideMsTeams && !isInsideTeamsMobile,
    [isInsideMsTeams, isInsideTeamsMobile],
  );

  const isDevice = isMobile();

  // const [deviceType, setDeviceType] = useState('desktop');
  const [iosVersion, setIosVersion] = useState(0);

  function iOSversion() {
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
      const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
      return parseInt(v[1], 10);
    }
    return null;
  }

  useEffect(() => {
    const version = iOSversion();
    setIosVersion(version);
  }, []);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <ErrorBoundary>
        <MetaTags />
        {translationsAreInitialized && <SomethingWentWrongModal isOpen={!!showSomethingWentWrong} />}
        <S.FrameContainer {...{ shouldAddPadding, isDevice }}>
          <S.AppContainer
            isInsideMsTeams={isInsideMsTeams}
            className={`${iosVersion < 17 ? '' : 'ios-gte-17'}`}
            id='app-container'
          >
            {!translationsAreInitialized ? (
              <Loader />
            ) : isDevice ? (
              <Div100vh>
                <Routes />
              </Div100vh>
            ) : (
              <Routes />
            )}
          </S.AppContainer>
          <S.BodyPortal ref={bodyPortal} />
          {geofenceContentInfo && <Geofence />}
        </S.FrameContainer>
      </ErrorBoundary>
    </GoogleOAuthProvider>
  );
};

const S = {
  FrameContainer: styled.div<{
    shouldAddPadding: boolean;
    isDevice: boolean;
  }>(
    ({ shouldAddPadding, isDevice }) => css`
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      padding: ${shouldAddPadding ? '25px 32px 32px 32px' : 0};

      ${!isDevice
        ? `
        width: ${APP_WIDTH}px;
        height: ${APP_HEIGHT}px;
        max-width: 100vw;
        max-height: 100vh;
      `
        : ''}
    `,
  ),
  AppContainer: styled.div<{
    isInsideMsTeams: boolean;
  }>`
    z-index: 1;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fff;
    overflow-x: hidden;
    overflow-y: auto;
  `,
  BodyPortal: styled.div`
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
  `,
};

export default App;

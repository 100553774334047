import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import useQueryParams from '../../../hooks/useQueryParams';

interface Props {
  children: React.ReactNode;
  onBack: (e) => void;
  onNext: (e) => void;
}

export const SideControls: FC<Props> = ({ children, onBack, onNext }) => {
  const { queryParams } = useQueryParams();
  const blockTapSideNavigation = queryParams?.blockTapSideNavigation;

  const handleBackClick = useCallback(
    (e) => {
      if (blockTapSideNavigation) {
        return;
      }

      onBack(e);
    },
    [blockTapSideNavigation, onBack],
  );

  const handleNextClick = useCallback(
    (e) => {
      if (blockTapSideNavigation) {
        return;
      }

      onNext(e);
    },
    [blockTapSideNavigation, onNext],
  );

  return (
    <S.Container>
      <S.LeftControl disabled={blockTapSideNavigation} onClick={handleBackClick} id='biteNavToPreviousSection' />
      {children}
      <S.RightControl disabled={blockTapSideNavigation} onClick={handleNextClick} id='biteNavToNextSection' />
    </S.Container>
  );
};

const controlStyle = styled.div`
  width: 30px;
  height: 100%;
  position: absolute;
  top: 0;
`;

const S = {
  Container: styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
  `,
  LeftControl: styled(controlStyle)<{ disabled: boolean }>`
    left: 0;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  `,
  RightControl: styled(controlStyle)<{ disabled: boolean }>`
    right: 0;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  `,
};

import { all, takeEvery, select } from 'redux-saga/effects';
import { version } from '../../constants/app';
import { getGenericProps } from '../../services/googleTagManager/track';
import { authSelector } from '../auth/auth.selectors';
import { orgSelector } from '../organization/organization.selectors';
import { acrossSessionsId, sessionId } from './tracking.constants';
import { log, logError } from './tracking.slice';
import sendLog, { logError as sendErrorLog } from '../../services/log';
import { isProd } from '../../utils/env';
import { getFullName } from '../../utils/auth';

let eventCounter = 0;

function* logSaga({ payload }) {
  yield sendLogControllerSaga({
    logger: sendLog,
    payload,
  });
}

function* logErrorSaga({ payload }) {
  yield sendLogControllerSaga({
    logger: sendErrorLog,
    payload,
  });
}

const query = new URLSearchParams(window.location.search);
const isPreview = !!query.get('isPreview');

function* sendLogControllerSaga({ logger, payload }) {
  try {
    const org = yield select(orgSelector);
    const user = yield select(authSelector);
    const username = getFullName(user);

    const genericProps = yield getGenericProps();

    const enrichedPayload = {
      ...genericProps,

      url: window.location.href,
      app_version: version,
      app_env: isProd() ? 'prod' : 'staging',
      bites_session_id: sessionId,
      across_sessions_id: acrossSessionsId,

      user,
      bites_user_id: user?.id,
      username,
      user_email: user?.email,

      org,
      org_id: org?.id,
      org_name: org?.name,

      event_counter: ++eventCounter,

      isPreview,

      ...payload,
    };

    // console.log(enrichedPayload.event, enrichedPayload);
    yield logger(enrichedPayload);
  } catch (error) {
    console.error('Failed to log', error);
  }
}

export default function* appActivitySaga() {
  yield all([takeEvery(log, logSaga)]);
  yield all([takeEvery(logError, logErrorSaga)]);
}

import { useDispatch } from 'react-redux';

import msalInstance from '../../services/msalInstance';
import { resetAuthErrors, setIsSocialLoading, socialLoginEnd } from '../../store/auth/auth.actions';

const useMicrosoftLogin = () => {
  const dispatch = useDispatch();

  return async () => {
    try {
      dispatch(resetAuthErrors());
      dispatch(setIsSocialLoading(true));
      msalInstance.loginRedirect({
        scopes: [],
        state: window.location.pathname + window.location.search,
      });
      // login popup doesn't work on mobile, submitted a bug
      // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/3118
      // if (isTeamsPopup) {
      // msalInstance.loginRedirect({ scopes: [] })
      // return
      // }
      // const response = await msalInstance.loginPopup({ scopes: [] })
      // dispatch(
      //   socialLoginRequest({
      //     token: response.accessToken,
      //     backend: 'azuread-oauth2',
      //   })
      // )
    } catch (err) {
      dispatch(socialLoginEnd({ error: true }));
    }
  };
};

export default useMicrosoftLogin;

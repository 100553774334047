import React, { FC } from 'react';
import styled from 'styled-components';
import { Fabric } from '@fluentui/react/lib/Fabric';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';

import microsoftTeams from '../../services/msTeams';
import BitesLogo from '../../assets/images/bite-logo.png';

const TeamsMsgExtSettings: FC = () => {
  const onLogout = () => {
    microsoftTeams.authentication.notifySuccess('logout');
  };
  return (
    <Fabric>
      <S.Container>
        <S.CompanyLogo src={BitesLogo} />
        <S.ExplanationText>Hi, you are about to sign out of Bites Teams app.</S.ExplanationText>

        <PrimaryButton onClick={onLogout}>Sign out</PrimaryButton>
      </S.Container>
    </Fabric>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 20px;
`;
const CompanyLogo = styled.img`
  margin: 30px 0;
  width: 70px;
  height: 70px;
`;

const ExplanationText = styled.p`
  font-size: 18px;
  margin-bottom: 30px;
`;

const S = { Container, CompanyLogo, ExplanationText };

export default TeamsMsgExtSettings;

import React, { FC, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authInProgressSelector, errorCodesSelector } from '../../../../store/auth/auth.selectors';
import { loginWithPhone, resetAuthErrors, signupWithPhone } from '../../../../store/auth/auth.actions';
import { currentFlowSelector, phoneFormSelector } from '../../../../store/authForm/authForm.selectors';
import { setCurrentForm, setPhone } from '../../../../store/authForm/authForm.slice';
import PhoneNumberForm from './PhoneNumberForm';
import { EAuthFlowType, EAuthFormType } from '../../auth.types';
import { v4 as uuid } from 'uuid';
import { log } from '../../../../store/tracking/tracking.slice';

interface IProps {
  isAuthForm?: boolean;
}

const PhoneNumberAuth: FC<IProps> = ({ isAuthForm }) => {
  const dispatch = useDispatch();

  const errorCodes = useSelector(errorCodesSelector);
  const authInProgress = useSelector(authInProgressSelector);
  const currentFlow = useSelector(currentFlowSelector);
  const currentForm = isAuthForm ? EAuthFormType.AUTH : EAuthFormType.PHONE_NUMBER;
  const phone = useSelector(phoneFormSelector);

  const handleOtp = useCallback(
    (processId) => {
      dispatch(
        log({
          event: 'PhoneNumberAuth.handleOtp',
          processId,
          data: { destination: EAuthFormType.ENTER_CODE_PHONE, currentFlow, currentForm },
        }),
      );

      dispatch(setCurrentForm(EAuthFormType.ENTER_CODE_PHONE));
      dispatch(resetAuthErrors());
    },
    [currentFlow, currentForm, dispatch],
  );

  const handleContinue = useCallback(() => {
    const processId = uuid();
    dispatch(
      log({
        event: 'PhoneNumberAuth.handleContinue',
        processId,
        data: {
          currentFlow,
          currentForm,
          username: phone,
        },
      }),
    );

    if (currentFlow === EAuthFlowType.SIGN_IN) {
      dispatch(
        loginWithPhone({
          credentials: {
            username: phone,
          },
          processId,
          onOtp: handleOtp,
        }),
      );
      return;
    }

    dispatch(
      signupWithPhone({
        phone,
        processId,
        onOtp: handleOtp,
      }),
    );
  }, [currentFlow, currentForm, dispatch, handleOtp, phone]);

  const handlePhoneNumberChange = useCallback(
    (number: string) => {
      dispatch(setPhone(number));
      dispatch(resetAuthErrors());
    },
    [dispatch],
  );

  return (
    <PhoneNumberForm
      errorCodes={errorCodes}
      phone={phone}
      isLoading={authInProgress}
      onChange={handlePhoneNumberChange}
      onContinue={handleContinue}
      isAuthForm={isAuthForm}
    />
  );
};

export default memo(PhoneNumberAuth);

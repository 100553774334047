import React, { FC } from 'react';
import styled from 'styled-components';

interface Props {
  url?: string;
}

const CoverImage: FC<Props> = ({ url }) => {
  return (
    <S.CoverImageContainer>
      <S.CoverImage src={url} />
    </S.CoverImageContainer>
  );
};

const S = {
  CoverImageContainer: styled.div`
    height: 120px;
    width: 120px;
  `,
  CoverImage: styled.img`
    border-radius: 10px;
    object-fit: cover;
    width: 100%;
    height: 100%;
  `,
};

export default CoverImage;

import { memo, useEffect } from 'react';
import msalInstance from '../../services/msalInstance';

function MsTeamsAuthStart() {
  useEffect(() => {
    msalInstance.loginRedirect({ scopes: [] });
  }, []);
  return null;
}

export default memo(MsTeamsAuthStart);

import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import BiteSubjectsList from './components/BiteSubjectsList';

import { IPlaylist } from '../../../types/playlist';
import useDataFormatter from '../../../hooks/useDateFormatter';

interface IProps {
  playlist: IPlaylist;
  onClick: (any) => void;
}

const PlaylistItem: FC<IProps> = ({ playlist, onClick }) => {
  const { subject, bite_shares, created_at } = playlist;
  const { formatDate } = useDataFormatter();
  const handlePlaylistClick = () => onClick?.(playlist);

  return (
    <S.Container>
      <S.CoverImage coverImage={bite_shares[0]?.cover_url || ''} onClick={handlePlaylistClick} />
      <S.MainContainer>
        <S.Top>
          <S.Date>{formatDate(created_at)}</S.Date>
          <S.PlaylistLength>{playlist.bite_shares.length} Bites</S.PlaylistLength>
        </S.Top>
        <S.Subject onClick={handlePlaylistClick}>{subject}</S.Subject>
        <S.Divider />
        <BiteSubjectsList subjects={bite_shares.map((bite) => bite.subject)} />
      </S.MainContainer>
    </S.Container>
  );
};

const smallTextStyle = css`
  color: ${({ theme }) => theme.msColors.lightTextColor};
  font-size: 12px;
  font-weight: 500;
`;

const S = {
  Container: styled.div`
    border: 1px solid ${({ theme }) => theme.msColors.lightBorderColor2};

    /* height: 300px; */
  `,

  CoverImage: styled.div<{ coverImage: string }>`
    height: 150px;
    direction: rtl;
    padding: 3px 5px;
    cursor: pointer;
    ${({ coverImage }) => css`
      background-image: url(${coverImage});
      background-size: cover;
      background-color: pink;
    `}
  `,

  MainContainer: styled.div`
    padding: 12px;
  `,

  Top: styled.div`
    font-size: 9px;
    color: #4c4949;
    margin-bottom: 3px;
  `,

  Date: styled.span`
    margin-right: 7px;
    ${smallTextStyle}
  `,
  PlaylistLength: styled.span`
    ${smallTextStyle}
  `,

  Subject: styled.p`
    font-size: 18px;
    color: ${({ theme }) => theme.msColors.primaryTextcolor};
    font-weight: bold;
    margin: 10px 0;
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.msColors.hoverColor};
    }
  `,
  Divider: styled.div`
    width: 100%;
    height: 1px;
    background-color: #eee;
  `,
};

export default PlaylistItem;

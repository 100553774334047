import React, { useState, useCallback, useRef, useEffect, FC, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Lightbox from 'react-image-lightbox';
import ReactPlayer from 'react-player';

import { ReactComponent as PlayIcon } from '../../../../assets/icons/triangle.svg';
import { canPlayVideoSelector, selectedBiteSelector } from '../../../../store/bite/bite.selectors';
import { disableVideoPlaying } from '../../../../store/bite/bite.actions';
import { getIsRtl } from '../../../../locale/i18n';
import { ContinueButton, VideoPlayer } from '../../../../components/shared';
import VideoModal from '../../../../components/shared/CustomModal/VideoModal/VideoModal';
import useMoveToNextBiteSection from '../../../../hooks/useMoveToNextBiteSection';
import { authSelector } from '../../../../store/auth/auth.selectors';
import gtmTrack from '../../../../services/googleTagManager/track';
import useQueryParams from '../../../../hooks/useQueryParams';

interface IProps {
  summary: {
    subject: string;
    coverImage: string | null;
    file_type: 'image' | 'video' | 'youtube';
    media_url: string | null;
    videoStartsAt: null | number;
    videoEndsAt: null | number;
    image_url: string | null;
    subtitles_url?: string | null;
    subtitles_locale?: string | 'en';
  };
  isModalOpen: boolean;
  onFullyWatched: () => void;
  setIsModalOpen: any;
}

const SummaryMediaViewer: FC<IProps> = ({ onFullyWatched, summary, isModalOpen, setIsModalOpen }) => {
  const dispatch = useDispatch();

  const { queryParams } = useQueryParams();
  const playlistId = queryParams?.playlistId;

  const { selectedBite } = useSelector(selectedBiteSelector);

  const currentSubtitles = useMemo<{ url: string; locale: string }>(
    () => ({
      url: summary?.subtitles_url,
      locale: summary?.subtitles_locale,
    }),
    [summary?.subtitles_locale, summary?.subtitles_url],
  );

  const [isCoverLightboxOpen, setIsCoverLightboxOpen] = useState(false);

  const playVideo = () => {
    dispatch(disableVideoPlaying());
    setIsModalOpen(true);
  };

  const canPlayVideo = useSelector(canPlayVideoSelector);

  const auth = useSelector(authSelector);

  const playerRef = useRef<ReactPlayer>(null);

  const isVideo = (): boolean => ['video', 'youtube'].includes(summary?.file_type);

  const handleClick = () => {
    if (!isModalOpen) {
      if (summary?.file_type === 'image') {
        setIsCoverLightboxOpen(true);
      } else if (isVideo()) {
        playVideo();
      }
    }
  };

  const { proceedToNextSection } = useMoveToNextBiteSection({
    sectionType: 'summary',
  });

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  useEffect(() => {
    gtmTrack('summary_section', {
      bite_id: selectedBite?.bite,
      bite_share_id: selectedBite?.id,
      content_org_id: selectedBite?.orgid,
      sharing_mode: selectedBite?.sharing_mode,
      playlist_id: playlistId,
    });
  }, [playlistId, selectedBite]);

  return (
    <>
      <S.Wrapper style={{ height: '100%', paddingBottom: '30px' }}>
        {!isVideo() && (
          <S.ImageContainer onClick={handleClick} file_type={summary?.file_type} data-cy='summaryContainer'>
            <S.CoverImg src={summary?.file_type === 'image' ? summary?.image_url : summary?.coverImage} />
          </S.ImageContainer>
        )}
        {!isModalOpen && isVideo() && (
          <S.Container image_url={summary?.image_url} coverImage={summary?.coverImage}>
            <VideoPlayer
              onFullyWatched={null}
              closeModal={closeModal}
              media_url={summary?.media_url}
              file_type={summary?.file_type}
              videoEndsAt={summary?.videoEndsAt}
              videoStartsAt={summary?.videoStartsAt}
              section={'intro'}
              playerRef={playerRef}
              playing={true}
              loop={true}
              muted={true}
              controls={false}
              isFullScreen={false}
              subtitles_url={summary?.subtitles_url}
              subtitles_locale={summary?.subtitles_locale}
            />
            <S.IconContainer onClick={handleClick} file_type={summary?.file_type} data-cy='introContainer'>
              <S.PlayButton data-test='play' display={canPlayVideo ? 'flex' : 'none'}>
                <S.PlayIcon id='btnPlayVideo' />
              </S.PlayButton>
            </S.IconContainer>
          </S.Container>
        )}
        <S.ContinueButton
          onClick={() => {
            proceedToNextSection();
            gtmTrack('summary_next_button', { bites_user_id: auth?.id });
          }}
        />
        {isCoverLightboxOpen && (
          <Lightbox
            mainSrc={summary?.file_type === 'image' ? summary?.image_url : summary?.coverImage}
            onCloseRequest={() => {
              setIsCoverLightboxOpen(false);
            }}
          />
        )}
        {isModalOpen && (
          <VideoModal
            onFullyWatched={onFullyWatched}
            closeModal={closeModal}
            media_url={summary?.media_url}
            currentSubtitles={currentSubtitles}
            file_type={summary?.file_type}
            videoEndsAt={summary?.videoEndsAt}
            videoStartsAt={summary?.videoStartsAt}
            section={'summary'}
          />
        )}
      </S.Wrapper>
    </>
  );
};

const CONTINUE_BUTTON_HEIGHT = 40;

const S: any = {};

S.Wrapper = styled.div`
  padding: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  flex: 1;
`;

S.ImageContainer = styled.div<{ file_type: string }>`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 15px;
  cursor: pointer;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  ${({ file_type }) => file_type === 'image' && 'margin-bottom: 10px'};
`;

S.Container = styled.div<{
  file_type: string;
  image_url: string;
  coverImage: string;
}>`
  position: relative;
  display: block;
  width: 100%;
  height: ${((window.innerWidth - 60) * 5) / 4}px;
  cursor: pointer;
  background-image: url(${({ file_type, image_url, coverImage }) => (file_type === 'image' ? image_url : coverImage)});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  -webkit-transform: translateZ(0);
  box-shadow: 0 19px 51px 0 rgba(0, 0, 0, 0.16), 0 14px 19px 0 rgba(0, 0, 0, 0.07);
  border-radius: 32px;
  overflow: hidden;
  max-width: 440px;
  max-height: 550px ${({ file_type }) => file_type === 'image' && 'margin-bottom: 10px'};
`;

S.IconContainer = styled.div<{ file_type: string }>`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 15px;
  cursor: pointer;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
`;

S.CoverImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  max-width: 100%;
  max-height: calc(100% - ${CONTINUE_BUTTON_HEIGHT}px);
`;

S.PlayButton = styled.div<{ display: string }>`
  width: 70px;
  height: 70px;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${({ display }) => `
		display: ${display}
	`};

  ${({ theme }) => css`
    border: 2px solid ${theme.colors.white};
  `};
`;

S.PlayIcon = styled(PlayIcon)`
  width: 40px;
  transform: translateX(10%);
  transform-origin: left;
  ${({ theme }) => css`
    fill: ${theme.colors.white};
  `};
`;

S.Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 23.5px;
  margin-bottom: 16.5px;
  z-index: 1;
  ${({ theme }) => css`
    background-color: ${theme.colors.lightGray7};
  `};
`;

S.Subject = styled.p`
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  direction: ${() => (getIsRtl() ? 'rtl' : 'ltr')};
  writing-mode: horizontal-tb;
  z-index: 1;
  ${({ theme }) => css`
    color: ${theme.colors.white};
  `};
`;
S.ContinueButton = styled(ContinueButton)`
  margin-top: 24px;
  height: ${CONTINUE_BUTTON_HEIGHT}px;
`;
export default SummaryMediaViewer;

import * as msal from '@azure/msal-browser';

import { APP, MS_PERSONAL_TENANT_ID } from '../constants/app';
import history from '../navigation/history';
import store from '../store';
import { setIsSocialLoading, socialLoginEnd, socialLoginRequest } from '../store/auth/auth.actions';

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID,
    redirectUri: window.location.origin,
    navigateToLoginRequestUrl: false,
  },
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

export default msalInstance;

msalInstance
  .handleRedirectPromise()
  .then((response) => {
    if (!response) {
      // not the logic case, just return
      return;
    }

    history.push(response.state ? response.state : { ...history.location, pathname: '/auth/signin' });
    store.dispatch(setIsSocialLoading(true));

    const token = response.tenantId === MS_PERSONAL_TENANT_ID ? response.idToken : response.accessToken;
    store.dispatch(
      socialLoginRequest({
        token,
        backend: 'azuread-oauth2',
        app: APP,
      }),
    );
  })
  .catch(() => {
    store.dispatch(socialLoginEnd({ error: true }));
  });

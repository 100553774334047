import React, { FC, memo, useCallback, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { currentFlowSelector, employeeIdSelector } from '../../../store/authForm/authForm.selectors';
import { setEmployeeId } from '../../../store/authForm/authForm.slice';
import { login, resetAuthErrors } from '../../../store/auth/auth.actions';
import { v4 as uuid } from 'uuid';
import { EAuthFormType } from '../auth.types';
import { authInProgressSelector } from '../../../store/auth/auth.selectors';
import FormTextInput from '../../../components/shared/Inputs/FormTextInput';
import Button from '../../../components/shared/Buttons';
import { log } from '../../../store/tracking/tracking.slice';
import Header from './Header';
import AuthError from './AuthError';
import useAuthError from '../hooks/useAuthError';
import useAuthTitles from '../hooks/useAuthTitles';
import { continueButtonDataCy, employeeIdInputDataCy } from './emailForm.constants';

interface IProps {
  isAuthForm?: boolean;
}

const SevenShiftsEmployeeIdForm: FC<IProps> = ({ isAuthForm }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const employeeId = useSelector(employeeIdSelector);
  const authInProgress = useSelector(authInProgressSelector);

  const [employeeIdError, setEmployeeIdErrorError] = useState(null);

  const currentFlow = useSelector(currentFlowSelector);
  const currentForm = isAuthForm ? EAuthFormType.AUTH : EAuthFormType.SEVEN_SHIFTS_EMPLOYEE_ID;

  const { title, subtitle, texts } = useAuthTitles();

  const formError = useAuthError();
  const handleChangeEmployeeId = useCallback(
    (text) => {
      if (!text.match(/^[0-9]*$/)) {
        return;
      }
      dispatch(setEmployeeId(text));
      setEmployeeIdErrorError(null);
      dispatch(resetAuthErrors());
    },
    [dispatch],
  );

  const handleEmployeeIdSignInSubmit = useCallback(
    (processId) => {
      dispatch(
        log({
          event: 'SevenShiftsEmployeeIdForm.handleEmployeeIdSignInSubmit',
          processId,
          data: {
            currentFlow,
            currentForm,
            sevenShiftsEmployeeId: Number(employeeId.trim()),
          },
        }),
      );

      dispatch(
        login({
          sevenShiftsEmployeeId: Number(employeeId.trim()),
          processId,
        }),
      );
    },
    [dispatch, currentFlow, currentForm, employeeId],
  );

  const handleContinuePress = useCallback(() => {
    if (authInProgress) {
      return;
    }

    const isEmployeeIdValid = !!employeeId.trim() || /^\d+$/.test(employeeId.trim());

    const processId = uuid();
    dispatch(
      log({
        event: 'SevenShiftsEmployeeIdForm.handleContinuePress',
        processId,
        data: {
          currentFlow,
          currentForm,
          isEmployeeIdValid,
        },
      }),
    );

    if (!isEmployeeIdValid) {
      setEmployeeIdErrorError(t('authForm.employeeIdIsNotValid'));
      return;
    }

    handleEmployeeIdSignInSubmit(processId);
  }, [authInProgress, currentFlow, currentForm, dispatch, employeeId, handleEmployeeIdSignInSubmit, t]);

  const renderForm = useCallback(() => {
    return (
      <>
        <S.TextInput
          type={'tel'}
          style={S.TextInputContainerStyles}
          value={employeeId}
          onChange={handleChangeEmployeeId}
          label={t('authForm.employeeId')}
          onEnterPress={handleContinuePress}
          error={employeeIdError}
          data-cy={employeeIdInputDataCy}
        />
        <S.ContinueButton isLoading={authInProgress} onClick={handleContinuePress} data-cy={continueButtonDataCy}>
          {t('common.Continue')}
        </S.ContinueButton>
      </>
    );
  }, [authInProgress, employeeId, employeeIdError, handleChangeEmployeeId, handleContinuePress, t]);

  if (isAuthForm) {
    return renderForm();
  }

  return (
    <S.Container>
      <Header
        title={isAuthForm ? title : t('authForm.employeeIdForm.title')}
        description={isAuthForm && subtitle}
        texts={texts}
      />
      <AuthError isVisible={!!formError}>{formError}</AuthError>
      <S.Form>{renderForm()}</S.Form>
    </S.Container>
  );
};

const S = {
  Container: styled.div`
    @keyframes slideInFromLeft {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
    animation: 0.3s ease-out 0s 1 slideInFromLeft;
  `,

  Form: styled.div`
    margin-top: 32px;
    align-items: center;
    margin-bottom: 50px;
  `,
  TextInput: styled(FormTextInput)`
    display: flex;
    margin-bottom: 18px;
  `,
  ContinueButton: styled(Button)`
    min-height: 50px;
    min-width: 298px;
    margin-bottom: 18px;
  `,
  TextInputContainerStyles: { marginBottom: 18 },
};

export default memo(SevenShiftsEmployeeIdForm);

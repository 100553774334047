import { getQueryParam, ISetQueryParamsProps, setQueryParam } from './queryParams';

const REDIRECT_QUERY_NAME = 'redirect';

export const setRedirectUri = ({
  pathname,
  redirectUri,
  replace,
}: {
  pathname?: ISetQueryParamsProps['pathname'];
  redirectUri: ISetQueryParamsProps['value'];
  replace?: ISetQueryParamsProps['replace'];
}) => {
  setQueryParam({ pathname, name: REDIRECT_QUERY_NAME, value: redirectUri, replace });
};

export const getRedirectUri = () => {
  return getQueryParam(REDIRECT_QUERY_NAME);
};

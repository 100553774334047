import React, { useState, useEffect, forwardRef, useRef, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ReactComponent as AsteriskSvg } from '../../../assets/icons/asterisk.svg';
import { getIsRtl } from '../../../locale/i18n';
import Loader from '../Loader';

export const BaseInput = styled.input`
  text-align: left;
  font-size: 16px;
  line-height: 22px;
  ${({ theme }) => css`
    color: ${theme.colors.darkPrimary};
  `};
`;

interface IProps {
  style?: any;
  type?: string;
  value?: string;
  placeholder?: string;
  // eslint-disable-next-line no-undef
  icon?: JSX.Element;
  letterSpacing?: string;
  centered?: boolean;
  isConfirmEnabled?: boolean;
  isMandatory?: boolean;
  onChange?: (e: any) => void;
  onKeyPress?: (e: any) => void;
  handleSubmit?: () => void;
  refCallback?: ((ref: any) => () => void) | ((ref: any) => void);
  inputWidth?: number;
  inputMode?: string;
  autoComplete?: string;
  pattern?: string;
  withSubmitButton?: boolean;
  isLoading?: boolean;
}
// eslint-disable-next-line
const InputWithIcon = forwardRef<HTMLInputElement, IProps>(({ style = {}, ...props }, forwardedRef) => {
  const {
    icon,
    placeholder,
    letterSpacing,
    centered,
    isConfirmEnabled,
    isMandatory,
    handleSubmit,
    refCallback,
    inputWidth = 300,
    inputMode,
    autoComplete,
    pattern,
    withSubmitButton,
    isLoading,
  } = props;
  const [type, setType] = useState<string>(props.type || 'text');
  const { t } = useTranslation();

  const inputStyles = useMemo(
    () => ({
      textAlign: centered ? 'center' : getIsRtl() ? 'right' : 'left',
      letterSpacing: letterSpacing,
      ...style,
    }),
    [centered, letterSpacing, style],
  );

  const handleSwitchType = () => {
    setType(type === 'text' ? 'password' : 'text');
  };

  const innerRef = useRef();
  const inputRef = forwardedRef || innerRef;

  useEffect(() => {
    if (refCallback) {
      return refCallback(inputRef);
    }
  }, [refCallback, inputRef]);

  return (
    <S.InputContainer>
      {icon && <S.IconWrapper>{icon}</S.IconWrapper>}
      {isMandatory && <S.AsteriskIcon />}
      <S.Input
        className={props.type === 'password' ? 'pass' : ''}
        {...props}
        type={type}
        // @ts-ignore
        inputMode={inputMode}
        autoComplete={autoComplete}
        pattern={pattern}
        inputWidth={inputWidth}
        placeholder={placeholder}
        isIcon={!!icon}
        ref={inputRef}
        style={inputStyles}
      />
      {props.type === 'password' && (
        <S.InputControl onClick={handleSwitchType}>
          {type === 'text' ? t('common.hide') : t('common.show')}
        </S.InputControl>
      )}
      {withSubmitButton &&
        (isLoading ? (
          <S.LoaderContainer>
            <Loader />
          </S.LoaderContainer>
        ) : (
          <S.GoControl onClick={handleSubmit} isConfirmEnabled={isConfirmEnabled}>
            <span>GO</span>
          </S.GoControl>
        ))}
    </S.InputContainer>
  );
});

const S = {
  Input: styled(BaseInput)<{
    isIcon: boolean;
    inputWidth: number;
    inputMode: string;
  }>`
    
    height: 44px;
    line-height: 44px;
    margin-bottom: 12px;
    border-radius: 25px;
    padding: 21px 25px 20px;
    text-align: center;
    ${({ theme, isIcon, inputWidth }) => css`
      width: ${inputWidth}px;
      background-color: ${theme.colors.white};
      border: 1px solid ${theme.colors.lightGray5};
      ${isIcon ? (getIsRtl() ? 'padding-right: 60px' : 'padding-left: 60px') : ''}
    `};
    }
  `,
  InputContainer: styled.div`
    position: relative;
  `,
  AsteriskIcon: styled(AsteriskSvg)`
    position: absolute;
    ${() => (getIsRtl() ? 'right: 44px;' : 'left: 44px;')};
    top: 26px;
    transform: translate(0, -100%);
    width: 8px;
    height: 8px;
  `,
  InputControl: styled.div`
    position: absolute;
    padding-top: 1px;
    padding-left: 10px;
    padding-right: 10px;
    height: 34px;
    line-height: 34px;
    cursor: pointer;
    text-transform: uppercase;
    top: 5px;
    text-align: center;
    border-radius: 20px;
    font-size: 12px;
    letter-spacing: 0.88px;
    ${({ theme }) => css`
      background-color: ${theme.colors.lightGray3};
      border: 0.5px solid ${theme.colors.lightGray1};
      color: ${theme.colors.lightText};
    `};
    ${() => (getIsRtl() ? 'left: 6px;' : 'right: 6px;')};
  `,
  LoaderContainer: styled.div`
    position: absolute;
    top: 20px;
    right: 6px;
    width: 36px;
    height: 36px;
  `,
  GoControl: styled.div<{ isConfirmEnabled: boolean }>`
    top: 21px;
    right: 6px;
    position: absolute;
    width: 36px;
    line-height: 30px;
    text-transform: uppercase;
    padding-left: 6px;
    padding-top: 4px;
    border-radius: 18px;
    font-size: 15.5px;
    letter-spacing: 0.88px;
    ${({ theme, isConfirmEnabled }) => css`
      background-color: ${isConfirmEnabled ? theme.colors.primaryBlue : theme.colors.lightGray5};
      cursor: ${isConfirmEnabled ? 'pointer' : 'not-allowed'};
      color: ${theme.colors.white};
    `};
  `,
  IconWrapper: styled.div`
    position: absolute;
    ${() => (getIsRtl() ? 'right: 20px;' : 'left: 20px;')};
    top: 29px;
    transform: translate(0, -100%);
    width: 16px;
    height: 16px;
  `,
};

export default InputWithIcon;

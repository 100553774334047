import styled from 'styled-components';

const RoundedInputsContainer = styled.div`
  border-radius: 15px;

  & > div:first-child input {
    border-radius: 15px 15px 0 0;
    margin-bottom: 1px;
  }

  & > div:last-child input {
    border-radius: 0 0 15px 15px;
  }

  & > div:first-child:last-child input {
    border-radius: 15px;
  }
`;

export default RoundedInputsContainer;

import React, { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';

const FormContainer: FC = ({ children }) => {
  const formRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const ref = contentRef.current;

    const observer = new ResizeObserver((entries) => {
      const entry = entries[0];
      formRef.current.style.height = `${entry.contentRect.height}px`;
    });

    observer.observe(ref);
    return () => observer.unobserve(ref);
  }, []);

  return (
    <S.Container>
      <S.Wrapper ref={formRef}>
        <S.Content ref={contentRef}>{children}</S.Content>
      </S.Wrapper>
    </S.Container>
  );
};

const S = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    align-items: center;
    padding: 15px 24px 24px;
  `,
  Wrapper: styled.div`
    position: relative;
    display: flex;
    width: 100%;
    max-width: 398px;
    border-radius: 10px;
    box-shadow: 0 7px 24px 0 rgba(106, 105, 125, 0.2);
    background-color: ${({ theme }) => theme.colors.white};
    transition: 0.3s ease height;
    overflow: hidden;
  `,
  Content: styled.div`
    display: flex;
    flex-direction: column;
    height: auto;
    align-self: flex-start;
    width: 100%;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 10px;
    z-index: 1;
    min-height: 1px;
  `,
};

export default FormContainer;

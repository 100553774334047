import styled from 'styled-components';

const StyledIconWrapper = styled.div<{ disabled: boolean }>`
  background-color: black;
  border-radius: 100%;
  width: 20px;
  height: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme, disabled }) => (disabled ? '#919194' : theme.colors.darkerPrimaryBlue2)};

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export default StyledIconWrapper;

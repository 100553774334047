import { all, takeLatest, select, put, takeLeading } from 'redux-saga/effects';
import { cloneDeep } from 'lodash';

import biteShareUserActionTypes from './biteShareUser.types';
import { biteShareUserSelector } from './biteShareUser.selectors';
import {
  addOpenEndedAnswerSuccess,
  addOpenEndedAnswerError,
  updateBiteShareUserProgressSuccess,
  addMultipleChoicesAnswerSuccess,
  fullyWatchedBiteIntroVideoSuccess,
} from './biteShareUser.actions';

import * as calls from '../api/calls/BiteShareUser.calls';
import { IAction } from '../types';
import { IResponseOpenEndedAnswer } from '../../types/biteShare';
import { addMultipleChoicesAnswerSuccessBite } from '../bite/bite.actions';
import { uploadImage } from '../api/calls/bite.calls';
import { dataURItoBlob } from '../../utils/utils';
import { setShowSomethingWentWrong } from '../appActivity/appActivity.slice';
import { selectedBiteSelector } from '../bite/bite.selectors';
import { logError } from '../tracking/tracking.slice';
import { EShowSomethingWentWrongError } from '../appActivity/appActivity.types';

function* updateBiteShareUserProgress(action) {
  const biteShareUser = yield select(biteShareUserSelector);
  // const biteShareUserId = biteShareUser.bite_share_user_id;
  const biteShareUserId = biteShareUser.id;

  const progress = action.payload;
  try {
    if (biteShareUserId && progress.bite_progress.length && biteShareUser.bite_progress !== progress.bite_progress) {
      const { data } = yield calls.updateBiteShareUserProgress(biteShareUserId, progress);
      yield put(updateBiteShareUserProgressSuccess(data));
    }
  } catch (error) {
    yield put(logError({ event: 'updateBiteShareUserProgress: error', data: { error } }));
  }
}

function* addOpenEndedAnswer(action: IAction<IResponseOpenEndedAnswer>) {
  try {
    let mediaId = '';
    let { image } = action.payload;
    if (image) {
      if (typeof image === 'string') {
        image = dataURItoBlob(image);
      }
      const { data } = yield uploadImage(image);
      mediaId = data.id;
    }
    const newPayload: IResponseOpenEndedAnswer = {
      question: action.payload.question,
      bite_share_user: action.payload.bite_share_user,
    };
    if (mediaId) {
      newPayload.media = mediaId;
    }
    if (action.payload.answer) {
      newPayload.answer = action.payload.answer;
    }
    const { data } = yield calls.addOpenEndedAnswer(newPayload);
    yield put(addOpenEndedAnswerSuccess(data));
  } catch (err) {
    yield put(addOpenEndedAnswerError(err));
  }
}

function* addMultipleChoicesAnswer(action) {
  try {
    const { selectedBite } = yield select(selectedBiteSelector);
    const { data } = yield calls.addOpenEndedAnswer(action.payload.answer);

    const { selectedBite: newSelectedBite } = yield select(selectedBiteSelector);

    if (selectedBite.id !== newSelectedBite?.id) {
      return;
    }

    const formattedSections = cloneDeep(selectedBite.bite_sections);
    const questionSection = formattedSections.find((s) => s.type === 'question');
    const question = questionSection.questions[0];
    const choices = question.choices;

    const updatedChoices = choices.map((choice, idx) => {
      return {
        ...choice,
        ...data.choices[idx],
      };
    });

    questionSection.questions = [
      {
        ...questionSection.questions[0],
        choices: updatedChoices,
      },
    ];

    const userSelectionMap = action.payload.answer.choices_ids.reduce((map, choiceId) => {
      map[choiceId] = true;
      return map;
    }, {});

    const formattedBiteShareChoices = updatedChoices
      .filter((c) => userSelectionMap[c.id])
      .map((choice) => {
        return { choice };
      });

    const payload = {
      answer: action.payload.answer,
      response: data,
      formattedSections,
      formattedBiteShareChoices,
    };
    yield put(addMultipleChoicesAnswerSuccessBite(payload));
    yield put(addMultipleChoicesAnswerSuccess(payload));

    if (typeof action.payload.callback === 'function') {
      action.payload.callback();
    }
  } catch (error) {
    yield put(logError({ event: 'addMultipleChoicesAnswer: error', data: { error } }));
    yield put(setShowSomethingWentWrong({ type: EShowSomethingWentWrongError.DEFAULT }));
  }
}

function* fullyWatchedBiteIntroVideo(action) {
  const biteShareUserId = action.payload;
  try {
    yield calls.fullyWatchedBiteIntroVideo(biteShareUserId);
    yield put(fullyWatchedBiteIntroVideoSuccess());
  } catch (error) {
    yield put(logError({ event: 'fullyWatchedBiteIntroVideo: error', data: { error } }));
  }
}

export default function* biteShareUserSaga() {
  yield all([
    takeLatest(biteShareUserActionTypes.UPDATE_BITE_SHARE_USER_PROGRESS_REQUEST, updateBiteShareUserProgress),
    takeLatest(biteShareUserActionTypes.OPEN_ENDED_QUESTION_REQUEST, addOpenEndedAnswer),
    takeLeading(biteShareUserActionTypes.MULTIPLE_CHOICES_QUATION_REQUEST, addMultipleChoicesAnswer),
    takeLatest(biteShareUserActionTypes.FULLY_WATCHED_BITE_INTRO_VIDEO_REQUEST, fullyWatchedBiteIntroVideo),
  ]);
}

import React, { FC, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

interface IProps {
  errors: string[];
  showMargin: boolean | false;
  cleanErrors?: () => void;
}

const ErrorMessage: FC<IProps> = ({ errors, showMargin = false }) => {
  const containerRef = useRef();

  useEffect(() => {
    const containerEl = containerRef.current as HTMLElement;
    containerEl.style.marginBottom = showMargin ? `-${containerEl.offsetHeight}px` : '0px';
  }, [errors, showMargin]);
  return (
    <StyledErrorMessage ref={containerRef} shouldTranslate={showMargin}>
      {errors.map((error, key) => (
        <div key={key}>{error}</div>
      ))}
    </StyledErrorMessage>
  );
};

const StyledErrorMessage = styled.div<{ shouldTranslate: boolean }>`
  transform: ${({ shouldTranslate }) => (shouldTranslate ? 'translateY(-100%)' : 'translateY(-30%)')};
  font-size: 16px;
  padding-left: 5px;
  margin-bottom: 5px;
  ${({ theme }) => css`
    color: ${theme.colors.warning};
  `}
`;

export default ErrorMessage;

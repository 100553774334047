import * as calls from '../api/calls/onboarding.calls';
import * as authCalls from '../api/calls/auth.calls';
import { logError } from '../tracking/tracking.slice';
import { setAuth } from '../auth/auth.actions';
import store from '../store';

const ONBOARDING_QUERY_PARAM = 'onboarding';
let onboardingTokenPromise = null;

async function applyOnboardingToken() {
  const query = new URLSearchParams(window.location.search);
  const onboardingToken = query.get(ONBOARDING_QUERY_PARAM);

  if (!onboardingToken) {
    return;
  }

  if (onboardingTokenPromise) {
    return onboardingTokenPromise;
  }

  onboardingTokenPromise = (async () => {
    try {
      const onboardResponse = await calls.onboardUser(onboardingToken);
      if (onboardResponse.status > 299) {
        throw new Error(
          `Failed to apply onboarding token. Response status: ${onboardResponse.status}, response data: ${onboardResponse.data}`,
        );
      }

      const userResponse = await authCalls.getLoggedInUser();
      if (userResponse.status > 299) {
        throw new Error(
          `Failed to get logged in user. Response status: ${userResponse.status}, response data: ${userResponse.data}`,
        );
      }

      const user = userResponse.data;
      store.dispatch(setAuth({ user }));
    } catch (error) {
      onboardingTokenPromise = null;
      store.dispatch(
        logError({
          event: 'applyOnboardingToken: failed to apply onboarding token',
          data: { error },
        }),
      );
    }
  })();

  return onboardingTokenPromise;
}

export { applyOnboardingToken };

const Types = {
  GET_ORGANIZATION_REQUEST: 'GET_ORGANIZATION_REQUEST',
  GET_ORGANIZATION_SUCCESS: 'GET_ORGANIZATION_SUCCESS',
  GET_ORGANIZATION_ERROR: 'GET_ORGANIZATION_ERROR',

  GET_ORGANIZATION_FEATURE_FLAGS: 'GET_ORGANIZATION_FEATURE_FLAGS',
  SET_ORGANIZATION_FEATURE_FLAGS: 'SET_ORGANIZATION_FEATURE_FLAGS',

  UPDATE_USER_ORG: 'UPDATE_USER_ORG',
  UPDATE_ORGANIZATION_SUCCESS: 'UPDATE_ORGANIZATION_SUCCESS',
};

export default Types;

export interface IUserOrgExtendedData {
  employeeId?: string;
  israeliId?: string;
  auth1?: number;
  auth2?: number;
  auth3?: number;
  auth4?: number;
  auth5?: number;
  auth6?: number;
  auth7?: number;
  auth8?: number;
  auth9?: number;
}
export interface IUpdateUserOrgAction extends IUserOrgExtendedData {
  processId: string;
  onSuccess?: () => void;
  onError?: () => void;
}

import { useEffect, useRef, useState } from 'react';

const useIsDisplayedIsVisible = (isVisibleProp: boolean, animationTs = 250) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isDisplayed, setIsDisplayed] = useState(false);

  const isVisiblePropRef = useRef(isVisibleProp);
  isVisiblePropRef.current = isVisibleProp;

  const isVisibleRef = useRef(isVisible);
  isVisibleRef.current = isVisible;

  const isDisplayedRef = useRef(isDisplayed);
  isDisplayedRef.current = isDisplayed;

  const isInitialRenderRef = useRef(true);

  useEffect(() => {
    if (isInitialRenderRef.current) {
      return;
    }

    if (isDisplayed) {
      setIsVisible(true);
    }
  }, [isDisplayed]);

  useEffect(() => {
    if (isInitialRenderRef.current) {
      isInitialRenderRef.current = false;
      return;
    }

    if (!isVisibleProp) {
      setIsVisible(false);

      setTimeout(() => {
        if (isVisibleProp || isVisibleRef.current || !isDisplayedRef.current) {
          return;
        }

        setIsDisplayed(false);
      }, animationTs);

      return;
    }

    setIsDisplayed(true);
  }, [animationTs, isVisibleProp]);

  return { isDisplayed, isVisible };
};
export default useIsDisplayedIsVisible;

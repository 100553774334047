import React, { memo, useEffect, useMemo } from 'react';
import SubtitlesTranslationsControls from './SubtitlesTranslationsControls';
import SubtitlesAutoTranslationsControls from './SubtitlesAutoTranslationsControls';
import useAlwaysTranslate from '../../../../hooks/useAlwaysTranslate';
import { useDispatch, useSelector } from 'react-redux';
import { defineSubtitlesLocale } from '../../../../store/bite/bite.actions';
import localStorage from '../../../../utils/localStorage';
import useBrowserLang from '../../../../hooks/useBrowserLang';
import {
  currentBiteLocaleSelector,
  isSubtitlesTranslatedManuallySelector,
  subtitlesDisabledSelector,
} from '../../../../store/bite/bite.selectors';

interface IProps {
  mode?: 'CONTROLS';
  withLocalesTabs?: boolean;
  withAlwaysTranslate?: boolean;
  withDisplayAnimation: boolean;
  currentLocale: string;
  subtitles: { locale: string; enabled: boolean }[];
  isVisible: boolean;
  isLoading: boolean;
  isError: boolean;
  translatingIntoLocale: string | null;
  source: string;
  onSelect: (props: { locale: string; isManually?: boolean; withSetCurrentLocale?: boolean }) => void;
}

const SubtitlesTranslations = ({
  mode,
  withLocalesTabs,
  withAlwaysTranslate,
  withDisplayAnimation,
  currentLocale,
  subtitles,
  isVisible,
  isLoading,
  isError,
  translatingIntoLocale,
  source,
  onSelect,
}: IProps) => {
  const dispatch = useDispatch();

  const subtitlesDisabled = useSelector(subtitlesDisabledSelector);

  const enabledSubtitles = useMemo(() => subtitles?.find((x) => x.enabled), [subtitles]);
  const isSubtitlesTranslatedManually = useSelector(isSubtitlesTranslatedManuallySelector);
  const currentTranslate = useSelector(currentBiteLocaleSelector);

  const { initialIsAlwaysTranslate, initialLocale, isInitialTranslationDone } = useAlwaysTranslate();
  const browserLang = useBrowserLang();

  const closedTranslationControls = useMemo(() => {
    const closedTranslationControlsTsRaw = localStorage.getItem('closedTranslationControlsTs');

    if (!closedTranslationControlsTsRaw) {
      return false;
    }

    const closedTranslationControlsTs = parseInt(closedTranslationControlsTsRaw, 10);

    if (isNaN(closedTranslationControlsTs)) {
      return false;
    }

    return closedTranslationControlsTs + 7 * 24 * 60 * 60 * 1000 > Date.now();
  }, []);

  useEffect(() => {
    if (enabledSubtitles?.locale !== null || initialIsAlwaysTranslate) {
      return;
    }

    dispatch(defineSubtitlesLocale());
  }, [dispatch, enabledSubtitles?.locale, initialIsAlwaysTranslate]);

  if (
    !enabledSubtitles ||
    subtitlesDisabled ||
    (!initialIsAlwaysTranslate &&
      !enabledSubtitles.locale &&
      !(!mode && !isSubtitlesTranslatedManually && currentTranslate)) ||
    (!mode &&
      !currentTranslate &&
      ((initialIsAlwaysTranslate && enabledSubtitles.locale === currentLocale && isInitialTranslationDone) || // translate intro -> q -> null -> intro (to displaty one-liner)
        (initialIsAlwaysTranslate && enabledSubtitles.locale === initialLocale) ||
        (!initialIsAlwaysTranslate && enabledSubtitles.locale === browserLang) ||
        closedTranslationControls))
  ) {
    return null;
  }

  if (!mode && (initialIsAlwaysTranslate || (!isSubtitlesTranslatedManually && currentTranslate))) {
    return (
      <SubtitlesAutoTranslationsControls
        withDisplayAnimation={withDisplayAnimation}
        currentLocale={currentLocale}
        enabledLocale={enabledSubtitles.locale}
        isVisible={isVisible}
        isLoading={isLoading}
        isError={isError}
        translatingIntoLocale={translatingIntoLocale}
        source={source}
        onSelect={onSelect}
      />
    );
  }

  return (
    <SubtitlesTranslationsControls
      withLocalesTabs={withLocalesTabs}
      withAlwaysTranslate={withAlwaysTranslate}
      withDisplayAnimation={withDisplayAnimation}
      withHighlight={mode !== 'CONTROLS'}
      currentLocale={currentLocale}
      enabledLocale={enabledSubtitles.locale}
      isVisible={isVisible}
      isLoading={isLoading}
      isError={isError}
      translatingIntoLocale={translatingIntoLocale}
      source={source}
      onSelect={onSelect}
    />
  );
};
export default memo(SubtitlesTranslations);

import React, { FC, useState } from 'react';
import styled from 'styled-components';

interface IProps {
  subjects: string[];
}

const VISIBLE_SUBJECT_AMOUNT = 2;

const BiteSubjectsList: FC<IProps> = ({ subjects }) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded((prev) => !prev);
  const filteredSubject = expanded ? subjects : subjects.slice(0, VISIBLE_SUBJECT_AMOUNT);
  const expandeBtnText = expanded ? 'Collapse' : `+ ${subjects.length - VISIBLE_SUBJECT_AMOUNT} more`;

  return (
    <S.Container>
      {filteredSubject.map((subject) => (
        <S.SubjectItem key={subject}>&#8226; {subject}</S.SubjectItem>
      ))}
      {<S.MoreText onClick={toggleExpanded}>{expandeBtnText}</S.MoreText>}
    </S.Container>
  );
};

const S = {
  Container: styled.ul`
    padding: 5px 0;
  `,
  SubjectItem: styled.li`
    color: ${({ theme }) => theme.msColors.lightTextColor};
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 3px;
  `,
  MoreText: styled.p`
    color: ${({ theme }) => theme.msColors.purple};
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.msColors.hoverColor};
    }
  `,
};

export default BiteSubjectsList;

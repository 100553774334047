import { useCallback } from 'react';
import { useGoogleLogin as useReactGoogleLogin } from '@react-oauth/google';
import type { TokenResponse } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import { log, logError } from '../../store/tracking/tracking.slice';
import { resetAuthErrors, setIsSocialLoading, socialLoginEnd, socialLoginRequest } from '../../store/auth/auth.actions';
import { APP } from '../../constants/app';

const useGoogleLogin = () => {
  const dispatch = useDispatch();

  const onLoginSuccess = useCallback(
    (loginResponse: TokenResponse) => {
      const token = loginResponse.access_token;

      dispatch(
        log({
          event: 'useGoogleLogin: success',
        }),
      );

      dispatch(setIsSocialLoading(true));
      dispatch(
        socialLoginRequest({
          token,
          backend: 'google-oauth2',
          app: APP,
        }),
      );
    },
    [dispatch],
  );

  const onError = useCallback(
    (error) => {
      dispatch(
        logError({
          event: 'useGoogleAuth: error',
          data: { error },
        }),
      );
      dispatch(socialLoginEnd({ error: true }));
    },
    [dispatch],
  );

  const signIn = useReactGoogleLogin({
    onSuccess: onLoginSuccess,
    onError,
    onNonOAuthError: onError,
  });

  const login = useCallback(() => {
    dispatch(
      log({
        event: 'useGoogleLogin',
      }),
    );
    dispatch(resetAuthErrors());
    signIn();
  }, [dispatch, signIn]);

  return login;
};

export default useGoogleLogin;

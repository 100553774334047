import React, { FC, memo, useCallback, useMemo } from 'react';
import AuthError from './AuthError';
import styled from 'styled-components';
import Header from './Header';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import EmailForm from './EmailForm';
import { TERMS_CONDITIONS_URL } from '../../../utils/auth';
import { EAuthMethods } from '../../../store/authForm/authForm.types';
import SocialButtons from './SocialButtons';
import { log } from '../../../store/tracking/tracking.slice';
import { allowedAuthMethodsSelector } from '../../../store/organization/organization.selectors';
import PhoneNumberAuth from './PhoneNumber/PhoneNumberAuth';
import useAuthError from '../hooks/useAuthError';
import useAuthTitles from '../hooks/useAuthTitles';
import SevenShiftsEmployeeIdForm from './SevenShiftsEmploeeIdForm';
import { currentFlowSelector } from '../../../store/authForm/authForm.selectors';
import { EAuthFlowType } from '../auth.types';

const AuthForm: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const allowedAuthMethods = useSelector(allowedAuthMethodsSelector);
  const currentFlow = useSelector(currentFlowSelector);

  const formError = useAuthError();
  const { title, subtitle, texts } = useAuthTitles();

  const signUpAllowedAuthMethods = useMemo(
    () =>
      allowedAuthMethods.filter(
        (method) => method.signUpEnabled !== false && method.type !== EAuthMethods.SEVEN_SHIFTS_EMPLOYEE_ID,
      ),
    [allowedAuthMethods],
  );

  const allowedMethods = currentFlow === EAuthFlowType.SIGN_UP ? signUpAllowedAuthMethods : allowedAuthMethods;

  const credentialsMethodsMap = useMemo(
    () => ({
      [EAuthMethods.EMAIL]: !!allowedMethods.find((method) => method.type === EAuthMethods.EMAIL),
      [EAuthMethods.PHONE]: !!allowedMethods.find((method) => method.type === EAuthMethods.PHONE),
      [EAuthMethods.SEVEN_SHIFTS_EMPLOYEE_ID]: !!allowedMethods.find(
        (method) => method.type === EAuthMethods.SEVEN_SHIFTS_EMPLOYEE_ID,
      ),
    }),
    [allowedMethods],
  );

  const hasCredentialsMethod = useMemo(
    () => Object.values(credentialsMethodsMap).some((value) => value),
    [credentialsMethodsMap],
  );

  const activeMethod = useMemo(() => {
    const defaultMethod = allowedMethods.find((method) => method?.isDefault);
    if (
      defaultMethod?.type === EAuthMethods.SEVEN_SHIFTS_EMPLOYEE_ID ||
      (credentialsMethodsMap[EAuthMethods.SEVEN_SHIFTS_EMPLOYEE_ID] &&
        !credentialsMethodsMap[EAuthMethods.PHONE] &&
        !credentialsMethodsMap[EAuthMethods.EMAIL])
    ) {
      return EAuthMethods.SEVEN_SHIFTS_EMPLOYEE_ID;
    }

    if (
      defaultMethod?.type === EAuthMethods.EMAIL ||
      (credentialsMethodsMap[EAuthMethods.EMAIL] && !credentialsMethodsMap[EAuthMethods.PHONE])
    ) {
      return EAuthMethods.EMAIL;
    }

    if (defaultMethod?.type === EAuthMethods.PHONE || credentialsMethodsMap[EAuthMethods.PHONE]) {
      return EAuthMethods.PHONE;
    }

    return null;
  }, [allowedMethods, credentialsMethodsMap]);

  const renderActiveMethod = useCallback(() => {
    switch (activeMethod) {
      case EAuthMethods.SEVEN_SHIFTS_EMPLOYEE_ID:
        return <SevenShiftsEmployeeIdForm isAuthForm />;
      case EAuthMethods.EMAIL:
        return <EmailForm isAuthForm />;
      case EAuthMethods.PHONE:
        return <PhoneNumberAuth isAuthForm />;
      default:
        return null;
    }
  }, [activeMethod]);

  const handleTermsConditionsPress = useCallback(() => {
    dispatch(
      log({
        event: 'AuthForm.handleTermsConditionsPress',
      }),
    );
  }, [dispatch]);

  return (
    <S.Container>
      <Header title={title} description={subtitle} texts={texts} />
      <AuthError isVisible={!!formError}>{formError}</AuthError>
      <S.Form hasCredentialsMethod={hasCredentialsMethod}>
        {hasCredentialsMethod ? renderActiveMethod() : null}
        {allowedMethods.length > 1 && !!activeMethod && (
          <S.Separator>
            <S.SeparatorLine />
            <S.SeparatorText>{t('authForm.or')}</S.SeparatorText>
            <S.SeparatorLine />
          </S.Separator>
        )}
        <SocialButtons activeMethod={activeMethod} />
        <S.TermsAndConditions>
          {t('authForm.agreeToOur')}
          <S.TermsAndConditionsLink href={TERMS_CONDITIONS_URL} target={'blank'} onClick={handleTermsConditionsPress}>
            {t('authForm.terms')}
          </S.TermsAndConditionsLink>
        </S.TermsAndConditions>
      </S.Form>
    </S.Container>
  );
};

const S = {
  Container: styled.div`
    @keyframes slideInFromLeft {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
    animation: 0.3s ease-out 0s 1 slideInFromLeft;
  `,
  Form: styled.div<{ hasCredentialsMethod }>`
    margin-top: ${({ hasCredentialsMethod }) => (hasCredentialsMethod ? 32 : 24)}px;
    align-items: center;
  `,
  Header: styled.div`
    width: 100%;
    align-items: center;
    justify-content: center;
  `,
  HeaderTitle: styled.span`
    color: ${({ theme }) => theme.colors.text};
    font-size: 20px;
    line-height: 28px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-weight: 400;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  `,
  HeaderDescription: styled.span`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.gray19};
    font-size: 14px;
    line-height: 16px;
    margin-top: 4px;
    display: flex;
    flex-direction: column;
  `,
  ForgotPassword: styled.div`
    cursor: pointer;
  `,
  ForgotPasswordText: styled.span`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.gray19};
    font-size: 14px;
    line-height: 16px;
    margin-top: 24px;
    text-align: center;
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.gray19};
  `,
  Separator: styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 24px 0;
  `,
  SeparatorLine: styled.div`
    flex: 1;
    height: 1px;
    background: ${({ theme }) => theme.colors.lightGray19};
  `,
  SeparatorText: styled.span`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.text};
    font-size: 14px;
    line-height: 16px;
    margin: 0 10px;
  `,
  TermsAndConditions: styled.div`
    display: flex;
    flex-direction: column;
    max-width: 295px;
    margin-top: 24px;
    margin-bottom: 30px;
    align-items: center;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.gray19};
    font-size: 14px;
    line-height: 18px;
    text-align: center;
  `,
  TermsAndConditionsLink: styled.a`
    cursor: pointer;
    margin-left: 4px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.gray19};
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.gray19};
  `,
};

export default memo(AuthForm);

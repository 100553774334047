import axios from 'axios';
import BitesApi, { API_BASE_URL } from '..';
import { IUpdateUserOrgRequest } from '../../../types/auth';

export const getOrganizationById = (organizationID: number) => BitesApi.get(`/organizations/${organizationID}/`);

export const updateOrganizationById = (organizationId: number, data: IUpdateUserOrgRequest) =>
  BitesApi.patch(`/user_organization/${organizationId}/`, data);

export const updateUserById = (data: any) => BitesApi.patch('/accounts/user/', data);

export const getOrganizationFeatureFlags = (orgId: number) =>
  axios.get<{
    featureFlags: Record<string, boolean>;
  }>(`${API_BASE_URL}/api/common_services/organization/${orgId}/feature_flags`);

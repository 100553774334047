import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as BitesLogoIcon } from '../../../assets/icons/bites-logo.svg';
import { organizationBrandingSelector } from '../../../store/organization/organization.selectors';
import styled from 'styled-components';
import { AWS_URL } from '../../../constants/app';

const OrgLogo: FC = () => {
  const organizationBranding = useSelector(organizationBrandingSelector);

  if (organizationBranding?.logo_url) {
    return (
      <S.LogoContainer>
        <S.Logo src={`${AWS_URL}/${organizationBranding.logo_url}`} alt='logo' />
      </S.LogoContainer>
    );
  }

  return (
    <S.LogoContainer>
      <BitesLogoIcon />
    </S.LogoContainer>
  );
};

const S = {
  LogoContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 91px;
    height: 49px;
  `,
  Logo: styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
  `,
};

export default memo(OrgLogo);

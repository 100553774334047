import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import last from 'lodash/last';

import { biteSectionTypesSelector } from '../../../../store/bite/bite.selectors';

const useIsEndOfBite = () => {
  const sectionsTypes = useSelector(biteSectionTypesSelector);
  const location = useLocation();
  const currentSectionType = last(location.pathname.split('/'));
  return currentSectionType === last(sectionsTypes);
};

export default useIsEndOfBite;

/* eslint-disable react-hooks/exhaustive-deps */
import { all, takeLatest, put, call, select } from 'redux-saga/effects';

import BiteActionTypes, { IUpdateUserOrgAction } from './profile.types';
import * as actions from './profile.actions';

import * as calls from '../api/calls/profile.calls';
import { setLanguageByNumber } from '../../locale/i18n';
import { updateOrganizationSuccess } from './profile.actions';
import handleHttpErrors from '../../utils/errors/handleHttpErrors';
import { updateOrganizationById } from '../api/calls/profile.calls';
import { log, logError } from '../tracking/tracking.slice';
import { IUpdateUserOrgRequest } from '../../types/auth';
import { orgSelector } from '../organization/organization.selectors';
import { authSelector } from '../auth/auth.selectors';

import { redirectAfterAuth } from '../auth/auth.saga';
import { IAction } from '../types';
import { setRedirectUri } from '../../utils/redirection';
import { EShowSomethingWentWrongError } from '../appActivity/appActivity.types';
import { featureFlagsByOrgIdSelector, organizationSelector } from './profile.selectors';

function* getOrganizationById(action) {
  const id = action.payload;
  try {
    const organization = yield select(organizationSelector);
    if (organization?.id && parseInt(organization.id, 10) === parseInt(id, 10)) {
      yield put(actions.getOrganizationSuccess(organization));
      return;
    }

    const { data } = yield calls.getOrganizationById(id);
    setLanguageByNumber(data.language);
    yield put(actions.getOrganizationSuccess(data));
  } catch (err) {
    yield put(actions.getOrganizationError());
    setRedirectUri({ pathname: '/auth/signin', redirectUri: window.location.pathname + window.location.search });
  }
}

function* updateUserOrgSaga({ payload }: IAction<IUpdateUserOrgAction>) {
  const { processId, onSuccess, onError, employeeId, israeliId, ...datas } = payload;

  const org = yield select(orgSelector);
  const user = yield select(authSelector);

  const userOrg = user.organizations.find(({ id }) => id === org.id);

  const requestPayload: IUpdateUserOrgRequest = {
    ...datas,
  };
  if (employeeId) {
    requestPayload.employee_id = employeeId;
  }
  if (israeliId) {
    requestPayload.israeli_id = israeliId;
  }

  try {
    yield put(
      log({
        event: 'updateUserOrgSaga: start',
        processId,
        data: {
          orgId: userOrg.user_organization_id,
          requestPayload,
        },
      }),
    );

    const { data: newUserOrg } = yield updateOrganizationById(userOrg.user_organization_id, requestPayload);

    yield put(
      log({
        event: 'updateUserOrgSaga: success',
        processId,
      }),
    );

    yield put(updateOrganizationSuccess(newUserOrg));

    if (typeof onSuccess === 'function') {
      onSuccess();
    }

    yield redirectAfterAuth();
  } catch (error) {
    yield call(handleHttpErrors, {
      event: 'updateUserOrgSaga: error',
      processId,
      error,
      data: {
        orgId: userOrg.user_organization_id,
        requestPayload,
      },
      showSomethingWentWrongError: EShowSomethingWentWrongError.SETTING_PROFILE,
    });

    if (typeof onError === 'function') {
      yield onError();
    }
  }
}

function* getOrganizationFeatureFlags(action) {
  const orgId = action.payload;
  try {
    const featureFlagsByOrgId = yield select(featureFlagsByOrgIdSelector);
    if (featureFlagsByOrgId[orgId]) {
      return;
    }

    const {
      data: { featureFlags },
    } = yield calls.getOrganizationFeatureFlags(orgId);
    yield put(
      actions.setOrganizationFeatureFlags({
        orgId,
        featureFlags,
      }),
    );
  } catch (error) {
    yield put(
      logError({
        event: 'getOrganizationFeatureFlags',
        data: {
          errorMessage: error?.message,
          errorStack: error?.stack,
          orgId,
        },
      }),
    );
  }
}

export default function* profileSagaWatcher() {
  yield all([
    takeLatest(BiteActionTypes.GET_ORGANIZATION_REQUEST, getOrganizationById),
    takeLatest(BiteActionTypes.GET_ORGANIZATION_REQUEST, getOrganizationFeatureFlags),
    takeLatest(BiteActionTypes.GET_ORGANIZATION_FEATURE_FLAGS, getOrganizationFeatureFlags),
    takeLatest(BiteActionTypes.UPDATE_USER_ORG, updateUserOrgSaga),
  ]);
}

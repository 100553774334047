import styled from 'styled-components';

import AsyncImage from './AsyncImage';

const Container = styled.div<{ isQuiz: boolean }>`
  width: 120px;
  height: 120px;
  border-radius: 15px;
  overflow: hidden;
  position: relative;

  display: ${({ isQuiz }) => (isQuiz ? 'block' : 'grid')};
  grid-template-columns: repeat(2, minmax(48%, 1fr));
  grid-template-rows: repeat(2, minmax(48%, 1fr));
  grid-gap: 5px;
`;

const ImageTile = styled(AsyncImage)`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
`;

export default {
  Container,
  ImageTile,
};

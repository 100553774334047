export const getBiteFirstSection = (bite: any) => {
  const biteSections = bite.bite_sections;
  const enahncedIntroMedia = getEnhancedMedia(bite);

  const firstSection = biteSections.find((section) => {
    switch (section.type) {
      case 'intro':
        return !!(section.media || enahncedIntroMedia);
      case 'question':
        return !!section.questions.length;
      case 'summary':
        return !!section.summary_cards.length;
    }
    return false;
  });

  return firstSection?.type;
};

export const sectionsOrder = {
  intro: 0,
  question: 1,
  summary: 2,
  discussion: 3,
  content: 3,
  done: 4,
};

export const getEnhancedMedia = (bite: any) => {
  const DONE_STATUS = 'done';
  const media = bite?.enhancements?.find((x) => x.enabled && x.upload_status === DONE_STATUS);
  if (media) {
    return media;
  }
  return bite?.enhancements?.find((x) => x.upload_status === DONE_STATUS);
};
export const getIntroMedia = (bite: any) => {
  const section = bite.bite_sections.find(({ type }) => type === 'intro');
  return section ? section.media || getEnhancedMedia(bite) : null;
};

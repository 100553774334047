import React from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import ErrorFallback from './ErrorFallback';

interface Props {
  children: any;
}

function ErrorBoundary(props: Props) {
  const { children } = props;

  const handleError = (error: Error, info: { componentStack: string }) => {
    console.warn(error);
    console.warn(info.componentStack);
  };

  return (
    <ReactErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
      {children}
    </ReactErrorBoundary>
  );
}

export default ErrorBoundary;

import React from 'react';

import { Button } from '../shared';

function ErrorFallback() {
  const handleReload = () => window.location.reload();

  return (
    <div>
      <div>Something went wrong, we are sorry about that!</div>
      <Button onClick={handleReload}>Click here to reload the app</Button>
    </div>
  );
}

export default ErrorFallback;

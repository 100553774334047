import React, { FC } from 'react';
import Modal from 'react-modal';
import styled, { css, useTheme } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { authCommonSelector } from '../../../store/auth/auth.selectors';
import { Button } from '../../../components/shared';
import { ReactComponent as ErrorIcon } from '../../../assets/icons/error-403.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-modal.svg';
import useCustomTranslation from '../../../hooks/useCustomTranslation';
import {
  setIsRestrictedContentModalShown,
  setIsRestrictedContentForAnotherOrgModalShown,
  logout,
} from '../../../store/auth/auth.actions';
import { setRedirectUri } from '../../../utils/redirection';

const ErrorModal: FC = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const { prefixedT } = useCustomTranslation('screens.auth.SecuredContent');

  const { isRestrictedContentModalShown, isRestrictedContentForAnotherOrgModalShown } = useSelector(authCommonSelector);

  const close = () => {
    setRedirectUri({ redirectUri: null, replace: true });
    dispatch(setIsRestrictedContentModalShown(false));
    dispatch(setIsRestrictedContentForAnotherOrgModalShown(false));
  };

  const handleLogout = () => {
    dispatch(logout({ saveRedirectUri: true }));
    dispatch(setIsRestrictedContentModalShown(false));
    dispatch(setIsRestrictedContentForAnotherOrgModalShown(false));
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isRestrictedContentModalShown || isRestrictedContentForAnotherOrgModalShown}
      onRequestClose={close}
      style={{
        overlay: {
          zIndex: 1000,
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: theme.colors.lightGray14,
          padding: 0,
          paddingTop: '30px',
          paddingBottom: '20px',
          borderRadius: 20,
          border: 'none',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: '90%',
          width: '380px',
          boxShadow: '0 12px 19px 0 rgba(60, 128, 209, 0.09)',
        },
      }}
    >
      <S.CloseModalIcon onClick={close} />
      <div>
        <ErrorIcon width={100} height={100} />
      </div>

      <S.TextContainer>
        <S.HelperTextTitle>{prefixedT('accessRestricted')}</S.HelperTextTitle>
        <S.HelperText>{prefixedT('securedContent')}</S.HelperText>
      </S.TextContainer>

      <S.Divider />
      <S.ButtonWrapper>
        <S.LogoutText onClick={handleLogout}>{prefixedT('switchAccounts')}</S.LogoutText>
        <Button onClick={close} inline>
          {t('common.ok')}
        </Button>
      </S.ButtonWrapper>
    </Modal>
  );
};

const S = {
  TextContainer: styled.div`
    margin-bottom: 20px;
  `,
  ButtonWrapper: styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-self: stretch;
    padding: 0px 10px;
  `,
  HelperTextTitle: styled.span`
    margin-top: 10px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    ${({ theme }) => css`
      color: ${theme.colors.darkPrimary};
      font-family: ${theme.fontFamilies.GilroyBold};
      display: flex;
      max-width: 350px;
      justify-content: center;
      align-items: center;
      font-weight: bold;
    `}
  `,
  HelperText: styled.span`
    text-align: center;
    font-size: 20px;
    ${({ theme }) => css`
      color: ${theme.colors.darkPrimary};
      font-family: ${theme.fontFamilies.GilroyMedium};
      display: flex;
      max-width: 350px;
      justify-content: center;
      align-items: center;
    `}
  `,

  LogoutText: styled.span`
    font-size: 20px;
    cursor: pointer;
    ${({ theme }) => css`
      color: ${theme.colors.primaryBlue};
      font-family: ${theme.fontFamilies.GilroyBold};
    `}
  `,

  CloseModalIcon: styled(CloseIcon)`
    width: 32px;
    height: 32px;
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 10px;
  `,
  Divider: styled.div(
    ({ theme }) => css`
      margin-bottom: 10px;
      width: 100%;
      height: 1px;
      background-color: ${theme.colors.lightGray12};
      @media ${theme.breakPoints.mobileL} {
        display: none;
      }
    `,
  ),
};

export default ErrorModal;

import React, { FC, useCallback, useState } from 'react';
import styled, { useTheme, css } from 'styled-components';

import { useHistory } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../../assets/icons/back.svg';
import { getIsRtl } from '../../../locale/i18n';

interface IProps {
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
}

const BackButton: FC<IProps> = ({ onClick, disabled, className }) => {
  const [isPressed, setIsPressed] = useState(false);
  const theme = useTheme();
  const history = useHistory();
  const isRtl = getIsRtl();

  const goBack = useCallback(() => {
    if (disabled) {
      return;
    }
    if (typeof onClick === 'function') {
      onClick();
      return;
    }

    history.goBack();
  }, [disabled, history, onClick]);

  const handleMouseDown = useCallback(() => {
    setIsPressed(true);
  }, []);

  const handleMouseUp = useCallback(() => {
    setIsPressed(false);
  }, []);

  return (
    <S.BackButton
      className={className}
      isPressed={isPressed}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onClick={goBack}
      disabled={disabled}
      isRtl={isRtl}
    >
      <BackIcon width={24} height={24} fill={theme.colors.primaryBlue} />
    </S.BackButton>
  );
};

const S = {
  BackButton: styled.div<{
    disabled?: boolean;
    isPressed?: boolean;
    isRtl: boolean;
  }>`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 50%;
    height: 40px;
    width: 40px;
    opacity: ${({ isPressed }) => (isPressed ? 0.3 : 1)};
    box-shadow: 0 7px 24px 0 rgba(106, 105, 125, 0.3);
    ${({ theme, disabled }) =>
      disabled &&
      css`
        background-color: ${theme.colors.darkGray3};
      `}
    transform: ${({ isRtl }) => (isRtl ? 'rotate(180deg)' : 'none')};
  `,
};

export default BackButton;

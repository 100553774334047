import styled, { css } from 'styled-components';

const ScreenHeader = styled.header`
  margin-top: 30px;
  margin-bottom: 22px;
  font-size: 16px;
  text-align: center;
  ${({ theme }) => css`
    color: ${theme.colors.darkPrimary};
  `}
`;

export default ScreenHeader;

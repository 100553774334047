import smartlookClient from 'smartlook-client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'react-image-lightbox/style.css';
import 'react-phone-number-input/style.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';

import './services/msalInstance';
import './services/googleTagManager';
import './locale/i18n';
import './style/fonts.css';
import { isProd } from './utils/env';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  normalizeDepth: 10,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 0.05,
});

const searchParams = new URLSearchParams(window.location.search);
if (!searchParams.has('test') && isProd()) {
  smartlookClient.init('5a4560dd1250f8e3822c2a0aced3d7d5037b181b');
}

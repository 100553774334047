import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  resetGeofence,
  setGeofenceContentInfo,
  setGeofenceData,
  setGeofenceModalOpened,
  setIsContentNotAvailable,
  startPolling,
} from '../../store/geofence/geofence.slice';
import InitialGeofenceModal from './modals/InitialGeofenceModal';
import useDelayedLogic from '../../hooks/useDelayedCall';
import BottomSheet from '../shared/BottomSheet';
import styled, { useTheme } from 'styled-components';
import WifiLottie from '../shared/Lottie/WifiLottie';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { log } from '../../store/tracking/tracking.slice';

const Geofence = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [delayedCall] = useDelayedLogic(2000);

  const watcherRef = useRef(null);

  const handleGetPositionSuccess = useCallback(
    (position: any) => {
      if (!position?.coords) {
        return;
      }

      dispatch(
        setGeofenceData({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          accuracy: position.coords.accuracy,
        }),
      );
    },
    [dispatch],
  );

  const handleGeolocationError = useCallback(() => {
    dispatch(
      log({
        event: 'geofence.handleGeolocationError: geolocation request error',
      }),
    );
    dispatch(setIsContentNotAvailable(true));
  }, [dispatch]);

  useEffect(() => {
    dispatch(setGeofenceModalOpened(true));
    return () => {
      if (watcherRef.current) {
        navigator.geolocation.clearWatch(watcherRef.current);
      }
      dispatch(resetGeofence());
      dispatch(setGeofenceModalOpened(false));
    };
  }, [dispatch]);

  const handleApplyGeofence = useCallback(() => {
    dispatch(startPolling());
    if (!navigator.geolocation) {
      dispatch(
        log({
          event: 'geofence.handleApplyGeofence: geolocation not supported',
        }),
      );
      dispatch(setIsContentNotAvailable(true));
      return;
    }

    navigator.geolocation.getCurrentPosition(delayedCall(handleGetPositionSuccess), handleGeolocationError);
    watcherRef.current = navigator.geolocation.watchPosition(delayedCall(handleGetPositionSuccess));
  }, [delayedCall, dispatch, handleGeolocationError, handleGetPositionSuccess]);

  const handleClose = useCallback(() => {
    dispatch(setGeofenceContentInfo(null));
  }, [dispatch]);

  const renderContent = useCallback(() => {
    return <InitialGeofenceModal onApply={handleApplyGeofence} />;
  }, [handleApplyGeofence]);

  return (
    <BottomSheet backdropColor='rgba(0, 0, 0, 0.5)' backgroundColor={theme.colors.white}>
      <S.Container>
        <S.Top>
          <WifiLottie />
          <S.CloseIcon onClick={handleClose}>
            <CloseIcon color={theme.colors.text} />
          </S.CloseIcon>
        </S.Top>
        <S.Content>{renderContent()}</S.Content>
      </S.Container>
    </BottomSheet>
  );
};

const S = {
  Container: styled.div`
    padding: 17px 22px;
  `,
  Content: styled.div`
    width: 100%;
    height: 100%;
  `,
  Top: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
  `,
  CloseIcon: styled.div`
    cursor: pointer;
    width: 14px;
    height: 14px;
  `,
};

export default Geofence;

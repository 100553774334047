import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import Header from './Header';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { organizationLoadingSelector, organizationSelector } from '../../../store/profile/profile.selectors';
import { useProfileData } from '../hooks/useData';
import Select from '../../../components/shared/Inputs/Select';
import Button from '../../../components/shared/Buttons';
import { useTranslation } from 'react-i18next';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { orgSelector } from '../../../store/organization/organization.selectors';
import { getOrganizationRequest, updateUserOrg } from '../../../store/profile/profile.actions';
import Loader from '../../../components/shared/Loader';
import { v4 as uuid } from 'uuid';
import { log } from '../../../store/tracking/tracking.slice';
import FormTextInput from '../../../components/shared/Inputs/FormTextInput';
import { authSelector } from '../../../store/auth/auth.selectors';
import AuthError from './AuthError';
import { getIsIsraeliIdValid } from '../../../utils/auth';

const ExtendedInfo = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const org = useSelector(orgSelector);
  const organization = useSelector(organizationSelector);
  const user = useSelector(authSelector);
  const isOrganizationLoading = useSelector(organizationLoadingSelector);

  const [authData, setAuthData] = useState({});
  const [employeeId, setEmployeeId] = useState('');
  const [israeliId, setIsraeliId] = useState('');

  const [isProcessing, setIsProcessing] = useState(false);

  const [error, setError] = useState<string>(null);
  const [isRequestError, setIsRequestError] = useState(false);
  const [isIsraeliIdError, setIsIsraeliIdError] = useState(false);

  const selectData = useProfileData(organization);

  const userOrg = useMemo(() => {
    return user?.organizations.find(({ id }) => id === org.id);
  }, [user, org]);

  const isLoading = !org || isOrganizationLoading;

  useEffect(() => {
    if (org) {
      dispatch(getOrganizationRequest(org.id));
    }
  }, [dispatch, org]);

  const isMountedRef = useIsMounted();

  const requestIsraeliId = org?.forceIsraeliId && !userOrg.israeli_id;
  const requestEmployeeId = org?.forceEmployeeId && !userOrg.employee_id;

  const inputsNum = Object.keys(selectData).length + (requestEmployeeId ? 1 : 0) + (requestIsraeliId ? 1 : 0);

  const handleSelect = useCallback(({ label, value, id }) => {
    setIsRequestError(false);

    setAuthData((prevAuthData) => ({
      ...prevAuthData,
      [id]: { value, label },
    }));
  }, []);

  const handleChangeIsraeliId = useCallback((value) => {
    setIsIsraeliIdError(false);
    setIsraeliId(value);
  }, []);

  const submitHandler = useCallback(() => {
    setIsRequestError(false);

    const isIsraeliIdValid = requestIsraeliId ? getIsIsraeliIdValid(israeliId) : true;
    const isIsraeliIdEmpty = requestIsraeliId ? !israeliId.length : false;

    if (!isIsraeliIdValid) {
      setIsIsraeliIdError(true);
    }

    const isEmployeeEmpty = requestEmployeeId ? !employeeId.length : false;

    const userValuesNum = Object.keys(authData).length + (employeeId ? 1 : 0) + (israeliId ? 1 : 0);
    const hasEmptyDatas = userValuesNum < inputsNum;

    if (hasEmptyDatas || !isIsraeliIdValid || isIsraeliIdEmpty || isEmployeeEmpty) {
      setError(t('authForm.extendedInfo.fieldsShouldntBeEmpty'));
      return;
    }

    const data: any = {};
    Object.keys(authData).forEach((key) => (data[key] = authData[key].value));
    if (employeeId) {
      data.employeeId = employeeId;
    }
    if (israeliId) {
      data.israeliId = israeliId;
    }

    setIsProcessing(true);

    const onSuccess = () => {
      if (!isMountedRef.current) {
        return;
      }

      dispatch(
        log({
          event: 'ExtendedInfo.submitHandler: onSuccess',
          processId: processId,
          data,
        }),
      );

      setIsProcessing(false);
    };

    const onError = () => {
      if (!isMountedRef.current) {
        return;
      }

      dispatch(
        log({
          event: 'ExtendedInfo.submitHandler: onError',
          processId: processId,
          data,
        }),
      );

      setIsProcessing(false);
      setIsRequestError(true);
    };

    const processId = uuid();
    dispatch(
      log({
        event: 'ExtendedInfo.submitHandler: updateUserOrg',
        processId: processId,
        data,
      }),
    );

    dispatch(updateUserOrg({ ...data, processId, onSuccess, onError }));
  }, [authData, dispatch, employeeId, inputsNum, isMountedRef, israeliId, requestEmployeeId, requestIsraeliId, t]);

  return (
    <S.Container>
      <Header title={t('authForm.extendedInfo.title')} description={t('authForm.extendedInfo.description')} />
      <AuthError isVisible={isRequestError}>{t('common.somethingWentWrong')}</AuthError>
      {isLoading ? (
        <S.LoaderWrapper>
          <Loader />
        </S.LoaderWrapper>
      ) : (
        <>
          <S.SelectorsContainer>
            {requestEmployeeId && (
              <S.TextInput
                style={S.TextInputContainerStyles}
                value={employeeId}
                onChange={setEmployeeId}
                label={t('screens.SetYourProfile.enterEmployeeId')}
                autoComplete='off'
                error={!employeeId && error}
                onEnterPress={submitHandler}
              />
            )}
            {requestIsraeliId && (
              <S.TextInput
                style={S.TextInputContainerStyles}
                value={israeliId}
                onChange={handleChangeIsraeliId}
                label={t('screens.SetYourProfile.enterIsraeliId')}
                autoComplete='off'
                error={isIsraeliIdError ? t('certificate.invalidIsraeliId') : !israeliId && error}
                onEnterPress={submitHandler}
              />
            )}
            {Object.keys(selectData).map((itemData) => (
              <Selector
                key={itemData}
                onSelect={handleSelect}
                id={itemData}
                selectedData={authData}
                data={selectData}
                error={!authData[itemData] && error}
              />
            ))}
          </S.SelectorsContainer>
          <S.ContinueButton isLoading={isProcessing} onClick={submitHandler}>
            {t('common.Continue')}
          </S.ContinueButton>
        </>
      )}
    </S.Container>
  );
};

const Selector = ({ data, onSelect, selectedData, error, id }) => {
  const formattedData = data[id].data.map((item) => ({ label: item.name, value: item.id }));
  const value = formattedData.find((item) => item.value === selectedData?.[id]?.value);
  const label = data[id].title;

  const handleSelect = useCallback((item) => onSelect({ label: item.label, value: item.value, id }), [id, onSelect]);

  return <S.Select id={id} label={label} value={value} onChange={handleSelect} options={formattedData} error={error} />;
};

const S = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: stretch;
    min-width: 298px;
  `,
  SelectorsContainer: styled.div`
    margin-top: 36px;
  `,
  Select: styled(Select)<{ error: string }>`
    margin-bottom: 18px;
  `,
  ErrorContainer: styled.div`
    color: red;
    font-size: 16px;
    margin: 20px 0;
    width: 100%;
    text-align: center;
    align-self: center;
  `,
  ContinueButton: styled(Button)`
    min-height: 50px;
    margin-bottom: 31px;
  `,
  TextInput: styled(FormTextInput)`
    height: 50px;
  `,
  TextInputContainerStyles: { marginBottom: 18 },
  LoaderWrapper: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100%;
  `,
};

export default memo(ExtendedInfo);

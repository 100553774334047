import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { Route } from 'react-router-dom';
import type { RouteChildrenProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ControllersBar from './components/ControllersBar';
import { ReactComponent as FeedLoader } from '../../assets/loaders/feed-loader.svg';
import InfinitePlaylistList from '../../components/playlist';
import { playlistsSelector } from '../../store/playlist/playlist.selectors';
import { bitesSelector } from '../../store/bite/bite.selectors';
import InfiniteBiteList from '../../components/bite/InfiniteBiteList';
import { HEADER_HEIGHT } from '../../components/layout/StyledHeader';
import gtmTrack from '../../services/googleTagManager/track';
import useRemoveQueryParams from '../../hooks/useRemoveQueryParams';
import useHomeScreenLogic from './useHomeScreenLogic';
import { IPublicFeedConfig } from '../../navigation/Routes';
import { CONTROLLERS_BAR_HEIGHT } from './Home.constants';

interface IProps extends RouteChildrenProps {
  config: IPublicFeedConfig;
}

const Home: FC<IProps> = ({ config: { path, orgId } }) => {
  const { handleBiteClick, handlePlaylistClick } = useHomeScreenLogic({ orgId, isPublicFeed: true });

  useRemoveQueryParams(['timestamp']);

  return (
    <>
      <S.Container>
        <ControllersBar pathPrefix={path} />
        <S.Feed>
          <Route
            path={`${path}/bites`} //
            component={() => <Bites orgId={orgId} onBiteClick={handleBiteClick} />}
          />
          <Route
            path={`${path}/playlists`} //
            component={() => <Playlists orgId={orgId} onPlaylistClick={handlePlaylistClick} />}
          />
        </S.Feed>
      </S.Container>
    </>
  );
};

const Bites = ({
  orgId,
  onBiteClick,
}: {
  orgId: number;
  onBiteClick: (biteShareId: number, subject: string) => void;
}) => {
  const { isLoading: isBitesLoading } = useSelector(bitesSelector);

  useEffect(() => {
    gtmTrack('pageview', {
      page_title: 'Public Feed',
      tab: 'bites',
    });
  }, []);

  return isBitesLoading ? (
    <S.FeedLoader />
  ) : (
    <InfiniteBiteList orgId={orgId} isPublicFeed withoutProgressIndication onBiteClick={onBiteClick} />
  );
};

const Playlists = ({ orgId, onPlaylistClick }: { orgId: number; onPlaylistClick: (playlist: any) => void }) => {
  const { isLoading: isPlaylistLoading } = useSelector(playlistsSelector);

  useEffect(() => {
    gtmTrack('pageview', {
      page_title: 'Public Feed',
      tab: 'playlists',
    });
  }, []);

  return isPlaylistLoading ? (
    <S.FeedLoader />
  ) : (
    <InfinitePlaylistList orgId={orgId} isPublicFeed onPlaylistClick={onPlaylistClick} />
  );
};

const S: any = {};

const LOGO_CONTAINER_HEIGHT = 160;
const FEED_TOP_PADDING = 30;

S.Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 30px;
`;

S.Feed = styled.div`
  padding-top: ${FEED_TOP_PADDING};
  width: 100%;
  height: 100%;
`;

const FEED_LOADER_TOP_OFFSET = HEADER_HEIGHT + LOGO_CONTAINER_HEIGHT + CONTROLLERS_BAR_HEIGHT + FEED_TOP_PADDING;
const FEED_LOADER_HEIGHT = 75;

S.FeedLoader = styled(FeedLoader)`
  margin: ${(window.innerHeight - FEED_LOADER_TOP_OFFSET) / 2 - FEED_LOADER_HEIGHT / 2}px auto 0;
  height: ${FEED_LOADER_HEIGHT}px;
  display: block;
`;

export default Home;

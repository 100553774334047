import { formatDistance, format } from 'date-fns';
import type { Locale } from 'date-fns';
import { he, es, fr, de, pt } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { TLocale } from '../locale/i18n';

const dateFnsLocaleMap: { [key in TLocale]: Locale } = {
  en: undefined,
  he,
  es,
  fr,
  de,
  pt,
};

const useDataFormatter = () => {
  const { i18n } = useTranslation();
  const currentLocale = i18n.language;

  const formatToRelativeDate = (date: string) => {
    return formatDistance(new Date(date), new Date(), {
      addSuffix: true,
      locale: dateFnsLocaleMap[currentLocale] || undefined,
    });
  };

  const formatDate = (date: string) => {
    return format(new Date(date), 'MM/dd/yyyy');
  };

  return { formatToRelativeDate, formatDate };
};

export default useDataFormatter;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import BitesApi from '../store/api';
import { VimeoLink, VimeoLinksResponse } from '../types/media';

const useVimeoLink = (videoLinkRequired: boolean, vimeoVideoId?: string) => {
  const [isFetchingLink, setIsFetchingLink] = useState(false);
  const [isVideoStillDecoding, setVideoStillDecoding] = useState(false);
  const [fetchingLinkError, setFetchingLinkError] = useState('');
  const [vimeoVideoUri, setVimeoVideoUri] = useState<null | string>(null);
  const [size, setSize] = useState<any>(null);

  useEffect(() => {
    const fetchVideoLinks = async () => {
      try {
        setIsFetchingLink(true);
        const isPreview = new URLSearchParams(window.location.search).has('isPreview');
        const res = await BitesApi.get('media/video_links/', {
          params: {
            videoId: vimeoVideoId,
            embed: window !== window.top && !isPreview ? 'true' : undefined,
          },
        });
        const vimeoLinksResponse = res.data as VimeoLinksResponse;
        if (vimeoLinksResponse.error) {
          setFetchingLinkError(vimeoLinksResponse.error);
        } else if (!vimeoLinksResponse.length) {
          setVideoStillDecoding(true);
        } else {
          const small: VimeoLink[] = [];
          const large: VimeoLink[] = [];
          let s3: VimeoLink = null;
          vimeoLinksResponse.forEach((element) => {
            if (element.width === 0) {
              s3 = element;
              return;
            }
            if (element.quality === 'hls') {
              return;
            }
            if (parseInt(element.rendition, 10) < 720) {
              small.push(element);
              return;
            }
            large.push(element);
          });
          small.sort((a, b) => parseInt(a.rendition, 10) - parseInt(b.rendition, 10)); //from smallest to largest
          large.sort((a, b) => parseInt(b.rendition, 10) - parseInt(a.rendition, 10)); //from biggest to smallest

          const highestQualityVideo = small.length > 0 ? small.pop() : large.length > 0 ? large.pop() : s3;
          setVimeoVideoUri(highestQualityVideo.link);
          setSize({
            width: highestQualityVideo.width,
            height: highestQualityVideo.height,
          });
        }

        setIsFetchingLink(false);
      } catch (error) {
        setFetchingLinkError('Error Loading the required video');
      }
    };
    if (videoLinkRequired && vimeoVideoId) {
      fetchVideoLinks();
    }
  }, []);

  return {
    isFetchingLink,
    isVideoStillDecoding,
    fetchingLinkError,
    vimeoVideoUri,
    size,
  };
};

export default useVimeoLink;

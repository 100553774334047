import React, { CSSProperties, FC } from 'react';
import styled from 'styled-components';

import BackDrop from './BackDrop';

interface IProps {
  isModalOpen: boolean;
  closeModal?: () => void;
  wrapperStyles?: CSSProperties;
  containerStyles?: CSSProperties;
}

const CustomModal: FC<IProps> = (props) => {
  return (
    props.isModalOpen && (
      <S.ModalContainer style={props.wrapperStyles}>
        <S.Modal style={props.containerStyles}>{props.children}</S.Modal>
        <BackDrop show={props.isModalOpen} closeModal={props.closeModal} />
      </S.ModalContainer>
    )
  );
};

const ModalContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  flex-direction: column;
  flex: 1 auto;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Modal = styled.div`
  position: ${(props) => props.style?.position || 'absolute'};
  z-index: 500;
  background-color: white;
  width: 70%;
  padding: 10px;
  top: ${(props) => props.style?.top || '30'}%;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  border-radius: 25px;
`;

const CloseBtn = styled.div`
  cursor: pointer;
  text-align: right;
`;

const S = { ModalContainer, Modal, CloseBtn };

export default CustomModal;

import axios from 'axios';

import BitesApi, { API_BASE_URL, defaultHeaders } from '../';
import {
  IGetBitesTokenFromSocialBody,
  IPasswordRecoveryBody,
  INewPasswordCredentials,
  ISigninCredentials,
  ISignupEmailCredentials,
  ISignupPhoneCredentials,
  ILoginWithPhone,
  IFinalizeProfileDataRequest,
} from '../../../types/auth';
import { ECAptchaAction, wrapWithCaptcha } from '../../../utils/captcha';
import { IUserOrganizations } from '../../auth/auth.types';

export const login = wrapWithCaptcha(ECAptchaAction.LOGIN, (credentials: ISigninCredentials, { headers } = {}) =>
  BitesApi.post('/accounts/login_v2/', credentials, { headers }),
);

export const logout = () => BitesApi.post('/accounts/logout/');

export const loginWithPhone = wrapWithCaptcha(
  ECAptchaAction.LOGIN,
  (credentials: ILoginWithPhone['credentials'], { headers } = {}) =>
    BitesApi.post('/accounts/login_v2/', credentials, { headers }),
);

export const signupWithEmail = wrapWithCaptcha(
  ECAptchaAction.SIGNUP,
  (payload: ISignupEmailCredentials, { headers } = {}) =>
    BitesApi.post('/accounts/registration_v2/', payload, { headers }),
);

export const signupWithPhone = wrapWithCaptcha(
  ECAptchaAction.SIGNUP,
  (payload: ISignupPhoneCredentials, { headers } = {}) =>
    BitesApi.post('/accounts/registration_v2/', payload, { headers }),
);

export const verificateOrganizationCode = (payload: any) => BitesApi.post('/organization/validate_code/', payload);

export const getBitesAccessTokenWithSocialToken = wrapWithCaptcha(
  ECAptchaAction.LOGIN,
  (body: IGetBitesTokenFromSocialBody, { headers } = {}) =>
    axios({
      url: `${API_BASE_URL}/teams/get_auth_token/`,
      method: 'POST',
      data: body,
      headers: {
        ...defaultHeaders,
        ...(headers || {}),
      },
      withCredentials: true,
    }),
);

export const getLoggedInUser = (token?: string) => {
  const headers = {};
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  return BitesApi.get('/accounts/user/', {
    headers,
  });
};

export const finalizeUserProfile = (userId: IUserOrganizations['id'], payload: IFinalizeProfileDataRequest) =>
  BitesApi.post(`/accounts/finalize_registration/${userId}/`, payload);

export const checkExistUser = (body) => BitesApi.post(`${API_BASE_URL}/api/accounts/validate_social_user/`, body);

export const passwordRecovery = wrapWithCaptcha(
  ECAptchaAction.PASSWORD_RESET,
  (body: IPasswordRecoveryBody, { headers } = {}) => BitesApi.post('/accounts/password_reset/', body, { headers }),
);

export const setNewRecoverPassword = (body: INewPasswordCredentials) =>
  BitesApi.post('/accounts/password_reset/confirm/', body);

export const addTenantToMicrosoftUser = (body: { tenant_id: string }) =>
  BitesApi({
    url: `${API_BASE_URL}/teams/tenant/`,
    method: 'POST',
    data: body,
  });

export const addUserToOrganization = (code: number) =>
  BitesApi.post('/accounts/attach_organization_v2/', {
    code,
  });

export const validateOtat = (token: string) =>
  BitesApi.post('/auth/validate_otat/', {
    token,
  });

export const validateDpt = (token: string) =>
  BitesApi.post('/auth/validate_dpt/', {
    token,
  });

const requestVideoFullscreen = async (
  videoPlayer: HTMLVideoElement & {
    mozRequestFullScreen?(): Promise<void>;
    webkitRequestFullscreen?(): Promise<void>;
    msRequestFullscreen?(): Promise<void>;
    webkitEnterFullscreen?(): Promise<void>;
  },
) => {
  if (!videoPlayer) {
    return;
  }
  if (videoPlayer.requestFullscreen) {
    await videoPlayer.requestFullscreen();
    return;
  }
  if (videoPlayer.mozRequestFullScreen) {
    videoPlayer.mozRequestFullScreen();
    return;
  }
  if (videoPlayer.webkitRequestFullscreen) {
    videoPlayer.webkitRequestFullscreen();
    return;
  }
  if (videoPlayer.msRequestFullscreen) {
    videoPlayer.msRequestFullscreen();
    return;
  }
  if (videoPlayer.webkitEnterFullscreen) {
    videoPlayer.webkitEnterFullscreen();
    return;
  }
};
export default requestVideoFullscreen;

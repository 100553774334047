import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '..';

const socialShareSlice = createSlice({
  name: 'socialShare',
  initialState: {
    title: '',
  },
  reducers: {
    setTitle: (state, action) => {
      state.title = action.payload;
    },
  },
});

export const { setTitle } = socialShareSlice.actions;

export default socialShareSlice.reducer;

export const socialShareSelector = (state: RootState) => state.socialShare;

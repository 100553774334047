import React from 'react';
import Lottie from 'lottie-react';
import wifiAnimation from '../../../assets/lottie/wifi-lottie.json';

const WifiLottie = () => {
  return (
    <Lottie
      animationData={wifiAnimation}
      loop
      style={{
        width: 24,
        height: 24,
      }}
    />
  );
};

export default WifiLottie;

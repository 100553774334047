import React, { FC, useEffect } from 'react';
import styled, { css } from 'styled-components';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import last from 'lodash/last';

import { selectedBiteSelector } from '../../../store/bite/bite.selectors';
import { selectedPlaylistSelector } from '../../../store/playlist/playlist.selectors';
import { ContinueButton } from '../../../components/shared';
import gtmTrack from '../../../services/googleTagManager/track';
import useQueryParams from '../../../hooks/useQueryParams';

interface IProps {
  html: string;
}

const Content: FC<IProps> = ({ html }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { selectedPlaylist } = useSelector(selectedPlaylistSelector);
  const { selectedBite } = useSelector(selectedBiteSelector);

  const { queryParams } = useQueryParams();
  const playlistId = queryParams?.playlistId;

  const bite_shares = selectedPlaylist?.bite_shares;
  const isInsidePlaylist = window.location.search.includes('playlistId');

  const isLastBiteShareInPlaylist = () => {
    const lastBiteShareId = last(bite_shares).id;
    const currentBiteShareId = selectedBite.id;
    return lastBiteShareId === currentBiteShareId;
  };

  const goToNextBiteShareInPlaylist = () => {
    const idx = bite_shares.map((bs) => bs.id).indexOf(selectedBite.id);
    const nextBiteShareUrl = `/bites/${bite_shares[idx + 1].id}/intro?playlistId=${selectedPlaylist.id}`;
    history.push(nextBiteShareUrl, { withoutModal: true });
  };

  const isShowContinueButton = isInsidePlaylist && !isLastBiteShareInPlaylist();

  useEffect(() => {
    gtmTrack('content_section', {
      bite_id: selectedBite?.bite,
      bite_share_id: selectedBite?.id,
      content_org_id: selectedBite?.orgid,
      sharing_mode: selectedBite?.sharing_mode,
      playlist_id: playlistId,
    });
  }, [playlistId, selectedBite]);

  return (
    <S.Container>
      <S.HtmlWrapper isShowContinueButton={isShowContinueButton}>{parse(html)}</S.HtmlWrapper>
      {isShowContinueButton && (
        <ContinueButton
          text={t('screens.viewBite.continueToNextBite')}
          style={{ height: 40 }}
          onClick={goToNextBiteShareInPlaylist}
        />
      )}
    </S.Container>
  );
};

const S: any = {};
S.Container = styled.div`
  padding: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

S.HtmlWrapper = styled.div<{ isShowContinueButton: boolean }>`
  flex: 1;
  ${({ isShowContinueButton }) => css`
    margin-bottom: ${isShowContinueButton ? '10px' : '0'};
  `}
`;

export default React.memo(Content);

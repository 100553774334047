import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntercom } from 'react-use-intercom';

import { getRedirectUri } from '../utils/redirection';
import { authSelector, isAuthenticatedSelector } from '../store/auth/auth.selectors';
import { orgSelector } from '../store/organization/organization.selectors';
import { socialShareSelector } from '../store/socialShare/socialShare.slice';
import { getFullName } from '../utils/auth';

function Intercom() {
  const { title } = useSelector(socialShareSelector);
  const currentUser = useSelector(authSelector);
  const org = useSelector(orgSelector);
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  const { boot, shutdown, update } = useIntercom();

  useEffect(() => {
    if (!isAuthenticated) {
      if (currentUser) {
        const name = getFullName(currentUser);
        update({
          name: `${name} (Frontliner)`,
        });
        setTimeout(shutdown, 5000); // allow time to process last call to Intercom API
      } else {
        boot();

        setTimeout(() => {
          const redirectURL = getRedirectUri();
          update({
            name: 'Guest (Frontliner)',
            customAttributes: {
              org_name: org.name,
              org_id: org.id,
              title,
              url_trying_to_access: redirectURL,
            },
          });
          // hide intercom if non-auth pages
          if (window?.location?.pathname?.lastIndexOf('auth') < 0) {
            shutdown();
          }
        }, 2500); // allow time to process last call to Intercom API
      }
    } else {
      shutdown();
    }
  }, [currentUser, boot, shutdown, update, org.id, org.name, title, isAuthenticated]);

  return null;
}

export default Intercom;

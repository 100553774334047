import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { currentFlowSelector } from '../../../store/authForm/authForm.selectors';
import { EAuthFlowType, EAuthFormType } from '../auth.types';
import useMicrosoftLogin from '../../../hooks/useSocialLogins/useMicrosoftLogin';
import React, { memo, useCallback, useMemo } from 'react';
import { setCurrentForm } from '../../../store/authForm/authForm.slice';
import { EAuthMethods } from '../../../store/authForm/authForm.types';
import styled from 'styled-components';
import SocialButton from './SocialButton';
import AppleSocialButton from './AppleSocialButton';
import { ReactComponent as PhoneIcon } from '../../../assets/icons/phone.svg';
import { ReactComponent as MailIcon } from '../../../assets/icons/mail.svg';
import { ReactComponent as MicrosoftIcon } from '../../../assets/icons/microsoft.svg';
import { ReactComponent as GoogleIcon } from '../../../assets/icons/google-colorfull.svg';
import { ReactComponent as AppleIcon } from '../../../assets/icons/apple-icon.svg';
import SevenShiftsIcon from '../../../assets/icons/7shifts.png';
import { log } from '../../../store/tracking/tracking.slice';
import { allowedAuthMethodsSelector } from '../../../store/organization/organization.selectors';
import useGoogleLogin from '../../../hooks/useSocialLogins/useGoogleLogin';
import { emailButtonDataCy } from './socialButtons.constants';

interface IProps {
  activeMethod?: EAuthMethods;
}

const SocialButtons: React.FC<IProps> = ({ activeMethod }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const allowedAuthMethods = useSelector(allowedAuthMethodsSelector);
  const currentFlow = useSelector(currentFlowSelector);
  const currentForm = currentFlow === EAuthFlowType.SIGN_IN ? 'signInForm' : 'signUpForm';

  const loginWithMicrosoft = useMicrosoftLogin();
  const loginWithGoogle = useGoogleLogin();

  const signUpAllowedAuthMethods = useMemo(
    () =>
      allowedAuthMethods.filter(
        (method) => method.signUpEnabled !== false && method.type !== EAuthMethods.SEVEN_SHIFTS_EMPLOYEE_ID,
      ),
    [allowedAuthMethods],
  );

  const allowedMethods = useMemo(() => {
    const methods = currentFlow === EAuthFlowType.SIGN_UP ? signUpAllowedAuthMethods : allowedAuthMethods;
    return methods.filter((method) => method.type !== activeMethod);
  }, [activeMethod, allowedAuthMethods, currentFlow, signUpAllowedAuthMethods]);

  const handlePhoneClick = useCallback(() => {
    dispatch(
      log({
        event: 'SocialButtons.handlePhoneClick',
        data: {
          destination: EAuthFormType.PHONE_NUMBER,
          currentFlow,
          currentForm,
        },
      }),
    );

    dispatch(setCurrentForm(EAuthFormType.PHONE_NUMBER));
  }, [currentFlow, currentForm, dispatch]);

  const handleEmployeeIdClick = useCallback(() => {
    dispatch(
      log({
        event: 'SocialButtons.handleEmployeeIdClick',
        data: {
          destination: EAuthFormType.SEVEN_SHIFTS_EMPLOYEE_ID,
          currentFlow,
          currentForm,
        },
      }),
    );

    dispatch(setCurrentForm(EAuthFormType.SEVEN_SHIFTS_EMPLOYEE_ID));
  }, [currentFlow, currentForm, dispatch]);

  const handleEmailClick = useCallback(() => {
    dispatch(
      log({
        event: 'SocialButtons.handleEmailClick',
        data: {
          destination: EAuthFormType.EMAIL,
          currentFlow,
          currentForm,
        },
      }),
    );

    dispatch(setCurrentForm(EAuthFormType.EMAIL));
  }, [currentFlow, currentForm, dispatch]);

  const renderSocialButton = useCallback(
    ({ type }) => {
      switch (type) {
        case EAuthMethods.SEVEN_SHIFTS_EMPLOYEE_ID:
          return (
            <S.SocialButton
              key={type}
              onClick={handleEmployeeIdClick}
              icon={<S.ImageIcon src={SevenShiftsIcon} />}
              title={t(`authForm.${currentForm}.withEmployeeId`)}
              dataSet={emailButtonDataCy}
            />
          );
        case EAuthMethods.EMAIL:
          return (
            <S.SocialButton
              key={type}
              onClick={handleEmailClick}
              icon={<MailIcon width={24} height={24} />}
              title={t(`authForm.${currentForm}.withMail`)}
              dataSet={emailButtonDataCy}
            />
          );
        case EAuthMethods.PHONE:
          return (
            <S.SocialButton
              key={type}
              onClick={handlePhoneClick}
              icon={<PhoneIcon width={24} height={24} />}
              title={t(`authForm.${currentForm}.withPhone`)}
            />
          );
        case EAuthMethods.MICROSOFT:
          return (
            <S.SocialButton
              key={type}
              onClick={loginWithMicrosoft}
              icon={<MicrosoftIcon width={24} height={24} />}
              title={t(`authForm.${currentForm}.withMicrosoft`)}
            />
          );
        case EAuthMethods.GOOGLE:
          return (
            <S.SocialButton
              key={type}
              onClick={loginWithGoogle}
              icon={<GoogleIcon width={24} height={24} />}
              title={t(`authForm.${currentForm}.withGoogle`)}
            />
          );
        case EAuthMethods.APPLE:
          return (
            <S.AppleSocialButton
              key={type}
              icon={<AppleIcon width={24} height={24} fill={'black'} />}
              title={t(`authForm.${currentForm}.withApple`)}
            />
          );
        default:
          return null;
      }
    },
    [currentForm, handleEmailClick, handleEmployeeIdClick, handlePhoneClick, loginWithGoogle, loginWithMicrosoft, t],
  );

  if (!allowedMethods.length) {
    return null;
  }

  return <S.SocialButtons>{allowedMethods.map(renderSocialButton)}</S.SocialButtons>;
};

const S = {
  SocialButtons: styled.div`
    min-width: 298px;
  `,
  SocialButton: styled(SocialButton)`
    min-width: 295px;
  `,
  AppleSocialButton: styled(AppleSocialButton)`
    min-width: 295px;
  `,
  ImageIcon: styled.img`
    width: 35px;
    height: 35px;
    margin-left: -7px;
  `,
};

export default memo(SocialButtons);

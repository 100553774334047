import styled, { css } from 'styled-components';

import StyledLink from './StyledLink';

const CallToAction: any = {};

CallToAction.Container = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
`;

CallToAction.Text = styled.span`
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 2px;
  ${({ theme }) => css`
    color: ${theme.colors.darkPrimary};
  `};
`;

CallToAction.Link = StyledLink;

export default CallToAction;

import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import PlaylistItem from './PlaylistItem';

import { IPlaylist } from '../../../types/playlist';
import useExpandedList from '../../../hooks/useExpandedList';
import { StyledLink } from '../../components/shared';

interface IProps {
  playlists: IPlaylist[];
  onPlaylistClick: (any) => void;
}

const PlaylistList: FC<IProps> = ({ playlists, onPlaylistClick }) => {
  const { list: expandablePlaylists, toggleIsExpanded, toggleText } = useExpandedList(playlists);
  return (
    <S.Container>
      <S.Grid>
        {expandablePlaylists.map((playlist) => (
          <PlaylistItem key={playlist.id} playlist={playlist} onClick={onPlaylistClick} />
        ))}
      </S.Grid>

      {!!toggleText && (
        <div style={{ marginTop: 12 }}>
          <StyledLink onClick={() => toggleIsExpanded()}>{toggleText}</StyledLink>
        </div>
      )}
    </S.Container>
  );
};

const S = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Grid: styled.div(
    ({ theme }) => css`
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      grid-column-gap: 25px;
      grid-row-gap: 25px;

      @media ${theme.breakPoints.tablet} {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      @media ${theme.breakPoints.mobileL} {
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }
    `,
  ),
};

export default PlaylistList;

import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import type { NavLinkProps } from 'react-router-dom';

import { ReactComponent as NotchSVG } from '../../../../assets/icons/notch.svg';

interface IProps extends NavLinkProps {
  to: string;
  icon: any;
  padding?: string;
  // eslint-disable-next-line no-unused-vars
  onClick: (evt: any) => void;
}

const NotchedNavLink: FC<IProps> = ({ to, icon: Icon, padding, onClick, ...rest }) => {
  const iconName = to.split('/')[3];
  return (
    <StyledNotchedNavLink to={to} onClick={onClick} {...rest}>
      <StyledNotch id={`${iconName}IconNotch`} />
      <StyledIconWrapper id={`${iconName}IconContainer`}>
        <StyledIcon component={<Icon />} padding={padding} id={`${iconName}Icon`} />
      </StyledIconWrapper>
    </StyledNotchedNavLink>
  );
};

export const StyledIcon = styled(({ component, ...props }) => React.cloneElement(component, props))`
  width: 40px;
  height: 40px;
  z-index: 20;
  ${({ theme, padding }) => css`
    fill: ${theme.colors.white};
    padding: ${padding || '6px'};
  `}
`;

export const StyledIconWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryBlue};
  border-radius: 50%;
  position: relative;
  z-index: 20;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledNotch = styled(NotchSVG)`
  position: absolute;
  bottom: -1px;
  height: 0px;
  user-select: none;
  color: ${({ theme }) => theme.colors.white};
  transition: 0.5s ease height;
`;

const StyledNotchedNavLink = styled(NavLink)`
  position: relative;
  /* flex: 1; */
  width: 10%;
  margin: 0 7.5%;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  &.active {
    ${StyledNotch} {
      height: 46px;
      transition: 0.5s ease height;
    }
  }
`;

export default NotchedNavLink;

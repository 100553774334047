import React, { FC } from 'react';
import styled from 'styled-components';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import NotchedNavLink from './NotchedNavLink';
import HomeOrPlaylistButton from './HomeOrPlaylistButton';

import { StyledHeader } from '../../../../components/layout';
import { selectedBiteSelector } from '../../../../store/bite/bite.selectors';
import { biteShareUserSelector } from '../../../../store/biteShareUser/biteShareUser.selectors';
import { enableVideoPlaying } from '../../../../store/bite/bite.actions';
import { getIsRtl } from '../../../../locale/i18n';
import useRedirectToBiteSection from '../../../../hooks/useRedirectToBiteSection';
import useQueryParams from '../../../../hooks/useQueryParams';

interface IProps {
  links: {
    path: string;
    svgIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  }[];
}

const Header: FC<IProps> = ({ links }) => {
  const match = useRouteMatch();
  const { queryParams } = useQueryParams();
  const isPreview = queryParams?.isPreview;
  const dispatch = useDispatch();
  const onEnableVideoPlaying = () => {
    dispatch(enableVideoPlaying());
  };
  const { selectedBite } = useSelector(selectedBiteSelector);
  const biteShareUser = useSelector(biteShareUserSelector);

  const redirectToBiteSection = useRedirectToBiteSection(selectedBite, biteShareUser);

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, requestedSectionName) => {
    e.preventDefault();

    if (requestedSectionName === 'intro') {
      onEnableVideoPlaying();
    }

    redirectToBiteSection(requestedSectionName);
  };

  return (
    <S.Header>
      <S.Nav>
        <S.SectionLinksContainer>
          {links.map((link) => {
            const { path, svgIcon: SvgIcon } = link;
            const sectionName = path.slice(1, path.length);
            return (
              <NotchedNavLink
                data-cy={`header-${path.slice(1)}`}
                key={path}
                to={match.url + path}
                icon={SvgIcon}
                onClick={(e) => handleClick(e, sectionName)}
              />
            );
          })}
        </S.SectionLinksContainer>
        {!isPreview && <HomeOrPlaylistButton />}
      </S.Nav>
    </S.Header>
  );
};

const S = {
  Header: styled(StyledHeader)`
    display: flex;
    justify-content: space-between;
    min-height: 75px;
    padding-top: 5px;

    ${() =>
      getIsRtl() &&
      `
			direction: rtl;
            writing-mode: horizontal-tb;
		`};
  `,

  Nav: styled.nav`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  `,

  SectionLinksContainer: styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-start;
  `,
};

export default Header;

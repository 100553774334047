import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import BiteLogoSrc from '../../assets/images/bite-logo.png';

const PoweredByBites: FC = () => {
  return (
    <S.Container>
      <S.Text>Powered by</S.Text>
      <S.BitesLogo src={BiteLogoSrc} />
      <S.Text>Bites</S.Text>
    </S.Container>
  );
};

const S: any = {};

S.Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 16px 0;
`;

S.Text = styled.span`
  font-size: 13px;
  line-height: 21px;
  ${({ theme }) => css`
    color: ${theme.colors.black2};
    font-family: ${theme.fontFamilies.GilroyMedium};
  `}
`;

S.BitesLogo = styled.img`
  width: 14px;
  margin-left: 2px;
  margin-right: 5px;
  transform: translateY(-25%);
`;

export default PoweredByBites;

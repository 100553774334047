import React, { FC } from 'react';
import styled from 'styled-components';

import { ContinueButton } from '../../../components/shared';
import useGoToNext from './hooks/useGoToNext';

const GoNextBlueButton: FC = () => {
  const { handleNext } = useGoToNext();
  return <S.ContinueButton onClick={handleNext} />;
};

const S = {
  ContinueButton: styled(ContinueButton)`
    margin-top: 20px;
  `,
};

export default GoNextBlueButton;

import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import MediaViewer from './components/MediaViewer';
import QuestionCount from './components/QuestionCount';

import { ContinueButton } from '../../components/shared';
import { getIsRtl } from '../../locale/i18n';
import {
  answerQuizQuestion,
  selectQuiz,
  selectedQuizSettingSelector,
  setQuizProcessingFalse,
} from '../../store/quiz/quiz.slice';
import OpenEndedAnswer from '../../components/question/OpenEndedAnswer';
import ChoiceList from '../../components/question/ChoiceList';
import gtmTrack from '../../services/googleTagManager/track';
import { authSelector } from '../../store/auth/auth.selectors';
import BackButton from '../../components/shared/Buttons/BackButton';
import { redirectAfterAnswering } from '../../store/quiz/quiz.saga';
import history from '../../navigation/history';
import TranslationsDropdown from '../../components/shared/TranslationsDropdown';
import useQuizTranslation from './hooks/useQuizTranslation';
import { startQuizDataSet } from './quizIntro.constants';

interface Props {
  match: any;
}

function QuizQuestion(props: Props) {
  const { questionId } = props.match.params;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isRtl = getIsRtl();

  const { questions, isProcessing } = useSelector(selectQuiz);
  const activeQuestion = questions.find((q) => q.id === Number(questionId));
  const settings = useSelector(selectedQuizSettingSelector);
  const auth = useSelector(authSelector);
  const isMultiChoiceSurvey = activeQuestion.type === 'survey' && activeQuestion.is_multi_choice;
  const questionsIds = questions.map((q) => q.id);
  const currentQuestionIndex = questionsIds.indexOf(activeQuestion.id);
  const [openEndedText, setOpenEndedText] = useState(activeQuestion.openEndedText || '');
  const [openEndedImage, setOpenEndedImage] = useState(activeQuestion.answerMedia?.image_url || null);
  const [userChoicesIds, setUserChoicesIds] = useState<number[]>(activeQuestion.userChoicesIds || []);
  const isPreview = new URLSearchParams(window.location.search).has('isPreview');
  const isAnsweredQuestion = activeQuestion.isAnswered && !isPreview;
  const isDisabled =
    isProcessing || (activeQuestion.type === 'open ended' && openEndedText === '') || userChoicesIds.length === 0;

  const choices = useMemo(() => {
    const sortedChoices = activeQuestion.choices?.slice().sort((a, b) => a.order - b.order);
    return sortedChoices || [];
  }, [activeQuestion]);

  const { isLoadingTranslations, questionTranslation, choicesTranslations, setLocale } = useQuizTranslation({
    activeQuestion,
    sortedChoices: choices,
  });

  const translationsDropdownContainerStyles = useMemo(
    () => ({
      position: 'absolute',
      top: 8,
      right: isRtl ? 'auto' : 4,
      left: isRtl ? 4 : 'auto',
    }),
    [isRtl],
  );
  const translationsDropdownStyles = useMemo(
    () => ({
      position: 'absolute',
      top: 40,
      right: isRtl ? 'auto' : 4,
      left: isRtl ? 4 : 'auto',
    }),
    [isRtl],
  );

  // reset state between questions
  useEffect(() => {
    setOpenEndedImage(activeQuestion.answerMedia?.image_url || null);
    setOpenEndedText(activeQuestion.openEndedText || '');
    setUserChoicesIds(activeQuestion.userChoicesIds || []);
    dispatch(setQuizProcessingFalse());
  }, [
    activeQuestion.answerMedia?.image_url,
    activeQuestion.media,
    activeQuestion.openEndedText,
    activeQuestion.userChoicesIds,
    dispatch,
  ]);

  const isUserCorrect = useMemo(() => {
    if (userChoicesIds.length === 0) {
      return;
    }

    if (isPreview && !activeQuestion.isAnsweredPreview) {
      return;
    }

    const correctChoice = activeQuestion.choices.find((c) => c.is_correct);
    if (!correctChoice) {
      return;
    }

    return userChoicesIds.includes(correctChoice.id);
  }, [userChoicesIds, isPreview, activeQuestion.isAnsweredPreview, activeQuestion.choices]);

  useEffect(() => {
    if (currentQuestionIndex !== 0) {
      gtmTrack('quiz_question_next_button', { bites_user_id: auth?.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeQuestion.id, questions]);

  const multipleChoiceHelperText = useMemo(() => {
    if (userChoicesIds.length === 0 || isUserCorrect === undefined) {
      return t('screens.viewBite.MarkAnswer');
    }

    return isUserCorrect ? t('screens.viewBite.Correct') : t('screens.viewBite.WrongAnswer');
  }, [userChoicesIds, isUserCorrect, t]);

  const handleContinue = useCallback(() => {
    if (isAnsweredQuestion) {
      redirectAfterAnswering({ questionId: activeQuestion.id, questions });
      return;
    }
    dispatch(
      // @ts-ignore
      answerQuizQuestion({
        question: activeQuestion.id,
        choices_ids: userChoicesIds,
        bite_share_user: activeQuestion.bite_share_user_id,
      }),
    );
  }, [isAnsweredQuestion, dispatch, activeQuestion.id, activeQuestion.bite_share_user_id, userChoicesIds, questions]);

  const handleSubmitOpenEnded = useCallback(() => {
    if (isAnsweredQuestion) {
      redirectAfterAnswering({ questionId: activeQuestion.id, questions });
      return;
    }
    dispatch(
      // @ts-ignore
      answerQuizQuestion({
        question: activeQuestion.id,
        answer: openEndedText,
        image: openEndedImage,
        bite_share_user: activeQuestion.bite_share_user_id,
      }),
    );
  }, [
    isAnsweredQuestion,
    dispatch,
    activeQuestion.id,
    activeQuestion.bite_share_user_id,
    openEndedText,
    openEndedImage,
    questions,
  ]);

  const handleToggleChoice = useCallback(
    (choiceId) => {
      if (isMultiChoiceSurvey) {
        const newCheckedChoices = userChoicesIds.includes(choiceId)
          ? userChoicesIds.filter((choice) => choice !== choiceId)
          : [...userChoicesIds, choiceId];

        setUserChoicesIds(newCheckedChoices);
        return;
      }
      setUserChoicesIds([choiceId]);
    },
    [userChoicesIds, isMultiChoiceSurvey],
  );

  const handleBackButtonClick = useCallback(() => {
    if (currentQuestionIndex === 0) {
      return;
    }
    const quizId = history.location.pathname.split('/')[2];
    const nextQuestionId = questions[currentQuestionIndex - 1]?.id;
    history.push(`/quiz/${quizId}/question/${nextQuestionId}${window.location.search}`);
  }, [currentQuestionIndex, questions]);

  const handleSelectLocale = useCallback(
    ({ locale }) => {
      setLocale(locale);
    },
    [setLocale],
  );

  return (
    <S.Wrapper
      data-cy={startQuizDataSet.cy}
      backgroundColor={settings.backgroundUrl ? null : settings.backgroundColor}
      backgroundImage={settings.backgroundUrl}
    >
      <S.Container questionColor={settings.questionColor}>
        {currentQuestionIndex !== 0 && <BackButton onClick={handleBackButtonClick} />}
        <QuestionCount />
        <S.QuestionText questionColor={settings.questionColor}>
          {questionTranslation || activeQuestion.text}
        </S.QuestionText>
        <MediaViewer
          media_type={activeQuestion.media?.file_type}
          media_url={activeQuestion.media?.media_url}
          image_url={activeQuestion.media?.image_url}
          cover_url={activeQuestion.cover_url}
        />

        {activeQuestion.type === 'open ended' && (
          <OpenEndedAnswer
            text={openEndedText}
            onTextChange={(evt) => setOpenEndedText(evt.target.value)}
            image={openEndedImage}
            onImageChange={setOpenEndedImage}
            onSubmit={handleSubmitOpenEnded}
            isProcessing={isProcessing}
            isAnswered={isAnsweredQuestion}
          />
        )}
        {activeQuestion.type === 'multiple choices' && (
          <S.DynamicBlueText isUserCorrect={isUserCorrect}>{multipleChoiceHelperText}</S.DynamicBlueText>
        )}
        {activeQuestion.type !== 'open ended' && (
          <ChoiceList
            choices={choices}
            onChoiceClick={handleToggleChoice}
            questionColor={settings.questionColor}
            userChoicesIds={userChoicesIds}
            isMultiChoiceSurvey={isMultiChoiceSurvey}
            withoutHightlight={activeQuestion.type === 'survey'}
            isAnswered={isAnsweredQuestion}
            choicesTranslations={choicesTranslations}
          />
        )}
        <S.TranslationsContainer isRtl={isRtl}>
          <TranslationsDropdown
            isLoading={isLoadingTranslations}
            containerStyles={translationsDropdownContainerStyles}
            dropdownStyles={translationsDropdownStyles}
            onSelect={handleSelectLocale}
          />
        </S.TranslationsContainer>
      </S.Container>
      {(userChoicesIds.length > 0 || isAnsweredQuestion) && !(activeQuestion.type === 'open ended') && (
        <S.ContinueButton disabled={isDisabled} onClick={handleContinue} />
      )}
    </S.Wrapper>
  );
}

const S = {
  Wrapper: styled.div<{ backgroundColor: string; backgroundImage: string }>`
    & {
      padding: 30px;
      position: relative;
      display: block;
      background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
      z-index:1;
      height: 100%;
    }
    &::before {
      content:'';
      background-image: url(${({ backgroundImage }) => backgroundImage});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      filter: blur(4px) brightness(0.6);
      opacity:0.4;
      position:absolute;
      top:0;
      bottom:0;
      right:0;
      left:0;
      width:auto;
      height:auto;
      z-index:-3;
    }
  }
  `,
  Container: styled.div<{ questionColor: string }>`
    & {
      border-radius: 15px;
      padding: 4px 20px 24px;
      position: relative;
      display: block;

      box-shadow: 0 12px 19px 0 rgba(60, 128, 209, 0.09);
      z-index: 1;
      min-height: 350px;
      ${() => getIsRtl() && ' direction: rtl; '};
      writing-mode: horizontal-tb;
    }
    &::before {
      content: '';
      opacity: 0.7;
      position: absolute;
      background-color: ${({ theme, questionColor }) => questionColor || theme.colors.white};
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: auto;
      height: auto;
      z-index: -3;
    }
  `,
  QuestionText: styled.p<{ questionColor: string }>`
    font-size: 21px;
    line-height: 1.2;
    margin-bottom: 41px;
    text-align: center;
    color: ${({ theme }) => theme.colors.darkPrimary};
  `,
  DynamicBlueText: styled.p<{ isUserCorrect: boolean | undefined }>`
    text-align: center;
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 29px;
    ${({ theme, isUserCorrect }) => css`
      color: ${theme.colors.primaryBlue};
      ${isUserCorrect !== undefined &&
      css`
        color: ${isUserCorrect ? theme.colors.green1 : theme.colors.red1};
      `}
    `}
  `,
  ContinueButton: styled(ContinueButton)`
    margin-top: 20px;
  `,
  TranslationsContainer: styled.div<{ isRtl: boolean }>`
    z-index: 100;
    position: absolute;
    top: 0;
    right: ${({ isRtl }) => (isRtl ? 'auto' : 0)};
    left: ${({ isRtl }) => (isRtl ? 0 : 'auto')};
  `,
};

export default QuizQuestion;

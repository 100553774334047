import { useSelector } from 'react-redux';

import { authSelector } from '../store/auth/auth.selectors';
import BitesLogo from '../assets/images/bite-logo.png';
import { orgSelector } from '../store/organization/organization.selectors';

interface Response {
  logo: string;
  isLoadingLogo: boolean;
}

const useGetOrganizationLogo = (): Response => {
  const auth = useSelector(authSelector);
  const { logoUrl } = useSelector(orgSelector);

  if (!auth?.organizations) {
    return {
      isLoadingLogo: true,
      logo: null,
    };
  }

  return {
    isLoadingLogo: false,
    logo: auth.organizations.length === 0 ? BitesLogo : logoUrl || BitesLogo,
  };
};

export default useGetOrganizationLogo;

import ProfileActionTypes, { IUserOrgExtendedData } from './profile.types';

//get organization
export const getOrganizationRequest = (organization: number) => ({
  type: ProfileActionTypes.GET_ORGANIZATION_REQUEST,
  payload: organization,
});
export const getOrganizationSuccess = (data: any) => ({
  type: ProfileActionTypes.GET_ORGANIZATION_SUCCESS,
  payload: data,
});
export const getOrganizationError = () => ({
  type: ProfileActionTypes.GET_ORGANIZATION_ERROR,
});

export const updateOrganizationSuccess = (userOrg: any) => ({
  type: ProfileActionTypes.UPDATE_ORGANIZATION_SUCCESS,
  payload: { userOrg },
});

export const updateUserOrg = (payload: IUserOrgExtendedData) => ({
  type: ProfileActionTypes.UPDATE_USER_ORG,
  payload,
});

export const getOrganizationFeatureFlags = (orgId: number) => ({
  type: ProfileActionTypes.GET_ORGANIZATION_FEATURE_FLAGS,
  payload: orgId,
});
export const setOrganizationFeatureFlags = (payload: { orgId: number; featureFlags: Record<string, boolean> }) => ({
  type: ProfileActionTypes.SET_ORGANIZATION_FEATURE_FLAGS,
  payload,
});

/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react';

export const useProfileData = (data: any) => {
  const result = useMemo(() => {
    const obj = {};
    if (data) {
      const dataKeys = Object.keys(data)
        .filter((item) => item.includes('data'))
        .filter((el) => el.includes('title'));
      dataKeys.forEach((key) => {
        const keyName = key.replace('_title', '');
        if (data[keyName].length) {
          obj[keyName] = {
            title: data[`${keyName}_title`],
            data: data[keyName],
          };
        }
      });
      return obj;
    }
    return { ...obj };
  }, [data]);
  return result;
};

import React, { FC } from 'react';
import styled from 'styled-components';

import Loader from '../Loader';
import { ReactComponent as SendGrayIcon } from '../../../assets/icons/send_gray.svg';
import { ReactComponent as SendIcon } from '../../../assets/icons/send.svg';
import { getIsRtl } from '../../../locale/i18n';
import { sendButtonDataSet } from './sendButton.constants';

export interface IProps extends React.HTMLProps<HTMLButtonElement> {
  isLoading?: boolean;
  className?: string;
  disabled?: boolean;
}

const SendButton: FC<IProps> = ({ isLoading, disabled, ...rest }) => {
  return (
    <S.Button {...rest} data-cy={sendButtonDataSet.cy}>
      {isLoading ? <Loader /> : disabled ? <S.DisabledSendIcon /> : <S.SendIcon />}
    </S.Button>
  );
};

const S: any = {
  Button: styled.button<{ disabled?: boolean }>`
    position: relative;
    border-radius: 29px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  `,
  SendIcon: styled(SendIcon)`
    width: 21px;
    height: 22px;
    ${() => getIsRtl() && 'transform: rotate(180deg);'};
  `,
  DisabledSendIcon: styled(SendGrayIcon)`
    width: 21px;
    height: 22px;
    ${() => getIsRtl() && 'transform: rotate(180deg);'};
  `,
};

export default SendButton;

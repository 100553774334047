import * as microsoftTeams from '@microsoft/teams-js';
import noop from 'lodash/noop';

microsoftTeams.initialize();
export const TASK_MODULE_HEIGHT = 700;
export const TASK_MODULE_WIDTH = 500;

export const openTaskModule = (route: string, title?: string) => {
  microsoftTeams.tasks.startTask(
    {
      url: window.location.origin + route,
      title: title || '',
      height: TASK_MODULE_HEIGHT,
      width: TASK_MODULE_WIDTH,
      card: '',
    },
    noop,
  );
  microsoftTeams.tasks.submitTask(null, null);
};

export default microsoftTeams;

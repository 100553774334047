import { useEffect } from 'react';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';

import { getOrgSettings } from '../store/organization/organization.slice';

const useFetchOrgSettingsByQueryParams = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const { orgId } = queryString.parse(window.location.search);
    if (!orgId) {
      return;
    }
    dispatch(getOrgSettings(orgId));
  }, [dispatch]);
};

export default useFetchOrgSettingsByQueryParams;

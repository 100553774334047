import React, { useState } from 'react';
import styled from 'styled-components';

import usa from '../../../assets/flags/usa.png';
import israel from '../../../assets/flags/israel.png';
import spain from '../../../assets/flags/spain.png';
import france from '../../../assets/flags/france.png';
import germany from '../../../assets/flags/germany.png';
import brazil from '../../../assets/flags/brazil.png';
import i18n, { getIsRtl, LOCALES, setLanguage, TLocale } from '../../../locale/i18n';

const textByLocale: { [key in TLocale]: string } = {
  en: 'English',
  he: 'עברית',
  es: 'Español',
  fr: 'Français',
  de: 'Deutsche',
  pt: 'Português',
};
const flagByLocale: { [key in TLocale]: string } = {
  en: usa,
  he: israel,
  es: spain,
  fr: france,
  de: germany,
  pt: brazil,
};

function SelectLanguage({ onSelect }) {
  const [isOpen, setIsOpen] = useState(false);
  const [text, setText] = useState(textByLocale[i18n.language]);
  const [src, setSrc] = useState(flagByLocale[i18n.language]);

  const handleSelect = (locale) => {
    setIsOpen(false);
    setText(textByLocale[locale]);
    setSrc(flagByLocale[locale]);
    setLanguage(locale);
    onSelect();
  };

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <S.ChosenLang onClick={toggle} id='btnSelectLanguage'>
        <S.Flag src={src} alt='chosen language' id='btnSelectLanguageFlag' />
        <span id='btnSelectLanguageText'>{text}</span>
      </S.ChosenLang>
      {isOpen && (
        <div style={{ position: 'relative' }}>
          <S.ChooseLang>
            {LOCALES.map((locale) => (
              <S.ChooseLangRow id={`btnChoose-${locale}`} onClick={() => handleSelect(locale)} key={locale}>
                <S.Flag src={flagByLocale[locale]} alt={textByLocale[locale]} id={`btnChooseFlag-${locale}`} />
                <span id={`btnChooseText-${locale}`}>{textByLocale[locale]}</span>
              </S.ChooseLangRow>
            ))}
          </S.ChooseLang>
        </div>
      )}
    </div>
  );
}

const S: any = {};

S.ChosenLang = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

S.ChooseLang = styled.div`
  position: absolute;
  z-index: 10;
  background: #fff;
  top: 5px;
  border: 1px solid black;
  ${() => (getIsRtl() ? 'right: 0;' : 'left: 0')};
`;

S.ChooseLangRow = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
`;

S.Flag = styled.img`
  width: 32px;
  ${() => (getIsRtl() ? 'margin-left: 10px;' : 'margin-right: 10px;')};
`;

export default SelectLanguage;

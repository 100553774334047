import React, { FC } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';

import PlaylistPreview from './PlaylistPreview';

import { playlistsSelector } from '../../store/playlist/playlist.selectors';
import { playlistLoadNext } from '../../store/playlist/playlist.actions';
import { searchSelector } from '../../store/search/search.selectors';
import { Loader } from '../shared';
import MetaTitle from '../MetaTitle';
import EmptyList from '../shared/EmptyList';

interface IProps {
  orgId?: number;
  isPublicFeed?: boolean;
  onPlaylistClick?: (any: any) => void;
}

const InfinitePlaylistList: FC<IProps> = ({ orgId, isPublicFeed, onPlaylistClick }) => {
  const { playlists, totalPlaylists, isLoadingNext } = useSelector(playlistsSelector);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleNext = () => {
    if (isLoadingNext) {
      return;
    }
    dispatch(playlistLoadNext({ orgId, isPublicFeed }));
  };

  const search = useSelector(searchSelector);

  const isEmptyList = playlists.length === 0;
  const emptyListMessage = search.playlist === '' ? t('screens.home.emptyPlayList') : t('screens.home.emptySearchList');
  return !isEmptyList ? (
    <S.List
      dataLength={playlists.length}
      next={handleNext}
      hasMore={totalPlaylists > playlists.length}
      scrollableTarget='app-container'
      loader={
        <div style={{ margin: 20 }}>
          <Loader />
        </div>
      }
    >
      {playlists.map((playlist) => {
        let coverImages;
        if (!playlist) {
          return false;
        }
        if (playlist.is_quiz) {
          coverImages = playlist.bite_shares
            ?.filter((bite) => bite.subject.indexOf('intro') > -1)
            .map((bite) => bite.cover_url);
        } else {
          const noEmptyBites = playlist.bite_shares?.filter((bite) => !bite.no_sections);
          coverImages = noEmptyBites.map((bite) => bite.cover_url);
        }

        return (
          <PlaylistPreview key={playlist.id} playlist={playlist} coverImages={coverImages} onClick={onPlaylistClick} />
        );
      })}
      <MetaTitle title={t('metaTitles.playlistsList')} />
    </S.List>
  ) : (
    <EmptyList message={emptyListMessage} />
  );
};

const S: any = {};

S.List = styled(InfiniteScroll)`
  display: flex;
  flex-direction: column;
  align-items: center;
  > div {
    margin-bottom: 20px;
    width: 310px;
  }
`;

export default InfinitePlaylistList;

import React, { FC } from 'react';
import styled, { useTheme } from 'styled-components';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

const FillLoader: FC = () => {
  const theme = useTheme();
  return (
    <S.Container>
      <Loader type='Audio' color={theme.colors.primaryBlue} />
    </S.Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const S = { Container };

export default FillLoader;

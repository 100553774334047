import React, { FC, useRef } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { useDispatch } from 'react-redux';
import ReactPlayer from 'react-player';

import VideoPlayer from './VideoPlayer';

import { ReactComponent as CloseSVG } from '../../../assets/icons/close.svg';
import { enableVideoPlaying } from '../../../store/bite/bite.actions';
import { GtmVideo } from '../../../services/googleTagManager';
import useVimeoLink from '../../../hooks/useVimeoLinks';
import { videoModalDataSet } from '../../../components/shared/CustomModal/VideoModal/videoModal.constants';

interface IProps {
  onCloseModal: () => void;
  media_url: string | null;
  file_type: string;
}

const VideoModal: FC<IProps> = ({ media_url, file_type, onCloseModal }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const playerRef = useRef<ReactPlayer>(null);

  const { vimeoVideoUri } = useVimeoLink(file_type === 'video', media_url);

  const onClickCloseIcon = () => {
    onExitVideo();
    dispatch(enableVideoPlaying());
    onCloseModal();
  };

  const onExitVideo = () => {
    const tagManagerVideo = new GtmVideo(
      'Video Existed',
      playerRef.current?.getCurrentTime(),
      playerRef.current?.getDuration(),
      vimeoVideoUri,
      vimeoVideoUri ? 'Vimeo' : 'Other',
    );

    tagManagerVideo.sendData();
  };

  return (
    <S.Container data-cy={videoModalDataSet.cy}>
      <S.TopContainer>
        <CloseSVG
          data-test='close'
          onClick={onClickCloseIcon}
          width='15px'
          height='15px'
          fill={theme.colors.primaryBlue}
          id='btnCloseVideo'
        />
      </S.TopContainer>

      <S.VideoContainer>
        <VideoPlayer
          {...{
            media_url,
            file_type,
            onCloseModal,
            playerRef,
          }}
        />
      </S.VideoContainer>
    </S.Container>
  );
};

const S = {
  Container: styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    z-index: 20;

    display: flex;
    justify-content: flex-start;

    ${({ theme }) => css`
      background-color: ${theme.colors.darkBlueBg};
    `}
  `,

  ContentContainer: styled.div``,

  VideoContainer: styled.div`
    padding: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100% video {
      border-radius: 10px;
      object-fit: contain;
      flex: 1;
    }
  `,

  TopContainer: styled.div`
    padding: 17px;
    display: flex;
    flex-direction: row-reverse;
    position: absolute;
    z-index: 999;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 34px;
  `,

  Instructions: styled.p`
    text-align: center;
    margin-top: 68px;
    font-size: 16px;
    line-height: 20px;
    ${({ theme }) => css`
      color: ${theme.colors.white};
    `}
  `,
};

export default VideoModal;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { IAuthDataMissingPayload, IInitialState } from './authForm.types';
import { EAuthFlowType, EAuthFormType } from '../../screens/auth/auth.types';

export const initialState: IInitialState = {
  currentFlow: null,
  currentForm: EAuthFormType.AUTH,
  employeeId: '',
  email: '',
  password: '',
  phone: '',
  userSelectedCountryCode: null,
  firstName: '',
  lastName: '',
  recoveryToken: null,
  isAuthDataMissing: false,
};

const AUTH_FORM = 'AUTH_FORM';

const authFormSlice = createSlice({
  name: AUTH_FORM,
  initialState,
  reducers: {
    setCurrentFlow: (state, { payload }: PayloadAction<EAuthFlowType>) => {
      state.currentFlow = payload;
    },
    setCurrentForm: (state, { payload }: PayloadAction<EAuthFormType>) => {
      state.currentForm = payload;
    },
    setEmployeeId: (state, { payload }: PayloadAction<string>) => {
      state.employeeId = payload;
    },
    setEmail: (state, { payload }: PayloadAction<string>) => {
      state.email = payload;
    },
    setPassword: (state, { payload }: PayloadAction<string>) => {
      state.password = payload;
    },
    setPhone: (state, { payload }: PayloadAction<string>) => {
      state.phone = payload;
    },
    setFirstName: (state, { payload }: PayloadAction<string>) => {
      state.firstName = payload;
    },
    setLastName: (state, { payload }: PayloadAction<string>) => {
      state.lastName = payload;
    },
    setIsAuthDataMissing: (state, { payload }: PayloadAction<boolean>) => {
      state.isAuthDataMissing = payload;
    },
    setMissedAuthData: (state, { payload }: PayloadAction<IAuthDataMissingPayload>) => {
      state.currentForm = EAuthFormType.USER_INFO;
      state.isAuthDataMissing = payload.isAuthDataMissing;
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
      state.email = payload.email;
      state.phone = payload.phone;
    },
    setRecoveryToken: (state, { payload }: PayloadAction<string>) => {
      state.recoveryToken = payload;
    },
    setUserSelectedCountryCode: (state, { payload }: PayloadAction<string>) => {
      state.userSelectedCountryCode = payload;
    },
    resetAuthForm: () => initialState,
  },
});

export const {
  setCurrentFlow,
  setCurrentForm,
  setEmployeeId,
  setEmail,
  setPassword,
  setPhone,
  setFirstName,
  setLastName,
  setIsAuthDataMissing,
  setMissedAuthData,
  resetAuthForm,
  setRecoveryToken,
  setUserSelectedCountryCode,
} = authFormSlice.actions;

export default authFormSlice.reducer;

import localStorage from '../utils/localStorage';
import { driver, Config } from 'driver.js';
import 'driver.js/dist/driver.css';
import { RefObject, useEffect, useRef } from 'react';
import { useIsMounted } from './useIsMounted';

type Props = {
  delay?: number;
  side?: 'top' | 'right' | 'bottom' | 'left' | 'over';
  align?: 'start' | 'center' | 'end';
  displayedLocalStorageKey: string;
  clickedLocalStorageKey?: string;
  text: string;
  ref: RefObject<HTMLDivElement>;
  backdropOpacity?: number;
  withBackdrop?: boolean;
};
export const usePopoverHighlight = ({
  delay = 0,
  side = 'bottom',
  align = 'center',
  displayedLocalStorageKey,
  clickedLocalStorageKey,
  text,
  ref,
  backdropOpacity,
  withBackdrop = true,
}: Props) => {
  const isMountedRef = useIsMounted();

  const driverRef = useRef<ReturnType<typeof driver>>(null);

  useEffect(() => {
    let destroyTimeout: ReturnType<typeof setTimeout>;

    const run = async () => {
      await new Promise((resolve) => setTimeout(resolve, delay));

      if (
        !isMountedRef.current ||
        !ref.current ||
        !displayedLocalStorageKey ||
        localStorage.getItem(displayedLocalStorageKey) ||
        (clickedLocalStorageKey && localStorage.getItem(clickedLocalStorageKey))
      ) {
        return;
      }

      const config: Config = {
        overlayOpacity: backdropOpacity !== undefined ? backdropOpacity : 0.5,
        stagePadding: 0,
        onPopoverRender: () => {
          if (!withBackdrop) {
            document.body.classList.add('driverjs-no-overlay');
            document.body.classList.remove('driver-active');
          }
        },
        onDestroyed: () => {
          if (!withBackdrop) {
            document.body.classList.remove('driverjs-no-overlay');
          }
        },
      };
      driverRef.current = driver(config);

      driverRef.current.highlight({
        element: ref.current,
        popover: {
          description: text,
          side,
          align,
        },
      });

      localStorage.setItem(displayedLocalStorageKey, '1');

      destroyTimeout = setTimeout(() => {
        driverRef.current?.destroy();
      }, 5000);
    };

    run();

    return () => {
      clearTimeout(destroyTimeout);
      driverRef.current?.destroy();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayedLocalStorageKey]);

  return {
    driverRef,
  };
};

export const isStaging = () => process.env.REACT_APP_ENV === 'staging';

export const isProd = () => !isStaging() && process.env.NODE_ENV === 'production';

export const isDev = () => process.env.NODE_ENV === 'development';

export const getEnvName = (): string => {
  return isDev() ? 'dev' : isStaging() ? 'staging' : isProd() ? 'production' : 'local';
};

export const getDevelopmentBackendUrl = () => process.env.REACT_APP_BACKEND_URL;

import { useEffect, useState } from 'react';

const useKeyboardHeight = () => {
  const [keyboardHeight, setKeyboardHeight] = useState(0);

  useEffect(() => {
    const handleViewportChange = () => {
      // Determining the height by which the viewport is shifted due to the keyboard
      const offset = window.innerHeight - window.visualViewport.height;
      setKeyboardHeight(offset > 0 ? offset : 0);
    };

    window.visualViewport.addEventListener('resize', handleViewportChange);

    return () => {
      window.visualViewport.removeEventListener('resize', handleViewportChange);
    };
  }, []);

  return {
    keyboardHeight,
  };
};

export default useKeyboardHeight;

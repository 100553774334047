/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as microsoftTeams from '@microsoft/teams-js';

import { bitesSelector } from '../../../store/bite/bite.selectors';
import { playlistsSelector } from '../../../store/playlist/playlist.selectors';
import useWindowSize from '../../../hooks/useWindowSize';
import { biteQueryRequest } from '../../../store/bite/bite.actions';
import { playlistQueryRequest } from '../../../store/playlist/playlist.actions';
import { microsoftDashboardBreakPoint } from '../../../components/layout/breakPoints';
import { openTaskModule } from '../../../services/msTeams';
import { authSelector, microsoftTeamsContextSelector } from '../../../store/auth/auth.selectors';
import { orgSelector } from '../../../store/organization/organization.selectors';
import { getOrgSettings } from '../../../store/organization/organization.slice';

export default () => {
  const dispatch = useDispatch();
  const { bites, isLoading: isBitesLoading, error: bitesError } = useSelector(bitesSelector);
  const { playlists, isLoading: isPlaylistLoading, error: playlistsError } = useSelector(playlistsSelector);
  const microsoftTeamsContext: null | microsoftTeams.Context = useSelector(microsoftTeamsContextSelector);
  const windowSize = useWindowSize();
  const history = useHistory();

  const { id: activeOrgId } = useSelector(orgSelector);
  const currentUser = useSelector(authSelector);

  useEffect(() => {
    const teamsOrg = microsoftTeamsContext
      ? currentUser?.organizations.find((org) => {
          return org.is_teams_related;
        })
      : null;
    if (!teamsOrg || teamsOrg.id === activeOrgId) {
      dispatch(biteQueryRequest());
      dispatch(playlistQueryRequest());
    } else {
      dispatch(getOrgSettings(teamsOrg.id));
    }
  }, [activeOrgId]);

  //// FILTER BITES
  const [bitesFilter, setBitesFilter] = useState('');
  const filteredBites = bites.filter((bite) => {
    return (
      bite.subject.toLowerCase().includes(bitesFilter.toLowerCase()) ||
      bite.description.toLowerCase().includes(bitesFilter.toLowerCase())
    );
  });
  /////////////////

  //// FILTER PLAYLIST
  const [playlistFilter, setPlaylistFilter] = useState('');
  const filteredPlaylists = playlists.filter((playlist) => {
    return (
      playlist.subject.toLowerCase().includes(playlistFilter.toLowerCase()) ||
      playlist.description.toLowerCase().includes(playlistFilter.toLowerCase())
    );
  });
  /////////////////

  const shouldOpenInsideTaskModule = microsoftTeamsContext && windowSize.width > microsoftDashboardBreakPoint;

  const handleBiteClick = (biteShareId: number, subject: string) => {
    const url = `/bites/${biteShareId}`;
    if (shouldOpenInsideTaskModule) {
      openTaskModule(url, subject);
    } else {
      history.push(url);
    }
  };

  const handlePlaylistClick = (playlist) => {
    const url = playlist.is_quiz ? `/quiz/${playlist.id}` : `/playlists/${playlist.id}`;
    if (shouldOpenInsideTaskModule) {
      openTaskModule(url, playlist.subject);
    } else {
      history.push(url);
    }
  };

  return {
    bites,
    playlists,
    bitesFilter,
    filteredBites,
    isBitesLoading,
    bitesError,
    playlistFilter,
    filteredPlaylists,
    handleBiteClick,
    handlePlaylistClick,
    setBitesFilter,
    setPlaylistFilter,
    isPlaylistLoading,
    playlistsError,
  };
};

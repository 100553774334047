import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import BiteItem from './BiteItem';

import { IBitePreviewProps } from '../../../types/bite';
import { StyledLink } from '../shared';
import useExpandedList from '../../../hooks/useExpandedList';

interface IProps {
  bites: IBitePreviewProps[];
  onBiteClick: (biteShareId: number, subject: string) => any;
}

const BiteList: FC<IProps> = ({ bites, onBiteClick }) => {
  const { list: expndableBites, toggleIsExpanded, toggleText } = useExpandedList(bites);

  return (
    <S.Container>
      <S.Grid>
        {expndableBites.map((bite) => (
          <BiteItem key={bite.id} bite={bite} onClick={onBiteClick} />
        ))}
      </S.Grid>
      <div style={{ marginTop: 12 }}>
        <StyledLink onClick={() => toggleIsExpanded()}>{toggleText}</StyledLink>
      </div>
    </S.Container>
  );
};

const S = {
  Container: styled.div``,

  Grid: styled.div(
    ({ theme }) => css`
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-column-gap: 25px;
      grid-row-gap: 25px;
      /* margin-bottom: 12px; */
      @media ${theme.breakPoints.tablet} {
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }
    `,
  ),
};

export default BiteList;

import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import { configSelector } from '../store/appActivity/appActivity.selectors';

function MetaTags() {
  // @ts-ignore
  const { organization, socialShare } = useSelector((state) => state);
  const config = useSelector(configSelector);

  const ogImage = organization.brandShareUrl || config.defaultBrandShareImage;

  return (
    <Helmet>
      {socialShare.title && <title>{socialShare.title}</title>}
      {socialShare.title && <meta property='og:title' content={socialShare.title} />}

      {ogImage && <meta property='og:image' itemProp='image' content={ogImage} />}
    </Helmet>
  );
}

export default MetaTags;

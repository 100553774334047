import history from '../navigation/history';

export interface ISetQueryParamsProps {
  pathname?: string | null;
  name: string;
  value: string | null;
  replace?: boolean;
}

export const setQueryParam = ({ pathname, name, value, replace }: ISetQueryParamsProps) => {
  const query = new URLSearchParams(history.location.search);

  if (!value) {
    query.delete(name);
  } else {
    query.set(name, value);
  }

  if (replace) {
    history.replace({
      ...history.location,
      pathname: pathname || history.location.pathname,
      search: query.toString(),
    });
    return;
  }

  history.push({ ...history.location, pathname: pathname || history.location.pathname, search: query.toString() });
};

export const getQueryParam = (name: ISetQueryParamsProps['name']) => {
  const query = new URLSearchParams(history.location.search);
  return query.get(name);
};

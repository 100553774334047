export const askMeAnythingCy = 'ask-me-anything';
export const suggestionCy = 'suggestion';

export const suggestionItemCy = 'suggestion-item';

export const successAnswerCollapsedCy = 'success-answer-collapsed';
export const successAnswerExpandedCy = 'success-answer-expanded';
export const failureAnswerCy = 'failure-answer';
export const noAnswerCy = 'no-answer';
export const answerLoaderCy = 'answer-loader';

export const relatedContentCy = 'related-content';
export const relatedContentItemCy = 'related-content-item';

export const searchContentItemCy = 'search-content-item';

export const showMoreButtonCy = 'show-more-button';
export const betaPlateCy = 'beta-plate';

export const promptInputCy = 'prompt-input';

export const editModeInputButtonsCy = 'edit-mode-input-buttons';

export const watchModeInputButtonsCy = 'watch-mode-input-buttons';

export const inputBackIconButtonCy = 'input-back-icon-button';
export const inputSearchIconButtonCy = 'input-search-icon-button';
export const inputEditIconButtonCy = 'input-edit-icon-button';
export const inputRedoIconButtonCy = 'input-redo-icon-button';
export const inputClearIconButtonCy = 'input-clear-icon-button';

export const suggestionModeSuggestionsCy = 'suggestion-mode-suggestions';

export const suggestionModeHistoryCy = 'suggestion-mode-history';

export const homeButtonCy = 'home-button';

export const applyButtonCy = 'apply-button';

import axios from 'axios';
import { isProd } from '../../utils/env';

const baseUrl = isProd() ? 'https://bites-api.com' : 'https://staging.bites-api.com';

const bitesCrmTrack = async (data) => {
  try {
    await axios.post(`${baseUrl}/api/common_services/crm/track`, data);
  } catch (error) {
    // ignore
  }
};
export default bitesCrmTrack;

import AuthActionTypes from './auth.types';

import { IAuth } from '../../types/auth';
import ProfileActionTypes from '../profile/profile.types';
import microsoftTeams from '../../services/msTeams';
import { setCurrentFlow, setCurrentForm } from '../authForm/authForm.slice';

const initState = {
  // user object
  auth: null as IAuth,

  // statuses
  isInitialAuthInProgress: true,
  isAuthenticated: false,
  isWrongVerificationCode: false,
  isPhoneMissing: false,
  authError: false,
  errorCodes: [],
  authSuite: {
    auth: {
      isLoading: false,
    },
    social: {
      isLoading: false,
      error: false,
    },
    userProfile: {
      isLoading: false,
      errors: [],
    },
    // forgot password screen
    passwordRecovery: {
      isLoading: false,
      isSuccess: false,
      error: false,
    },
    // reset password screen
    newRecoverPassword: {
      isLoading: false,
      errors: [],
    },
  },

  // microsoft
  microsoftTeamsContext: null as null | microsoftTeams.Context,

  // modals
  isRestrictedContentForAnotherOrgModalShown: false,
  isRestrictedContentModalShown: false,
  isRestrictedAllowJoinModalShown: false,
  isNothingToDisplayModalShown: false,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case AuthActionTypes.SET_IS_INITIAL_AUTH_IN_PROGRESS:
      return {
        ...state,
        isInitialAuthInProgress: action.payload,
      };

    case AuthActionTypes.LOGIN_REQUEST:
    case AuthActionTypes.LOGIN_WITH_PHONE_REQUEST:
    case AuthActionTypes.SET_TOKEN_AND_GET_USER:
      return {
        ...state,
        authSuite: {
          ...state.authSuite,
          auth: {
            isLoading: true,
          },
        },
      };
    case ProfileActionTypes.UPDATE_ORGANIZATION_SUCCESS: {
      const organizations = [...state.auth.organizations];
      return {
        ...state,
        auth: {
          ...state.auth,
          ...action.payload.user,
          organizations: organizations.map((userOrg) => {
            const newUserOrg = action.payload.userOrg;
            if (userOrg.id === newUserOrg.organization) {
              return {
                ...userOrg,
                employee_id: newUserOrg.employee_id,
                israeli_id: newUserOrg.israeli_id,
                is_profile_complete: true,
              };
            }
            return userOrg;
          }),
        },
      };
    }
    case AuthActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        authError: false,
        isWrongVerificationCode: false,
        authSuite: {
          ...state.authSuite,
          auth: {
            isLoading: false,
          },
          social: {
            isLoading: false,
            error: null,
          },
        },
      };
    case AuthActionTypes.SET_IS_NOT_LOADING:
      return {
        ...state,
        authSuite: {
          ...state.authSuite,
          auth: {
            isLoading: false,
          },
        },
      };
    case AuthActionTypes.SET_IS_PHONE_MISSING:
      return {
        ...state,
        isPhoneMissing: action.payload,
      };

    case AuthActionTypes.SET_WRONG_VERIFICATION_CODE:
      return {
        ...state,
        isWrongVerificationCode: action.payload,
        authError: false,
      };
    case AuthActionTypes.LOGIN_ERROR:
      return {
        ...state,
        authError: true,
        authSuite: {
          ...state.authSuite,
          auth: {
            isLoading: false,
          },
        },
      };
    case AuthActionTypes.SIGNUP_WITH_EMAIL_REQUEST:
    case AuthActionTypes.SIGNUP_WITH_PHONE_REQUEST:
      return {
        ...state,
        authSuite: {
          ...state.authSuite,
          auth: {
            isLoading: true,
          },
        },
      };

    case AuthActionTypes.SIGNUP_SUCCESS:
      return {
        ...state,
        authSuite: {
          ...state.authSuite,
          auth: {
            isLoading: false,
          },
        },
      };
    case AuthActionTypes.SET_IS_SOCIAL_LOGIN:
      return {
        ...state,
        authSuite: {
          ...state.authSuite,
          social: {
            isLoading: action.payload,
            error: false,
          },
        },
      };
    case AuthActionTypes.SOCIAL_LOGIN_REQUEST_END:
      return {
        ...state,
        authSuite: {
          ...state.authSuite,
          social: {
            error: action.payload.error,
            isLoading: false,
          },
        },
      };
    case AuthActionTypes.SET_AUTH:
      return {
        ...state,
        auth: action.payload?.user || null,
      };
    case AuthActionTypes.SET_MICROSOFT_CONTEXT:
      return {
        ...state,
        microsoftTeamsContext: action.payload,
      };

    case AuthActionTypes.PASSWORD_RECOVERY_REQUEST:
      return {
        ...state,
        authSuite: {
          ...state.authSuite,
          passwordRecovery: {
            isLoading: true,
            isSuccess: false,
            error: false,
          },
        },
      };

    case AuthActionTypes.PASSWORD_RECOVERY_SUCCESS:
      return {
        ...state,
        authSuite: {
          ...state.authSuite,
          passwordRecovery: {
            isLoading: false,
            isSuccess: true,
            error: false,
          },
        },
      };

    case AuthActionTypes.PASSWORD_RECOVERY_ERROR:
      return {
        ...state,
        authSuite: {
          ...state.authSuite,
          passwordRecovery: {
            isLoading: false,
            isSuccess: false,
            error: true,
          },
        },
      };

    case AuthActionTypes.SET_NEW_RECOVERY_PASSWORD_REQUEST:
      return {
        ...state,
        authSuite: {
          ...state.authSuite,
          newRecoverPassword: {
            isLoading: true,
            errors: [],
          },
        },
      };

    case AuthActionTypes.SET_NEW_RECOVERY_PASSWORD_ERROR:
      return {
        ...state,
        authSuite: {
          ...state.authSuite,
          newRecoverPassword: {
            isLoading: false,
            errors: action.payload,
          },
        },
      };
    case AuthActionTypes.SET_IS_RESTRICTED_CONTENT_FOR_ANOTHER_ORG_MODAL_SHOWN:
      return {
        ...state,
        isRestrictedContentForAnotherOrgModalShown: action.payload,
      };
    case AuthActionTypes.SET_IS_RESTRICTED_CONTENT_MODAL_SHOWN:
      return {
        ...state,
        isRestrictedContentModalShown: action.payload,
      };
    case AuthActionTypes.SET_IS_RETRICTED_ALLOW_JOIN_MODAL_SHOWN:
      return {
        ...state,
        isRestrictedAllowJoinModalShown: action.payload,
      };
    case AuthActionTypes.SET_IS_NOTHING_TO_DISPLAY_MODAL_SHOWN:
      return {
        ...state,
        isNothingToDisplayModalShown: action.payload,
      };

    case AuthActionTypes.CLEAR_USER_PROFILE_ERRORS_BY_TYPE:
      return (() => {
        const newErrors = state.authSuite.userProfile.errors.filter((errorCode) => !action.payload.includes(errorCode));

        if (newErrors.length === state.authSuite.userProfile.errors.length) {
          return state;
        }

        return {
          ...state,
          authSuite: {
            ...state.authSuite,
            userProfile: {
              ...state.authSuite.userProfile,
              errors: newErrors,
            },
          },
        };
      })();

    case AuthActionTypes.REFRESH_USER_SUCCESS: {
      return {
        ...state,
        auth: action.payload.user,
        authSuite: {
          ...state.authSuite,
          userProfile: {
            isLoading: false,
            errors: [],
          },
        },
      };
    }

    case AuthActionTypes.REFRESH_USER_FAILURE: {
      return {
        ...state,
        authSuite: {
          ...state.authSuite,
          userProfile: {
            ...state.authSuite.userProfile,
            isLoading: false,
          },
        },
      };
    }

    case AuthActionTypes.SET_REFRESH_USER_ERRORS: {
      return {
        ...state,
        authSuite: {
          ...state.authSuite,
          userProfile: {
            ...state.authSuite.userProfile,
            errors: action.payload || [],
          },
        },
      };
    }

    case AuthActionTypes.FINALIZE_USER_PROFILE:
      return {
        ...state,
        authError: false,
        authSuite: {
          ...state.authSuite,
          userProfile: {
            isLoading: true,
            errors: [],
          },
        },
      };
    case AuthActionTypes.RESET_AUTH_ERROR:
      return {
        ...state,
        authError: false,
      };

    case setCurrentFlow.type:
    case setCurrentForm.type:
    case AuthActionTypes.RESET_AUTH_ERRORS:
      return {
        ...state,
        isWrongVerificationCode: false,
        isPhoneMissing: false,
        authError: false,
        errorCodes: [],
        authSuite: {
          ...state.authSuite,
          social: {
            ...state.authSuite.social,
            error: false,
          },
          userProfile: {
            ...state.authSuite.userProfile,
            errors: [],
          },
          passwordRecovery: {
            ...state.authSuite.passwordRecovery,
            error: false,
          },
          newRecoverPassword: {
            ...state.authSuite.newRecoverPassword,
            errors: [],
          },
        },
      };

    case AuthActionTypes.SET_AUTH_ERROR_CODES:
      return {
        ...state,
        errorCodes: action.payload,
        authSuite: {
          ...state.authSuite,
          auth: {
            isLoading: false,
          },
        },
      };

    default:
      return state;
  }
};

export default authReducer;

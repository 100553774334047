import SearchActionTypes from './search.types';

import { ISearch } from '../../types/search';

const initialState: ISearch = {
  playlist: '',
  bite: '',
};

const searchReducer = (state = initialState, action: any): typeof initialState => {
  switch (action.type) {
    case SearchActionTypes.UPDATE_SEARCH:
      return {
        ...state,
        [action.payload.type]: action.payload.value,
      };

    case SearchActionTypes.RESET_SEARCH:
      return initialState;

    default:
      return state;
  }
};

export default searchReducer;

import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SelectLanguage from './SelectLanguage';
import SideMenuOrgs from './SideMenuOrgs';

import { ReactComponent as CloseSVG } from '../../../assets/icons/close.svg';
import LogoutButton from '../../../components/shared/LogoutButton';
import { getIsRtl } from '../../../locale/i18n';
import gtmTrack from '../../../services/googleTagManager/track';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const SideMenu: FC<IProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const handleClick = () => {
    onClose();
    gtmTrack('home', {});
  };
  return (
    <>
      <S.Menu isOpen={isOpen} data-cy='sideMenu'>
        <S.Top>
          <S.CloseSVG onClick={onClose} id='btnCloseSideMenu' data-cy='closeSideMenu'>
            X
          </S.CloseSVG>
        </S.Top>
        <SelectLanguage onSelect={onClose} />

        <S.Main>
          <SideMenuOrgs onSelect={onClose} />
          <S.Link onClick={handleClick} to='/bites' data-cy='sideMenuHome'>
            {t('sideMenu.home')}
          </S.Link>
          <S.Link onClick={onClose} to='/join-organization' data-cy='sideMenuJoinOrg'>
            {t('sideMenu.joinOrganization')}
          </S.Link>
        </S.Main>

        <S.LogoutButton />
      </S.Menu>
    </>
  );
};

const S = {
  Menu: styled.aside<{ isOpen: boolean }>`
    z-index: 10;
    position: absolute;
    top: 0;
    height: 100%;
    left: 0%;
    width: 70%;
    padding: 13px;
    display: flex;
    flex-direction: column;
    ${({ theme, isOpen }) => css`
      background-color: ${theme.colors.white};
      transform: translateX(${isOpen ? '0%' : '-100%'});
      ${() =>
        getIsRtl() &&
        `
			direction: rtl;
			transform: translateX(${isOpen ? '0%' : '100%'});
			left: auto;
			right: 0%;
		`};
    `}

    transition: all 0.5s;
  `,

  Top: styled.div`
    display: flex;
    flex-direction: row-reverse;
  `,

  Main: styled.div`
    flex: 1;
  `,

  Link: styled(Link)`
    display: block;
    margin-bottom: 10px;
  `,

  Bottom: styled.div``,

  LogoutButton: styled(LogoutButton)`
    background-color: transparent;
    display: inline;
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.darkPrimary};
  `,

  CloseSVG: styled(CloseSVG)`
    width: 22px;
    height: 22px;
    fill: ${({ theme }) => theme.colors.primaryBlue};
    cursor: pointer;
  `,

  ToggleButton: styled.button``,
};

export default SideMenu;

import styled from 'styled-components';
import React, { FC, memo, useCallback, useRef, useState } from 'react';
import Header from './Header';
import { useTranslation } from 'react-i18next';
import { clearUserProfileErrorsByType, finalizeUserProfile, resetAuthError } from '../../../store/auth/auth.actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  emailFormSelector,
  firstNameSelector,
  lastNameSelector,
  phoneFormSelector,
} from '../../../store/authForm/authForm.selectors';
import {
  isAuthErrorSelector,
  isProfileLoadingSelector,
  profileErrorsSelector,
} from '../../../store/auth/auth.selectors';
import { setEmail, setFirstName, setLastName } from '../../../store/authForm/authForm.slice';
import PhoneInputWithIcon from '../../../components/shared/Inputs/PhoneInput';
import FormTextInput from '../../../components/shared/Inputs/FormTextInput';
import Button from '../../../components/shared/Buttons';
import { v4 as uuid } from 'uuid';
import { EResponseErrors, getIsEmailValid } from '../../../utils/auth';
import { log } from '../../../store/tracking/tracking.slice';
import { IFinalizeProfileAction } from '../../../store/auth/auth.types';
import AuthError from './AuthError';
import { organizationBrandingSelector } from '../../../store/organization/organization.selectors';

const PHONE_ERRORS = [EResponseErrors.phone_conflict, EResponseErrors.bad_phone];
const EMAIL_ERRORS = [EResponseErrors.email_conflict, EResponseErrors.bad_email];

const UserInfoForm: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const email = useSelector(emailFormSelector);
  const initialPhone = useSelector(phoneFormSelector);
  const firstName = useSelector(firstNameSelector);
  const lastName = useSelector(lastNameSelector);
  const isLoading = useSelector(isProfileLoadingSelector);
  const responseErrors = useSelector(profileErrorsSelector);
  const isAuthError = useSelector(isAuthErrorSelector);

  const organizationBranding = useSelector(organizationBrandingSelector);

  const [phone, setPhone] = useState(initialPhone);

  const hasEmailRef = useRef(!!email);
  const hasNameRef = useRef(firstName && lastName);

  const [emailError, setEmailError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [firstNameError, setFirstNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  const phoneDisplayError = responseErrors.includes(EResponseErrors.phone_conflict)
    ? t('authForm.phoneTaken')
    : responseErrors.includes(EResponseErrors.bad_phone)
    ? t('authForm.phoneNumberForm.phoneNotValid')
    : phoneError;

  const emailDisplayError = responseErrors.includes(EResponseErrors.email_conflict)
    ? t('authForm.emailTaken')
    : responseErrors.includes(EResponseErrors.bad_email)
    ? t('authForm.emailIsNotValid')
    : emailError;

  const handleFirstNameChange = useCallback(
    (text) => {
      dispatch(setFirstName(text));
      dispatch(resetAuthError());
      setFirstNameError(null);
    },
    [dispatch],
  );

  const handleLastNameChange = useCallback(
    (text) => {
      dispatch(setLastName(text));
      dispatch(resetAuthError());
      setLastNameError(null);
    },
    [dispatch],
  );

  const handleEmailChange = useCallback(
    (text) => {
      setEmailError(null);
      dispatch(resetAuthError());
      dispatch(clearUserProfileErrorsByType(EMAIL_ERRORS));
      dispatch(setEmail(text));
    },
    [dispatch],
  );

  const handlePhoneChange = useCallback(
    (text) => {
      setPhoneError(null);
      dispatch(resetAuthError());
      dispatch(clearUserProfileErrorsByType(PHONE_ERRORS));
      setPhone(text);
    },
    [dispatch],
  );

  const handleContinue = useCallback(() => {
    if (isLoading) {
      return;
    }

    const isFirstNameError = !hasNameRef.current && !firstName;
    if (isFirstNameError) {
      setFirstNameError(t('authForm.required'));
    }

    const isLastNameError = !hasNameRef.current && !lastName;
    if (isLastNameError) {
      setLastNameError(t('authForm.required'));
    }

    const isEmailValid = getIsEmailValid(email);
    const isEmailError = !hasEmailRef.current && email && !isEmailValid;

    if (isEmailError) {
      setEmailError(t('authForm.emailIsNotValid'));
    }

    const isPhoneError = !initialPhone && !isPhoneValid && phone.length > 5;
    if (isPhoneError) {
      setPhoneError(t('authForm.errorInvalidNumber'));
    }

    if (isEmailError || isPhoneError || isLastNameError || isFirstNameError) {
      return;
    }

    const processId = uuid();
    dispatch(
      log({
        event: 'UserInfoForm.handleContinue',
        processId: processId,
        data: {
          email,
          firstName,
          lastName,
          phone,
        },
      }),
    );

    const payload: IFinalizeProfileAction = { processId };

    if (!hasNameRef.current) {
      payload.firstName = firstName;
      payload.lastName = lastName;
    }

    if (!hasEmailRef.current) {
      payload.email = email;
    }

    if (!initialPhone) {
      payload.phone = phone;
    }

    dispatch(finalizeUserProfile(payload));
  }, [isLoading, firstName, initialPhone, lastName, email, isPhoneValid, phone, dispatch, t]);

  return (
    <S.Container>
      <Header title={t('authForm.userInfoForm.title')} texts={organizationBranding.user_info_texts || []} />
      <AuthError isVisible={isAuthError && !emailDisplayError && !phoneDisplayError}>
        {t('common.somethingWentWrong')}
      </AuthError>
      <S.Form>
        {!hasNameRef.current && (
          <>
            <S.TextInput
              style={S.TextInputStyles}
              label={t('authForm.userInfoForm.firstName')}
              value={firstName}
              onChange={handleFirstNameChange}
              onEnterPress={handleContinue}
              error={firstNameError}
            />
            <S.TextInput
              style={S.TextInputStyles}
              label={t('authForm.userInfoForm.lastName')}
              value={lastName}
              onChange={handleLastNameChange}
              onEnterPress={handleContinue}
              error={lastNameError}
            />
          </>
        )}

        {!hasEmailRef.current && (
          <S.TextInput
            style={S.TextInputStyles}
            label={t('authForm.userInfoForm.email')}
            value={email}
            onChange={handleEmailChange}
            error={emailDisplayError}
            onEnterPress={handleContinue}
          />
        )}

        {!initialPhone && (
          <PhoneInputWithIcon
            onValidationChange={setIsPhoneValid}
            value={phone}
            onChange={handlePhoneChange}
            style={S.TextInputStyles}
            label={t('authForm.userInfoForm.phoneNumber')}
            error={phoneDisplayError}
            onEnterPress={handleContinue}
          />
        )}

        <S.ContinueButton isLoading={isLoading} onClick={handleContinue}>
          {t('common.Continue')}
        </S.ContinueButton>
      </S.Form>
    </S.Container>
  );
};

const S = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    align-items: center;
  `,
  Form: styled.div`
    margin-top: 36px;
    width: 298px;
  `,
  BackButtonContainer: styled.div`
    height: 40px;
    width: 40px;
    margin: 11px 0 0 14px;
  `,
  Header: styled.div`
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: -10px;
  `,
  TextInput: styled(FormTextInput)`
    margin-bottom: 22px;
  `,
  TextInputStyles: {
    marginBottom: 18,
  },
  ContinueButton: styled(Button)`
    min-height: 50px;
    min-width: 298px;
    margin-top: 2px;
    margin-bottom: 36px;
  `,
};

export default memo(UserInfoForm);

import { createAction, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { IConfig, IContentLocale, IGeolocation, IInitialState } from './appActivity.types';

const appActivity = 'appActivity';

export const initialState: IInitialState = {
  config: {
    defaultBrandShareImage: null,
  },
  geolocation: null,
  contentLocales: {
    data: null,
    isLoading: false,
    error: null,
  },
  showSomethingWentWrong: null,
  translationsAreInitialized: false,
};

export const runInit = createAction(`${appActivity}/runInit`);
export const requestGeolocation = createAction(`${appActivity}/requestGeolocation`);

const appActivitySlice = createSlice({
  name: appActivity,
  initialState,
  reducers: {
    setConfig: (state, { payload }: PayloadAction<IConfig>) => {
      state.config = payload;
    },
    setGeolocation: (state, { payload }: PayloadAction<IGeolocation>) => {
      state.geolocation = payload;
    },
    setShowSomethingWentWrong: (state, { payload }: PayloadAction<IInitialState['showSomethingWentWrong']>) => {
      state.showSomethingWentWrong = payload;
    },
    setTranslationsAreInitialized: (state) => {
      state.translationsAreInitialized = true;
    },
    loadContentLocales: (state) => {
      state.contentLocales.isLoading = !state.contentLocales.data;
      state.contentLocales.error = null;
    },
    setContentLocales: (state, { payload }: PayloadAction<IContentLocale[]>) => {
      state.contentLocales.data = payload;
      state.contentLocales.isLoading = false;
      state.contentLocales.error = null;
    },
    setContentLocalesIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.contentLocales.isLoading = payload;
    },
    setContentLocalesError: (state, { payload }: PayloadAction<any>) => {
      state.contentLocales.isLoading = false;
      state.contentLocales.error = payload;
    },
  },
});

export const {
  setConfig,
  setGeolocation,
  loadContentLocales,
  setContentLocales,
  setContentLocalesIsLoading,
  setContentLocalesError,
  setShowSomethingWentWrong,
  setTranslationsAreInitialized,
} = appActivitySlice.actions;

export default appActivitySlice.reducer;

import React, { FC, memo } from 'react';

import ChoiceItem, { IProps as IChoiceItemProps } from './ChoiceItem';

interface IProps extends Partial<IChoiceItemProps> {
  choices: any[];
  withoutHightlight?: boolean;
  questionColor?: string;
  isMultiChoiceSurvey: boolean;
}

const ChoiceList: FC<IProps> = ({ choices, withoutHightlight, ...choiceItemProps }) => {
  return (
    <div>
      {choices?.map((choice, idx) => (
        <ChoiceItem
          withoutHightlight={withoutHightlight}
          key={choice.id}
          choice={choice}
          idx={idx}
          {...choiceItemProps}
        />
      ))}
    </div>
  );
};

export default memo(ChoiceList);

/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useWindowSize from './useWindowSize';

import { deviceSizes } from '../style/themes/defaultTheme';

export default (list: any[]) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const { width } = useWindowSize();

  const isMobileWidth = width < deviceSizes.mobileL;

  const numberOfItemsToPreview = isMobileWidth && true ? 2 : 4;

  const dynamicList = isExpanded ? list : list.slice(0, numberOfItemsToPreview);

  const toggleIsExpanded = () => {
    setIsExpanded((prevState) => !prevState);
  };

  const toggleText = useMemo(() => {
    if (dynamicList.length < numberOfItemsToPreview) {
      return '';
    }
    return isExpanded ? t('common.See Less') : t('common.See All');
  }, [isExpanded, dynamicList.length, numberOfItemsToPreview]);

  return { list: dynamicList, toggleIsExpanded, toggleText };
};

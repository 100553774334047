import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';

interface IProps {
  src: string;
  style?: any;
}

const AsyncImage: FC<IProps> = ({ src, style = {} }) => {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <>
      {isLoading && <S.PulsingBackground style={style} />}
      <S.Img
        src={src}
        onLoad={() => setIsLoading(false)}
        style={{ ...style, display: isLoading ? 'none' : 'block' }}
        id='playlistImageTiles'
      />
    </>
  );
};

const S = {
  Img: styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    ${({ theme: { colors } }) => css`
      background-color: ${colors.lightGray1};
    `}
  `,
  PulsingBackground: styled.div`
    width: 100%;
    height: 100%;
    animation-name: color;
    animation-duration: 2s;
    animation-iteration-count: infinite;

    ${({ theme: { colors } }) => css`
      background-color: ${colors.lightGray1};
      @keyframes color {
        0% {
          background-color: ${colors.lightGray2};
        }
        50% {
          background-color: ${colors.lightGray3};
        }
        100% {
          background-color: ${colors.lightGray1};
        }
      }
    `}
  `,
};

export default AsyncImage;

import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, CustomModal } from '../shared';

import { showSomethingWentWrongSelector } from '../../store/appActivity/appActivity.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { sessionId } from '../../store/tracking/tracking.constants';
import { log } from '../../store/tracking/tracking.slice';
import { EShowSomethingWentWrongError } from '../../store/appActivity/appActivity.types';

const modalStyle = {
  width: 'calc(100% - 80px)',
  maxWidth: '500px',
  padding: '30px',
};

const buttonStyle = {
  marginTop: '30px',
};

interface IProps {
  isOpen: boolean;
}

const messageByType = {
  [EShowSomethingWentWrongError.DEFAULT]: 'default',
  [EShowSomethingWentWrongError.AUTH]: 'auth',
  [EShowSomethingWentWrongError.PASSWORD_RECOVERY]: 'passwordRecovery',
  [EShowSomethingWentWrongError.SETTING_PROFILE]: 'settingProfile',
};

const SomethingWentWrongModal: FC<IProps> = ({ isOpen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const showSomethingWentWrong = useSelector(showSomethingWentWrongSelector);

  const handleOnClick = useCallback(() => {
    dispatch(
      log({
        event: 'SomethingWentWrongModal.handleOnClick: reload',
      }),
    );

    window.location.reload();
  }, [dispatch]);

  const handleOpenIntercom = useCallback(() => {
    dispatch(
      log({
        event: 'SomethingWentWrongModal.handleOpenIntercom',
        data: {
          type: showSomethingWentWrong?.type,
        },
      }),
    );

    window.Intercom('boot');
    window.Intercom(
      'showNewMessage',
      `${t(`components.somethingWentWrongModal.${messageByType[showSomethingWentWrong?.type]}`)}\n ${sessionId}`,
    );
  }, [dispatch, showSomethingWentWrong?.type, t]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    dispatch(
      log({
        event: 'SomethingWentWrongModal: show modal',
      }),
    );
  }, [dispatch, isOpen]);

  return (
    <CustomModal isModalOpen={isOpen} containerStyles={modalStyle}>
      <S.Text>{t('components.somethingWentWrongModal.title')}</S.Text>
      <S.ControlsContainer>
        <Button style={buttonStyle} inverted onClick={handleOpenIntercom}>
          {t('components.somethingWentWrongModal.contactSupport')}
        </Button>
        <Button style={buttonStyle} onClick={handleOnClick}>
          {t('components.somethingWentWrongModal.button')}
        </Button>
      </S.ControlsContainer>
    </CustomModal>
  );
};

const S = {
  Text: styled.p`
    font-size: 20px;
  `,
  ControlsContainer: styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 16px;
  `,
};

export default SomethingWentWrongModal;

import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import BiteApi, { API_BASE_URL } from '../';

export const query = (queryParams: string) => BiteApi.get(`/bite_shares/${queryParams}`);
export const queryFeed = (organizationId: string, queryParams: string) =>
  BiteApi.get(`/content/organization/${organizationId}/feed/bites/${queryParams}`);
export const queryPublicFeed = (queryParams: string) => BiteApi.get(`/public/bite_shares/${queryParams}`);

export const getById = async (
  id: number,
  { extend }: IFetchBiteOptions = {},
  headers?: { [ket: string]: number | string | boolean },
) => {
  const { data: biteShare } = await BiteApi.get(`/bite_shares/${id}/${extend ? `?extend=${extend.join(',')}` : ''}`, {
    headers,
  });

  return {
    data: {
      ...biteShare,
      bite_sections: biteShare?.extend?.sections,
      enhancements: biteShare?.extend?.enhancements,
      subtitles: biteShare?.extend?.subtitles,
    },
  };
};

interface ICommentData {
  associated_comment: null;
  comment: string;
  object_id: number | string;
  media?: number | string;
  type: string;
}

export type IFetchBiteOptions = {
  extend?: ['enhancements', 'subtitles', 'sections'];
};

export const addComment = ({
  id,
  commentText,
  fileID,
  type,
}: {
  id: number;
  commentText: string;
  fileID: number | string;
  type: string;
}) => {
  const data: ICommentData = {
    object_id: id,
    comment: encodeURIComponent(commentText),
    associated_comment: null,
    type,
  };

  if (fileID) {
    data.media = fileID;
  }

  return BiteApi.post('/comments/', data);
};

export const deleteComment = ({ id }: { id: number }) => {
  return BiteApi.delete(`/comments/${id}/`);
};

export const uploadImage = (image: any) => {
  const data = new FormData();

  data.append('file_type', 'image');
  data.append('file', image, `${uuidv4()}.png`);

  return BiteApi.post('/media/', data);
};

export const getCommentSuggestion = (taskId: string) => {
  return BiteApi.get(`/suggestions/comment/?task_id=${taskId}`);
};

export const defineSubtitlesLocale = (taskId: number) => {
  return axios.post(`${API_BASE_URL}/api/common_services/content/subtitles/define_locale`, {
    taskId,
  });
};

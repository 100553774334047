import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { IBitePreviewProps } from '../../../types/bite';
import useDataFormatter from '../../../hooks/useDateFormatter';

interface IProps {
  bite: IBitePreviewProps;
  onClick: (biteShareId: number, subject: string) => any;
}

const BiteItem: FC<IProps> = ({ bite, onClick }) => {
  const { subject, cover_url, created_at } = bite;
  const { formatDate } = useDataFormatter();
  return (
    <S.Container onClick={() => onClick?.(bite.id, subject)}>
      <S.CoverImage coverImage={cover_url} />
      <S.MiddleContainer>
        <S.Date>{formatDate(created_at)}</S.Date>
        <S.Subject>{bite.subject}</S.Subject>
      </S.MiddleContainer>
    </S.Container>
  );
};

const S = {
  Container: styled.div`
    cursor: pointer;
    padding: 12px;
    border: 1px solid ${({ theme }) => theme.msColors.lightBorderColor2};

    display: flex;
    justify-content: space-between;
  `,

  CoverImage: styled.div<{ coverImage: string }>`
    width: 70px;
    height: 70px;
    margin-right: 12px;

    ${({ coverImage }) => css`
      background-image: url(${coverImage});
      background-size: cover;
      background-color: #ccc;
    `}
  `,

  MiddleContainer: styled.div`
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
  `,

  Subject: styled.p`
    color: ${({ theme }) => theme.msColors.primaryTextcolor};
    font-size: 18px;
    font-weight: 900;
  `,

  Date: styled.p`
    color: ${({ theme }) => theme.msColors.lightTextColor2};
    font-size: 14px;
  `,
};

export default BiteItem;

import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import styled, { css } from 'styled-components';

import VideoModal from './VideoModal';

import { ReactComponent as PlayIcon } from '../../assets/icons/triangle.svg';

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: any;
  media: any;
  coverUrl: string;
  onPlay: (evt) => void;
}

function MediaViewer(props: Props) {
  const { media, isModalOpen, setIsModalOpen, coverUrl, onPlay } = props;
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  if (!media) {
    return null;
  }

  return (
    <div style={{ height: '100%', paddingBottom: '30px' }}>
      <S.MediaContainer
        onClick={() => setIsLightboxOpen(true)}
        coverImage={media.file_type === 'image' ? media.image_url : coverUrl}
        id='questionMediaClick'
        data-cy='mediaViewerContainer'
      >
        {(media.file_type === 'video' || media.file_type === 'youtube') && (
          <S.PlayButton onClick={onPlay} id='questionMediaPlay'>
            <S.PlayIcon />
          </S.PlayButton>
        )}
      </S.MediaContainer>
      {isLightboxOpen && (
        <Lightbox
          mainSrc={media.file_type === 'image' ? media.image_url : coverUrl}
          onCloseRequest={() => {
            setIsLightboxOpen(false);
          }}
        />
      )}
      {isModalOpen && (
        <VideoModal
          closeModal={() => setIsModalOpen(false)}
          media_url={media.media_url}
          file_type={media.file_type}
          videoEndsAt={media.video_ends_at}
          videoStartsAt={media.video_start_at}
          stayOnCurrentSection
          section='question'
        />
      )}
    </div>
  );
}

const S = {
  MediaContainer: styled.div<{ coverImage: string }>`
    position: relative;
    width: 100%;
    min-height: 300px;
    border-radius: 12px;
    padding: 30px 15px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${({ coverImage }) => css`
      background: url(${coverImage}) rgba(0, 0, 0, 0.2);
      background-size: cover;
      background-blend-mode: multiply;
    `};
  `,
  PlayButton: styled.div`
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    curosor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ theme }) => css`
      border: 2px solid ${theme.colors.white};
    `};
  `,
  PlayIcon: styled(PlayIcon)`
    width: 20px;
    transform: translateX(10%);
    transform-origin: left;
    ${({ theme }) => css`
      fill: ${theme.colors.white};
    `};
  `,
};

export default MediaViewer;

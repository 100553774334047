/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { removeAmpersands } from '../utils/botAuthRedirection';
import localStorage from '../utils/localStorage';

const redirectURLs = {
  p: '/playlists',
  f: '/',
  b: '/bites',
  j: '/join-organization',
};

const useCheckForRedirections = () => {
  const location = useLocation();
  const redirectUriAfterAuth = removeAmpersands(location.search);
  const query = new URLSearchParams(redirectUriAfterAuth);
  const redirectUriInsideSearch = query.get('redirect_uri');

  useEffect(() => {
    if (redirectUriInsideSearch) {
      localStorage.setItem('botRedirectUrl', redirectUriAfterAuth);
    }
  }, []);
};

export default useCheckForRedirections;

export const getRedirectURLs = (param) => redirectURLs[param] || '/';

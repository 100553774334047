import TagManager from 'react-gtm-module';

import { isProd, isStaging } from '../utils/env';
import store from '../store';

const GTM_ID = 'GTM-TKKVHDM';
const AUTH_STAGING = '5YOT0WeFdAsR0P8oPcC97A';
const AUTH_PROD = 'N97j8nF5pjL-ngaNfap_Mw';
const AUTH_DEV = 'NFECe9DHhzjFnIwuwZktHg';
const PREVIEW_STAGING = 'env-25';
const PREVIEW_PROD = 'env-1';
const PREVIEW_DEV = 'env-30';

let firstLoad = true;
let biteShareId: number;
let prevBiteShareId: number;

store.subscribe(() => {
  const state = store.getState();

  if (firstLoad) {
    firstLoad = false;
    TagManager.initialize({
      gtmId: GTM_ID,
      auth: getAuth(),
      preview: getPreview(),
      dataLayer: {
        'gtm.userId': state.authReducer.auth?.id,
      },
    });
  }

  prevBiteShareId = biteShareId;
  biteShareId = state.biteShareUserReducer.bite_share;

  if (biteShareId !== prevBiteShareId) {
    firstLoad = false;
    TagManager.initialize({
      gtmId: GTM_ID,
      auth: getAuth(),
      preview: getPreview(),
      dataLayer: {
        event: 'BiteShareId Changed',
        'gtm.userId': state.authReducer.auth?.id,
        'gtm.bitesShareID': state.biteShareUserReducer.bite_share,
      },
    });
  }
});

function getAuth() {
  if (isStaging()) {
    return AUTH_STAGING;
  }
  if (isProd()) {
    return AUTH_PROD;
  }
  return AUTH_DEV;
}

function getPreview() {
  if (isStaging()) {
    return PREVIEW_STAGING;
  }
  if (isProd()) {
    return PREVIEW_PROD;
  }
  return PREVIEW_DEV;
}

interface GtmVideoProps {
  dataLayer: {
    event: string;
    'gtm.videoCurrentTime'?: number;
    'gtm.videoDuration'?: number;
    'gtm.videoPercent'?: number;
    'gtm.videoProvider'?: string;
    'gtm.videoStatus'?: string;
    'gtm.videoTitle'?: string;
    'gtm.videoUrl'?: string;
    'gtm.videoVolume'?: string;
    'gtm.bitesShareID'?: string;
  };
}

export class GtmVideo {
  private data: GtmVideoProps;

  constructor(
    private videoStatus: string,
    private currentTime: number,
    private duration: number,
    private url: string,
    private provider: string,
    private moreDataKey?: string,
    private moreDataValue?: any,
  ) {
    this.data = {
      dataLayer: {
        event: this.videoStatus,
        'gtm.videoCurrentTime': this.currentTime,
        'gtm.videoDuration': this.duration,
        'gtm.videoUrl': this.url,
        'gtm.videoPercent': +((this.currentTime / this.duration) * 100).toFixed(2),
        'gtm.videoStatus': this.videoStatus,
        'gtm.videoProvider': this.provider,
        'gtm.bitesShareID': store.getState().biteShareUserReducer.bite_share,
      },
    };
    if (this.moreDataKey && this.moreDataValue) {
      this.data.dataLayer[this.moreDataKey] = this.moreDataValue;
    }
  }

  public sendData() {
    TagManager.dataLayer(this.data);
  }
}

export const gtmClick = (id) =>
  TagManager.dataLayer({
    dataLayer: {
      event: `${id}_click`,
      'gtm.bitesShareID': store.getState().biteShareUserReducer.bite_share,
    },
  });

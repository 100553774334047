import React, { FC, memo } from 'react';
import styled, { css } from 'styled-components';

import { ScreenHeader } from '../../../components/shared';
import { MainContentWrapper } from '../../../components/layout';

const TermsAndConditions: FC = () => {
  return (
    <S.Container>
      <ScreenHeader>
        TERMS & CONDITIONS
        <S.LastUpdated>Last updated: 26/05/20</S.LastUpdated>
      </ScreenHeader>
      <MainContentWrapper>
        <S.Divider></S.Divider>
        <S.TermsAndConditionContainer>
          <S.Heading style={{ marginBottom: '30px' }}>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY</S.Heading>

          <S.Heading>AGREEMENT TO TERMS</S.Heading>
          <S.Paragraph>
            These Terms and Conditions constitute a legally binding agreement made between you, whether personally or on
            behalf of an entity (“you”) and [business entity name] (“we,” “us” or “our”), concerning your access to and
            use of the [website name.com] website as well as any other media form, media channel, mobile website or
            mobile application related, linked, or otherwise connected thereto (collectively, the “Site”). You agree
            that by accessing the Site, you have read, understood, and agree to be bound by all of these Terms and
            Conditions Use.{' '}
            <S.Heading as='span'>
              IF YOU DO NOT AGREE WITH ALL OF THESE TERMS and CONDITIONS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING
              THE SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY.
            </S.Heading>
          </S.Paragraph>
          <S.Paragraph>
            Supplemental terms and conditions or documents that may be posted on the Site from time to time are hereby
            expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or
            modifications to these Terms and Conditions at any time and for any reason. We will alert you about any
            changes by updating the “Last updated” date of these Terms and Conditions and you waive any right to receive
            specific notice of each such change. It is your responsibility to periodically review these Terms and
            Conditions to stay informed of updates. You will be subject to, and will be deemed to have been made aware
            of and to have accepted, the changes in any revised Terms and Conditions by your continued use of the Site
            after the date such revised Terms are posted.
          </S.Paragraph>
        </S.TermsAndConditionContainer>

        {/* <button style={{ color: '#1253fa', fontSize: '15px' }}>Agree and Continue</button> */}
      </MainContentWrapper>
    </S.Container>
  );
};

const textBaseStyle = css`
  font-size: 16px;
  line-height: 19px;
  ${({ theme }) => css`
    color: ${theme.colors.darkPrimary};
  `};
`;

const S: any = {};

S.Container = styled.div`
  padding-bottom: 20px;
`;

S.LastUpdated = styled.div`
  opacity: 0.8;
  font-size: 16px;
  line-height: 28px;
  ${({ theme }) => css`
    color: ${theme.colors.darkPrimary};
  `};
`;

S.Divider = styled.div`
  margin-bottom: 32px;
  opacity: 0.27;
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.black};
`;

S.TermsAndConditionContainer = styled.div`
  ${textBaseStyle}
`;

S.Heading = styled.h4``;

S.Paragraph = styled.p`
  margin-bottom: 15px;
`;

export default memo(TermsAndConditions);

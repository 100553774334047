import { cloneDeep } from 'lodash';

import biteShareUserActionTypes from './biteShareUser.types';

import biteActionTypes from '../bite/bite.types';

const initState = {
  bite_progress: 'intro',
  id: null,
  user: null,
  video_progress: null,
  choices: [],
  bite_share: null,
  errors: [],
};

const biteShareUserReducer = (state = initState, action) => {
  switch (action.type) {
    case biteActionTypes.FETCH_SELECTED_BITE_SUCCESS: {
      const biteShareUser = action.payload.bite_share_user;
      return biteShareUser;
    }
    case biteShareUserActionTypes.OPEN_ENDED_QUESTION_SUCCESS:
      return {
        ...state,
        choices: state.choices.concat(action.payload),
      };

    case biteShareUserActionTypes.UPDATE_BITE_SHARE_USER_PROGRESS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case biteShareUserActionTypes.OPEN_ENDED_QUESTION_ERROR:
      return {
        ...state,
        errors: action.payload,
      };
    case biteShareUserActionTypes.MULTIPLE_CHOICES_QUATION_SUCCESS:
      return {
        ...state,
        choices: cloneDeep(action.payload.formattedBiteShareChoices),
        type: action.payload.response.type,
        bite_section: action.payload.response.bite_section,
        text: action.payload.response.text,
      };
    case biteShareUserActionTypes.FULLY_WATCHED_BITE_INTRO_VIDEO_SUCCESS: {
      return {
        ...state,
        video_progress: 100,
      };
    }
    default:
      return state;
  }
};

export default biteShareUserReducer;

import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInitialState } from './geofence.types';

const initialState: IInitialState = {
  isContentNotAvailable: false,
  isGeofenceModalOpened: false,
  contentInfo: null,
  geolocation: {
    latitude: null,
    longitude: null,
    accuracy: null,
  },
};

export const startPolling = createAction('geofence/startPolling');

const geofenceSlice = createSlice({
  name: 'geofence',
  initialState,
  reducers: {
    setGeofenceData: (state, { payload }: PayloadAction<IInitialState['geolocation']>) => {
      state.geolocation = payload;
    },
    setGeofenceContentInfo: (state, { payload }: PayloadAction<IInitialState['contentInfo']>) => {
      state.contentInfo = payload;
    },
    setGeofenceModalOpened: (state, { payload }: PayloadAction<IInitialState['isGeofenceModalOpened']>) => {
      state.isGeofenceModalOpened = payload;
    },
    setIsContentNotAvailable: (state, { payload }: PayloadAction<IInitialState['isContentNotAvailable']>) => {
      state.isContentNotAvailable = payload;
    },
    resetGeofence: () => initialState,
  },
});

export const {
  setGeofenceContentInfo,
  setGeofenceData,
  setGeofenceModalOpened,
  setIsContentNotAvailable,
  resetGeofence,
} = geofenceSlice.actions;

export default geofenceSlice.reducer;

import React, { FC } from 'react';
import styled, { css } from 'styled-components';

interface IProps {
  watched?: null | number;
  participants?: null | number;
  success_rate?: null | number;
  comments?: null | number;
}
const StatisticsBar: FC<IProps> = ({ participants, success_rate, comments }) => {
  return (
    <S.Container>
      <S.StatisticContainer>
        <S.FormattedStatisticText>
          {participants || '-'}
          <S.TotalPrticipantsCountText>/{participants || '-'}</S.TotalPrticipantsCountText>
        </S.FormattedStatisticText>
        <S.StatisticName>Participants</S.StatisticName>
      </S.StatisticContainer>

      <S.StatisticContainer>
        <S.FormattedStatisticText>{success_rate ? success_rate + '%' : '-'}</S.FormattedStatisticText>
        <S.StatisticName>Success rate</S.StatisticName>
      </S.StatisticContainer>

      <S.StatisticContainer>
        <S.FormattedStatisticText>{comments || '-'}</S.FormattedStatisticText>
        <S.StatisticName>Comments</S.StatisticName>
      </S.StatisticContainer>
    </S.Container>
  );
};

const S = {
  Container: styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 11px 22px;
    ${() => css`
      background-color: rgba(0, 0, 0, 0.03);
    `}
  `,
  StatisticContainer: styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  FormattedStatisticText: styled.p`
    ${({ theme }) => css`
      font-size: 18px;
      color: ${theme.colors.primaryBlue};
    `}
  `,
  StatisticName: styled.p`
    opacity: 0.4;
    ${({ theme }) => css`
      font-size: 14px;
      color: ${theme.colors.darkPrimary};
    `}
  `,
  TotalPrticipantsCountText: styled.span`
    ${({ theme }) => css`
      font-size: 14px;
      color: ${theme.colors.lightGray6};
    `}
  `,
};
export default StatisticsBar;

import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import useCustomTranslation from '../../../hooks/useCustomTranslation';
import { Button } from '../../../components/shared';
import BiteLogo from '../../../assets/images/bite-logo.png';
import MSLogo from '../../../assets/icons/microsoft-teams.svg';
import NoConnection from '../../../assets/icons/no-connection.svg';

export const NonMicrosoft = () => {
  const { prefixedT } = useCustomTranslation('screens.auth.nonMicrosoft');
  const history = useHistory();

  const clickHandler = () => history.push({ ...history.location, pathname: '/auth/signin' });

  return (
    <S.Container>
      <S.ImagesContainer>
        <S.Image src={BiteLogo} />
        <S.Image src={NoConnection} middle />
        <S.Image src={MSLogo} />
      </S.ImagesContainer>
      <S.Title>{prefixedT('title')}</S.Title>
      <S.SubTitle>{prefixedT('subTitle')}</S.SubTitle>
      <S.ButtonContainer>
        <Button onClick={clickHandler}>{prefixedT('btnText')}</Button>
      </S.ButtonContainer>
    </S.Container>
  );
};

const S = {
  Container: styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 90px 30px 30px 30px;
    align-items: center;
  `,
  Title: styled.div`
    font-size: 24px;
    font-weight: 500;
    line-height: 1.25;
    color: #475e7e;
    margin-bottom: 16px;
    text-align: center;
  `,
  SubTitle: styled.div`
    font-size: 20px;
    font-weight: 500;
    opacity: 0.8;
    line-height: 1.4;
    color: #304358;
    text-align: center;
    margin-bottom: 40px;
  `,
  ButtonContainer: styled.div`
    width: calc(100% - 100px);
    min-width: 178px;
  `,
  ImagesContainer: styled.div`
    display: flex;
    align-items: center;
    width: calc(100% - 100px);
    min-width: 178px;
    height: 64px;
    margin-bottom: 64px;
    justify-content: center;
  `,
  Image: styled.img<{ middle?: boolean }>`
    width: auto;
    height: 100%;
    ${({ middle }) =>
      middle
        ? `
            max-height: 32px;
            margin: 0 30px;
        `
        : ''}
  `,
};

import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Fabric } from '@fluentui/react/lib/Fabric';
import { useSelector } from 'react-redux';

import useHomeScreenLogic from './useHomeScreenLogic';
import Search from './components/Search';
import MyStats from './components/MyStats';

import useCustomTranslation from '../../../hooks/useCustomTranslation';
import { BiteList } from '../../components/bite';
import { PlaylistList } from '../../components/playlist';
import { bitesLoaderSelector } from '../../../store/bite/bite.selectors';
import { Loader } from '../../../components/shared';
import { playlistsLoadingSelector } from '../../../store/playlist/playlist.selectors';

const Home: FC = () => {
  const { prefixedT } = useCustomTranslation('microsoft.Home');
  const {
    bites,
    playlists,
    filteredBites,
    filteredPlaylists,
    handleBiteClick,
    handlePlaylistClick,
    setBitesFilter,
    setPlaylistFilter,
  } = useHomeScreenLogic();

  const biteLoading = useSelector(bitesLoaderSelector);
  const playlistLoading = useSelector(playlistsLoadingSelector);

  const bitesCount = bites.length;
  const playlistsCount = playlists.length;
  const commentsCount = bites.reduce((counter, currBite) => {
    return counter + currBite.comments;
  }, 0);

  const bitesFallback = 'No bites found.';
  const playlistsFallback = 'No Playlists found.';

  return (
    <Fabric>
      <S.Container>
        <S.MainContent>
          <MyStats {...{ bitesCount, commentsCount }} />

          <S.RightPanel>
            <S.SearchContainer>
              <Search setPlaylistsFilter={setPlaylistFilter} setBitesFilter={setBitesFilter} />
            </S.SearchContainer>

            <S.EntitiesContainer>
              <S.EntitySection>
                <S.SectionTitle>
                  {prefixedT('My bites')}
                  {!biteLoading && `(${bitesCount})`}
                </S.SectionTitle>
                {biteLoading ? (
                  <Loader />
                ) : filteredBites.length ? (
                  <BiteList bites={filteredBites} onBiteClick={handleBiteClick} />
                ) : (
                  bitesFallback
                )}
              </S.EntitySection>

              <S.EntitySection>
                <S.SectionTitle>
                  {prefixedT('My Playlists')} {!playlistLoading && `(${playlistsCount})`}
                </S.SectionTitle>
                {playlistLoading ? (
                  <Loader />
                ) : filteredPlaylists.length ? (
                  <PlaylistList playlists={filteredPlaylists} onPlaylistClick={handlePlaylistClick} />
                ) : (
                  playlistsFallback
                )}
              </S.EntitySection>
            </S.EntitiesContainer>
          </S.RightPanel>
        </S.MainContent>
      </S.Container>
    </Fabric>
  );
};

const S = {
  Container: styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 20px;

    * {
      border-radius: 3px;
    }
  `,

  MainContent: styled.main(
    ({ theme }) => css`
      width: 100%;
      height: 100%;
      display: flex;
      flex: 1;
      justify-content: space-between;
      overflow: scroll;
      @media ${theme.breakPoints.mobileL} {
        flex-direction: column;
        height: auto;
        padding-bottom: 25px;
      }
    `,
  ),

  RightPanel: styled.div`
    flex: 5;
    display: flex;
    flex-direction: column;
  `,

  SearchContainer: styled.div`
    height: 30px;
  `,

  EntitiesContainer: styled.div`
    flex: 1;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,

  EntitySection: styled.section`
    flex: 1;
  `,

  SectionTitle: styled.h3(
    ({ theme }) => css`
      margin-top: 32px;
      margin-bottom: 18px;
      font-size: 24px;
      font-weight: 400;
      @media ${theme.breakPoints.mobileL} {
        margin-top: 16px;
        font-size: 20px;
        font-weight: 800;
      }
    `,
  ),
};

export default Home;

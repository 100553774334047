import history from '../navigation/history';

const removeQueryParams = (params: string[]) => {
  const query = new URLSearchParams(window.location.search);

  params.forEach((param) => {
    if (!query.has(param)) {
      return;
    }

    query.delete(param);
  });

  history.replace({
    ...history.location,
    search: query.toString(),
  });
};

export default removeQueryParams;

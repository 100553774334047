import React, { FC, memo, useCallback } from 'react';
import AuthInfo from '../AuthInfo';
import { useTranslation } from 'react-i18next';
import { log } from '../../../../store/tracking/tracking.slice';
import { EAuthFlowType, EAuthFormType } from '../../auth.types';
import { setCurrentFlow, setCurrentForm } from '../../../../store/authForm/authForm.slice';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const SuccessInfo: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleContinue = useCallback(() => {
    dispatch(
      log({ event: 'SuccessInfo.handleContinue', data: { destination: EAuthFormType.FORGOT_PASSWORD_SUCCESS } }),
    );
    dispatch(setCurrentFlow(EAuthFlowType.SIGN_IN));
    dispatch(setCurrentForm(EAuthFormType.AUTH));
    history.replace({ ...history.location, pathname: '/auth/signin' });
  }, [dispatch, history]);

  return (
    <AuthInfo
      title={t('authForm.forgotPasswordForm.successTitle')}
      description={t('authForm.forgotPasswordForm.successInfo')}
      onContinue={handleContinue}
    />
  );
};

export default memo(SuccessInfo);

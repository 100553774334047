import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { useDispatch, useSelector } from 'react-redux';

import Stat from './Stat';

import { logout } from '../../../../store/auth/auth.actions';
import { authSelector } from '../../../../store/auth/auth.selectors';
import { getFullName } from '../../../../utils/auth';

interface IProps {
  bitesCount: number;
  commentsCount: number;
}

const MyStats: FC<IProps> = ({ bitesCount, commentsCount }) => {
  const dispatch = useDispatch();
  const user = useSelector(authSelector);
  const username = getFullName(user);

  return (
    <S.StatsWrapper>
      <S.StatsContainer style={{ flex: '0 1 auto', marginBottom: '20px' }}>
        <S.MyStatsHeading>{username}</S.MyStatsHeading>
      </S.StatsContainer>
      <S.StatsContainer>
        <S.MyStatsHeading>My Stats</S.MyStatsHeading>

        <S.Divider />

        <Stat.Container>
          <Stat.Number>{bitesCount || 0}</Stat.Number>
          <Stat.Text>
            Bites I <br />
            participated in
          </Stat.Text>
        </Stat.Container>

        <Stat.Container>
          <Stat.Number>{commentsCount || 0}</Stat.Number>
          <Stat.Text>Comments</Stat.Text>
        </Stat.Container>

        <S.LogoutButton onClick={() => dispatch(logout({ redirectUrl: '/auth/msteams-popup' }))}>
          Sign out
        </S.LogoutButton>
      </S.StatsContainer>
    </S.StatsWrapper>
  );
};

const StatsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StatsContainer = styled.div(
  ({ theme }) => css`
    margin-right: 20px;
    padding: 15px;
    padding-top: 20px;
    flex: 1;
    min-width: 200px;
    max-width: 300px;
    background-color: ${theme.msColors.lightBgColor};
    border: ${`1px solid ${theme.msColors.lightBorderColor}`};
    overflow: scroll;
    border-radius: 3px;
    @media ${theme.breakPoints.mobileL} {
      max-width: 100%;
      padding: 0;
      margin-right: 0;
      margin-bottom: 16px;
      display: flex;
      flex-wrap: wrap;
    }
  `,
);

const Divider = styled.div(
  ({ theme }) => css`
    margin: 12px 0;
    width: 100%;
    height: 1px;
    background-color: #ccc;
    @media ${theme.breakPoints.mobileL} {
      display: none;
    }
  `,
);

const MyStatsHeading = styled.h4(
  ({ theme }) => css`
    color: ${theme.msColors.primaryTextcolor};
    font-size: 16px;
    font-weight: 700;
    @media ${theme.breakPoints.mobileL} {
      display: none;
    }
  `,
);

const LogoutButton = styled(PrimaryButton)(
  ({ theme }) => css`
    background-color: ${theme.msColors.purple};
    border-color: ${theme.msColors.purple};
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);
    /* @media ${theme.breakPoints.mobileL} {
			display: none;
		} */
  `,
);

const LoggedInUser = styled.div(
  () => css`
    border: 1px solid #ccc;
    width: 100%;
    height: 30px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    padding: 5px;
  `,
);

const S = {
  StatsContainer,
  Divider,
  MyStatsHeading,
  LogoutButton,
  LoggedInUser,
  StatsWrapper,
};

export default MyStats;

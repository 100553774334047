import styled, { css } from 'styled-components';

const StyledTimeAgo = styled.span`
  display: block;
  font-size: 14px;
  line-height: 16px;
  opacity: 0.4;
  ${({ theme }) => css`
    color: ${theme.colors.darkPrimary};
  `}
`;

export default StyledTimeAgo;

import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
}

function MetaTitle(props: Props) {
  const { title } = props;
  const { t } = useTranslation();

  return (
    <Helmet>
      <title>
        {title} | {t('metaTitles.postfix')}
      </title>
    </Helmet>
  );
}

export default MetaTitle;

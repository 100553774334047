import React, { FC, InputHTMLAttributes, useCallback, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import ReactSelect from 'react-select';
import type { CSSObjectWithLabel, Options, StylesConfig } from 'react-select';
import { getIsRtl } from '../../../locale/i18n';
import defaultTheme from '../../../style/themes';

interface IProps extends Omit<InputHTMLAttributes<string>, 'style' | 'value' | 'onChange'> {
  value: { value: string; label: string };
  className?: string;
  label?: string;
  error?: string;
  onChange: (value: any) => void;
  options: Options<{ value: string; label: string }>;
}

const Select: FC<IProps> = ({ value, className, label, error, onChange, options }) => {
  const rtl = getIsRtl();

  const [openedMenuDirection, setOpenedMenuDirection] = useState<'top' | 'bottom' | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');

  const handleMenuClose = useCallback(() => {
    setOpenedMenuDirection(null);
  }, []);

  const styles: StylesConfig = useMemo(
    () => ({
      control: (baseStyles, state) => ({
        fontFamily: defaultTheme.fontFamilies.Arimo,
        position: 'relative',
        flexDirection: getIsRtl() ? 'row-reverse' : 'row',
        display: 'flex',
        flex: 1,
        marginLeft: -1,
        width: 'calc(100% + 2px)',
        height: 49,
        background: defaultTheme.colors.white,
        borderRadius: `${49 / 2}px`,
        borderBottomLeftRadius: state.menuIsOpen ? 0 : 25,
        borderBottomRightRadius: state.menuIsOpen ? 0 : 25,
        borderWidth: 0,
      }),
      menu: (base, state) => {
        setOpenedMenuDirection(state.placement);

        const baseStyles: CSSObjectWithLabel = {
          ...base,
          position: 'absolute',
          fontFamily: defaultTheme.fontFamilies.Arimo,
          border: `1px solid ${error ? defaultTheme.colors.pinkError : defaultTheme.colors.lightGray34}`,
          borderRadius: `${49 / 2}px`,
          overflow: 'hidden',
          background: defaultTheme.colors.white,
          zIndex: 5,
          width: '100%',
          margin: 0,
          boxShadow: 'none',
        };

        if (state.placement === 'bottom') {
          return {
            ...baseStyles,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderTopWidth: 0,
          };
        }

        return {
          ...baseStyles,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderBottomWidth: 0,
        };
      },
      singleValue: (baseStyles) => ({
        ...baseStyles,
        paddingLeft: 16,
        fontFamily: defaultTheme.fontFamilies.Arimo,
      }),
      input: (baseStyles) => ({
        ...baseStyles,
        paddingLeft: 16,
        fontFamily: defaultTheme.fontFamilies.Arimo,
      }),
      indicatorSeparator: () => ({}),
      dropdownIndicator: (base) => ({
        ...base,
        paddingRight: 16,
      }),
      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    }),
    [error],
  );

  return (
    <S.Container className={className}>
      <S.LabelWrapper rtl={rtl} isShifted={!!value || !!searchValue.length}>
        <S.Label>{label}</S.Label>
      </S.LabelWrapper>
      <S.SelectContainer isError={!!error} openedMenuDirection={openedMenuDirection}>
        <ReactSelect
          menuPortalTarget={document.body}
          isRtl={rtl}
          options={options}
          value={value}
          onChange={onChange}
          onInputChange={setSearchValue}
          styles={styles}
          placeholder={''}
          onMenuClose={handleMenuClose}
          menuPlacement={'auto'}
          minMenuHeight={300}
        />
      </S.SelectContainer>
      {!!error && <S.ErrorText>{error}</S.ErrorText>}
    </S.Container>
  );
};

const S = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    position: relative;
  `,
  SelectContainer: styled.div<{ isError?: boolean; openedMenuDirection?: 'top' | 'bottom' | null }>`
    box-sizing: border-box;
    width: 100%;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    flex-direction: ${getIsRtl() ? 'row-reverse' : 'row'};
    height: 50px;
    background: ${defaultTheme.colors.white};
    border: 1px solid ${({ isError }) => (isError ? defaultTheme.colors.pinkError : defaultTheme.colors.lightGray34)};
    border-radius: ${50 / 2}px;
    ${({ openedMenuDirection }) =>
      openedMenuDirection === 'bottom'
        ? css`
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom-width: 0;
          `
        : openedMenuDirection === 'top'
        ? css`
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-top-width: 0;
          `
        : null};

    overflow: hidden;
  `,
  LabelWrapper: styled.div<{ isShifted?: boolean; rtl?: boolean }>`
    position: absolute;
    pointer-events: none;
    z-index: 2;
    transition: all 300ms;
    top: 19px;
    ${({ rtl, isShifted }) =>
      rtl
        ? css`
            right: 22px;
            transform: translateX(${isShifted ? 3 : 0}px) translateY(${isShifted ? -25 : 0}px)
              scale(${isShifted ? 0.75 : 1});
          `
        : css`
            left: 22px;
            transform: translateX(${isShifted ? -3 : 0}px) translateY(${isShifted ? -25 : 0}px)
              scale(${isShifted ? 0.75 : 1});
          `}
    background-color: ${({ isShifted }) => (isShifted ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0)')};
  `,
  Label: styled.div<{ isShifted?: boolean }>`
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 12px;
    color: ${({ theme }) => theme.colors.gray19};
    padding: 0 5px;
    transition: all 0.3s ease;
    margin-bottom: -4px;
  `,
  ErrorText: styled.span`
    display: inline-block;
    text-align: center;
    align-self: center;
    width: 297px;
    font-size: 13px;
    line-height: 15px;
    color: ${({ theme }) => theme.colors.pinkError};
  `,
};

export default Select;

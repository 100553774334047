/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, FC } from 'react';
import styled, { css } from 'styled-components';

import Input, { BaseInput } from './Input';

import useCustomTranslation from '../../../hooks/useCustomTranslation';

type IProps = React.HTMLProps<HTMLInputElement>;

const PasswordInput: FC<IProps> = ({ style: containerStyle, ...inputProps }) => {
  const { prefixedT } = useCustomTranslation('components.PasswordInput');
  const [isVisible, toggleIsVisible] = useState(false);
  const handleToggleClick = () => toggleIsVisible((prevIsVisible) => !prevIsVisible);

  const toggleButtonText = useMemo(() => (isVisible ? prefixedT('HIDE') : prefixedT('SHOW')), [isVisible]);

  return (
    <S.InputSlate as='div' style={containerStyle}>
      <S.Input {...inputProps} type={isVisible ? 'text' : 'password'} />
      <S.HideShowController onClick={handleToggleClick}>{toggleButtonText}</S.HideShowController>
    </S.InputSlate>
  );
};

const S: any = {};

S.InputSlate = styled(Input)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

S.InputWrapper = styled.div`
  flex: 1;
  width: 200px;
  background-color: red;
`;

S.Input = styled(BaseInput)<{ type: string }>`
  flex: 1;
  font-size: 18px;
  line-height: 21px;
  text-align: left;
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    color: ${theme.colors.darkPrimary};
  `};

  ${({ type }) => type === 'password' && css``}
`;

S.HideShowController = styled.button`
  font-size: 14px;
  line-height: 17px;
  opacity: 0.4;

  margin-left: 15px;

  ${({ theme }) => css`
    color: ${theme.colors.darkPrimary};
  `}
`;

export default PasswordInput;

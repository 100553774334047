import bitesCrmTrack from '../bitesCrm';
import { datadogLogger } from '../datadog/datadog';

const log = async (data) => {
  data = { ...data };
  try {
    datadogLogger.info(data);
  } catch (error) {
    //pass
  }

  bitesCrmTrack({ ...data, logType: 'log' });
};
export default log;

export const logError = async (data) => {
  data = { ...data };
  try {
    datadogLogger.error(data);
  } catch (error) {
    //pass
  }

  bitesCrmTrack({ ...data, logType: 'error' });
};

import { useTranslation } from 'react-i18next';
import React from 'react';
import styled from 'styled-components';
import { getIsRtl } from '../../../locale/i18n';
import { noAnswerCy } from '../aiPrompt.constants';

const EmptyAnswer = () => {
  const isRtl = getIsRtl();
  const { t } = useTranslation();

  return (
    <S.Container data-cy={noAnswerCy}>
      <S.Bold>{t('knowledge.emptyAnswer.thereAreNoResults')}</S.Bold>
      <S.Paragraph>
        {t('knowledge.emptyAnswer.imStillLearning')}
        {'\n'}
        {t('knowledge.emptyAnswer.cantFindRelevantContent')}
        {'\n'}
        {t('knowledge.emptyAnswer.youCanTryTo')}
      </S.Paragraph>
      <S.List isRtl={isRtl}>
        <S.ListItem>
          <S.Bold>{t('knowledge.emptyAnswer.rephrasing')}</S.Bold>: {t('knowledge.emptyAnswer.useDifferentWords')}
        </S.ListItem>
        <S.ListItem>
          <S.Bold>{t('knowledge.emptyAnswer.expanding')}</S.Bold>: {t('knowledge.emptyAnswer.provideMoreContext')}
        </S.ListItem>
        <S.ListItem>
          {t('knowledge.emptyAnswer.clicking')}
          <S.Bold> {t('knowledge.emptyAnswer.retry')}</S.Bold>: {t('knowledge.emptyAnswer.sometimesRefreshingHelps')}
        </S.ListItem>
        <S.ListItem>{t('knowledge.emptyAnswer.ps')}</S.ListItem>
      </S.List>
      <S.Paragraph>{t('knowledge.emptyAnswer.ifYoureStillStuck')}</S.Paragraph>
    </S.Container>
  );
};

const S = {
  Container: styled.span``,
  Bold: styled.span`
    font-weight: 700;
  `,
  Paragraph: styled.p`
    display: block;
    margin-top: 28px;
  `,
  List: styled.ul<{ isRtl: boolean }>`
    margin-top: 14px;
    list-style-type: disc;
    ${({ isRtl }) => (isRtl ? 'padding-right: 20px;' : 'padding-left: 20px;')}
  `,
  ListItem: styled.li`
    margin-bottom: 10px;
  `,
};

export default EmptyAnswer;

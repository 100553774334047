import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as CheckmarkSVG } from '../../assets/icons/check-mark.svg';
interface IProps {
  isChecked: boolean;
  onClick?: () => void;
}

const Checkbox: FC<IProps> = ({ isChecked, onClick, ...restOfProps }) => {
  return (
    <S.Checkbox isChecked={isChecked} onClick={onClick} {...restOfProps}>
      {isChecked && <S.Checkmark />}
    </S.Checkbox>
  );
};

const S = {
  Checkbox: styled.div<{ isChecked: boolean }>`
    display: inline-block;
    width: 19px;
    height: 19px;
    border-radius: 3px;
    ${({ theme, isChecked }) => css`
      border: 1.5px solid ${theme.colors.primaryBlue};

      ${isChecked &&
      css`
        background-color: ${theme.colors.primaryBlue};
      `}
    `}
  `,

  Checkmark: styled(CheckmarkSVG)`
    width: 15px;
    height: auto;
    fill: white;
  `,
};

export default Checkbox;

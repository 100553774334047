import styled, { css } from 'styled-components';

const TopInstructions = styled.div`
  text-align: center;
  opacity: 0.8;
  line-height: 24px;
  ${({ theme }) => css`
    color: ${theme.colors.darkPrimary};
  `}
`;

export default TopInstructions;

import React, { CSSProperties, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import { getIsRtl } from '../../../locale/i18n';

export const BaseInput = styled.input`
  text-align: left;
  font-size: 18px;
  line-height: 21px;
  ${({ theme }) => css`
    color: ${theme.colors.darkPrimary};
  `};
`;

const InputComponent = ({ style = {}, ...props }) => {
  const [type, setType] = useState<string>(props.type || 'text');

  const inputStyles: CSSProperties = useMemo(
    () => ({
      textAlign: getIsRtl() ? 'right' : 'left',
      ...style,
    }),
    [style],
  );

  const handleSwichType = () => {
    setType(type === 'text' ? 'password' : 'text');
  };

  return (
    <S.InputContainer>
      <S.Input
        className={props.type === 'password' ? 'pass' : ''}
        {...props}
        disabled={props.disabled}
        type={type}
        style={inputStyles}
      />
      {props.type === 'password' && (
        <S.InputControl onClick={handleSwichType}>{type === 'text' ? 'hide' : 'show'}</S.InputControl>
      )}
    </S.InputContainer>
  );
};

const S = {
  Input: styled(BaseInput)<{ disabled: boolean }>`
    width: 100%;
    border-radius: 15px;
    padding: 20px 25px;
    ${({ theme, disabled }) => css`
      background-color: ${theme.colors.white};
      ${disabled && `color: ${theme.colors.lightGray8}`}
    `};
    box-shadow: 0 12px 19px 0 rgba(60, 128, 209, 0.09);
    &.pass {
      padding-right: 70px;
      ${() =>
        getIsRtl() &&
        `
			padding-right: 25px;
			padding-left: 70px;
		`};
    }
  `,
  InputContainer: styled.div`
    position: relative;
  `,
  InputControl: styled.span`
    right: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    text-transform: uppercase;
    opacity: 0.4;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.88px;
    color: #344356;
    ${() =>
      getIsRtl() &&
      `
			right: auto;
			left: 20px;
		`};
  `,
};

export default InputComponent;

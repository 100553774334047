let localStorage: {
  getItem: (key: string) => string | null;
  setItem: (key: string, value: string) => void;
  removeItem: (key: string) => void;
  clear: () => void;
};
try {
  window.localStorage.getItem('test');
  localStorage = window.localStorage;
} catch (e) {
  // eslint-disable-next-line no-console
  console.log('localStorage is not available');
  localStorage = {
    getItem: () => null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setItem: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    removeItem: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    clear: () => {},
  };
}
export default localStorage;

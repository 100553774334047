import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsAlwaysTranslate } from '../store/bite/bite.actions';
import { alwaysTranslateToLocaleSelector } from '../store/bite/bite.selectors';
import localStorage from '../utils/localStorage';
import { useGetLocaleLabel } from './useTranslationOptions';
import gtmTrack from '../services/googleTagManager/track';

export interface ILocale {
  locale: string;
  label: string;
  googleTranslate?: boolean;
}

const LOCAL_STORAGE_LOCALE_KEY = 'alwaysTranslateToLocale';
const LOCAL_STORAGE_LOCALE_LABEL_KEY = 'alwaysTranslateToLocaleLabel';

const useAlwaysTranslate = () => {
  const dispatch = useDispatch();

  const getLocaleLabel = useGetLocaleLabel();

  const { initialIsAlwaysTranslate, isAlwaysTranslate, initialLocale, initialLocaleLabel, isInitialTranslationDone } =
    useSelector(alwaysTranslateToLocaleSelector);

  const toggleAlwaysTranslate = useCallback(
    async (selectedLocale: string) => {
      const newIsAlwaysTranslate = !isAlwaysTranslate;
      const label = getLocaleLabel(selectedLocale);

      if (newIsAlwaysTranslate) {
        localStorage.setItem(LOCAL_STORAGE_LOCALE_KEY, selectedLocale);
        localStorage.setItem(LOCAL_STORAGE_LOCALE_LABEL_KEY, label);

        gtmTrack('always_translate', { on: true });

        dispatch(setIsAlwaysTranslate(true));
        return;
      }

      localStorage.removeItem(LOCAL_STORAGE_LOCALE_KEY);
      localStorage.removeItem(LOCAL_STORAGE_LOCALE_LABEL_KEY);

      gtmTrack('always_translate', { on: false });
      dispatch(setIsAlwaysTranslate(false));
    },
    [dispatch, getLocaleLabel, isAlwaysTranslate],
  );

  const enableAlwaysTranslate = useCallback(
    (selectedLocale: string) => {
      const label = getLocaleLabel(selectedLocale);

      localStorage.setItem(LOCAL_STORAGE_LOCALE_KEY, selectedLocale);
      localStorage.setItem(LOCAL_STORAGE_LOCALE_LABEL_KEY, label);

      dispatch(setIsAlwaysTranslate(true));
      gtmTrack('always_translate', { on: true });
    },
    [dispatch, getLocaleLabel],
  );

  const disableAlwaysTranslate = useCallback(async () => {
    localStorage.removeItem(LOCAL_STORAGE_LOCALE_KEY);
    localStorage.removeItem(LOCAL_STORAGE_LOCALE_LABEL_KEY);

    dispatch(setIsAlwaysTranslate(false));
    gtmTrack('always_translate', { on: false });
  }, [dispatch]);

  return {
    initialIsAlwaysTranslate,
    initialLocale,
    initialLocaleLabel,
    isAlwaysTranslate,
    isInitialTranslationDone,
    toggleAlwaysTranslate,
    enableAlwaysTranslate,
    disableAlwaysTranslate,
  };
};

export default useAlwaysTranslate;

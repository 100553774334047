import { css } from 'styled-components';

const horizontalSpace = () => css`
  padding-left: 30px;
  padding-right: 30px;

  /* width: 100%;
	max-width: 315px;
	margin: 0 auto; */
`;

export default horizontalSpace;

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'react-simple-snackbar';
import { useRouteMatch } from 'react-router-dom';

import useIsUserAnsweredQuestion from './useIsUserAnsweredQuestion';
import useCustomHistory from './useCustomHistory';

import { colors } from '../style/themes/defaultTheme';
import { sectionsOrder } from '../utils/bite';
import { useCallback } from 'react';

export default function useRedirectToBiteSection(selectedBite: any, biteShareUser: any) {
  const { t } = useTranslation();

  const [openSnackbar] = useSnackbar({
    style: {
      bottom: '0',
      background: colors.primaryBlue,
      color: '#fff',
      textAlign: 'center',
      fontSize: '14px',
      borderRadius: '20px 20px 0 0',
      padding: '0 56px',
    },
    closeStyle: {
      position: 'absolute',
      right: '0',
      top: '7px',
    },
  });
  const isQuestionAnswerd = useIsUserAnsweredQuestion(selectedBite);
  const { historyPushWithPersistedQueryParams } = useCustomHistory();
  const match = useRouteMatch();

  const redirect = useCallback(
    (requestedSectionName: string) => {
      if (requestedSectionName === null) {
        return;
      }
      const isIntroSection = selectedBite.bite_sections.find((sec) => sec.type === 'intro');
      const isIntroVideo = isIntroSection?.media && isIntroSection?.media?.file_type !== 'image';
      const watchedFully = biteShareUser.video_progress === 100;

      const canSkipIntro =
        biteShareUser.bite_progress === 'intro' &&
        isIntroSection?.media?.file_type === 'image' &&
        (requestedSectionName === 'question' || isQuestionAnswerd);

      if (isIntroVideo && !canSkipIntro && !watchedFully && !selectedBite.skip_able) {
        openSnackbar(t('screens.viewBite.IntroCompletionSnackbar'));
        return;
      }

      if (
        canSkipIntro ||
        selectedBite.skip_able ||
        sectionsOrder[requestedSectionName] <= sectionsOrder[biteShareUser.bite_progress] ||
        ((requestedSectionName === 'discussion' || requestedSectionName === 'content') &&
          biteShareUser.bite_progress === 'summary')
      ) {
        historyPushWithPersistedQueryParams(`${match.url}/${requestedSectionName}`);
      }
    },
    [
      biteShareUser?.bite_progress,
      biteShareUser?.video_progress,
      historyPushWithPersistedQueryParams,
      isQuestionAnswerd,
      match.url,
      openSnackbar,
      selectedBite?.bite_sections,
      selectedBite?.skip_able,
      t,
    ],
  );

  return redirect;
}

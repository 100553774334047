import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Lightbox from 'react-image-lightbox';

import VideoModal from './VideoModal';

import { ReactComponent as PlayIcon } from '../../../assets/icons/triangle.svg';
import { playQuizIntroDataSet } from './mediaViewer.constants';

interface Props {
  media_type: string;
  media_url: string;
  image_url?: string;
  cover_url?: string;
}

function MediaViewer(props: Props) {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const { media_type, media_url, image_url, cover_url } = props;

  if (!media_url) {
    return null;
  }

  const getImgSrc = () => {
    if (media_type === 'image') {
      return image_url;
    }
    if (media_type === 'youtube') {
      const id = media_url.split('?v=')[1];
      return `https://img.youtube.com/vi/${id}/0.jpg`;
    }
    return cover_url;
  };

  const handleImageClick = () => {
    if (media_type === 'image') {
      setIsLightboxOpen(true);
    }
  };

  return (
    <S.Container>
      <S.Image src={getImgSrc()} onClick={handleImageClick} />
      {media_type !== 'image' && (
        <S.PlayButton onClick={() => setIsVideoModalOpen(true)} data-cy={playQuizIntroDataSet.cy}>
          <S.PlayIcon />
        </S.PlayButton>
      )}
      {isVideoModalOpen && (
        <VideoModal onCloseModal={() => setIsVideoModalOpen(false)} media_url={media_url} file_type={media_type} />
      )}
      {isLightboxOpen && (
        <Lightbox
          mainSrc={image_url}
          onCloseRequest={() => {
            setIsLightboxOpen(false);
          }}
        />
      )}
    </S.Container>
  );
}

const S = {
  Container: styled.div`
    margin: 0 auto 16px;
    width: 265px;
    height: 260px;
    padding: 14px 16px 13px;
    background: white;
    border-radius: 19px;
    box-shadow: 0 7px 5px 0 rgba(0, 0, 0, 0.3);
    border: solid 0.3px #6f6f6e;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  Image: styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 19px;
  `,
  PlayButton: styled.div`
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ theme }) => css`
      border: 2px solid ${theme.colors.white};
    `};
    position: absolute;
  `,
  PlayIcon: styled(PlayIcon)`
    width: 20px;
    transform: translateX(10%);
    transform-origin: left;
    ${({ theme }) => css`
      fill: ${theme.colors.white};
    `};
  `,
};

export default MediaViewer;

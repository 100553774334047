import React, { FC, useMemo } from 'react';
import styled from 'styled-components';

import StyledIndicatorItem from './StyledIndicatorItem';

import { getIsRtl } from '../../../../locale/i18n';

interface IProps {
  currentIndex: number;
  totalLength: number;
  onChangeIndex: (index: number) => any;
}

const IndicatorList: FC<IProps> = ({ currentIndex, totalLength, onChangeIndex }) => {
  const indicatorItems = useMemo(() => {
    const result = [];
    for (let i = 0; i < totalLength; i++) {
      result.push(
        <StyledIndicatorItem
          data-test={`indicator-${i}`}
          isActive={i === currentIndex}
          onClick={() => onChangeIndex(i)}
          key={i}
          id='summaryIndicatorItem'
        />,
      );
    }
    return result;
  }, [currentIndex, totalLength, onChangeIndex]);

  return <S.Container>{indicatorItems}</S.Container>;
};

const S: any = {};
S.Container = styled.div`
  display: flex;
  justify-content: center;
  ${() =>
    getIsRtl() &&
    `
			direction: rtl;
            writing-mode: horizontal-tb;
		`};

  ${StyledIndicatorItem} {
    margin: 0 5px;
    cursor: pointer;
  }
`;

export default IndicatorList;
